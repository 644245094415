import { SyntheticEvent, useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router';

import { Shipment, shipmentStates } from 'models/shipment';
import { DrumStates } from 'models/drum';
import { PileStates } from 'models/pile';
import type { User } from 'models/user';
import type { Drum } from 'models/drum';
import type { Pile } from 'models/pile';
import { shipmentsService } from 'services/shipmentsService';
import { drumsService } from 'services/drumsService';
import { permissionsService } from 'services/permissionsService';
import { useAccessToken, useLoggedInUser, useAppNavigation, useServerError } from 'hooks';

import { s } from 'i18n/strings';

import { Chip, IconButton, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';

import {
  backgroundColor,
  display,
  fontStyle,
  justifyContent,
  onlyComputedCombineClassnames,
  textColor,
  typedUtilityClassnames,
} from 'style/compoundClassnames';

import { PrimaryButton, SecondaryButton } from 'components/buttons';
import { ContextHeader } from 'components/headers';
import { RightDrawer } from 'components/drawers';
import { ActionConfirmationDialog } from 'components/dialogs';
import { Spinner } from 'components/Spinner';
import { Notification } from 'components/Notification';
import { AddDrumDrawerFormStageManager } from 'components/AddDrumDrawerFormStageManager';

import { ReactComponent as TrashIcon } from 'icons/trash.svg';

const tempDrumReferenceNumberPrefix = 'DRUM/';

const emptyDrumsMessageClassNames = typedUtilityClassnames('body1', display('flex'), justifyContent('justify-center'));
const drumStateIndicatorClassNames = typedUtilityClassnames('body2', backgroundColor('bg-normal-50'), textColor('text-black'));
const getDrumReferenceNumberClassNames = (drumReferenceNumber: string): string | undefined =>
  onlyComputedCombineClassnames({
    [textColor('text-onSurface-mediumEmphasis')]: drumReferenceNumber.startsWith(tempDrumReferenceNumberPrefix),
    [fontStyle('italic')]: drumReferenceNumber.startsWith(tempDrumReferenceNumberPrefix),
  });

interface DrumsTableProps {
  drums: Drum[];
  onSelected: (id: number) => void;
  canDelete: boolean;
  onDeleted: (event: SyntheticEvent, id: number) => void;
  canInteract: boolean;
}

const DrumsTable = ({ drums, onSelected, canDelete, onDeleted, canInteract }: DrumsTableProps): JSX.Element => {
  return (
    <TableContainer>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell />
            <TableCell className={typedUtilityClassnames('label')} align="left">
              {s.DrumProcessingPage_ReferenceNumberTableColumnTitle}
            </TableCell>
            <TableCell className={typedUtilityClassnames('label')} align="left">
              {s.DrumProcessingPage_RobinsonSealNumberTableColumnTitle}
            </TableCell>
            <TableCell className={typedUtilityClassnames('label')} align="left">
              {s.DrumProcessingPage_CoprocoSealNumberTableColumnTitle}
            </TableCell>
            <TableCell className={typedUtilityClassnames('label')} align="right">
              {s.DrumProcessingPage_GrossWeightTableColumnTitle}
            </TableCell>
            <TableCell className={typedUtilityClassnames('label')} align="right">
              {s.DrumProcessingPage_NetWeightTableColumnTitle}
            </TableCell>
            <TableCell />
            <TableCell />
          </TableRow>
        </TableHead>
        <TableBody className={typedUtilityClassnames('body1')}>
          {drums.map((drum) => (
            <TableRow key={drum.id} hover={canInteract} onClick={() => canInteract && onSelected(drum.id)}>
              <TableCell align="left" width="10">
                <Chip label={drum.state.name} className={drumStateIndicatorClassNames} />
              </TableCell>
              <TableCell align="left" width="15%">
                <span className={getDrumReferenceNumberClassNames(drum.referenceNumber)}>{drum.referenceNumber}</span>
              </TableCell>
              <TableCell align="left" width="15%">
                {drum.robinsonSealNumber}
              </TableCell>
              <TableCell align="left" width="15%">
                {drum.coprocoSealNumber}
              </TableCell>
              <TableCell align="right" width="15%">
                {drum.grossWeightInKg}
              </TableCell>
              <TableCell align="right" width="15%">
                {drum.netWeightInKg}
              </TableCell>
              <TableCell width="*" />
              <TableCell align="right" width="10" sx={{ mx: '0.5rem' }}>
                {canDelete && (
                  <IconButton onClick={(event) => onDeleted(event, drum.id)} disabled={!canInteract}>
                    <TrashIcon />
                  </IconButton>
                )}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

const anyDrums = (drums: Drum[] | null) => drums != null && drums.length > 0;

const drumsProcessingFinished = (shipment: Shipment | null, drums: Drum[] | null) =>
  (shipment != null && shipment.state.name === shipmentStates.DrumsProcessed) ||
  (drums != null && drums.length > 0 && drums?.every((d) => d.state.name === DrumStates.Loaded));

const canAddDrum = (user: User | null, shipment: Shipment | null, drums: Drum[] | null) =>
  user != null && permissionsService.canAddDrum(user) && !drumsProcessingFinished(shipment, drums);

const canEditDrum = (user: User | null, shipment: Shipment | null, drums: Drum[] | null) =>
  user != null && permissionsService.canEditDrum(user) && !drumsProcessingFinished(shipment, drums);

const canDeleteDrum = (user: User | null, shipment: Shipment | null, drums: Drum[] | null) =>
  user != null && permissionsService.canDeleteDrum(user) && !drumsProcessingFinished(shipment, drums);

const canFinishDrumProcessing = (user: User | null, shipment: Shipment | null, drums: Drum[] | null) =>
  user != null && permissionsService.canEditDrum(user) && anyDrums(drums) && !drumsProcessingFinished(shipment, drums);

export const DrumProcessingPage = () => {
  const { shipmentId } = useParams<{ shipmentId: string }>();
  const accessToken = useAccessToken();
  const user = useLoggedInUser();

  const { navigateViewShipment, navigateShipments } = useAppNavigation();
  const { handleServerError } = useServerError();

  const [shipment, setShipment] = useState<Shipment | null>(null);
  const [piles, setPiles] = useState<Pile[] | null>(null);
  const [drums, setDrums] = useState<Drum[] | null>(null);
  const [selectedDrum, setSelectedDrum] = useState<Drum | null>(null);

  const [isLoading, setIsLoading] = useState(true);
  const [isActionInProgress, setIsActionInProgress] = useState(false);
  const [error, setError] = useState<string | null>(null);

  const [drawerOpen, setDrawerOpen] = useState<boolean>(false);

  const [openConfirmationDialog, setOpenConfirmationDialog] = useState(false);
  const handleOpenConfirmationDialog = () => setOpenConfirmationDialog(true);
  const handleCloseConfirmationDialog = () => setOpenConfirmationDialog(false);

  useEffect(() => {
    (async () => {
      try {
        setError(null);
        setIsLoading(true);
        // TODO: Refactor out accessToken null checks to shared API client class that will throw if null for any call, then remove this from every component
        if (!accessToken || !shipmentId) {
          return;
        }

        const shipmentRequest = shipmentsService.getById(accessToken, parseInt(shipmentId));
        const pilesRequest = shipmentsService.getPiles(accessToken, parseInt(shipmentId));
        const drumsRequest = shipmentsService.getDrums(accessToken, parseInt(shipmentId));

        const [shipment, piles, drums] = await Promise.all([shipmentRequest, pilesRequest, drumsRequest]);

        setShipment(shipment);
        setPiles(piles);
        setDrums(drums);
      } catch (error) {
        const errorMessage = handleServerError(error);
        setError(`${s.DrumProcessingPage_CouldNotGetDrums_ErrorMessage}: ${errorMessage}`);
      } finally {
        setIsLoading(false);
      }
    })();
  }, [accessToken, handleServerError, shipmentId]);

  const refreshData = useCallback(async () => {
    setError(null);
    if (accessToken && shipmentId) {
      try {
        const pilesRequest = shipmentsService.getPiles(accessToken, parseInt(shipmentId));
        const drumsRequest = shipmentsService.getDrums(accessToken, parseInt(shipmentId));
        const [piles, drums] = await Promise.all([pilesRequest, drumsRequest]);
        setPiles(piles);
        setDrums(drums);
      } catch (error) {
        const errorMessage = handleServerError(error);
        setError(`${s.DrumProcessingPage_CouldNotGetDrums_ErrorMessage}: ${errorMessage}`);
      }
    }
  }, [accessToken, handleServerError, shipmentId]);

  const handleSave = async () => {
    setDrawerOpen(false);
    await refreshData();
  };

  const handleSaveError = async (error: unknown) => {
    setDrawerOpen(false);
    const errorMessage = handleServerError(error);
    setError(`${s.DrumProcessingPage_CouldNotCreateOrUpdateDrum_ErrorMessage}: ${errorMessage}`);
  };

  const handleClose = () => {
    setDrawerOpen(false);
  };

  const handleBack = () => {
    shipmentId ? navigateViewShipment(shipmentId) : navigateShipments();
  };

  const handleAddDrum = () => {
    if (canAddDrum(user, shipment, drums)) {
      setSelectedDrum(null);
      setDrawerOpen(true);
    }
  };

  const handleFinishDrumProcessing = async () => {
    try {
      if (canFinishDrumProcessing(user, shipment, drums)) {
        if (accessToken && shipmentId) {
          await shipmentsService.finishDrumsProcessing(accessToken, parseInt(shipmentId));
          handleCloseConfirmationDialog();
          navigateViewShipment(shipmentId);
        }
      }
    } catch (error) {
      handleCloseConfirmationDialog();
      const errorMessage = handleServerError(error);
      setError(`${s.DrumProcessingPage_CouldNotFinishDrumsProcessing_ErrorMessage}: ${errorMessage}`);
    }
  };

  const handleDrumSelected = (drumId: number | undefined) => {
    if (drums && drumId) {
      const drum = drums.find((d) => d.id === drumId);
      drum && setSelectedDrum(drum);
      setDrawerOpen(true);
    }
  };

  const handleDeleteDrum = async (event: SyntheticEvent, drumId: number | undefined) => {
    event.stopPropagation();

    try {
      // TODO: Refactor out accessToken null checks to shared API client class that will throw if null for any call, then remove this from every component
      if (accessToken && drumId && canDeleteDrum(user, shipment, drums)) {
        setIsActionInProgress(true);
        await drumsService.delete(accessToken, drumId); // TODO: consider removing drum photos from cache (if added)
        await refreshData();
      }
    } catch (error) {
      const errorMessage = handleServerError(error);
      setError(`${s.DrumProcessingPage_CouldNotDeleteDrum_ErrorMessage}: ${errorMessage}`);
    } finally {
      setIsActionInProgress(false);
    }
  };

  return (
    <>
      {isLoading ? (
        <Spinner />
      ) : (
        <>
          <ContextHeader
            contextTitle={s.DrumProcessingPage_Title}
            contextSubTitle={shipment?.shipmentReference}
            leftButtonOne={<SecondaryButton label={s.DrumProcessingPage_BackButton} onClick={handleBack} />}
            rightButtonOne={
              <SecondaryButton
                label={s.DrumProcessingPage_AddButton}
                onClick={handleAddDrum}
                disabled={!canAddDrum(user, shipment, drums)}
              />
            }
            rightButtonTwo={
              <PrimaryButton
                label={s.DrumProcessingPage_FinishButton}
                onClick={handleOpenConfirmationDialog}
                disabled={!canFinishDrumProcessing(user, shipment, drums)}
              />
            }
            showContextHeaderContentSpacer
          />
          <div className={onlyComputedCombineClassnames(typedUtilityClassnames('mainLayoutPadding'))}>
            <Notification message={error!} severity="error" onClose={() => setError(null)} />
            {drums && drums.length > 0 && user ? (
              <DrumsTable
                drums={drums}
                onSelected={handleDrumSelected}
                canDelete={canDeleteDrum(user, shipment, drums)}
                onDeleted={handleDeleteDrum}
                canInteract={!isActionInProgress}
              />
            ) : (
              <div className={emptyDrumsMessageClassNames}>{s.DrumProcessingPage_WillAppearHere}</div>
            )}
          </div>
        </>
      )}

      {shipmentId && piles && (
        <RightDrawer open={drawerOpen}>
          <AddDrumDrawerFormStageManager
            shipmentId={shipmentId}
            piles={piles}
            drum={selectedDrum}
            onSaveSuccess={handleSave}
            onSaveFailed={handleSaveError}
            onClose={handleClose}
            canEdit={selectedDrum != null ? canEditDrum(user, shipment, drums) : canAddDrum(user, shipment, drums)}
          />
        </RightDrawer>
      )}

      <ActionConfirmationDialog
        open={openConfirmationDialog}
        title={s.DrumProcessingPage_ConfirmationDialogTitle}
        items={[
          {
            label: s.DrumProcessingPage_ConfirmationDialogDrumsProcessedItemLabel,
            successCondition:
              drums != null &&
              drums.length > 0 &&
              drums.every((d) => d.state.name === DrumStates.Sealed && !d.referenceNumber.startsWith(tempDrumReferenceNumberPrefix)),
            successMessage: `${drums?.length} drum${drums?.length === 1 ? '' : 's'}`,
            errorMessage: s.DrumProcessingPage_ConfirmationDialogDrumsProcessedErrorMessage,
          },
          {
            label: s.DrumProcessingPage_ConfirmationDialogPilesConsumedItemLabel,
            successCondition: piles != null && piles.length > 0 && piles.every((p) => p.state.name === PileStates.Consumed),
            successMessage: s.DrumProcessingPage_ConfirmationDialogPilesConsumedSuccessMessage,
            errorMessage: s.DrumProcessingPage_ConfirmationDialogPilesConsumedErrorMessage,
          },
        ]}
        confirmButtonCaption={s.DrumProcessingPage_ConfirmationDialogFinishButtonCaption}
        handleConfirm={handleFinishDrumProcessing}
        cancelButtonCaption={s.DrumProcessingPage_ConfirmationDialogCancelButtonCaption}
        handleCancel={handleCloseConfirmationDialog}
      />
    </>
  );
};
