import {
  alignItems,
  display,
  inset,
  padding,
  textAlign,
  textDecoration,
  textDecorationThickness,
  typedUtilityClassnames,
  textUnderlineOffset,
  textColor,
  backgroundColor,
} from 'style/compoundClassnames';
import { NavLink } from 'react-router-dom';

import { _DropMenuBase } from 'components/drop-menus/_base/_DropMenuBase';
import { ReactComponent as MenuLogo } from 'icons/menu.svg';

import { s } from 'i18n/strings';
import type { PathInfo } from 'navigation/paths';
import type { ExclusivelyStringKeyedRecordNonOptional } from 'types/utility-types';

export interface NavigationDropMenuProps<T extends Readonly<ExclusivelyStringKeyedRecordNonOptional<T, string>>> {
  paths: PathInfo<T>[];
  logoButtonClassNames: string;
}

const individualItemsClassNames = typedUtilityClassnames(
  textColor('text-primary-900'),
  textAlign('text-left'),
  'navBlockFont',
  padding('py-4', 'px-8'),
  'overlayBlackStates',
);

const activeNavLinkClassNames = typedUtilityClassnames(
  textDecoration('underline'),
  textUnderlineOffset('underline-offset-4'),
  textDecorationThickness('decoration-from-font'),
);

const activeItemClassNames = typedUtilityClassnames(backgroundColor('bg-overlayBlack-focused'));

const getNavigationMenuPathItem = <T extends Readonly<ExclusivelyStringKeyedRecordNonOptional<T, string>>>({
  path,
  key,
  pageDisplayName,
}: PathInfo<T>) => {
  const NavigationMenuPathItem = () => (
    <NavLink key={key} className={individualItemsClassNames} to={path}>
      {({ isActive }) => (!isActive ? pageDisplayName : <span className={activeNavLinkClassNames}>{pageDisplayName}</span>)}
    </NavLink>
  );
  return NavigationMenuPathItem;
};

const topLevelMenuElementForBaseClassNames = typedUtilityClassnames(display('flex'), alignItems('items-center'));

const itemsContainerClassNames = typedUtilityClassnames(inset('top-full', 'left-0.5'), padding('py-2'), display('md:hidden'));

export const NavigationDropMenu = <T extends Readonly<ExclusivelyStringKeyedRecordNonOptional<T, string>>>({
  paths,
  logoButtonClassNames,
}: NavigationDropMenuProps<T>): JSX.Element => {
  return (
    <_DropMenuBase
      buttonContents={<MenuLogo title={s.NavigationDropMenu_MenuButtonAriaLabel} className={logoButtonClassNames} />}
      buttonAriaLabel={s.NavigationDropMenu_MenuButtonAriaLabel}
      topLevelMenuClassNames={topLevelMenuElementForBaseClassNames}
      itemsContainerClassNames={itemsContainerClassNames}
      activeItemClassNames={activeItemClassNames}
    >
      {paths.map((path) => getNavigationMenuPathItem(path))}
    </_DropMenuBase>
  );
};
