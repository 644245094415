export class User {
  // eslint-disable-next-line no-useless-constructor
  constructor(public id: string, public fullName: string, public emailAddress: string, public roles: UserRole[] = []) {}
}

export class UserRole {
  // eslint-disable-next-line no-useless-constructor
  constructor(public id: number, public name: string) {}
}

export const UserRoles = {
  GlobalAdministrator: 'Global Administrator',
  OfficeAdministrator: 'Office Administrator',
  YardForeman: 'Yard Foreman',
  DrumInspector: 'Drum Inspector',
  WarehouseForeman: 'Warehouse Foreman',
} as const;
