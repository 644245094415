import { useState, useEffect, useCallback, SyntheticEvent } from 'react';
import { useParams } from 'react-router';

import { Truck, TruckStates } from 'models/truck';
import { Bag } from 'models/bag';
import { Shipment } from 'models/shipment';
import { User } from 'models/user';
import { permissionsService } from 'services/permissionsService';
import { shipmentsService } from 'services/shipmentsService';
import { truckService } from 'services/truckService';
import { bagsService } from 'services/bagsService';
import { useAccessToken, useLoggedInUser, useAppNavigation, useServerError } from 'hooks';

import { s } from 'i18n/strings';

import { IconButton, Table, TableHead, TableBody, TableCell, TableContainer, TableRow, Chip } from '@mui/material';

import {
  backgroundColor,
  display,
  justifyContent,
  onlyComputedCombineClassnames,
  textColor,
  typedUtilityClassnames,
} from 'style/compoundClassnames';

import { ContextHeader } from 'components/headers';
import { PrimaryButton, SecondaryButton } from 'components/buttons';
import { RightDrawer } from 'components/drawers';
import { BagDrawerForm } from 'components/drawer-forms';
import { Spinner } from 'components/Spinner';
import { ActionConfirmationDialog } from 'components/dialogs';
import { Notification } from 'components/Notification';

import { ReactComponent as TrashIcon } from 'icons/trash.svg';

const emptyBagsMessageClassNames = typedUtilityClassnames('body1', display('flex'), justifyContent('justify-center'));
const bagStateIndicatorClassNames = typedUtilityClassnames('body2', backgroundColor('bg-normal-50'), textColor('text-black'));

const canAddBag = (user: User | null, truck: Truck | null) =>
  user != null && permissionsService.canAddBag(user) && truck != null && truck.state.name === TruckStates.Arrived;

const canEditBag = (user: User | null, truck: Truck | null) =>
  user != null && permissionsService.canEditBag(user) && truck != null && truck.state.name === TruckStates.Arrived;

const canDeleteBag = (user: User | null, truck: Truck | null) =>
  user != null && permissionsService.canDeleteBag(user) && truck != null && truck.state.name === TruckStates.Arrived;

const canFinishWeighingBags = (user: User | null, truck: Truck | null, bags: Bag[] | null) =>
  user != null &&
  permissionsService.canEditBag(user) &&
  truck != null &&
  truck.state.name === TruckStates.Arrived &&
  bags != null &&
  bags.length > 0;

interface BagsTableProps {
  bags: Bag[];
  onSelected: (id: number) => void;
  canDelete: boolean;
  onDeleted: (event: SyntheticEvent, id: number) => void;
  canInteract: boolean;
}

const BagsTable = ({ bags, onSelected, canDelete, onDeleted, canInteract }: BagsTableProps): JSX.Element => {
  return (
    <TableContainer>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell />
            <TableCell className={typedUtilityClassnames('label')} align="left">
              {s.BagProcessingPage_MineSeal}
            </TableCell>
            <TableCell className={typedUtilityClassnames('label')} align="right">
              {s.BagProcessingPage_ExpectedWeight}
            </TableCell>
            <TableCell className={typedUtilityClassnames('label')} align="right">
              {s.BagProcessingPage_ActualWeight}
            </TableCell>
            <TableCell />
            <TableCell />
          </TableRow>
        </TableHead>
        <TableBody className={typedUtilityClassnames('body1')}>
          {bags.map(({ id, state, itsciSealNumber, mineWeightInKg, warehouseWeightInKg }) => (
            <TableRow key={id} hover={canInteract} onClick={() => canInteract && onSelected(id)}>
              <TableCell align="left" width="10">
                <Chip label={state.name} className={bagStateIndicatorClassNames} />
              </TableCell>
              <TableCell align="left" width="15%">
                {itsciSealNumber}
              </TableCell>
              <TableCell align="right" width="20%">
                {mineWeightInKg}
              </TableCell>
              <TableCell align="right" width="20%">
                {warehouseWeightInKg === 0 ? '' : warehouseWeightInKg}
              </TableCell>
              <TableCell width="*" />
              <TableCell align="right" width="10" sx={{ mx: '0.5rem' }}>
                {canDelete && (
                  <IconButton onClick={(event) => onDeleted(event, id)} disabled={!canInteract}>
                    <TrashIcon />
                  </IconButton>
                )}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export const BagProcessingPage = (): JSX.Element => {
  const { shipmentId } = useParams<{ shipmentId: string }>();
  const accessToken = useAccessToken();
  const user = useLoggedInUser();

  const { navigateBagsProcessing, navigateShipments } = useAppNavigation();
  const { handleServerError } = useServerError();

  const [shipment, setShipment] = useState<Shipment | null>(null);
  const [truck, setTruck] = useState<Truck | null>(null);
  const [bags, setBags] = useState<Bag[] | null>(null);
  const [selectedBag, setSelectedBag] = useState<Bag | null>(null);

  const [isLoading, setIsLoading] = useState(true);
  const [isActionInProgress, setIsActionInProgress] = useState(false);
  const [error, setError] = useState<string | null>(null);

  const [drawerOpen, setDrawerOpen] = useState<boolean>(false);

  const [openConfirmationDialog, setOpenConfirmationDialog] = useState(false);
  const handleOpenConfirmationDialog = () => setOpenConfirmationDialog(true);
  const handleCloseConfirmationDialog = () => setOpenConfirmationDialog(false);

  useEffect(() => {
    (async () => {
      try {
        setError(null);
        setIsLoading(true);
        // TODO: Refactor out accessToken null checks to shared API client class that will throw if null for any call, then remove this from every component
        if (accessToken && shipmentId) {
          const shipmentRequest = shipmentsService.getById(accessToken, parseInt(shipmentId));
          const bagsRequest = shipmentsService.getBags(accessToken, parseInt(shipmentId));
          const trucksRequest = truckService.getAllForShipment(accessToken, parseInt(shipmentId));

          const [shipment, bags, trucks] = await Promise.all([shipmentRequest, bagsRequest, trucksRequest]);

          setShipment(shipment);
          setBags(bags);

          if (trucks && trucks.length > 0) {
            setTruck(trucks[0]);
          }
        }
      } catch (error) {
        const errorMessage = handleServerError(error);
        setError(`${s.BagProcessingPage_CouldNotGetBags_ErrorMessage}: ${errorMessage}`);
      } finally {
        setIsLoading(false);
      }
    })();
  }, [accessToken, handleServerError, shipmentId]);

  const handleAddBag = () => {
    if (canAddBag(user, truck)) {
      setSelectedBag(null);
      setDrawerOpen(true);
    }
  };

  const handleFinishWeighingBags = async () => {
    try {
      if (accessToken && shipmentId) {
        await shipmentsService.finishBagsWeighing(accessToken, parseInt(shipmentId));
        handleCloseConfirmationDialog();
        navigateBagsProcessing(shipmentId);
      }
    } catch (error) {
      handleCloseConfirmationDialog();
      const errorMessage = handleServerError(error);
      setError(`${s.BagProcessingPage_CouldFinishWeighingBags_ErrorMessage}: ${errorMessage}`);
    }
  };

  const handleBack = () => {
    shipmentId ? navigateBagsProcessing(shipmentId) : navigateShipments();
  };

  const refreshData = useCallback(async () => {
    setError(null);
    if (shipmentId && accessToken) {
      try {
        const bags = await shipmentsService.getBags(accessToken, parseInt(shipmentId));
        setBags(bags);
      } catch (error) {
        const errorMessage = handleServerError(error);
        setError(`${s.BagProcessingPage_CouldNotGetBags_ErrorMessage}: ${errorMessage}`);
      }
    }
  }, [accessToken, handleServerError, shipmentId]);

  const handleSave = async () => {
    setDrawerOpen(false);
    await refreshData();
  };

  const handleSaveError = async (error: unknown) => {
    setDrawerOpen(false);
    const errorMessage = handleServerError(error);
    setError(`${s.BagProcessingPage_CouldNotCreateOrUpdateBag_ErrorMessage}: ${errorMessage}`);
  };

  const handleClose = async () => {
    setDrawerOpen(false);
  };

  const handleBagSelected = (bagId: number | undefined) => {
    if (bags && bagId) {
      const bag = bags.find((b) => b.id === bagId);
      setSelectedBag(bag!);
      setDrawerOpen(true);
    }
  };

  const handleDeleteBag = async (event: SyntheticEvent, bagId: number | undefined) => {
    event.stopPropagation();

    try {
      // TODO: Refactor out accessToken null checks to shared API client class that will throw if null for any call, then remove this from every component
      if (accessToken && bagId && canDeleteBag(user, truck)) {
        setIsActionInProgress(true);
        await bagsService.delete(accessToken, bagId); // TODO: consider removing bag photo from cache (if added)
        await refreshData();
      }
    } catch (error) {
      const errorMessage = handleServerError(error);
      setError(`${s.BagProcessingPage_CouldNotDeleteBag_ErrorMessage}: ${errorMessage}`);
    } finally {
      setIsActionInProgress(false);
    }
  };

  return (
    <>
      {isLoading ? (
        <Spinner />
      ) : (
        <>
          <ContextHeader
            contextTitle={s.BagProcessingPage_Title}
            contextSubTitle={shipment?.shipmentReference}
            leftButtonOne={<SecondaryButton label={s.BagProcessingPage_BackButton} onClick={handleBack} />}
            rightButtonOne={
              <SecondaryButton
                label={s.BagProcessingPage_FinishWeighingBagsButton}
                onClick={handleOpenConfirmationDialog}
                disabled={!canFinishWeighingBags(user, truck, bags)}
              />
            }
            rightButtonTwo={
              <PrimaryButton label={s.BagProcessingPage_AddButton} onClick={handleAddBag} disabled={!canAddBag(user, truck)} />
            }
            showContextHeaderContentSpacer
          />
          <div className={onlyComputedCombineClassnames(typedUtilityClassnames('mainLayoutPadding'))}>
            <Notification message={error!} severity="error" onClose={() => setError(null)} />
            {bags && bags.length > 0 && user ? (
              <BagsTable
                bags={bags}
                onSelected={handleBagSelected}
                canDelete={canDeleteBag(user, truck)}
                onDeleted={handleDeleteBag}
                canInteract={!isActionInProgress}
              />
            ) : (
              <div className={emptyBagsMessageClassNames}>{s.BagProcessingPage_WillAppearHere}</div>
            )}
          </div>
        </>
      )}
      {shipmentId && truck && (
        <RightDrawer open={drawerOpen}>
          <BagDrawerForm
            truckId={truck.id}
            onSaveSuccess={handleSave}
            onSaveFailed={handleSaveError}
            onClose={handleClose}
            bag={selectedBag}
            canEdit={selectedBag != null ? canEditBag(user, truck) : canAddBag(user, truck)}
          />
        </RightDrawer>
      )}

      <ActionConfirmationDialog
        open={openConfirmationDialog}
        title={s.BagProcessingPage_ConfirmationDialogTitle}
        items={[
          {
            label: s.BagProcessingPage_ConfirmationDialogBagsProcessedItemLabel,
            successCondition: bags != null && bags.length > 0 && bags.every((b) => b.warehouseWeightInKg && b.documentCount),
            successMessage: `${bags?.length} ${
              bags?.length === 1
                ? s.BagProcessingPage_ConfirmationDialogSingularBagItem
                : s.BagProcessingPage_ConfirmationDialogPluralBagsItem
            }`,
            errorMessage: s.BagProcessingPage_ConfirmationDialogBagsProcessedErrorMessage,
          },
        ]}
        confirmButtonCaption={s.BagProcessingPage_ConfirmationDialogFinishButtonCaption}
        handleConfirm={handleFinishWeighingBags}
        cancelButtonCaption={s.BagProcessingPage_ConfirmationDialogCancelButtonCaption}
        handleCancel={handleCloseConfirmationDialog}
      />
    </>
  );
};
