import { ShipmentSummaryPiles } from 'models/shipment';
import { _ManifestItemBase } from 'components/manifest-items/_base/_ManifestItemBase';
import { s } from 'i18n/strings';
import { typedUtilityClassnames } from 'style/compoundClassnames';
import { ReactComponent as PilesIcon } from 'icons/piles.svg';

const columnItemContainerClassNames = typedUtilityClassnames('body2');
const columnItemCaptionClassNames = typedUtilityClassnames('label');

export interface PilesManifestItemProps {
  pilesSummary: ShipmentSummaryPiles;
  editOnClick: () => void;
}

export const PilesManifestItem = ({ pilesSummary, editOnClick }: PilesManifestItemProps): JSX.Element => {
  return (
    <_ManifestItemBase
      title={s.PilesManifestItem_Title}
      icon={<PilesIcon />}
      actionButtonCaption={s.PilesManifestItem_EditButtonCaption}
      actionButtonOnClick={editOnClick}
      actionButtonDisabled={false}
      actionButtonVisible={true}
      content={
        <>
          <div className={columnItemContainerClassNames}>
            <span className={columnItemCaptionClassNames}>{s.PilesManifestItem_BagsCountLabel}: </span>
            {pilesSummary.count}
          </div>
        </>
      }
    />
  );
};
