export const msalConfig = {
  auth: {
    clientId: window.__RUNTIME_CONFIG__.REACT_APP_MSAL_AUTH_CLIENT_ID!,
    authority: window.__RUNTIME_CONFIG__.REACT_APP_MSAL_AUTH_AUTHORITY!,
    redirectUri: window.__RUNTIME_CONFIG__.REACT_APP_MSAL_AUTH_REDIRECT_URI!,
  },

  cache: {
    cacheLocation: 'sessionStorage',
    storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
  },
};

// Add scopes here for ID token to be used at Microsoft identity platform endpoints
export const loginRequest = {
  scopes: [window.__RUNTIME_CONFIG__.REACT_APP_MSAL_LOGIN_SCOPE!],
};
