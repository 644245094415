import type { ReactElement } from 'react';

import {
  alignItems,
  backgroundColor,
  display,
  justifyContent,
  margin,
  minHeight,
  padding,
  textColor,
  typedUtilityClassnames,
} from 'style/compoundClassnames';

const headerItemContainerClassNames = typedUtilityClassnames(
  display('flex'),
  justifyContent('justify-between'),
  backgroundColor('bg-card-200'),
  minHeight('min-h-16'),
  margin('mt-4'),
  padding('px-4', 'py-2'),
  alignItems('items-center'),
);
const headerItemTitleContainerClassNames = typedUtilityClassnames(display('flex'), alignItems('items-center'));
const headerItemIconClassNames = typedUtilityClassnames(padding('pl-1', 'pr-4'));
const headerItemTitleClassNames = typedUtilityClassnames('headline5', textColor('text-primary-900'));

export interface HeaderItemProps {
  title: string;
  icon: ReactElement;
  actionButton?: ReactElement;
  actionButtonVisible?: boolean;
}

export const HeaderItem = ({ title, icon, actionButton, actionButtonVisible }: HeaderItemProps) => {
  return (
    <div className={headerItemContainerClassNames}>
      <div className={headerItemTitleContainerClassNames}>
        <div className={headerItemIconClassNames}>{icon}</div>
        <div className={headerItemTitleClassNames}>{title}</div>
      </div>
      {actionButtonVisible && actionButton}
    </div>
  );
};
