import type { MouseEventHandler, ReactElement } from 'react';
import { _ButtonBase, _ButtonBaseProps } from 'components/buttons/_base/_ButtonBase';
import { typedUtilityClassnames, backgroundColor, textColor, onlyComputedCombineClassnames } from 'style/compoundClassnames';

export interface PrimaryButtonProps extends Partial<Pick<_ButtonBaseProps, 'type' | 'form'>> {
  disabled?: boolean;
  onClick?: MouseEventHandler;
  label: string | ReactElement;
  buttonClassNames?: string;
}

const enabledClasses = typedUtilityClassnames(backgroundColor('bg-primary-900', 'hover:bg-primary-500'));
const disabledClasses = typedUtilityClassnames(backgroundColor('bg-onSurface-disabled'));
const commonClasses = typedUtilityClassnames(textColor('text-white'));

export const PrimaryButton = ({
  disabled = false,
  onClick,
  label,
  type = 'button',
  buttonClassNames,
  form,
}: PrimaryButtonProps): JSX.Element => (
  <>
    <_ButtonBase
      label={label}
      type={type}
      onClick={onClick}
      disabled={disabled}
      buttonClassNames={onlyComputedCombineClassnames(
        { [enabledClasses]: !disabled, [disabledClasses]: disabled },
        commonClasses,
        buttonClassNames,
      )}
      form={form}
    />
  </>
);
