import { ErrorMessage, FieldValuesFromFieldErrors } from '@hookform/error-message';
import type { FieldErrors, FieldName, FieldValues } from 'react-hook-form';
import { textColor, typedUtilityClassnames, onlyComputedCombineClassnames, minHeight, maxHeight, padding } from 'style/compoundClassnames';

export interface FormValidationErrorProps<TFormState extends FieldValues> {
  name: FieldName<FieldValuesFromFieldErrors<FieldErrors<TFormState>>>;
  errors?: FieldErrors<TFormState>;
  associatedInputId: string;
  errorsDisplayContainerClassNames?: string;
}

export const getMessageAsError = (errorMessage: string) => `⚠ ${errorMessage}`;

const getContainerClassNames = (errorsDisplayContainerClassNames: string | undefined) =>
  onlyComputedCombineClassnames(
    errorsDisplayContainerClassNames,
    typedUtilityClassnames(minHeight('min-h-6'), maxHeight('max-h-fit'), padding('pt-0.5', 'pb-1')),
  );
const errorMessageTextClassNames = typedUtilityClassnames('caption', textColor('text-error-600'));

// https://stackoverflow.com/questions/4707936/error-message-span-vs-label
const getErrorMessageDisplay = (associatedInputId: string): (({ message }: { message: string }) => JSX.Element) => {
  const ErrorMessageDisplay = ({ message }: { message: string }) => (
    <label htmlFor={associatedInputId} className={errorMessageTextClassNames}>
      {getMessageAsError(message)}
    </label>
  );
  return ErrorMessageDisplay;
};

export const FormValidationError = function <TFormState extends FieldValues>({
  name,
  errors,
  errorsDisplayContainerClassNames,
  associatedInputId,
}: FormValidationErrorProps<TFormState>): JSX.Element {
  return (
    <div className={getContainerClassNames(errorsDisplayContainerClassNames)}>
      <ErrorMessage errors={errors} name={name} render={getErrorMessageDisplay(associatedInputId)} />
    </div>
  );
};
