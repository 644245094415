import { s } from 'i18n/strings';
import { typedUtilityClassnames } from 'style/compoundClassnames';
import { _ConfirmationDialogBase } from 'components/dialogs/_base';

const contentClassNames = typedUtilityClassnames('body1');

export interface BasicConfirmationDialogProps {
  open: boolean;
  title: string;
  content: string;
  handleConfirm: () => void;
  confirmDisabled: boolean;
  handleCancel: () => void;
  cancelDisabled: boolean;
}

export const BasicConfirmationDialog = ({
  open,
  title,
  content,
  handleConfirm,
  confirmDisabled,
  handleCancel,
  cancelDisabled,
}: BasicConfirmationDialogProps): JSX.Element => {
  return (
    <_ConfirmationDialogBase
      open={open}
      title={title}
      primaryButtonCaption={s.BasicConfirmationDialog_ConfirmButtonCaption}
      primaryButtonAction={handleConfirm}
      primaryButtonDisabled={confirmDisabled}
      secondaryButtonCaption={s.BasicConfirmationDialog_CancelButtonCaption}
      secondaryButtonAction={handleCancel}
      secondaryButtonDisabled={cancelDisabled}
    >
      <div className={contentClassNames}>{content}</div>
    </_ConfirmationDialogBase>
  );
};
