import { s } from 'i18n/strings';
import { FieldError, FieldErrors, SubmitHandler, UseFormHandleSubmit, UseFormRegister } from 'react-hook-form';
import { typedUtilityClassnames, width } from 'style/compoundClassnames';
import { getRequiredInputLength } from 'utilities/formValidation/stringValidation';
import { ExclusivelyStringKeyedRecordAsNonOptional, FormFieldNames } from 'utility-types';
import { z } from 'zod';
import { UncontrolledTextInput } from 'components/form-components/inputs';

const formClassNames = typedUtilityClassnames('nonExpandingMainLayoutContentSection', width('w-96'));

const shipmentRequiredFieldsValidation = (minLength: number, maxLength: number) =>
  getRequiredInputLength(
    s.FormValidationError_FieldRequired,
    minLength,
    s.FormValidationError_MinimumLengthError.replace('{{length}}', minLength.toString()),
    maxLength,
    s.FormValidationError_MaximumLengthError.replace('{{length}}', maxLength.toString()),
  );

export const editShipmentFormStateSchema = z.object({
  shipmentReference: shipmentRequiredFieldsValidation(5, 30),
});

export type EditShipmentFormState = z.infer<typeof editShipmentFormStateSchema>;

export const editShipmentFormStateFieldNames: ExclusivelyStringKeyedRecordAsNonOptional<FormFieldNames<EditShipmentFormState>> = {
  shipmentReference: 'shipmentReference',
} as const;

export interface EditShipmentFormProps {
  formId: string;
  canEdit: boolean;
  onSubmit: SubmitHandler<EditShipmentFormState>;
  handleSubmit: UseFormHandleSubmit<EditShipmentFormState>;
  register: UseFormRegister<EditShipmentFormState>;
  shipmentReferenceInputError: FieldError | undefined;
  errors: FieldErrors<EditShipmentFormState>;
}

export const EditShipmentForm = ({
  formId,
  canEdit,
  onSubmit,
  handleSubmit,
  register,
  shipmentReferenceInputError,
  errors,
}: EditShipmentFormProps): JSX.Element => {
  return (
    <form id={formId} onSubmit={handleSubmit(onSubmit)} className={formClassNames}>
      <UncontrolledTextInput<EditShipmentFormState, typeof editShipmentFormStateFieldNames.shipmentReference>
        readOnly={!canEdit}
        label={s.EditShipmentPage_ShipmentIDLabelCaption}
        {...register(editShipmentFormStateFieldNames.shipmentReference, { disabled: !canEdit })}
        hasError={!!shipmentReferenceInputError}
        errors={errors}
        isRequired={true}
      />
    </form>
  );
};
