import { State } from 'models/state';
import { Bag } from 'models/bag';
import { Drum } from 'models/drum';

export class Pile {
  // eslint-disable-next-line no-useless-constructor
  constructor(public id: number, public reference: string, public state: State, public bags: Bag[], public drums: Drum[]) {}
}

export class PileWrite {
  // eslint-disable-next-line no-useless-constructor
  constructor(public bagIds: number[], public drumIds: number[]) {}
}

export const PileStates = {
  New: 'New',
  Sampled: 'Sampled',
  Consumed: 'Consumed',
} as const;
