import { useCallback } from 'react';
import { User, UserRole, UserRoles } from 'models/user';
import { useLoggedInUser } from 'hooks/useLoggedInUser';
import { FormControlLabel, Checkbox } from '@mui/material';
import { s } from 'i18n/strings';
import {
  backgroundColor,
  padding,
  borderColor,
  gridTemplateRows,
  gridTemplateColumns,
  typedUtilityClassnames,
  display,
  flexDirection,
  borderWidth,
  width,
  gap,
  textColor,
  gridRowStart,
  gridColumnStart,
  overflow,
} from 'style/compoundClassnames';
import { SecondaryButton } from 'components/buttons';

const formContainerClassNames = typedUtilityClassnames(
  display('grid'),
  gap('gap-x-6'),
  gridTemplateRows('grid-rows-mc3', 'lg:grid-rows-mc2'),
  gridTemplateColumns('lg:grid-cols-2'),
  'nonExpandingMainLayoutContentSection',
  overflow('overflow-hidden'),
);

const allInputsLabelsClassNames = typedUtilityClassnames('label', padding('pb-2'));

const textFieldLabelAndInputClassNames = typedUtilityClassnames(display('flex'), flexDirection('flex-col'));
const textFieldLabelClassNames = typedUtilityClassnames(allInputsLabelsClassNames, textColor('text-onSurface-highEmphasis'));
const inputFieldClassNames = typedUtilityClassnames(
  backgroundColor('bg-white'),
  borderColor('border-outlineColour'),
  'borders',
  padding('p-4'),
  'subtitle1',
);
const emailLabelAndInputClassNames = typedUtilityClassnames(textFieldLabelAndInputClassNames, padding('pt-4', 'lg:pt-0', 'pb-4'));
const resetPasswordButton = typedUtilityClassnames(
  gridRowStart('row-start-3', 'lg:row-start-2'),
  gridColumnStart('col-start-1', 'lg:col-start-2'),
);

const checklistContainerClassNames = typedUtilityClassnames(display('flex'), flexDirection('flex-col'));
const checklistBorderClassNames = typedUtilityClassnames(borderWidth('border-b'), borderColor('border-outlineColour'), width('w-full'));
const checklistTitleClassNames = typedUtilityClassnames(allInputsLabelsClassNames, checklistBorderClassNames, padding('pt-8', 'lg:pt-16'));
const individualChecklistItemsClassNames = typedUtilityClassnames(padding('p-4'), checklistBorderClassNames);

export interface EditUserFormProps {
  user: User;
  allUserRoles: UserRole[];
  checkedRoles: boolean[];
  setCheckedRoles: (checkedRoles: boolean[]) => void;
  setSaveAllowed: (saveAllowed: boolean) => void;
  savingInProgress: boolean;
}

export const EditUserForm = ({
  user: { id, fullName, emailAddress, roles },
  allUserRoles,
  checkedRoles,
  setCheckedRoles,
  setSaveAllowed,
  savingInProgress,
}: EditUserFormProps) => {
  const loggedInUser = useLoggedInUser();

  const handleRolesChanged = useCallback(
    (role: UserRole) => {
      const updatedCheckedRoles = checkedRoles.map((item, index) => (index === role.id - 1 ? !item : item));
      setCheckedRoles(updatedCheckedRoles);

      const initialUserRoles = roles;
      const changedUserRoles = allUserRoles.filter((r) => updatedCheckedRoles[r.id - 1]);
      const rolesUnchanged =
        initialUserRoles.length === changedUserRoles.length &&
        initialUserRoles.every((value, index) => value.id === changedUserRoles[index].id);
      setSaveAllowed(!rolesUnchanged && changedUserRoles.length > 0);
    },
    [roles, allUserRoles, checkedRoles, setCheckedRoles, setSaveAllowed],
  );

  const isAdminRoleDisabled = (userId: string, userRole: UserRole) => {
    return (
      loggedInUser?.id === userId && (userRole.name === UserRoles.GlobalAdministrator || userRole.name === UserRoles.OfficeAdministrator)
    );
  };

  return (
    <form className={formContainerClassNames}>
      <div className={textFieldLabelAndInputClassNames}>
        <label className={textFieldLabelClassNames} htmlFor="fullName">
          {s.EditUserForm_FullNameTextFieldLabel}
        </label>
        <input className={inputFieldClassNames} type="text" id="fullName" placeholder={fullName} disabled />
      </div>
      <div className={emailLabelAndInputClassNames}>
        <label className={textFieldLabelClassNames} htmlFor="emailAddress">
          {s.EditUserForm_EmailTextFieldLabel}
        </label>
        <input className={inputFieldClassNames} type="text" id="emailAddress" placeholder={emailAddress} disabled />
      </div>
      <SecondaryButton buttonClassNames={resetPasswordButton} label={s.EditUserForm_ResetPasswordButtonCaption} disabled={true} />
      <fieldset className={checklistContainerClassNames}>
        <legend className={checklistTitleClassNames}>{s.EditUserForm_RolesCheckboxSectionLabel}</legend>
        {allUserRoles.map((role) => (
          <FormControlLabel
            className={individualChecklistItemsClassNames}
            key={role.id}
            label={role.name}
            control={
              <Checkbox
                disableRipple
                checked={checkedRoles[role.id - 1]}
                onChange={() => handleRolesChanged(role)}
                disabled={savingInProgress || isAdminRoleDisabled(id, role)}
              />
            }
          />
        ))}
      </fieldset>
    </form>
  );
};
