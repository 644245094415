import React from 'react';
import { createRoot } from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import { RecoilRoot } from 'recoil';

import { StyledEngineProvider } from '@mui/material/styles';
import { ThemeProvider, createTheme } from '@mui/material';

import cssVariables from 'style/style.config.css';

import { PublicClientApplication } from '@azure/msal-browser';
import { MsalProvider } from '@azure/msal-react';
import { msalConfig } from './authConfig';

import './index.css';
import { App } from 'App';
import reportWebVitals from './reportWebVitals';
import '@fontsource/lato';

const msalInstance = new PublicClientApplication(msalConfig);

const theme = createTheme({
  typography: {
    fontFamily: cssVariables.fontFamily,
  },
  palette: {
    primary: {
      main: cssVariables.colourPrimary900,
      light: cssVariables.colourPrimary100,
    },
    secondary: {
      main: cssVariables.colourSecondary900,
      light: cssVariables.colourSecondary100,
    },
    action: {
      hover: cssVariables.overlayBlackHover,
    },
  },
});

const rootElement = document.getElementById('root');
if (!rootElement) {
  throw new Error('Failed to find root element');
}
const root = createRoot(rootElement);
root.render(
  <React.StrictMode>
    <ThemeProvider theme={theme}>
      <StyledEngineProvider injectFirst>
        <MsalProvider instance={msalInstance}>
          <BrowserRouter>
            <RecoilRoot>
              <App />
            </RecoilRoot>
          </BrowserRouter>
        </MsalProvider>
      </StyledEngineProvider>
    </ThemeProvider>
  </React.StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
