import { borderRadius, onlyComputedCombineClassnames, typedUtilityClassnames } from 'style/compoundClassnames';
import { _IconButtonBase, _IconButtonBaseTypes, _IconButtonBaseProps } from 'components/icon-buttons/_base/IconButtonBase';
import type { ReactElement, MouseEventHandler } from 'react';

export interface RoundIconButtonProps extends Omit<_IconButtonBaseProps, 'type'> {
  children: ReactElement;
  buttonClassNames?: string;
  onClick?: MouseEventHandler;
  disabled: boolean;
  ariaLabel: string;
}

const getButtonClassNames = (buttonClassNames: string | undefined) =>
  onlyComputedCombineClassnames(typedUtilityClassnames(borderRadius('rounded-full'), 'overlayBlackStates'), buttonClassNames);

export const CircularIconButton = ({ children, buttonClassNames, onClick, disabled, ariaLabel }: RoundIconButtonProps): JSX.Element => {
  return (
    <_IconButtonBase
      disabled={disabled}
      ariaLabel={ariaLabel}
      type={_IconButtonBaseTypes.button}
      buttonClassNames={getButtonClassNames(buttonClassNames)}
      onClick={onClick}
    >
      {children}
    </_IconButtonBase>
  );
};
