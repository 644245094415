import { ShipmentSummaryContainer } from 'models/shipment';
import { _ManifestItemBase } from 'components/manifest-items/_base/_ManifestItemBase';
import { s } from 'i18n/strings';
import {
  typedUtilityClassnames,
  textTransform,
  margin,
  textColor,
  padding,
  display,
  gridTemplateColumns,
  gap,
  width,
} from 'style/compoundClassnames';
import { ReactComponent as ContainerIcon } from 'icons/container.svg';

const contentLayoutClassNames = typedUtilityClassnames(
  display('grid'),
  gridTemplateColumns('xl:grid-cols-3', 'lg:grid-cols-3', 'md:grid-cols-3', 'grid-cols-1'),
  gap('gap-6'),
  width('w-full'),
);
const columnTitleClassNames = typedUtilityClassnames(
  'navBlockFont',
  textColor('text-onSurface-mediumEmphasis'),
  textTransform('uppercase'),
  padding('pb-2'),
);
const columnItemContainerClassNames = typedUtilityClassnames('body2', textColor('text-onSurface-highEmphasis'));
const columnItemCaptionClassNames = typedUtilityClassnames('label', textColor('text-onSurface-highEmphasis'));
const passportNumberClassNames = typedUtilityClassnames('caption', textColor('text-onSurface-mediumEmphasis'), margin('ml-12'));

const containerFreightForwarderInfoExists = (containerSummary: ShipmentSummaryContainer) => {
  return (
    containerSummary.truckLicensePlate != null &&
    containerSummary.truckLicensePlate.length > 0 &&
    containerSummary.trailerLicensePlate != null &&
    containerSummary.trailerLicensePlate.length > 0 &&
    containerSummary.driverFirstName != null &&
    containerSummary.driverFirstName.length > 0 &&
    containerSummary.driverLastName != null &&
    containerSummary.driverLastName.length > 0 &&
    containerSummary.driverPassportNumber != null &&
    containerSummary.driverPassportNumber.length > 0
  );
};

export interface ContainerProcessingManifestItemProps {
  containerSummary: ShipmentSummaryContainer;
  editOnClick: () => void;
}

export const ContainerProcessingManifestItem = ({ containerSummary, editOnClick }: ContainerProcessingManifestItemProps): JSX.Element => {
  return (
    <_ManifestItemBase
      title={s.ContainerProcessingManifestItem_Title}
      icon={<ContainerIcon />}
      actionButtonCaption={s.ContainerProcessingManifestItem_EditButtonCaption}
      actionButtonOnClick={editOnClick}
      actionButtonDisabled={false}
      actionButtonVisible={true}
      content={
        <div className={contentLayoutClassNames}>
          <div className={columnItemContainerClassNames}>
            <div className={columnTitleClassNames}>{s.ContainerProcessingManifestItem_ContainerDetailsTitle}</div>
            <div className={columnItemContainerClassNames}>
              <span className={columnItemCaptionClassNames}>{s.ContainerProcessingManifestItem_TagBoxIdLabel}: </span>
              {containerSummary.tagBoxId}
            </div>
            {containerSummary.leopardLockId && (
              <div className={columnItemContainerClassNames}>
                <span className={columnItemCaptionClassNames}>{s.ContainerProcessingManifestItem_LeopardLockIdLabel}: </span>
                {containerSummary.leopardLockId}
              </div>
            )}
            {containerSummary.containerSealId && (
              <div className={columnItemContainerClassNames}>
                <span className={columnItemCaptionClassNames}>{s.ContainerProcessingManifestItem_ContainerSealIdLabel}: </span>
                {containerSummary.containerSealId}
              </div>
            )}
          </div>

          {containerFreightForwarderInfoExists(containerSummary) && (
            <div>
              <div className={columnTitleClassNames}>{s.ContainerProcessingManifestItem_FreightForwarderSectionTitle}</div>
              <div className={columnItemContainerClassNames}>
                <span className={columnItemCaptionClassNames}>
                  {s.ContainerProcessingManifestItem_FreightForwarderRegistrationNoLabel}:{' '}
                </span>
                {containerSummary.truckLicensePlate}
              </div>
              <div className={columnItemContainerClassNames}>
                <span className={columnItemCaptionClassNames}>{s.ContainerProcessingManifestItem_FreightForwarderTrailerNoLabel}: </span>
                {containerSummary.trailerLicensePlate}
              </div>
              <div className={columnItemContainerClassNames}>
                <span className={columnItemCaptionClassNames}>{s.ContainerProcessingManifestItem_FreightForwarderDriverNameLabel}: </span>
                {containerSummary.driverFirstName} {containerSummary.driverLastName}
              </div>
              <div className={passportNumberClassNames}>
                {s.ContainerProcessingManifestItem_FreightForwarderDriverPassportLabel}: {containerSummary.driverPassportNumber}
              </div>
            </div>
          )}
        </div>
      }
    />
  );
};
