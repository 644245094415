import { apiRequest } from './request';
import { Document } from 'models/document';

const BaseUrl = window.__RUNTIME_CONFIG__.REACT_APP_API_BASE_URL;

export const documentsService = {
  async getById(accessToken: string, id: number) {
    return await apiRequest.get<Document>(`${BaseUrl}/documents/${id}`, accessToken);
  },
  async getByEntityId(accessToken: string, entityId: number) {
    return await apiRequest.get<Document[]>(`${BaseUrl}/documents/entity/${entityId}`, accessToken);
  },
  async download(accessToken: string, id: number) {
    return await apiRequest.download(`${BaseUrl}/documents/${id}/download`, accessToken);
  },
  async delete(accessToken: string, id: number) {
    return await apiRequest.delete(`${BaseUrl}/documents/${id}`, accessToken);
  },
};
