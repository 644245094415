import { apiRequest } from 'services/request';
import type { Drum, DrumWrite } from 'models/drum';
import type { Document } from 'models/document';

const BaseUrl = window.__RUNTIME_CONFIG__.REACT_APP_API_BASE_URL;

export const drumsService = {
  async getById(accessToken: string, id: number) {
    return await apiRequest.get<Drum>(`${BaseUrl}/drums/${id}`, accessToken);
  },
  async create(accessToken: string, drum: DrumWrite) {
    return await apiRequest.post<Drum, DrumWrite>(`${BaseUrl}/drums`, accessToken, drum);
  },
  async update(accessToken: string, id: number, drum: DrumWrite) {
    return await apiRequest.put<Drum, DrumWrite>(`${BaseUrl}/drums?id=${id}`, accessToken, drum);
  },
  async delete(accessToken: string, id: number) {
    await apiRequest.delete(`${BaseUrl}/drums?id=${id}`, accessToken);
  },
  async getDocuments(accessToken: string, id: number) {
    return await apiRequest.get<Document[]>(`${BaseUrl}/drums/${id}/documents`, accessToken);
  },
};
