import { ReactElement } from 'react';

import { borderColor, borderWidth, display, flexDirection, padding, typedUtilityClassnames } from 'style/compoundClassnames';

import { HeaderItem } from 'components/HeaderItem';
import { SecondaryButton } from 'components/buttons';

const itemContainerClassNames = typedUtilityClassnames(display('flex'), flexDirection('flex-col'));
const itemContentClassNames = typedUtilityClassnames(
  display('flex'),
  borderWidth('border'),
  borderColor('border-outlineColour'),
  padding('px-8', 'py-4'),
);

export interface _ManifestItemBaseProps {
  title: string;
  icon: ReactElement;
  actionButtonCaption: string;
  actionButtonOnClick: () => void;
  actionButtonDisabled: boolean;
  actionButtonVisible: boolean;
  content: ReactElement;
}

export const _ManifestItemBase = ({
  title,
  icon,
  actionButtonCaption,
  actionButtonOnClick,
  actionButtonDisabled,
  actionButtonVisible,
  content,
}: _ManifestItemBaseProps): JSX.Element => {
  return (
    <div className={itemContainerClassNames}>
      <HeaderItem
        title={title}
        icon={icon}
        actionButton={<SecondaryButton label={actionButtonCaption} onClick={actionButtonOnClick} disabled={actionButtonDisabled} />}
        actionButtonVisible={actionButtonVisible}
      />
      <div className={itemContentClassNames}>{content}</div>
    </div>
  );
};
