import { Container } from 'models/container';
import { s } from 'i18n/strings';
import { _ManifestItemBase } from 'components/manifest-items/_base/_ManifestItemBase';
import { display, gap, gridTemplateColumns, typedUtilityClassnames, width } from 'style/compoundClassnames';
// import { ReactComponent as DrumsIcon } from 'icons/drums.svg';
import { ReactComponent as ContainerIcon } from 'icons/container.svg';

const contentLayoutClassNames = typedUtilityClassnames(
  display('grid'),
  gridTemplateColumns('xl:grid-cols-3', 'lg:grid-cols-3', 'md:grid-cols-3', 'grid-cols-1'),
  gap('gap-6'),
  width('w-full'),
);
const columnItemContainerClassNames = typedUtilityClassnames('body2');
const columnItemCaptionClassNames = typedUtilityClassnames('label');

export interface ContainerDrumsManifestItemProps {
  container: Container;
  editOnClick: () => void;
}

export const ContainerDrumsManifestItem = ({ container, editOnClick }: ContainerDrumsManifestItemProps): JSX.Element => {
  return (
    <_ManifestItemBase
      title={s.ContainerDrumsManifestItem_Title}
      icon={<ContainerIcon />}
      actionButtonCaption={s.ContainerDrumsManifestItem_EditButtonCaption}
      actionButtonOnClick={editOnClick}
      actionButtonDisabled={false}
      actionButtonVisible={true}
      content={
        <div className={contentLayoutClassNames}>
          <div>
            <div className={columnItemContainerClassNames}>
              <span className={columnItemCaptionClassNames}>{s.ContainerDrumsManifestItem_TagBoxIDCaption}: </span>
              {container.tagBoxId}
            </div>
            {container.leopardLockId && (
              <div className={columnItemContainerClassNames}>
                <span className={columnItemCaptionClassNames}>{s.ContainerDrumsManifestItem_LeopardLockIDCaption}: </span>
                {container.leopardLockId}
              </div>
            )}
            {container.containerSealId && (
              <div className={columnItemContainerClassNames}>
                <span className={columnItemCaptionClassNames}>{s.ContainerDrumsManifestItem_ContainerSealIDCaption}: </span>
                {container.containerSealId}
              </div>
            )}
          </div>
          {/* <div className={columnItemContainerClassNames}>
            <span className={columnItemCaptionClassNames}>{s.ContainerDrumsManifestItem_NumberOfDrumsCaption}: </span>
            {container.drums.length}
          </div> */}
        </div>
      }
    />
  );
};
