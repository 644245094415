import { useEffect, useState } from 'react';

import { Document, DocumentInfo, documentTypes } from 'models/document';
import { shipmentStates, ShipmentSummary } from 'models/shipment';
import { containerStates } from 'models/container';
import { TruckStates } from 'models/truck';
import { Pile, PileStates } from 'models/pile';
import { User } from 'models/user';
import { permissionsService } from 'services/permissionsService';
import { shipmentsService } from 'services/shipmentsService';
import { useAccessToken, useLoggedInUser, useServerError } from 'hooks';

import { s } from 'i18n/strings';

import {
  display,
  flexDirection,
  justifyContent,
  margin,
  onlyComputedCombineClassnames,
  textColor,
  textTransform,
  typedUtilityClassnames,
} from 'style/compoundClassnames';

import { DocumentItem } from 'components/DocumentItem';
import { Notification } from 'components/Notification';
import { Spinner } from 'components/Spinner';

const mainContentClassNames = onlyComputedCombineClassnames(
  typedUtilityClassnames('mainLayoutPadding'),
  display('flex'),
  justifyContent('justify-center'),
  flexDirection('flex-col'),
);
const requiredDocumentsTitleClassNames = typedUtilityClassnames('headline5', textColor('text-primary-900'), margin('mb-4'));
const documentsSectionTitleClassNames = typedUtilityClassnames(
  'navBlockFont',
  textColor('text-onSurface-mediumEmphasis'),
  textTransform('uppercase'),
  margin('mt-5'),
);

const truckArrivalDocuments: DocumentInfo[] = [
  { title: s.ShipmentDocuments_CEEC1DocumentItemTitle, type: documentTypes.CEEC, required: true },
  { title: s.ShipmentDocuments_SAEMAPECertificateDocumentItemTitle, type: documentTypes.SAEMAPECertificate, required: true },
  { title: s.ShipmentDocuments_TransportCertificateDocumentItemTitle, type: documentTypes.TransportCertificate, required: true },
  { title: s.ShipmentDocuments_iTSCiExcelTagListDocumentItemTitle, type: documentTypes.iTSCiExcelTagList, required: true },
];

const truckOffloadedDocuments: DocumentInfo[] = [
  { title: s.ShipmentDocuments_TaxDocumentItemTitle, type: documentTypes.TaxDocument, required: true },
  { title: s.ShipmentDocuments_FineDocumentItemTitle, type: documentTypes.FineDocument, required: false },
];

const pileSamplingDocuments: DocumentInfo[] = [
  { title: s.ShipmentDocuments_RobinsonInspectionReportDocumentItemTitle, type: documentTypes.RobinsonInspectionReport, required: true },
  {
    title: s.ShipmentDocuments_RobinsonFinalAnalysisReportDocumentItemTitle,
    type: documentTypes.RobinsonFinalAnalysisReport,
    required: true,
  },
];

const containerDocuments: DocumentInfo[] = [
  { title: s.ShipmentDocuments_C1FormDocumentItemTitle, type: documentTypes.C1DeclarationForm, required: true },
  { title: s.ShipmentDocuments_FacteurCommercialeDocumentItemTitle, type: documentTypes.FactureCommerciale, required: true },
  { title: s.ShipmentDocuments_ExportLicenceDocumentItemTitle, type: documentTypes.ExportLicence, required: true },
  { title: s.ShipmentDocuments_CommerceExtDocumentItemTitle, type: documentTypes.CommerceExterieur, required: true },
  { title: s.ShipmentDocuments_FicheComptoirDocumentItemTitle, type: documentTypes.FicheComptoir, required: true },
  { title: s.ShipmentDocuments_CGADocumentItemTitle, type: documentTypes.CGA, required: true },
  { title: s.ShipmentDocuments_ContainerPackingListDocumentItemTitle, type: documentTypes.ContainerPackingList, required: true },
  { title: s.ShipmentDocuments_DGDADocumentItemTitle, type: documentTypes.DGDA, required: true },
  { title: s.ShipmentDocuments_AfrimetShiptFileDocumentItemTitle, type: documentTypes.AfrimetShipmentsFile, required: true },
  { title: s.ShipmentDocuments_TruckManifestDocumentItemTitle, type: documentTypes.TruckManifest, required: true },
];

const customsDocuments: DocumentInfo[] = [
  { title: s.ShipmentDocuments_LaboPriveeItemTitle, type: documentTypes.LaboPrivee, required: true },
  { title: s.ShipmentDocuments_CEECFinalItemTitle, type: documentTypes.CEECFinal, required: true },
];

const shipmentDocuments: DocumentInfo[] = [
  { title: s.ShipmentDocuments_VesselPackingListDocumentItemTitle, type: documentTypes.VesselPackingList, required: true },
  { title: s.ShipmentDocuments_ForkliftWeightRecordDocumentItemTitle, type: documentTypes.ForkliftWeightRecord, required: true },
  { title: s.ShipmentDocuments_BillOfLadingDocumentItemTitle, type: documentTypes.BillOfLading, required: true },
];

const additionalDocuments: DocumentInfo[] = [
  { title: s.ShipmentDocuments_COPDocumentItemTitle, type: documentTypes.COP, required: false },
  { title: s.ShipmentDocuments_DataSafetySheetDocumentItemTitle, type: documentTypes.DataSafetySheet, required: false },
  { title: s.ShipmentDocuments_OffloadSheetDocumentItemTitle, type: documentTypes.OffloadSheet, required: false },
  { title: s.ShipmentDocuments_PoliceOfMinesDocumentItemTitle, type: documentTypes.PoliceOfMines, required: false },
  { title: s.ShipmentDocuments_TechnicalSpecificationDocumentItemTitle, type: documentTypes.TechnicalSpecification, required: false },
];

const stringIsNullOrEmpty = (value: string) => value == null || value.length === 0;

const canEditTruckArrivalDocument = (user: User | null, shipmentSummary: ShipmentSummary) => {
  const truck = shipmentSummary.bagsSummary.truck;

  return (
    user != null &&
    permissionsService.canEditTruck(user) &&
    truck != null &&
    (truck.state.name === TruckStates.New || truck.state.name === TruckStates.Arrived)
  );
};

const canEditTruckOffloadedDocument = (user: User | null, shipmentSummary: ShipmentSummary, document: DocumentInfo) => {
  if (!user) {
    return false;
  }

  const truck = shipmentSummary.bagsSummary.truck;
  if (!truck) {
    return false;
  }

  if (!document.required) {
    return (
      permissionsService.canEditTruck(user) &&
      (truck.state.name === TruckStates.Inspected || truck.state.name === TruckStates.Offloaded) &&
      (shipmentSummary.state.name !== shipmentStates.Complete ||
        (shipmentSummary.state.name === shipmentStates.Complete && shipmentSummary.state.isOverridden))
    );
  }

  return (permissionsService.canEditTruck(user) && truck.state.name === TruckStates.Inspected) || truck.state.isOverridden;
};

const canEditPilesDocument = (user: User | null, piles: Pile[] | null) => {
  if (!user) {
    return false;
  }

  const anyPiles = piles != null && piles.length > 0;
  const allPilesSampledOrConsumed =
    piles != null && piles.length > 0 && piles.every((p) => p.state.name === PileStates.Sampled || p.state.name === PileStates.Consumed);
  const allPilesOverridden = piles != null && piles.length > 0 && piles.every((p) => p.state.isOverridden);

  return (
    (permissionsService.canEditPile(user) && anyPiles && !allPilesSampledOrConsumed) ||
    (permissionsService.canOverridePile(user) && allPilesOverridden)
  );
};

const canEditContainerDocument = (user: User | null, shipmentSummary: ShipmentSummary) => {
  if (!user) {
    return false;
  }

  const container = shipmentSummary.containerSummary;
  if (!container?.state) {
    return false;
  }

  const nonOverrideCondition = permissionsService.canEditContainer(user) && container.state.name !== containerStates.Done;
  const overrideCondition =
    permissionsService.canOverrideContainer(user) &&
    (container.state.name !== containerStates.Done || (container.state.name === containerStates.Done && container.state.isOverridden));

  return nonOverrideCondition || overrideCondition;
};

const canEditCustomsDocument = (user: User | null, shipmentSummary: ShipmentSummary) => {
  if (!user) {
    return false;
  }

  const shipmentInformationProvided =
    shipmentSummary.shipmentSummary.sailDate != null &&
    !stringIsNullOrEmpty(shipmentSummary.shipmentSummary.shipRegistrationNumber) &&
    !stringIsNullOrEmpty(shipmentSummary.shipmentSummary.shipName) &&
    !stringIsNullOrEmpty(shipmentSummary.shipmentSummary.billOfLadingId) &&
    !stringIsNullOrEmpty(shipmentSummary.shipmentSummary.shipContainerId) &&
    shipmentSummary.shipmentSummary.warehouseRepresentative != null;
  if (!shipmentInformationProvided) {
    return false;
  }

  const nonOverrideCondition =
    permissionsService.canEditShipment(user) &&
    (shipmentSummary.state.name === shipmentStates.WithFreightForwarder || shipmentSummary.state.name === shipmentStates.Sailed);

  const overrideCondition =
    permissionsService.canOverrideShipment(user) &&
    (shipmentSummary.state.name === shipmentStates.WithFreightForwarder ||
      shipmentSummary.state.name === shipmentStates.Sailed ||
      (shipmentSummary.state.name === shipmentStates.Complete && shipmentSummary.state.isOverridden));

  return nonOverrideCondition || overrideCondition;
};

const canEditShipmentDocument = (user: User | null, shipmentSummary: ShipmentSummary, document: DocumentInfo) => {
  if (!user) {
    return false;
  }

  if (!document.required) {
    return (
      permissionsService.canEditShipment(user) &&
      ((shipmentSummary.state.name !== shipmentStates.Future && shipmentSummary.state.name !== shipmentStates.Complete) ||
        (shipmentSummary.state.name === shipmentStates.Complete && shipmentSummary.state.isOverridden))
    );
  }

  const shipmentInformationProvided =
    shipmentSummary.shipmentSummary.sailDate != null &&
    !stringIsNullOrEmpty(shipmentSummary.shipmentSummary.shipRegistrationNumber) &&
    !stringIsNullOrEmpty(shipmentSummary.shipmentSummary.shipName) &&
    !stringIsNullOrEmpty(shipmentSummary.shipmentSummary.billOfLadingId) &&
    !stringIsNullOrEmpty(shipmentSummary.shipmentSummary.shipContainerId) &&
    shipmentSummary.shipmentSummary.warehouseRepresentative != null;
  if (!shipmentInformationProvided) {
    return false;
  }

  const nonOverrideCondition =
    permissionsService.canEditShipment(user) &&
    (shipmentSummary.state.name === shipmentStates.WithFreightForwarder || shipmentSummary.state.name === shipmentStates.Sailed);

  const overrideCondition =
    permissionsService.canOverrideShipment(user) &&
    (shipmentSummary.state.name === shipmentStates.WithFreightForwarder ||
      shipmentSummary.state.name === shipmentStates.Sailed ||
      (shipmentSummary.state.name === shipmentStates.Complete && shipmentSummary.state.isOverridden));

  return nonOverrideCondition || overrideCondition;
};

export interface ShipmentDocumentsProps {
  shipmentSummary: ShipmentSummary;
  piles: Pile[] | null;
  onDocumentUploaded: () => void;
}

export const ShipmentDocuments = ({ shipmentSummary, piles, onDocumentUploaded }: ShipmentDocumentsProps): JSX.Element => {
  const accessToken = useAccessToken();
  const user = useLoggedInUser();
  const { handleServerError } = useServerError();

  const [documents, setDocuments] = useState<Document[] | null>(null);
  const [documentUploadStarted, setDocumentUploadStarted] = useState(false);

  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    (async () => {
      try {
        setError(null);
        setIsLoading(true);
        if (accessToken) {
          const allDocuments = await shipmentsService.getAllDocuments(accessToken, shipmentSummary.id);
          setDocuments(allDocuments);
        }
      } catch (error) {
        const errorMessage = handleServerError(error);
        setError(`${s.ShipmentDocuments_RetrievingDocumentsError}: ${errorMessage}`);
      } finally {
        setIsLoading(false);
      }
    })();
  }, [accessToken, handleServerError, shipmentSummary]);

  const handleDocumentUploadStarted = (uploadStarted: boolean) => {
    setDocumentUploadStarted(uploadStarted);
  };

  const handleDocumentUploaded = async () => {
    try {
      if (accessToken) {
        const allDocuments = await shipmentsService.getAllDocuments(accessToken, shipmentSummary.id);
        setDocuments(allDocuments);
        onDocumentUploaded();
      }
    } catch (error) {
      const errorMessage = handleServerError(error);
      setError(`${s.ShipmentDocuments_RetrievingDocumentsError}: ${errorMessage}`);
    }
  };

  return (
    <>
      {isLoading ? (
        <Spinner />
      ) : (
        <div className={mainContentClassNames}>
          <Notification message={error!} severity="error" onClose={() => setError(null)} />
          <div className={requiredDocumentsTitleClassNames}>{s.ShipmentDocuments_RequiredDocumentsTitle}</div>
          <div>
            {documents &&
              shipmentDocuments.map((doc, index) => (
                <DocumentItem
                  key={index}
                  title={doc.title}
                  canEdit={canEditShipmentDocument(user, shipmentSummary, doc) && !documentUploadStarted}
                  shipmentId={shipmentSummary.id}
                  entityId={shipmentSummary.id}
                  documentTypeId={doc.type}
                  documentRequired={doc.required}
                  document={documents && documents.find((d) => d.documentType.id === doc.type)}
                  handleUploadStarted={handleDocumentUploadStarted}
                  handleUploadStatusChange={handleDocumentUploaded}
                />
              ))}
          </div>

          <div>
            <div className={documentsSectionTitleClassNames}>{s.ShipmentDocuments_CustomsDocumentsTitle}</div>
            {documents &&
              customsDocuments.map((doc, index) => (
                <DocumentItem
                  key={index}
                  title={doc.title}
                  canEdit={canEditCustomsDocument(user, shipmentSummary) && !documentUploadStarted}
                  shipmentId={shipmentSummary.id}
                  entityId={shipmentSummary.id}
                  documentTypeId={doc.type}
                  documentRequired={doc.required}
                  document={documents && documents.find((d) => d.documentType.id === doc.type)}
                  handleUploadStarted={handleDocumentUploadStarted}
                  handleUploadStatusChange={handleDocumentUploaded}
                />
              ))}
          </div>

          <div>
            <div className={documentsSectionTitleClassNames}>{s.ShipmentDocuments_ContainerProcessingDocumentsTitle}</div>
            {documents &&
              containerDocuments.map((doc, index) => (
                <DocumentItem
                  key={index}
                  title={doc.title}
                  canEdit={canEditContainerDocument(user, shipmentSummary) && !documentUploadStarted}
                  shipmentId={shipmentSummary.id}
                  entityId={shipmentSummary.containerSummary.id}
                  documentTypeId={doc.type}
                  documentRequired={doc.required}
                  document={documents && documents.find((d) => d.documentType.id === doc.type)}
                  handleUploadStarted={handleDocumentUploadStarted}
                  handleUploadStatusChange={handleDocumentUploaded}
                />
              ))}
          </div>

          <div>
            <div className={documentsSectionTitleClassNames}>{s.ShipmentDocuments_PileSamplingDocumentsTitle}</div>
            {documents &&
              pileSamplingDocuments.map((doc, index) => (
                <DocumentItem
                  key={index}
                  title={doc.title}
                  canEdit={canEditPilesDocument(user, piles) && !documentUploadStarted}
                  shipmentId={shipmentSummary.id}
                  entityId={shipmentSummary.id}
                  documentTypeId={doc.type}
                  documentRequired={doc.required}
                  document={documents && documents.find((d) => d.documentType.id === doc.type)}
                  handleUploadStarted={handleDocumentUploadStarted}
                  handleUploadStatusChange={handleDocumentUploaded}
                />
              ))}
          </div>

          <div>
            <div className={documentsSectionTitleClassNames}>{s.ShipmentDocuments_BagProcessingDocumentsTitle}</div>
            {documents &&
              truckArrivalDocuments.map((doc, index) => (
                <DocumentItem
                  key={index}
                  title={doc.title}
                  canEdit={canEditTruckArrivalDocument(user, shipmentSummary) && !documentUploadStarted}
                  shipmentId={shipmentSummary.id}
                  entityId={shipmentSummary.bagsSummary.truck?.id}
                  documentTypeId={doc.type}
                  documentRequired={doc.required}
                  document={documents && documents.find((d) => d.documentType.id === doc.type)}
                  handleUploadStarted={handleDocumentUploadStarted}
                  handleUploadStatusChange={handleDocumentUploaded}
                />
              ))}
            {documents &&
              truckOffloadedDocuments.map((doc, index) => (
                <DocumentItem
                  key={index}
                  title={doc.title}
                  canEdit={canEditTruckOffloadedDocument(user, shipmentSummary, doc) && !documentUploadStarted}
                  shipmentId={shipmentSummary.id}
                  entityId={shipmentSummary.bagsSummary.truck?.id}
                  documentTypeId={doc.type}
                  documentRequired={doc.required}
                  document={documents && documents.find((d) => d.documentType.id === doc.type)}
                  handleUploadStarted={handleDocumentUploadStarted}
                  handleUploadStatusChange={handleDocumentUploaded}
                />
              ))}
          </div>

          <div>
            <div className={documentsSectionTitleClassNames}>{s.ShipmentDocuments_AdditionalDocumentsTitle}</div>
            {documents &&
              additionalDocuments.map((doc, index) => (
                <DocumentItem
                  key={index}
                  title={doc.title}
                  canEdit={canEditShipmentDocument(user, shipmentSummary, doc) && !documentUploadStarted}
                  shipmentId={shipmentSummary.id}
                  entityId={shipmentSummary.id}
                  documentTypeId={doc.type}
                  documentRequired={doc.required}
                  document={documents && documents.find((d) => d.documentType.id === doc.type)}
                  handleUploadStarted={handleDocumentUploadStarted}
                  handleUploadStatusChange={handleDocumentUploaded}
                />
              ))}
          </div>
        </div>
      )}
    </>
  );
};
