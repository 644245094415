import { useState } from 'react';

import { Document } from 'models/document';
import { documentsService } from 'services/documentsService';
import { documentsLocalCacheService } from 'services/cacheService/documentsLocalCacheService';
import { useAccessToken, useAppNavigation, useServerError } from 'hooks';
import { formatDate } from 'utilities';

import { IconButton } from '@mui/material';

import {
  alignItems,
  borderColor,
  borderRadius,
  borderWidth,
  display,
  flexDirection,
  justifyContent,
  margin,
  padding,
  textColor,
  typedUtilityClassnames,
} from 'style/compoundClassnames';

import { s } from 'i18n/strings';

import { FileUploader } from 'components/FileUploader';
import { PrimaryButton } from 'components/buttons';

import { ReactComponent as FileUploadedIcon } from 'icons/fileUploaded.svg';
import { ReactComponent as FileNotUploadedIcon } from 'icons/fileNotUploaded.svg';
import { ReactComponent as TrashIcon } from 'icons/trash.svg';

const documentItemContainerClassNames = typedUtilityClassnames(
  display('flex'),
  justifyContent('justify-between'),
  margin('mt-2'),
  alignItems('items-center'),
  borderWidth('border'),
  borderColor('border-outlineColour'),
  borderRadius('rounded'),
);
const documentItemTitleContainerClassNames = typedUtilityClassnames(display('flex'));
const documentItemIconClassNames = typedUtilityClassnames(margin('mx-4', 'my-5'));
const documentItemTitleCaptionClassNames = typedUtilityClassnames(
  display('flex'),
  justifyContent('justify-start'),
  flexDirection('flex-col'),
  padding('py-2'),
);
const documentItemTitleClassNames = typedUtilityClassnames('label', textColor('text-onSurface-highEmphasis'));
const documentItemSubTitleClassNames = typedUtilityClassnames('caption', textColor('text-onSurface-disabled'));
const documentItemButtonClassNames = typedUtilityClassnames(margin('my-1', 'mr-4'));
const documentItemMultipleButtonsClassNames = typedUtilityClassnames(display('flex'));
const documentItemRemoveButtonClassNames = typedUtilityClassnames(padding('px-4'), margin('mr-1'));

export interface DocumentItemProps {
  title: string;
  canEdit: boolean;
  shipmentId: number | null | undefined;
  entityId: number | null | undefined;
  documentTypeId: number;
  documentRequired: boolean;
  document?: Document | null;
  handleUploadStarted?: (uploadStarted: boolean) => void;
  handleUploadStatusChange?: () => void;
}

export const DocumentItem = ({
  title,
  canEdit,
  shipmentId,
  entityId,
  documentTypeId,
  documentRequired,
  document,
  handleUploadStarted,
  handleUploadStatusChange,
}: DocumentItemProps): JSX.Element => {
  const accessToken = useAccessToken();
  const { navigateDocument } = useAppNavigation();
  const { handleServerError } = useServerError();

  const [uploadedDocument, setUploadedDocument] = useState<Document | null | undefined>(document);
  const [uploadStarted, setUploadStarted] = useState(false);
  const [error, setError] = useState<string | null>(null);

  const refreshDocumentStatus = async (error?: Error | null) => {
    if (error) {
      handleServerError(error);
      setError(s.DocumentItem_UploadFileError);
    }

    if (accessToken && shipmentId && entityId && documentTypeId) {
      try {
        const documents = await documentsService.getByEntityId(accessToken, entityId);
        const matchingDocument = documents.find((d) => d.documentType.id === documentTypeId);
        if (matchingDocument) {
          setUploadedDocument(matchingDocument);
        } else {
          setUploadedDocument(null);
        }

        if (handleUploadStatusChange) {
          handleUploadStatusChange();
        }
      } catch (error) {
        const errorMessage = handleServerError(error);
        setError(`${s.DocumentItem_GetFileError}: ${errorMessage}`);
      }
    }
  };

  const handleStartUpload = () => {
    setUploadStarted(true);
    if (handleUploadStarted) {
      handleUploadStarted(true);
    }
  };

  const handleUploadFinished = async (error?: Error | null) => {
    setUploadStarted(false);
    if (handleUploadStarted) {
      handleUploadStarted(false);
    }
    await refreshDocumentStatus(error);
  };

  const handleViewDocument = (documentId: number) => () => {
    if (shipmentId) {
      navigateDocument(shipmentId, documentId);
    }
  };

  const handleDeleteDocument = (document: Document | null | undefined) => async () => {
    if (accessToken && document) {
      try {
        await documentsService.delete(accessToken, document.id);

        if (await documentsLocalCacheService.has(document.filename)) {
          await documentsLocalCacheService.remove(document.filename);
        }
      } catch (error) {
        const errorMessage = handleServerError(error);
        setError(`${s.DocumentItem_DeleteFileError}: ${errorMessage}`);
        return;
      }

      await refreshDocumentStatus();
    }
  };

  return (
    <>
      <div className={documentItemContainerClassNames}>
        <div className={documentItemTitleContainerClassNames}>
          <div className={documentItemIconClassNames}>{uploadedDocument ? <FileUploadedIcon /> : <FileNotUploadedIcon />}</div>
          <div className={documentItemTitleCaptionClassNames}>
            <div className={documentItemTitleClassNames}>{title}</div>
            <div className={documentItemSubTitleClassNames}>
              {uploadedDocument
                ? s.DocumentItem_SubTitleForUploadedDocument.replace('{{uploadedBy}}', uploadedDocument.uploadedBy.fullName).replace(
                    '{{uploadedOn}}',
                    formatDate.getLongDate(uploadedDocument.uploadedOn),
                  )
                : uploadStarted
                ? s.DocumentItem_SubTitleUploadingInProgress
                : error != null
                ? error
                : documentRequired
                ? s.DocumentItem_SubTitleRequired
                : s.DocumentItem_SubTitleOptional}
            </div>
          </div>
        </div>
        <div className={documentItemButtonClassNames}>
          {uploadedDocument ? (
            <div className={documentItemMultipleButtonsClassNames}>
              {canEdit && (
                <IconButton onClick={handleDeleteDocument(uploadedDocument)} className={documentItemRemoveButtonClassNames}>
                  <TrashIcon />
                </IconButton>
              )}
              <PrimaryButton label={s.DocumentItem_ViewButtonCaption} onClick={handleViewDocument(uploadedDocument.id)} />
            </div>
          ) : (
            <FileUploader
              canUpload={canEdit}
              entityId={entityId}
              documentTypeId={documentTypeId}
              onStarted={handleStartUpload}
              onSuccess={handleUploadFinished}
              onError={(err) => handleUploadFinished(err)}
            />
          )}
        </div>
      </div>
    </>
  );
};
