import { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useForm, SubmitHandler } from 'react-hook-form';

import { User } from 'models/user';
import { ShipmentSummary } from 'models/shipment';
import { permissionsService } from 'services/permissionsService';
import { shipmentsService } from 'services/shipmentsService';
import { SamplingWrite } from 'models/sampling';
import { InstitutionNames, RepresentativeWrite } from 'models/representative';
import { Pile } from 'models/pile';
import { useAccessToken, useLoggedInUser, useAppNavigation, useServerError } from 'hooks';

import { s } from 'i18n/strings';

import { display, justifyContent, typedUtilityClassnames } from 'style/compoundClassnames';

import { ContextHeader } from 'components/headers';
import { buttonTypes, PrimaryButton, SecondaryButton } from 'components/buttons';
import { Spinner } from 'components/Spinner';
import { Notification } from 'components/Notification';
import {
  SamplingInformationForm,
  SamplingInformationFormState,
  samplingInformationFormStateFieldNames,
  samplingInformationFormStateSchema,
} from 'components/form-components';
import { zodResolver } from '@hookform/resolvers/zod';

const formContentContainerClassNames = typedUtilityClassnames('mainLayoutPadding', display('flex'), justifyContent('justify-center'));

const samplingInformationFormId = 'samplingInformationForm';

const canEditSamplingInformationForm = (user: User | null, isSavingForm: boolean, piles: Pile[] | null) => {
  if (!user || !piles || isSavingForm) {
    return false;
  }

  return permissionsService.canEditPile(user) && piles.length > 0;
};

export const SamplingInformationPage = (): JSX.Element => {
  const { shipmentId } = useParams<{ shipmentId: string }>();
  const accessToken = useAccessToken();
  const user = useLoggedInUser();

  const { navigatePilesProcessing, navigateShipments } = useAppNavigation();
  const { handleServerError } = useServerError();

  const [shipmentSummary, setShipmentSummary] = useState<ShipmentSummary | null>(null);
  const [piles, setPiles] = useState<Pile[] | null>(null);

  const [isSavingForm, setIsSavingForm] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  const {
    register,
    formState: {
      errors,
      errors: {
        [samplingInformationFormStateFieldNames.robinsonReferenceNo]: robinsonReferenceNoInputError,
        [samplingInformationFormStateFieldNames.contactFirstName]: contactFirstNameInputError,
        [samplingInformationFormStateFieldNames.contactLastName]: contactLastNameInputError,
      },
      isDirty,
    },
    handleSubmit,
    reset,
  } = useForm<SamplingInformationFormState>({
    resolver: zodResolver(samplingInformationFormStateSchema),
    defaultValues: {
      [samplingInformationFormStateFieldNames.robinsonReferenceNo]: '',
      [samplingInformationFormStateFieldNames.contactFirstName]: '',
      [samplingInformationFormStateFieldNames.contactLastName]: '',
    },
  });

  useEffect(() => {
    (async () => {
      try {
        setError(null);
        setIsLoading(true);
        // TODO: factor out accessToken null checking into a base API class to run at every call, then remove access token null checking from all components
        if (accessToken && shipmentId) {
          const shipmentSummaryRequest = shipmentsService.getSummary(accessToken, parseInt(shipmentId));
          const pilesRequest = shipmentsService.getPiles(accessToken, parseInt(shipmentId));
          const [shipmentSummary, piles] = await Promise.all([shipmentSummaryRequest, pilesRequest]);
          setPiles(piles);
          if (shipmentSummary) {
            setShipmentSummary(shipmentSummary);
            reset({
              [samplingInformationFormStateFieldNames.robinsonReferenceNo]: shipmentSummary.pilesSummary.samplingReference,
              [samplingInformationFormStateFieldNames.contactFirstName]: shipmentSummary.pilesSummary.samplingTechnician?.firstName,
              [samplingInformationFormStateFieldNames.contactLastName]: shipmentSummary.pilesSummary.samplingTechnician?.lastName,
            });
          }
        }
      } catch (error) {
        const errorMessage = handleServerError(error);
        setError(`${s.SamplingInformationPage_RetrievingSamplingInformationError}: ${errorMessage}`);
      } finally {
        setIsLoading(false);
      }
    })();
  }, [accessToken, handleServerError, reset, shipmentId]);

  const onSubmit: SubmitHandler<SamplingInformationFormState> = async (samplingInformationState) => {
    try {
      setIsSavingForm(true);
      // TODO: factor out accessToken null checking into a base API class to run at every call, then remove access token null checking from all components
      if (accessToken && shipmentId) {
        const samplingWrite = new SamplingWrite(
          samplingInformationState.robinsonReferenceNo,
          new RepresentativeWrite(
            samplingInformationState.contactFirstName,
            samplingInformationState.contactLastName,
            InstitutionNames.Robinson,
          ),
        );

        await shipmentsService.updateSamplingInformation(accessToken, parseInt(shipmentId), samplingWrite);
        shipmentId ? navigatePilesProcessing(shipmentId) : navigateShipments();
      }
    } catch (error) {
      const errorMessage = handleServerError(error);
      setError(`${s.SamplingInformationPage_SavingSamplingInformationError}: ${errorMessage}`);
    } finally {
      setIsSavingForm(false);
    }
  };

  const handleCancel = () => {
    shipmentId ? navigatePilesProcessing(shipmentId) : navigateShipments();
  };

  const canEdit = canEditSamplingInformationForm(user, isSavingForm, piles);

  return (
    <>
      {isLoading ? (
        <Spinner />
      ) : (
        <>
          <ContextHeader
            contextTitle={s.SamplingInformationPage_Title}
            contextSubTitle={shipmentSummary?.shipmentReference}
            leftButtonOne={<SecondaryButton label={s.SamplingInformationPage_CancelButtonCaption} onClick={handleCancel} />}
            rightButtonOne={
              <PrimaryButton
                label={s.SamplingInformationPage_SaveButtonCaption}
                type={buttonTypes.submit}
                form={samplingInformationFormId}
                disabled={!canEdit || !isDirty}
              />
            }
            showContextHeaderContentSpacer
          />
          <Notification message={error!} severity="error" onClose={() => setError(null)} />
          <section className={formContentContainerClassNames}>
            <SamplingInformationForm
              formId={samplingInformationFormId}
              canEdit={canEdit}
              onSubmit={onSubmit}
              handleSubmit={handleSubmit}
              register={register}
              robinsonReferenceNoInputError={robinsonReferenceNoInputError}
              contactFirstNameInputError={contactFirstNameInputError}
              contactLastNameInputError={contactLastNameInputError}
              errors={errors}
            />
          </section>
        </>
      )}
    </>
  );
};
