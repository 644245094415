import { useState, useEffect } from 'react';
import { User } from 'models/user';
import { sessionStorageService, sessionStorageKeys } from 'services/sessionStorageService';

export const useLoggedInUser = () => {
  const [user, setUser] = useState<User | null>(null);

  useEffect(() => {
    try {
      const loggedInUser = sessionStorageService.get<User>(sessionStorageKeys.loggedInUserData);
      if (loggedInUser != null) {
        setUser(loggedInUser);
      }
    } catch (error) {
      console.error('Could not get logged in user data');
    }
  }, []);

  return user;
};
