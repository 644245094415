const monthShortNames = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];

const monthLongNames = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
];

export const formatDate = {
  getLongDate(date: Date) {
    const dateTime = new Date(date);
    return `${dateTime.getDate().toString()} ${monthLongNames[dateTime.getMonth()]} ${dateTime.getFullYear()}`;
  },

  getDateAndTime(date: Date) {
    const dateTime = new Date(date);
    return `${dateTime.getDate().toString().padStart(2, '0')}/${(dateTime.getMonth() + 1)
      .toString()
      .padStart(2, '0')}/${dateTime.getFullYear()} ${dateTime.getHours().toString().padStart(2, '0')}:${dateTime
      .getMinutes()
      .toString()
      .padStart(2, '0')}`;
  },

  getActivityDate(date: Date) {
    const dateTime = new Date(date);
    return `${dateTime.getDate().toString()} ${monthShortNames[dateTime.getMonth()]} ${dateTime.getFullYear()} at ${dateTime
      .getHours()
      .toString()
      .padStart(2, '0')}h${dateTime.getMinutes().toString().padStart(2, '0')}`;
  },

  getDepartureDate(date: Date) {
    const dateTime = new Date(date);
    return `${monthLongNames[dateTime.getMonth()]} ${dateTime.getDate().toString()}, ${dateTime.getFullYear()}`;
  },

  getDate(date: Date) {
    const dateTime = new Date(date);
    return `${dateTime.getFullYear()}-${(dateTime.getMonth() + 1).toString().padStart(2, '0')}-${dateTime
      .getDate()
      .toString()
      .padStart(2, '0')}`;
  },

  getFilterDate(date: Date) {
    const dateTime = new Date(date);
    return `${monthLongNames[dateTime.getMonth()]} ${dateTime.getFullYear()}`;
  },

  getTime(date: Date) {
    const dateTime = new Date(date);
    return `${dateTime.getHours().toString().padStart(2, '0')}:${dateTime.getMinutes().toString().padStart(2, '0')}`;
  },
};
