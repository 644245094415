import { useEffect, useState } from 'react';
import { useParams } from 'react-router';

import { Shipment, shipmentStates, ShipmentSummaryShipment } from 'models/shipment';
import { Document, DocumentInfo, documentTypes } from 'models/document';
import { User } from 'models/user';
import { SensorData } from 'models/sensorData';
import { permissionsService } from 'services/permissionsService';
import { shipmentsService } from 'services/shipmentsService';
import { useAccessToken, useLoggedInUser, useAppNavigation, useServerError } from 'hooks';

import { s } from 'i18n/strings';

import { display, flexDirection, justifyContent, onlyComputedCombineClassnames, typedUtilityClassnames } from 'style/compoundClassnames';

import { PrimaryButton, SecondaryButton } from 'components/buttons';
import { HeaderItem } from 'components/HeaderItem';
import { ContextHeader } from 'components/headers';
import { DocumentItem } from 'components/DocumentItem';
import { Spinner } from 'components/Spinner';
import { IoTDeviceInformationManifestItem, ShipmentInformationManifestItem } from 'components/manifest-items';
import { ActionConfirmationDialog } from 'components/dialogs';
import { Notification } from 'components/Notification';

import { ReactComponent as ShipmentIcon } from 'icons/shipment.svg';
import { ReactComponent as DocumentsIcon } from 'icons/documents.svg';

const mainContentClassNames = onlyComputedCombineClassnames(
  typedUtilityClassnames('mainLayoutPadding'),
  display('flex'),
  justifyContent('justify-center'),
  flexDirection('flex-col'),
);

const shipmentDocuments: DocumentInfo[] = [
  { title: s.ShipmentDocuments_VesselPackingListDocumentItemTitle, type: documentTypes.VesselPackingList, required: true },
  { title: s.ShipmentDocuments_ForkliftWeightRecordDocumentItemTitle, type: documentTypes.ForkliftWeightRecord, required: true },
  { title: s.ShipmentDocuments_BillOfLadingDocumentItemTitle, type: documentTypes.BillOfLading, required: false },
];

const stringIsNullOrEmpty = (value: string) => value == null || value.length === 0;

const shipmentInformationProvided = (shipment: Shipment | null) =>
  shipment != null &&
  shipment.sailDate != null &&
  !stringIsNullOrEmpty(shipment.shipRegistrationNumber) &&
  !stringIsNullOrEmpty(shipment.shipName) &&
  !stringIsNullOrEmpty(shipment.billOfLadingId) &&
  !stringIsNullOrEmpty(shipment.shipContainerId) &&
  shipment.warehouseRepresentative != null;

const canEditShipmentInformation = (user: User | null, shipment: Shipment | null) =>
  user != null && permissionsService.canEditShipment(user) && shipment != null;

const canEditShipmentDocument = (user: User | null, shipment: Shipment | null, document: DocumentInfo) => {
  if (!user || !shipment || !shipmentInformationProvided(shipment)) {
    return false;
  }

  if (!document.required) {
    return (
      permissionsService.canEditShipment(user) &&
      (shipment.state.name !== shipmentStates.Complete || (shipment.state.name === shipmentStates.Complete && shipment.state.isOverridden))
    );
  }

  const nonOverrideCondition =
    permissionsService.canEditShipment(user) &&
    (shipment.state.name === shipmentStates.WithFreightForwarder || shipment.state.name === shipmentStates.Sailed);

  const overrideCondition =
    permissionsService.canOverrideShipment(user) &&
    (shipment.state.name !== shipmentStates.Complete || (shipment.state.name === shipmentStates.Complete && shipment.state.isOverridden));

  return nonOverrideCondition || overrideCondition;
};

const canConfirmShipSailed = (user: User | null, shipment: Shipment | null) =>
  user != null &&
  permissionsService.canEditShipment(user) &&
  shipmentInformationProvided(shipment) &&
  shipment != null &&
  shipment.state.name === shipmentStates.WithFreightForwarder;

export const ShipmentProcessingPage = () => {
  const { shipmentId } = useParams<{ shipmentId: string }>();
  const accessToken = useAccessToken();
  const user = useLoggedInUser();

  const { navigateViewShipment, navigateShipments, navigateShipmentDetails, navigateIoTDeviceInformation } = useAppNavigation();
  const { handleServerError } = useServerError();

  const [shipment, setShipment] = useState<Shipment | null>(null);
  const [shipmentSummaryShipment, setShipmentSummaryShipment] = useState<ShipmentSummaryShipment | null>(null);
  const [documents, setDocuments] = useState<Document[] | null>(null);

  const [missingDocuments, setMissingDocuments] = useState<DocumentInfo[]>([]);
  const handleMissingDocuments = (documents: Document[]) => {
    const missingDocuments: DocumentInfo[] = [];
    missingDocuments.push(
      ...shipmentDocuments.filter((d) => d.required && !documents.map((item) => item.documentType.id).includes(d.type)),
    );
    setMissingDocuments(missingDocuments);
  };

  const [sensorData, setSensorData] = useState<SensorData | null>(null);

  const [documentUploadStarted, setDocumentUploadStarted] = useState(false);

  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  const [openConfirmationDialog, setOpenConfirmationDialog] = useState(false);
  const handleOpenConfirmationDialog = () => setOpenConfirmationDialog(true);
  const handleCloseConfirmationDialog = () => setOpenConfirmationDialog(false);

  useEffect(() => {
    (async () => {
      // TODO: Refactor out accessToken null checks to shared API client class that will throw if null for any call, then remove this from every component
      if (!accessToken || !shipmentId) {
        return;
      }

      try {
        setError(null);
        setIsLoading(true);

        const shipmentRequest = shipmentsService.getById(accessToken, parseInt(shipmentId));
        const documentsRequest = shipmentsService.getAllDocuments(accessToken, parseInt(shipmentId));
        const iotSensorDataRequest = shipmentsService.getSensorData(accessToken, parseInt(shipmentId));

        const [shipment, documents, iotSensorData] = await Promise.all([shipmentRequest, documentsRequest, iotSensorDataRequest]);
        if (!shipment) {
          return;
        }

        setShipment(shipment);
        setShipmentSummaryShipment(
          new ShipmentSummaryShipment(
            shipment.shipRegistrationNumber,
            shipment.shipName,
            shipment.sailDate,
            shipment.billOfLadingId,
            shipment.shipContainerId,
            shipment.warehouseRepresentative,
          ),
        );

        setDocuments(documents);
        handleMissingDocuments(documents);

        setSensorData(iotSensorData);
      } catch (error) {
        const errorMessage = handleServerError(error);
        setError(`${s.ShipmentProcessingPage_GetShipmentDetailsError}: ${errorMessage}`);
      } finally {
        setIsLoading(false);
      }
    })();
  }, [accessToken, handleServerError, shipmentId]);

  const handleBack = () => {
    shipmentId ? navigateViewShipment(shipmentId) : navigateShipments();
  };

  const handleConfirmShipSailed = async (isOverridden: boolean, overrideNote: string) => {
    try {
      if (accessToken && shipmentId && canConfirmShipSailed(user, shipment)) {
        if (isOverridden) {
          await shipmentsService.confirmShipSailedWithOverride(accessToken, parseInt(shipmentId), overrideNote);
        } else {
          await shipmentsService.confirmShipSailed(accessToken, parseInt(shipmentId));
        }
        handleCloseConfirmationDialog();
        navigateViewShipment(shipmentId);
      }
    } catch (error) {
      handleCloseConfirmationDialog();
      const errorMessage = handleServerError(error);
      setError(`${s.ShipmentProcessingPage_ConfirmShipSailedError}: ${errorMessage}`);
    }
  };

  const handleEditShipmentInformation = () => {
    if (shipmentId) {
      navigateShipmentDetails(shipmentId);
    }
  };

  const handleEditIoTDeviceInformation = () => {
    if (shipmentId) {
      navigateIoTDeviceInformation(shipmentId);
    }
  };

  const handleDocumentUploadStarted = (uploadStarted: boolean) => {
    setDocumentUploadStarted(uploadStarted);
  };

  const handleDocumentUploaded = async () => {
    try {
      if (accessToken && shipmentId) {
        const documents = await shipmentsService.getAllDocuments(accessToken, parseInt(shipmentId));
        if (documents) {
          setDocuments(documents);
          handleMissingDocuments(documents);
        }
      }
    } catch (error) {
      const errorMessage = handleServerError(error);
      setError(`${s.ShipmentProcessingPage_GetShipmentDocumentsError}: ${errorMessage}`);
    }
  };

  return (
    <>
      {isLoading ? (
        <Spinner />
      ) : (
        <>
          <ContextHeader
            contextTitle={s.ShipmentProcessingPage_Title}
            contextSubTitle={shipment?.shipmentReference}
            leftButtonOne={<SecondaryButton label={s.ShipmentProcessingPage_BackButton} onClick={handleBack} />}
            rightButtonOne={
              <PrimaryButton
                label={s.ShipmentProcessingPage_FinishButton}
                onClick={handleOpenConfirmationDialog}
                disabled={!canConfirmShipSailed(user, shipment)}
              />
            }
            showContextHeaderContentSpacer
          />
          <div className={mainContentClassNames}>
            <Notification message={error!} severity="error" onClose={() => setError(null)} />
            {!shipment?.shipRegistrationNumber ? (
              <HeaderItem
                title={s.ShipmentProcessingPage_ShipmentInformationItemTitle}
                icon={<ShipmentIcon />}
                actionButton={
                  <SecondaryButton
                    label={s.ShipmentProcessingPage_ShipmentInformationItem_EditButton}
                    disabled={!canEditShipmentInformation(user, shipment)}
                    onClick={handleEditShipmentInformation}
                  />
                }
                actionButtonVisible={true}
              />
            ) : (
              <ShipmentInformationManifestItem
                shipmentSummary={shipmentSummaryShipment!}
                isInOverview={false}
                canEdit={canEditShipmentInformation(user, shipment)}
                editOnClick={handleEditShipmentInformation}
              />
            )}

            <IoTDeviceInformationManifestItem
              sensorData={sensorData!}
              canEdit={canEditShipmentInformation(user, shipment)}
              editOnClick={handleEditIoTDeviceInformation}
            />

            <HeaderItem title={s.ShipmentProcessingPage_DocumentsItemTitle} icon={<DocumentsIcon />} />
            {shipmentDocuments.map((doc, index) => (
              <DocumentItem
                key={index}
                title={doc.title}
                canEdit={canEditShipmentDocument(user, shipment, doc) && !documentUploadStarted}
                shipmentId={shipment?.id}
                entityId={shipment?.id}
                documentTypeId={doc.type}
                documentRequired={doc.required}
                document={documents && documents.find((d) => d.documentType.id === doc.type)}
                handleUploadStarted={handleDocumentUploadStarted}
                handleUploadStatusChange={handleDocumentUploaded}
              />
            ))}
          </div>
        </>
      )}

      {user && (
        <ActionConfirmationDialog
          open={openConfirmationDialog}
          title={s.ShipmentProcessingPage_ConfirmationDialogTitle}
          items={[
            {
              label:
                missingDocuments.length > 0
                  ? s.ShipmentProcessingPage_ConfirmationDialogDocumentsMissingItemLabel
                  : s.ShipmentProcessingPage_ConfirmationDialogDocumentsItemLabel,
              successCondition: missingDocuments.length === 0,
              successMessage: s.ShipmentProcessingPage_ConfirmationDialogDocumentsSuccessMessage,
              errorMessage: missingDocuments.map((d) => d.title).join(', '),
            },
          ]}
          confirmButtonCaption={s.ShipmentProcessingPage_ConfirmationDialogConfirmButtonCaption}
          handleConfirm={handleConfirmShipSailed}
          cancelButtonCaption={s.ShipmentProcessingPage_ConfirmationDialogCancelButtonCaption}
          handleCancel={handleCloseConfirmationDialog}
          allowOverride={permissionsService.canOverrideShipment(user)}
        />
      )}
    </>
  );
};
