import { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { SubmitHandler, useForm } from 'react-hook-form';

import { Shipment, ShipmentDepartureWrite } from 'models/shipment';
import { RepresentativeWrite } from 'models/representative';
import { User } from 'models/user';
import { shipmentsService } from 'services/shipmentsService';
import { permissionsService } from 'services/permissionsService';
import { useAccessToken, useLoggedInUser, useAppNavigation, useServerError } from 'hooks';

import { s } from 'i18n/strings';

import { zodResolver } from '@hookform/resolvers/zod';
import { ShipForm, ShipFormState, shipFormStateFieldNames, shipFormStateSchema } from 'components/form-components/forms';

import { display, justifyContent, typedUtilityClassnames } from 'style/compoundClassnames';

import { buttonTypes, PrimaryButton, SecondaryButton } from 'components/buttons';
import { ContextHeader } from 'components/headers';
import { Spinner } from 'components/Spinner';
import { Notification } from 'components/Notification';

const formContentContainerClassNames = typedUtilityClassnames('mainLayoutPadding', display('flex'), justifyContent('justify-center'));

const shipFormId = 'shipForm';

const canEditShipForm = (user: User | null, isSavingForm: boolean) => {
  if (!user || isSavingForm) {
    return false;
  }

  return permissionsService.canEditShipment(user) || permissionsService.canOverrideContainer(user);
};

export const ShipmentDetailsPage = () => {
  const { shipmentId } = useParams<{ shipmentId: string }>();
  const accessToken = useAccessToken();
  const user = useLoggedInUser();

  const { navigateShipments, navigateShipmentProcessing } = useAppNavigation();
  const { handleServerError } = useServerError();

  const [shipment, setShipment] = useState<Shipment | null>(null);
  const [sailDate, setSailDate] = useState<Date | null>(null);
  const [sailDateChanged, setSailDateChanged] = useState(false);

  const [isLoading, setIsLoading] = useState(true);
  const [isSavingForm, setIsSavingForm] = useState(false);
  const [error, setError] = useState<string | null>(null);

  const {
    register,
    formState: {
      errors,
      errors: {
        [shipFormStateFieldNames.shipRegistrationNumber]: shipRegistrationNumberInputError,
        [shipFormStateFieldNames.shipName]: shipNameInputError,
        [shipFormStateFieldNames.billOfLadingId]: billOfLadingIdInputError,
        [shipFormStateFieldNames.shipContainerId]: shipmentContainerIdInputError,
        [shipFormStateFieldNames.contactFirstName]: contactFirstNameInputError,
        [shipFormStateFieldNames.contactLastName]: contactLastNameInputError,
        [shipFormStateFieldNames.contactCompany]: contactCompanyInputError,
        [shipFormStateFieldNames.agent]: agentInputError,
        [shipFormStateFieldNames.carrier]: carrierInputError,
      },
      isDirty,
    },
    handleSubmit,
    reset,
  } = useForm<ShipFormState>({
    resolver: zodResolver(shipFormStateSchema),
    defaultValues: {
      [shipFormStateFieldNames.shipRegistrationNumber]: '',
      [shipFormStateFieldNames.shipName]: '',
      [shipFormStateFieldNames.billOfLadingId]: '',
      [shipFormStateFieldNames.shipContainerId]: '',
      [shipFormStateFieldNames.contactFirstName]: '',
      [shipFormStateFieldNames.contactLastName]: '',
      [shipFormStateFieldNames.contactCompany]: '',
      [shipFormStateFieldNames.agent]: '',
      [shipFormStateFieldNames.carrier]: '',
    },
  });

  useEffect(() => {
    (async () => {
      // TODO: Refactor out accessToken null checks to shared API client class that will throw if null for any call, then remove this from every component
      if (!accessToken || !shipmentId) {
        return;
      }

      try {
        setError(null);
        setIsLoading(true);

        const shipmentRequest = shipmentsService.getById(accessToken, parseInt(shipmentId));

        const [shipment] = await Promise.all([shipmentRequest]);
        if (!shipment) {
          return;
        }

        setShipment(shipment);
        setSailDate(shipment.sailDate);

        reset({
          [shipFormStateFieldNames.shipRegistrationNumber]: shipment.shipRegistrationNumber || '',
          [shipFormStateFieldNames.shipName]: shipment.shipName || '',
          [shipFormStateFieldNames.billOfLadingId]: shipment.billOfLadingId || '',
          [shipFormStateFieldNames.shipContainerId]: shipment.shipContainerId || '',
          [shipFormStateFieldNames.contactFirstName]: shipment.warehouseRepresentative?.firstName || '',
          [shipFormStateFieldNames.contactLastName]: shipment.warehouseRepresentative?.lastName || '',
          [shipFormStateFieldNames.contactCompany]: shipment.warehouseRepresentative?.institutionName || '',
          [shipFormStateFieldNames.agent]: shipment.warehouseRepresentative?.emailAddress || '',
          [shipFormStateFieldNames.carrier]: shipment.carrier || '',
        });
      } catch (error) {
        const errorMessage = handleServerError(error);
        setError(`${s.ShipmentDetailsPage_GetShipmentDetailsError}: ${errorMessage}`);
      } finally {
        setIsLoading(false);
      }
    })();
  }, [accessToken, handleServerError, reset, shipmentId]);

  const onSubmit: SubmitHandler<ShipFormState> = async (shipState) => {
    // TODO: check if user can save the truck
    try {
      setIsSavingForm(true);

      if (accessToken && shipmentId && shipment) {
        const shipmentDepartureWrite = new ShipmentDepartureWrite(
          shipState.shipRegistrationNumber,
          shipState.shipName,
          new Date(sailDate!),
          shipState.billOfLadingId,
          shipState.shipContainerId,
          new RepresentativeWrite(shipState.contactFirstName, shipState.contactLastName, shipState.contactCompany, shipState.agent),
        );

        if (shipState.carrier) {
          shipmentDepartureWrite.carrier = shipState.carrier;
        }

        await shipmentsService.updateShipmentDepartureInfo(accessToken, shipment.id, shipmentDepartureWrite);
        navigateShipmentProcessing(shipmentId);
      }
    } catch (error) {
      const errorMessage = handleServerError(error);
      setError(`${s.ShipmentDetailsPage_SaveShipmentInformationError}: ${errorMessage}`);
    } finally {
      setIsSavingForm(false);
    }
  };

  const handleCancel = () => {
    shipmentId ? navigateShipmentProcessing(shipmentId) : navigateShipments();
  };

  const handleSailDateChanged = (date: Date) => {
    setSailDate(date);
    setSailDateChanged(true);
  };

  const canEdit = canEditShipForm(user, isSavingForm);

  const isValidDate = (date: Date | null) => {
    if (date == null) {
      return false;
    }
    return !isNaN(new Date(date).getTime());
  };

  return (
    <>
      {isLoading ? (
        <Spinner />
      ) : (
        <>
          <ContextHeader
            contextTitle={s.ShipmentDetailsPage_Title}
            contextSubTitle={shipment?.shipmentReference}
            leftButtonOne={<SecondaryButton label={s.ShipmentDetailsPage_CancelButton} onClick={handleCancel} />}
            rightButtonOne={
              <PrimaryButton
                label={s.ShipmentDetailsPage_SaveButton}
                type={buttonTypes.submit}
                disabled={!canEdit || !(isDirty || sailDateChanged) || !isValidDate(sailDate)}
                form={shipFormId}
              />
            }
            showContextHeaderContentSpacer
          />
          <Notification message={error!} severity="error" onClose={() => setError(null)} />
          {shipmentId && shipment && (
            <section className={formContentContainerClassNames}>
              <ShipForm
                formId={shipFormId}
                disallowEdit={!canEdit}
                onSubmit={onSubmit}
                handleSubmit={handleSubmit}
                register={register}
                shipRegistrationNumberInputError={shipRegistrationNumberInputError}
                shipNameInputError={shipNameInputError}
                billOfLadingIdInputError={billOfLadingIdInputError}
                shipmentContainerIdInputError={shipmentContainerIdInputError}
                contactFirstNameInputError={contactFirstNameInputError}
                contactLastNameInputError={contactLastNameInputError}
                contactCompanyInputError={contactCompanyInputError}
                agentInputError={agentInputError}
                carrierInputError={carrierInputError}
                errors={errors}
                sailDate={sailDate}
                sailDateChanged={handleSailDateChanged}
              />
            </section>
          )}
        </>
      )}
    </>
  );
};
