import { MouseEvent, useState } from 'react';
import { Pile, PileStates } from 'models/pile';
import { s } from 'i18n/strings';
import { Table, TableBody, TableRow, TableCell, Checkbox, TableContainer } from '@mui/material';
import { padding, typedUtilityClassnames } from 'style/compoundClassnames';
import { _ConfirmationDialogBase } from 'components/dialogs/_base';

const dialogTableCaptionClassNames = typedUtilityClassnames('body1', padding('pt-2', 'pb-1'));

export interface PilesConsumedConfirmationDialogProps {
  open: boolean;
  piles: Pile[];
  handleConfirm: (piles: Pile[]) => void;
  confirmDisabled: boolean;
  handleCancel: () => void;
  cancelDisabled: boolean;
}

export const PilesConsumedConfirmationDialog = ({
  open,
  piles,
  handleConfirm,
  confirmDisabled,
  handleCancel,
  cancelDisabled,
}: PilesConsumedConfirmationDialogProps): JSX.Element => {
  const [selectedPiles, setSelectedPiles] = useState<readonly string[]>([]);

  const isConsumed = (pile: Pile) => pile.state.name === PileStates.Consumed;

  const handleClick = (_: MouseEvent<HTMLTableRowElement>, pile: Pile) => {
    if (isConsumed(pile)) {
      return;
    }

    const selectedIndex = selectedPiles.indexOf(pile.reference);
    let newSelectedPiles: readonly string[] = [];

    if (selectedIndex === -1) {
      newSelectedPiles = newSelectedPiles.concat(selectedPiles, pile.reference);
    } else if (selectedIndex === 0) {
      newSelectedPiles = newSelectedPiles.concat(selectedPiles.slice(1));
    } else if (selectedIndex === selectedPiles.length - 1) {
      newSelectedPiles = newSelectedPiles.concat(selectedPiles.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelectedPiles = newSelectedPiles.concat(selectedPiles.slice(0, selectedIndex), selectedPiles.slice(selectedIndex + 1));
    }

    setSelectedPiles(newSelectedPiles);
  };

  const isSelected = (name: string) => selectedPiles.indexOf(name) !== -1;

  return (
    <_ConfirmationDialogBase
      open={open}
      title={s.PilesConsumedConfirmationDialog_Title}
      primaryButtonCaption={s.PilesConsumedConfirmationDialog_OKButtonCaption}
      primaryButtonAction={() => handleConfirm(piles.filter((p) => selectedPiles.includes(p.reference)))}
      primaryButtonDisabled={confirmDisabled}
      secondaryButtonCaption={s.PilesConsumedConfirmationDialog_CancelButtonCaption}
      secondaryButtonAction={handleCancel}
      secondaryButtonDisabled={cancelDisabled}
    >
      <TableContainer>
        <div className={dialogTableCaptionClassNames}>{s.PilesConsumedConfirmationDialog_TableCaption}</div>
        <Table>
          <TableBody>
            {piles?.map((pile) => {
              const isItemSelected = isSelected(pile.reference) || isConsumed(pile);
              return (
                <TableRow key={pile.id} hover role="checkbox" selected={isItemSelected} onClick={(event) => handleClick(event, pile)}>
                  <TableCell padding="checkbox">
                    <Checkbox checked={isItemSelected} disabled={isConsumed(pile)} />
                  </TableCell>
                  <TableCell scope="row">{pile.reference}</TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </_ConfirmationDialogBase>
  );
};
