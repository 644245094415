import { _TextInputBase } from 'components/form-components/inputs/_base';
import { fixedForwardRef } from 'utilities/forwardRefFix';

import type { Ref } from 'react';
import type { _TextInputBaseProps } from 'components/form-components/inputs/_base';
import type { ExclusivelyStringKeyedRecordWithOptional } from 'utility-types';
import type { FieldValuesFromFieldErrors } from '@hookform/error-message';
import type { FieldName, FieldErrors } from 'react-hook-form';

export interface UncontrolledTextInputProps<
  TFormState extends ExclusivelyStringKeyedRecordWithOptional<TFormState>,
  TFieldName extends FieldName<FieldValuesFromFieldErrors<FieldErrors<TFormState>>>,
> extends _TextInputBaseProps<TFormState, TFieldName> {
  inputElementClassNames?: string | undefined;
}

export const UncontrolledTextInputRefRecipient = function <
  TFormState extends ExclusivelyStringKeyedRecordWithOptional<TFormState>,
  TFieldName extends FieldName<FieldValuesFromFieldErrors<FieldErrors<TFormState>>>,
>(
  {
    onChange,
    onBlur,
    name,
    label,
    labelClassNames = '',
    inputElementClassNames = '',
    hasError,
    readOnly,
    errors,
    showsErrors = true,
    isRequired,
  }: UncontrolledTextInputProps<TFormState, TFieldName>,
  ref: Ref<HTMLInputElement>,
): JSX.Element {
  return (
    <_TextInputBase<TFormState, TFieldName>
      labelClassNames={labelClassNames}
      name={name}
      label={label}
      inputElementClassNames={inputElementClassNames}
      onChange={onChange}
      onBlur={onBlur}
      ref={ref}
      hasError={hasError}
      readOnly={readOnly}
      errors={errors}
      showsErrors={showsErrors}
      isRequired={isRequired}
    />
  );
};

export const UncontrolledTextInput = fixedForwardRef(UncontrolledTextInputRefRecipient);
