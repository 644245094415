import { _ManifestItemBase } from 'components/manifest-items/_base/_ManifestItemBase';
import {
  typedUtilityClassnames,
  textTransform,
  textColor,
  margin,
  padding,
  display,
  gridTemplateColumns,
  gap,
  width,
} from 'style/compoundClassnames';
import { s } from 'i18n/strings';
import { ReactComponent as ShipmentIcon } from 'icons/shipment.svg';
import { ShipmentSummaryShipment } from 'models/shipment';
import { formatDate } from 'utilities';

const contentLayoutClassNames = typedUtilityClassnames(
  display('grid'),
  gridTemplateColumns('xl:grid-cols-3', 'lg:grid-cols-3', 'md:grid-cols-3', 'grid-cols-1'),
  gap('gap-6'),
  width('w-full'),
);
const columnTitleClassNames = typedUtilityClassnames(
  'navBlockFont',
  textColor('text-onSurface-mediumEmphasis'),
  textTransform('uppercase'),
  padding('pb-2'),
);
const columnItemContainerClassNames = typedUtilityClassnames('body2', textColor('text-onSurface-highEmphasis'));
const columnItemCaptionClassNames = typedUtilityClassnames('label', textColor('text-onSurface-highEmphasis'));
const institutionItemClassNames = typedUtilityClassnames(
  'caption',
  textColor('text-onSurface-mediumEmphasis'),
  margin('ml-14'),
  padding('pl-0.5'),
);

export interface ShipmentInformationManifestItemProps {
  shipmentSummary: ShipmentSummaryShipment;
  isInOverview: boolean;
  canEdit: boolean;
  editOnClick: () => void;
}

export const ShipmentInformationManifestItem = ({
  shipmentSummary,
  isInOverview,
  canEdit,
  editOnClick,
}: ShipmentInformationManifestItemProps): JSX.Element => {
  return (
    <_ManifestItemBase
      title={isInOverview ? s.ShipmentInformationManifestItemInOverviewPage_Title : s.ShipmentInformationManifestItemInProcessingPage_Title}
      icon={<ShipmentIcon />}
      actionButtonCaption={s.ShipmentInformationManifestItem_EditButtonCaption}
      actionButtonOnClick={editOnClick}
      actionButtonDisabled={!canEdit}
      actionButtonVisible={true}
      content={
        <div className={contentLayoutClassNames}>
          <div className={columnItemContainerClassNames}>
            <div className={columnTitleClassNames}>{s.ShipmentInformationManifestItem_SailDateSectionTitle}</div>
            <div className={columnItemCaptionClassNames}>{formatDate.getDepartureDate(shipmentSummary.sailDate)}</div>
          </div>

          <div className={columnItemContainerClassNames}>
            <div className={columnTitleClassNames}>{s.ShipmentInformationManifestItem_ShipInformationSectionTitle}</div>
            <div className={columnItemContainerClassNames}>
              <span className={columnItemCaptionClassNames}>{s.ShipmentInformationManifestItem_ShipRegistrationNoLabel}: </span>
              {shipmentSummary.shipRegistrationNumber}
            </div>
            <div className={columnItemContainerClassNames}>
              <span className={columnItemCaptionClassNames}>{s.ShipmentInformationManifestItem_ShipNameLabel}: </span>
              {shipmentSummary.shipName}
            </div>
            <div className={columnItemContainerClassNames}>
              <span className={columnItemCaptionClassNames}>{s.ShipmentInformationManifestItem_BillOfLadingIdLabel}: </span>
              {shipmentSummary.billOfLadingId}
            </div>
            <div className={columnItemContainerClassNames}>
              <span className={columnItemCaptionClassNames}>{s.ShipmentInformationManifestItem_ContainerIdLabel}: </span>
              {shipmentSummary.shipContainerId}
            </div>
          </div>

          <div className={columnItemContainerClassNames}>
            <div className={columnTitleClassNames}>{s.ShipmentInformationManifestItem_WarehouseInformationSectionTitle}</div>
            <div className={columnItemContainerClassNames}>
              <span className={columnItemCaptionClassNames}>{s.ShipmentInformationManifestItem_ContactLabel}: </span>
              {shipmentSummary.warehouseRepresentative?.firstName} {shipmentSummary.warehouseRepresentative?.lastName}
            </div>
            <div className={institutionItemClassNames}>{shipmentSummary.warehouseRepresentative?.institutionName}</div>
            <div className={columnItemContainerClassNames}>
              <span className={columnItemCaptionClassNames}>{s.ShipmentInformationManifestItem_AgentLabel}: </span>
              {shipmentSummary.warehouseRepresentative?.emailAddress}
            </div>
          </div>
        </div>
      }
    />
  );
};
