import { AccountInfo } from '@azure/msal-browser';
import {
  display,
  flexDirection,
  justifyContent,
  margin,
  onlyComputedCombineClassnames,
  textColor,
  typedUtilityClassnames,
} from 'style/compoundClassnames';
import { s } from 'i18n/strings';
import { NavigationHeader } from 'components/headers';
import { Spinner } from 'components/Spinner';
import { ReactComponent as LargeLogoIcon } from 'icons/large_logo.svg';

const mainContentClassNames = onlyComputedCombineClassnames(
  typedUtilityClassnames('mainLayoutPadding'),
  display('flex'),
  justifyContent('justify-center'),
);
const stackClassNames = typedUtilityClassnames(display('flex'), flexDirection('flex-col'));
const loginMessageClassNames = typedUtilityClassnames('headline5', textColor('text-primary-900'), margin('mt-10'));
const loginErrorMessageClassNames = typedUtilityClassnames('body1', margin('mt-4'));

export interface LoginProgressProps {
  account: AccountInfo;
  loginFailed: boolean;
}

export const LoginProgress = ({ account, loginFailed }: LoginProgressProps) => {
  return (
    <>
      <NavigationHeader />
      <div className={mainContentClassNames}>
        <div className={stackClassNames}>
          <LargeLogoIcon />
          {loginFailed ? (
            <>
              <div className={loginMessageClassNames}>
                {s.LoginProgress_CouldNotLogInUserTitle}: {account.name}
                <br /> ({account.username})
              </div>
              <div className={loginErrorMessageClassNames}>{s.LoginProgress_ContactSysAdminMessage}</div>
            </>
          ) : (
            <>
              <div className={loginMessageClassNames}>{s.LoginProgress_InProgressTitle}</div>
              <Spinner size={50} />
            </>
          )}
        </div>
      </div>
    </>
  );
};
