import { useState, useEffect } from 'react';

import { Shipment, ShipmentWrite } from 'models/shipment';
import type { User } from 'models/user';
import { permissionsService } from 'services/permissionsService';
import { shipmentsService } from 'services/shipmentsService';
import { useAccessToken, useLoggedInUser, useAppNavigation, useServerError } from 'hooks';

import { s } from 'i18n/strings';

import { Box, Typography, Backdrop, Stack, CircularProgress } from '@mui/material';

import { display, justifyContent, onlyComputedCombineClassnames, typedUtilityClassnames } from 'style/compoundClassnames';

import { ShipmentsList } from 'components/ShipmentsList';
import { ContextHeader } from 'components/headers';
import { PrimaryButton } from 'components/buttons';
import { Notification } from 'components/Notification';
import { Spinner } from 'components/Spinner';

const emptyShipmentsMessageClassNames = typedUtilityClassnames('body1', display('flex'), justifyContent('justify-center'));

const canCreateNewShipment = (user: User | null) => user != null && permissionsService.canAddShipment(user);

export const ShipmentsPage = () => {
  const accessToken = useAccessToken();
  const user = useLoggedInUser();

  const { navigateViewShipment } = useAppNavigation();
  const { handleServerError } = useServerError();

  const [shipments, setShipments] = useState<Shipment[] | null>(null);

  const [creatingInProgress, setCreatingInProgress] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    (async () => {
      try {
        setError(null);
        setIsLoading(true);
        // TODO: factor out accessToken null checking into a base API class to run at every call, then remove access token null checking from all components
        if (accessToken) {
          const shipments = await shipmentsService.getAll(accessToken);
          setShipments(shipments);
        }
      } catch (error) {
        const errorMessage = handleServerError(error);
        setError(`${s.ShipmentsPage_CouldNotRetrieveShipmentsError}: ${errorMessage}`);
      } finally {
        setIsLoading(false);
      }
    })();
  }, [accessToken, handleServerError]);

  const onViewShipmentDetailsClick = (id: number) => {
    navigateViewShipment(id);
  };

  const handleCreateNewShipment = async () => {
    // TODO: factor out accessToken null checking into a base API class to run at every call, then remove access token null checking from all components
    if (accessToken && canCreateNewShipment(user)) {
      try {
        setCreatingInProgress(true);
        const shipment = await shipmentsService.create(accessToken, new ShipmentWrite(null!));
        navigateViewShipment(shipment.id);
      } catch (error) {
        const errorMessage = handleServerError(error);
        setError(`${s.ShipmentsPage_CouldNotCreateShipmentsError}: ${errorMessage}`);
      } finally {
        setCreatingInProgress(false);
      }
    }
  };

  return (
    <>
      <ContextHeader
        contextTitle={s.ShipmentsPage_Title}
        rightButtonTwo={
          <PrimaryButton
            label={s.ShipmentsPage_NewShipmentButtonCaption}
            onClick={handleCreateNewShipment}
            disabled={!canCreateNewShipment(user)}
          />
        }
        showContextHeaderContentSpacer
      />
      <div className={onlyComputedCombineClassnames(typedUtilityClassnames('mainLayoutPadding'))}>
        <Notification message={error!} severity="error" onClose={() => setError(null)} />
        {isLoading ? (
          <Spinner />
        ) : shipments && shipments.length > 0 ? (
          <ShipmentsList shipments={shipments} onView={onViewShipmentDetailsClick} />
        ) : (
          <div className={emptyShipmentsMessageClassNames}>{s.ShipmentsPage_WillAppearHere}</div>
        )}

        {/* TODO: use tailwind */}
        <Backdrop open={creatingInProgress}>
          <Stack>
            <Box sx={{ display: 'flex', justifyContent: 'center' }}>
              <CircularProgress sx={{ color: 'white' }} />
            </Box>
            <Box sx={{ display: 'flex', justifyContent: 'center' }}>
              <Typography sx={{ color: 'white' }}>{s.ShipmentsPage_CreatingNewShipmentCaption}</Typography>
            </Box>
          </Stack>
        </Backdrop>
      </div>
    </>
  );
};
