import { ChangeEvent, MouseEvent, useEffect, useState } from 'react';

import { Drum } from 'models/drum';
import { Pile, PileStates } from 'models/pile';

import { s } from 'i18n/strings';

import { Checkbox, InputAdornment, Table, TableBody, TableCell, TableContainer, TableRow, TextField } from '@mui/material';

import { padding, typedUtilityClassnames, width } from 'style/compoundClassnames';

import { ReactComponent as SearchIcon } from 'icons/search.svg';

export interface LinkPilesDrumTabPanelProps {
  piles: Pile[];
  drum: Drum | null;
  selectedPiles: number[];
  onSelectedPiles: (ids: number[]) => void;
  canEdit: boolean;
  selectedPilesMainFormFieldIsDirty: boolean;
}

const selectAndSearchContainerClassNames = typedUtilityClassnames('drawerFormFullWidthOptions');
export const LinkPilesDrumTabPanel = ({
  drum,
  piles,
  selectedPiles,
  onSelectedPiles,
  canEdit,
  selectedPilesMainFormFieldIsDirty,
}: LinkPilesDrumTabPanelProps): JSX.Element => {
  const [filteredPiles, setFilteredPiles] = useState<Pile[] | null>(null);
  const [selectedPilesIds, setSelectedPilesIds] = useState<readonly string[]>([]);
  const [savedSelectedPilesIds, setSavedSelectedPilesIds] = useState<readonly string[]>([]);

  useEffect(() => {
    (async () => {
      try {
        let filteredPiles = piles;
        if (!drum) {
          filteredPiles = piles.filter((b) => b.state.name !== PileStates.Consumed);
        }

        setFilteredPiles(filteredPiles);

        let selectedPilesReferences: string[] = [];
        if (drum) {
          selectedPilesReferences = filteredPiles.filter((p) => drum.pileIds.includes(p.id)).map((p) => p.reference);
        }

        if (selectedPilesMainFormFieldIsDirty) {
          selectedPilesReferences = filteredPiles.filter((p) => selectedPiles.includes(p.id)).map((p) => p.reference);
        }

        setSelectedPilesIds(selectedPilesReferences);
        setSavedSelectedPilesIds(selectedPilesReferences);
      } catch (error) {
        console.error(error);
      }
    })();
  }, [drum, piles, selectedPiles, selectedPilesMainFormFieldIsDirty]);

  const handleSearchRequest = (event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    const filteredPiles = piles?.filter((pile) => {
      return pile.reference.toLowerCase().includes(event.target.value.toLowerCase());
    });
    setFilteredPiles(filteredPiles!);
  };

  const isPileNotSelectable = (pile: Pile, selected: boolean) => {
    return !selected && pile.state.name === PileStates.Consumed && !savedSelectedPilesIds.includes(pile.reference);
  };

  const handleClick = (_: MouseEvent<HTMLTableRowElement>, pile: Pile, itemSelected: boolean) => {
    if (!canEdit || isPileNotSelectable(pile, itemSelected)) {
      return;
    }

    const selectedIndex = selectedPilesIds.indexOf(pile.reference);
    let newSelectedPileId: readonly string[] = [];

    if (selectedIndex === -1) {
      newSelectedPileId = newSelectedPileId.concat(selectedPilesIds, pile.reference);
    } else if (selectedIndex === 0) {
      newSelectedPileId = newSelectedPileId.concat(selectedPilesIds.slice(1));
    } else if (selectedIndex === selectedPilesIds.length - 1) {
      newSelectedPileId = newSelectedPileId.concat(selectedPilesIds.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelectedPileId = newSelectedPileId.concat(selectedPilesIds.slice(0, selectedIndex), selectedPilesIds.slice(selectedIndex + 1));
    }

    setSelectedPilesIds(newSelectedPileId);

    const pilesIds = piles?.filter((p) => newSelectedPileId.includes(p.reference)).map((p) => p.id);
    onSelectedPiles(pilesIds);
  };

  const isSelected = (name: string) => selectedPilesIds.indexOf(name) !== -1;

  return (
    <>
      <TableContainer className={selectAndSearchContainerClassNames}>
        <TextField
          variant="outlined"
          size="small"
          placeholder={s.DrumDrawer_SearchFieldPlaceholder}
          className={typedUtilityClassnames('subtitle1', padding('px-6', 'pb-6'), width('w-full'))}
          onChange={(searchedValue) => handleSearchRequest(searchedValue)}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon className={typedUtilityClassnames('icons', width('w-5'))} />
              </InputAdornment>
            ),
          }}
        />

        <Table className={typedUtilityClassnames(padding('pt-6'))}>
          <TableBody>
            {filteredPiles?.map((pile) => {
              const isItemSelected = isSelected(pile.reference);
              return (
                <TableRow
                  key={pile.id}
                  className={typedUtilityClassnames('overlayBlackStates')}
                  hover
                  role="checkbox"
                  selected={isItemSelected}
                  onClick={(event) => handleClick(event, pile, isItemSelected)}
                >
                  <TableCell padding="checkbox">
                    <Checkbox
                      className={typedUtilityClassnames(padding('p-6'))}
                      checked={isItemSelected}
                      disabled={!canEdit || isPileNotSelectable(pile, isItemSelected)}
                    />
                  </TableCell>
                  <TableCell scope="row">{pile.reference}</TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};
