import { Truck } from 'models/truck';
import { InstitutionNames } from 'models/representative';
import { s } from 'i18n/strings';
import { _ManifestItemBase } from 'components/manifest-items/_base/_ManifestItemBase';
import {
  display,
  gap,
  gridTemplateColumns,
  margin,
  padding,
  textColor,
  textTransform,
  typedUtilityClassnames,
  width,
} from 'style/compoundClassnames';
import { ReactComponent as TruckIcon } from 'icons/truck.svg';

const contentLayoutClassNames = typedUtilityClassnames(
  display('grid'),
  gridTemplateColumns('xl:grid-cols-3', 'lg:grid-cols-3', 'md:grid-cols-3', 'grid-cols-1'),
  gap('gap-6'),
  width('w-full'),
);
const columnTitleClassNames = typedUtilityClassnames(
  'navBlockFont',
  textColor('text-onSurface-mediumEmphasis'),
  textTransform('uppercase'),
  padding('pb-2'),
);
const columnItemContainerClassNames = typedUtilityClassnames('body2');
const columnItemCaptionClassNames = typedUtilityClassnames('label');
const passportNumberClassNames = typedUtilityClassnames('caption', textColor('text-onSurface-mediumEmphasis'), margin('ml-12'));

export interface TruckManifestItemProps {
  truck: Truck;
  canEdit: boolean;
  editOnClick: () => void;
}

export const TruckManifestItem = ({ truck, canEdit, editOnClick }: TruckManifestItemProps) => {
  const coprocoStaffWitness = truck.witnesses.find((w) => w.institutionName === InstitutionNames.CoprocoStaff);
  const ceecOfficerWitness = truck.witnesses.find((w) => w.institutionName === InstitutionNames.CeecOfficer);
  const divisionOfMineWitness = truck.witnesses.find((w) => w.institutionName === InstitutionNames.DivisionOfMine);

  return (
    <_ManifestItemBase
      title={s.TruckManifestItem_Title}
      icon={<TruckIcon />}
      actionButtonCaption={s.TruckManifestItem_EditButtonCaption}
      actionButtonOnClick={editOnClick}
      actionButtonDisabled={!canEdit}
      actionButtonVisible={true}
      content={
        <div className={contentLayoutClassNames}>
          <div>
            <div className={columnTitleClassNames}>{s.TruckManifestItem_TruckInformationSectionTitle}</div>
            <div className={columnItemContainerClassNames}>
              <span className={columnItemCaptionClassNames}>{s.TruckManifestItem_TruckRegistrationNoLabel}: </span>
              {truck?.truckLicensePlate}
            </div>
            <div className={columnItemContainerClassNames}>
              <span className={columnItemCaptionClassNames}>{s.TruckManifestItem_TruckTrailerNoLabel}: </span>
              {truck?.trailerLicensePlate}
            </div>
            <div className={columnItemContainerClassNames}>
              <span className={columnItemCaptionClassNames}>{s.TruckManifestItem_TruckDriverNameLabel}: </span>
              {truck?.driverFirstName} {truck?.driverLastName}
            </div>
            <div className={passportNumberClassNames}>
              {s.TruckManifestItem_TruckDriverPassportLabel}: {truck?.driverPassportNumber}
            </div>
          </div>

          <div>
            <div className={columnTitleClassNames}>{s.TruckManifestItem_WitnessesSectionTitle}</div>
            <div className={columnItemContainerClassNames}>
              <span className={columnItemCaptionClassNames}>{InstitutionNames.CoprocoStaff}: </span>
              {coprocoStaffWitness?.firstName} {coprocoStaffWitness?.lastName}
            </div>
            <div className={columnItemContainerClassNames}>
              <span className={columnItemCaptionClassNames}>{InstitutionNames.CeecOfficer}: </span>
              {ceecOfficerWitness?.firstName} {ceecOfficerWitness?.lastName}
            </div>
            <div className={columnItemContainerClassNames}>
              <span className={columnItemCaptionClassNames}>{InstitutionNames.DivisionOfMine}: </span>
              {divisionOfMineWitness?.firstName} {divisionOfMineWitness?.lastName}
            </div>
          </div>
        </div>
      }
    />
  );
};
