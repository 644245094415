import { get, set, keys, del, clear } from 'idb-keyval';
import { CacheService } from './cacheService';

export const documentsLocalCacheService: CacheService<Blob> = {
  async has(key: string): Promise<boolean> {
    const result = await keys();
    return result.includes(key);
  },
  async get(key: string): Promise<Blob | null> {
    const result = await get<Blob | null>(key);
    if (result == null) {
      return null;
    }
    return result;
  },
  async set(key: string, value: Blob) {
    await set(key, value);
  },
  async remove(key: string) {
    await del(key);
  },
  async clear() {
    await clear();
  },
};
