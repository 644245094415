import type { SyntheticEvent } from 'react';
import { useState, useEffect, ReactNode } from 'react';
import { useParams } from 'react-router-dom';

import { Tabs, Tab } from '@mui/material';

import { shipmentStates, ShipmentSummary } from 'models/shipment';
import { Document, DocumentInfo, documentTypes } from 'models/document';
import { Pile } from 'models/pile';
import { User } from 'models/user';
import { permissionsService } from 'services/permissionsService';
import { shipmentsService } from 'services/shipmentsService';
import { useAccessToken, useLoggedInUser, useAppNavigation, useServerError } from 'hooks';
import { formatDate } from 'utilities';

import { s } from 'i18n/strings';

import { ShipmentStateIndicator } from 'components/ShipmentStateIndicator';
import { ShipmentOverview } from 'components/ShipmentOverview';
import { ShipmentActivity } from 'components/ShipmentActivity';
import { ShipmentDocuments } from 'components/ShipmentDocuments';
import { Spinner } from 'components/Spinner';
import { DarkContextHeader } from 'components/headers';
import { SecondaryButton } from 'components/buttons';
import { Notification } from 'components/Notification';
import { ActionConfirmationDialog } from 'components/dialogs';

import {
  alignItems,
  backgroundColor,
  display,
  flexDirection,
  justifyContent,
  padding,
  typedUtilityClassnames,
} from 'style/compoundClassnames';

const viewShipmentSubHeaderClassNames = typedUtilityClassnames(
  backgroundColor('bg-primary-800'),
  display('flex'),
  justifyContent('justify-center'),
  alignItems('items-center'),
  flexDirection('flex-col'),
  padding('pt-5'),
);

const tabStyle = {
  marginTop: '0.5rem',
  color: 'white',
  fontSize: '16px',
  textTransform: 'none',
  '&.Mui-selected': {
    color: 'white',
  },
};

interface TabPanelProps {
  children?: ReactNode;
  index: number;
  value: number;
}

const TabPanel = (props: TabPanelProps) => {
  const { children, value, index, ...other } = props;
  return (
    <div role="tabpanel" hidden={value !== index} {...other}>
      {value === index && children}
    </div>
  );
};

const customsDocuments: DocumentInfo[] = [
  { title: s.ShipmentDocuments_LaboPriveeItemTitle, type: documentTypes.LaboPrivee, required: true },
  { title: s.ShipmentDocuments_CEECFinalItemTitle, type: documentTypes.CEECFinal, required: true },
];

const shipmentDocuments: DocumentInfo[] = [
  { title: s.ShipmentDocuments_VesselPackingListDocumentItemTitle, type: documentTypes.VesselPackingList, required: true },
  { title: s.ShipmentDocuments_ForkliftWeightRecordDocumentItemTitle, type: documentTypes.ForkliftWeightRecord, required: true },
  { title: s.ShipmentDocuments_BillOfLadingDocumentItemTitle, type: documentTypes.BillOfLading, required: true },
];

const stringIsNullOrEmpty = (value: string) => value == null || value.length === 0;

const shipDetailsProvided = (shipmentSummary: ShipmentSummary | null) =>
  shipmentSummary != null &&
  shipmentSummary.shipmentSummary.sailDate != null &&
  !stringIsNullOrEmpty(shipmentSummary.shipmentSummary.shipRegistrationNumber) &&
  !stringIsNullOrEmpty(shipmentSummary.shipmentSummary.shipName) &&
  !stringIsNullOrEmpty(shipmentSummary.shipmentSummary.billOfLadingId) &&
  !stringIsNullOrEmpty(shipmentSummary.shipmentSummary.shipContainerId) &&
  shipmentSummary.shipmentSummary.warehouseRepresentative != null;

const canEditShipment = (user: User | null) => user != null && permissionsService.canEditShipment(user);

const canFinishShipment = (user: User | null, shipmentSummary: ShipmentSummary | null) =>
  user != null &&
  permissionsService.canEditShipment(user) &&
  shipmentSummary != null &&
  shipDetailsProvided(shipmentSummary) &&
  (shipmentSummary.state.name === shipmentStates.Sailed || shipmentSummary.state.name === shipmentStates.WithFreightForwarder);

interface ViewShipmentDetailsProps {
  shipmentSummary: ShipmentSummary;
  piles: Pile[] | null;
  onDocumentUploaded: () => void;
}

const ViewShipmentDetails = ({ shipmentSummary, piles, onDocumentUploaded }: ViewShipmentDetailsProps) => {
  const [tabValue, setTabValue] = useState(0);

  const handleTabChange = (_: SyntheticEvent, newValue: number) => {
    setTabValue(newValue);
  };

  return (
    <>
      <div className={viewShipmentSubHeaderClassNames}>
        <ShipmentStateIndicator shipmentState={shipmentSummary.state} />
        <Tabs centered value={tabValue} onChange={handleTabChange} TabIndicatorProps={{ style: { background: 'white' } }}>
          <Tab label={s.ViewShipmentPage_OverviewTabLabel} sx={tabStyle} />
          <Tab label={s.ViewShipmentPage_DocumentsTabLabel} sx={tabStyle} />
          <Tab label={s.ViewShipmentPage_ActivityTabLabel} sx={tabStyle} />
        </Tabs>
      </div>
      <div>
        <TabPanel value={tabValue} index={0}>
          <ShipmentOverview shipmentSummary={shipmentSummary} />
        </TabPanel>
        <TabPanel value={tabValue} index={1}>
          <ShipmentDocuments shipmentSummary={shipmentSummary} piles={piles} onDocumentUploaded={onDocumentUploaded} />
        </TabPanel>
        <TabPanel value={tabValue} index={2}>
          <ShipmentActivity shipmentSummary={shipmentSummary} />
        </TabPanel>
      </div>
    </>
  );
};

export const ViewShipmentPage = () => {
  const { shipmentId } = useParams<{ shipmentId: string }>();
  const accessToken = useAccessToken();
  const user = useLoggedInUser();

  const { navigateShipments, navigateEditShipment } = useAppNavigation();
  const { handleServerError } = useServerError();

  const [shipmentSummary, setShipmentSummary] = useState<ShipmentSummary | null>(null);
  const [piles, setPiles] = useState<Pile[] | null>(null);

  const [missingDocuments, setMissingDocuments] = useState<DocumentInfo[]>([]);
  const handleMissingDocuments = (documents: Document[]) => {
    const missingDocuments: DocumentInfo[] = [];
    missingDocuments.push(...customsDocuments.filter((d) => d.required && !documents.map((item) => item.documentType.id).includes(d.type)));
    missingDocuments.push(
      ...shipmentDocuments.filter((d) => d.required && !documents.map((item) => item.documentType.id).includes(d.type)),
    );
    setMissingDocuments(missingDocuments);
  };

  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  const [openConfirmationDialog, setOpenConfirmationDialog] = useState(false);
  const handleOpenConfirmationDialog = () => setOpenConfirmationDialog(true);
  const handleCloseConfirmationDialog = () => setOpenConfirmationDialog(false);

  useEffect(() => {
    (async () => {
      try {
        setError(null);
        setIsLoading(true);
        // TODO: factor out accessToken null checking into a base API class to run at every call, then remove access token null checking from all components
        if (accessToken && shipmentId) {
          const shipmentSummaryRequest = shipmentsService.getSummary(accessToken, parseInt(shipmentId));
          const shipmentDocumentsRequest = shipmentsService.getAllDocuments(accessToken, parseInt(shipmentId));
          const pilesRequest = shipmentsService.getPiles(accessToken, parseInt(shipmentId));

          const [shipmentSummary, documents, piles] = await Promise.all([shipmentSummaryRequest, shipmentDocumentsRequest, pilesRequest]);

          setShipmentSummary(shipmentSummary);
          setPiles(piles);

          handleMissingDocuments(documents);
        }
      } catch (error) {
        const errorMessage = handleServerError(error);
        setError(`${s.ViewShipmentPage_RetrievingShipmentDetailsError}: ${errorMessage}`);
      } finally {
        setIsLoading(false);
      }
    })();
  }, [accessToken, handleServerError, shipmentId]);

  const handleFinishShipment = async (isOverridden: boolean, overrideNote: string) => {
    try {
      if (accessToken && shipmentId) {
        if (!isOverridden) {
          await shipmentsService.completeShipment(accessToken, parseInt(shipmentId));
        } else {
          await shipmentsService.completeShipmentWithOverride(accessToken, parseInt(shipmentId), overrideNote);
        }

        handleCloseConfirmationDialog();
        navigateShipments();
      }
    } catch (error) {
      handleCloseConfirmationDialog();
      const errorMessage = handleServerError(error);
      setError(`${s.ViewShipmentPage_CompletingShipmentError}: ${errorMessage}`);
    }
  };

  const handleEditShipment = () => {
    if (canEditShipment(user) && shipmentId) {
      navigateEditShipment(shipmentId);
    }
  };

  const handleDocumentUploaded = async () => {
    try {
      if (accessToken && shipmentId) {
        const documents = await shipmentsService.getAllDocuments(accessToken, parseInt(shipmentId));
        handleMissingDocuments(documents);
      }
    } catch (error) {
      const errorMessage = handleServerError(error);
      setError(`${s.ViewShipmentPage_RetrievingDocumentsError}: ${errorMessage}`);
    }
  };

  const handleBack = () => {
    navigateShipments();
  };

  return (
    <>
      {isLoading ? (
        <Spinner />
      ) : (
        shipmentSummary &&
        user && (
          <>
            <DarkContextHeader
              contextTitle={shipmentSummary.shipmentReference}
              contextSubTitle={`${s.ViewShipmentPage_UpdateSubtitle} ${formatDate.getDateAndTime(shipmentSummary.lastUpdated)}`}
              leftButtonOne={<SecondaryButton label={s.ViewShipmentPage_BackButtonCaption} onClick={handleBack} />}
              rightButtonOne={
                <SecondaryButton
                  label={s.ViewShipmentPage_EditButtonCaption}
                  onClick={handleEditShipment}
                  disabled={!canEditShipment(user)}
                />
              }
              rightButtonTwo={
                <SecondaryButton
                  label={s.ViewShipmentPage_FinishButtonCaption}
                  onClick={handleOpenConfirmationDialog}
                  disabled={!canFinishShipment(user, shipmentSummary)}
                />
              }
              showContextHeaderContentSpacer={false}
            />
            <Notification message={error!} severity="error" onClose={() => setError(null)} />
            <ViewShipmentDetails shipmentSummary={shipmentSummary} piles={piles} onDocumentUploaded={handleDocumentUploaded} />
          </>
        )
      )}

      {user && (
        <ActionConfirmationDialog
          open={openConfirmationDialog}
          title={s.ViewShipmentPage_ConfirmationDialogTitle}
          items={[
            {
              label:
                missingDocuments.length > 0
                  ? s.ViewShipmentPage_ConfirmationDialogDocumentsMissingItemLabel
                  : s.ViewShipmentPage_ConfirmationDialogDocumentsItemLabel,
              successCondition: missingDocuments.length === 0,
              successMessage: s.ViewShipmentPage_ConfirmationDialogDocumentsSuccessMessage,
              errorMessage: missingDocuments.map((d) => d.title).join(', '),
            },
          ]}
          confirmButtonCaption={s.ViewShipmentPage_ConfirmationDialogConfirmButtonCaption}
          handleConfirm={handleFinishShipment}
          cancelButtonCaption={s.ViewShipmentPage_ConfirmationDialogCancelButtonCaption}
          handleCancel={handleCloseConfirmationDialog}
          allowOverride={permissionsService.canOverrideShipment(user)}
        />
      )}
    </>
  );
};
