// Manually sorted, alphabetically:
export const s = {
  // AccountSettingsDropMenu
  AccountSettingsDropMenu_LanguageSelectorLabel: 'Language',
  AccountSettingsDropMenu_LogoutButton: 'Logout',
  AccountSettingsDropMenu_AccountSettingsMenuButtonAriaLabel: 'Logout and Settings Menu',

  // ActionConfirmationDialog
  ActionConfirmationDialog_ReasoningTitle: 'Reasoning',
  ActionConfirmationDialog_ConfirmCheckboxLabel: 'Yes, I confirm that I am submitting incomplete data',
  ActionConfirmationDialog_OverrideReasonLabel: 'Reason for override',

  // App
  App_OfflineCaption: 'Offline',

  // BagDrawer
  BagDrawer_Save: 'Save',
  BagDrawer_BagId: 'Bag ID',
  BagDrawer_ExpectedWeight: 'Expected Weight',
  BagDrawer_ActualWeight: 'Actual Weight',
  BagDrawer_BagPhoto: 'Bag photo',
  BagDrawer_CloseDrawerIconButtonAriaLabel: 'Close Drawer',
  BagDrawer_SelectedPhotoPreviewAlternateText: 'Selected Photo Preview',
  BagDrawer_AddBagDrawerFormTitle: 'Add Bag',
  BagDrawer_EditBagDrawerFormTitle: 'Edit Bag',
  BagDrawer_PhotoInputButtonAriaLabel: 'Update Photo',
  BagDrawer_EditBagDrawerAccessibleFormTitle: 'Edit Bag Form',
  BagDrawer_AddBagDrawerAccessibleFormTitle: 'Add Bag Form',

  // BagProcessingPage
  BagProcessingPage_Title: 'Bag Processing',
  BagProcessingPage_AddButton: 'Add Bag',
  BagProcessingPage_BackButton: 'Back',
  BagProcessingPage_WillAppearHere: "Weighed and processed bags will appear here. To start, click 'Add Bag'",
  BagProcessingPage_ActualWeight: 'Actual weight (kg)',
  BagProcessingPage_ExpectedWeight: 'Expected weight (kg)',
  BagProcessingPage_MineSeal: 'Mine Seal',
  BagProcessingPage_FinishWeighingBagsButton: 'Finish Weighing Bags',
  BagProcessingPage_EvidenceAttached: 'Evidence Attached',
  BagProcessingPage_ConfirmationDialogTitle: 'Confirm Bags Weighing Finished',
  BagProcessingPage_ConfirmationDialogBagsProcessedItemLabel: 'Bags processed',
  BagProcessingPage_ConfirmationDialogSingularBagItem: 'bag',
  BagProcessingPage_ConfirmationDialogPluralBagsItem: 'bags',
  BagProcessingPage_ConfirmationDialogBagsProcessedErrorMessage: 'Not all bags have been processed',
  BagProcessingPage_ConfirmationDialogFinishButtonCaption: 'Finish Weighing',
  BagProcessingPage_ConfirmationDialogCancelButtonCaption: 'Cancel',
  BagProcessingPage_CouldNotRetrieveShipmentID_ErrorMessage: 'Could not retrieve shipment ID',
  BagProcessingPage_CouldNotCreateOrUpdateBag_ErrorMessage: 'Could not create or update a bag',
  BagProcessingPage_CouldNotGetBags_ErrorMessage: 'Could not retrieve bags',
  BagProcessingPage_CouldNotDeleteBag_ErrorMessage: 'Could not delete a bag',
  BagProcessingPage_CouldFinishWeighingBags_ErrorMessage: 'Could not finish weighing bags',

  // BagsManifestItem
  BagsManifestItem_Title: 'Bags',
  BagsManifestItem_EditButtonCaption: 'Edit',
  BagsManifestItem_BagsCountLabel: 'Count',
  BagsManifestItem_BagsExpectedWeightLabel: 'Expected weight',
  BagsManifestItem_BagsActualWeightLabel: 'Actual weight',

  // BagsProcessingManifestItem
  BagsProcessingManifestItem_Title: 'Bags',
  BagsProcessingManifestItem_EditButtonCaption: 'Edit',
  BagsProcessingManifestItem_BagsProcessedSectionTitle: 'Bags processed',
  BagsProcessingManifestItem_BagsProcessedCountLabel: 'Count',
  BagsProcessingManifestItem_BagsProcessedExpectedWeightLabel: 'Expected weight',
  BagsProcessingManifestItem_BagsProcessedActualWeightLabel: 'Actual weight',
  BagsProcessingManifestItem_IncomingTruckSectionTitle: 'Incoming truck information',
  BagsProcessingManifestItem_IncomingTruckRegistrationNoLabel: 'Registration No. Truck',
  BagsProcessingManifestItem_IncomingTruckTrailerNoLabel: 'Trailer',
  BagsProcessingManifestItem_IncomingTruckDriverNameLabel: 'Driver',
  BagsProcessingManifestItem_IncomingTruckDriverPassportLabel: 'Psp',
  BagsProcessingManifestItem_WitnessesSectionTitle: 'Witnesses',

  // BagsProcessingPage
  BagsProcessingPage_Title: 'Bags Processing',
  BagsProcessingPage_BackButton: 'Back',
  BagsProcessingPage_ArrivalInformationItemTitle: 'Arrival Information',
  BagsProcessingPage_ArrivalInformationItem_EditButton: 'Edit',
  BagsProcessingPage_DocumentsItemTitle: 'Documents',
  BagsProcessingPage_BagsItemTitle: 'Bags',
  BagsProcessingPage_BagsItem_EditButton: 'Edit',
  BagsProcessingPage_ConfirmationDialogTitle: 'Confirm Bags Processing Finished',
  BagsProcessingPage_ConfirmationDialogShipmentItemLabel: 'Shipment',
  BagsProcessingPage_ConfirmationDialogShipmentSuccessMessage: 'Shipment ID provided',
  BagsProcessingPage_ConfirmationDialogShipmentErrorMessage: 'Shipment ID is not updated',
  BagsProcessingPage_ConfirmationDialogDocumentsItemLabel: 'Documents',
  BagsProcessingPage_ConfirmationDialogDocumentsSuccessMessage: 'Required documents uploaded',
  BagsProcessingPage_ConfirmationDialogDocumentsMissingItemLabel: 'Documents missing',
  BagsProcessingPage_ConfirmationDialogFinishButtonCaption: 'Finish Processing',
  BagsProcessingPage_ConfirmationDialogCancelButtonCaption: 'Cancel',
  BagsProcessingPage_TruckSubTitle: 'Truck',
  BagsProcessingPage_FinishButton: 'Finish',
  BagsProcessingPage_RetrievingShipmentDetailsError: 'Could not retrieve shipment details',
  BagsProcessingPage_FinishBagsProcessingError: 'Could not finish bags processing',
  BagsProcessingPage_RetrievingTruckDetailsError: 'Could not retrieve truck details',

  // BasicConfirmationDialog
  BasicConfirmationDialog_ConfirmButtonCaption: 'Confirm',
  BasicConfirmationDialog_CancelButtonCaption: 'Cancel',

  // ContainerDrumsManifestItem
  // ContainerDrumsManifestItem_Title: 'Drums',
  ContainerDrumsManifestItem_Title: 'Container Stuffing and Sealing',
  ContainerDrumsManifestItem_EditButtonCaption: 'Edit',
  // ContainerDrumsManifestItem_TagBoxIDCaption: 'Tag Box ID',
  ContainerDrumsManifestItem_TagBoxIDCaption: 'IoT Device ID',
  ContainerDrumsManifestItem_LeopardLockIDCaption: 'Leopard Lock ID',
  ContainerDrumsManifestItem_ContainerSealIDCaption: 'Container Seal ID',
  ContainerDrumsManifestItem_NumberOfDrumsCaption: 'Number of drums',

  // ContainerDrumsPage
  // ContainerDrumsPage_Title: 'Container Drums',
  ContainerDrumsPage_Title: 'Container Stuffing and Sealing',
  ContainerDrumsPage_CancelButton: 'Cancel',
  ContainerDrumsPage_SaveButton: 'Save',
  // ContainerDrumsPage_TagBoxIDCaption: 'Tag Box ID',
  ContainerDrumsPage_TagBoxIDCaption: 'IoT Device ID',
  // ContainerDrumsPage_TagBoxPhotoInputLabel: 'Tag Box Photo',
  ContainerDrumsPage_TagBoxPhotoInputLabel: 'IoT Device Photo',
  ContainerDrumsPage_EmptyContainerPhotoInputLabel: 'Empty Container Photo',
  ContainerDrumsPage_FilledContainerPhotoInputLabel: 'Filled Container Photo',
  ContainerDrumsPage_ContainerSealIdInputLabel: 'Container Seal ID',
  ContainerDrumsPage_LeopardLockIdInputLabel: 'Leopard Lock ID',
  ContainerDrumsPage_LeopardLockIdPhotoInputLabel: 'Leopard Lock ID Photo',
  ContainerDrumsPage_ContainerSealIdPhotoInputLabel: 'Container Seal ID Photo',
  ContainerDrumsPage_DrumsSectionTitle: 'Drums',
  ContainerDrumsPage_SelectedDrumsCaption: 'Please tick off all of the drums that are in the container',
  ContainerDrumsPage_SealedContainerSectionTitle: 'Sealed Container',
  ContainerDrumsPage_SealedContainerPhotoInputLabel: 'Sealed Container ID Photo',
  ContainerDrumsPage_UnknownContainerIdErrorMessage: 'Container ID is required to proceed',
  ContainerDrumsPage_GetContainerError: 'Could not retrieve container details',
  ContainerDrumsPage_SaveContainerError: 'Could not save container',
  ContainerDrumsPage_UploadPhotoError: 'Could not upload photo',
  ContainerDrumsPage_GetPhotoError: 'Could not retrieve photo details',

  // ContainerProcessingManifestItem
  ContainerProcessingManifestItem_Title: 'Containers',
  ContainerProcessingManifestItem_EditButtonCaption: 'Edit',
  ContainerProcessingManifestItem_ContainerDetailsTitle: 'Container details',
  // ContainerProcessingManifestItem_TagBoxIdLabel: 'Tag Box ID',
  ContainerProcessingManifestItem_TagBoxIdLabel: 'IoT Device ID',
  ContainerProcessingManifestItem_LeopardLockIdLabel: 'Leopard Lock ID',
  ContainerProcessingManifestItem_ContainerSealIdLabel: 'Container Seal ID',
  ContainerProcessingManifestItem_FreightForwarderSectionTitle: 'Freight forwarder',
  ContainerProcessingManifestItem_FreightForwarderRegistrationNoLabel: 'Registration No. Truck',
  ContainerProcessingManifestItem_FreightForwarderTrailerNoLabel: 'Trailer',
  ContainerProcessingManifestItem_FreightForwarderDriverNameLabel: 'Driver',
  ContainerProcessingManifestItem_FreightForwarderDriverPassportLabel: 'Psp',

  // ContainerProcessingPage
  ContainerProcessingPage_Title: 'Container Processing',
  ContainerProcessingPage_BackButton: 'Back',
  ContainerProcessingPage_FinishButton: 'Finish',
  ContainerProcessingPage_FreightForwarderInformationItemTitle: 'Freight Forwarder Information',
  ContainerProcessingPage_FreightForwarderInformationItem_EditButton: 'Edit',
  ContainerProcessingPage_DocumentsItemTitle: 'Documents',
  // ContainerProcessingPage_DrumsItemTitle: 'Drums',
  ContainerProcessingPage_DrumsItemTitle: 'Container Stuffing and Sealing',
  ContainerProcessingPage_DrumsItem_EditButton: 'Edit',
  ContainerProcessingPage_ContainerStateSubTitle: 'Container',
  ContainerProcessingPage_ActionConfirmationDialogTitle: 'Confirm Container Processing Finished',
  ContainerProcessingPage_ActionConfirmationDialogFinishButtonCaption: 'Finish Processing',
  ContainerProcessingPage_ActionConfirmationDialogContainerStateItemLabel: 'Container state',
  ContainerProcessingPage_ActionConfirmationDialogContainerStateSuccessMessage: 'Sealed',
  ContainerProcessingPage_ActionConfirmationDialogContainerStateErrorMessage: 'Container is not sealed',
  ContainerProcessingPage_ActionConfirmationDialogDocumentsSuccessMessage: 'Documents',
  ContainerProcessingPage_ActionConfirmationDialogDocumentsMissingItemLabel: 'Documents missing',
  ContainerProcessingPage_ActionConfirmationDialogFreightForwarderItemLabel: 'Freight forwarder',
  ContainerProcessingPage_ActionConfirmationDialogFreightForwarderSuccessMessage: 'Provided',
  ContainerProcessingPage_ActionConfirmationDialogFreightForwarderErrorMessage: 'Not provided',
  ContainerProcessingPage_ActionConfirmationDialogCancelButtonCaption: 'Cancel',
  ContainerProcessingPage_RetrievingContainerDetailsError: 'Could not retrieve container details',
  ContainerProcessingPage_FinishContainerProcessingError: 'Could not finish container processing',
  ContainerProcessingPage_GetShipmentDocumentsError: 'Could not retrieve shipment documents',

  // Context Header
  ContextHeader_ContextTitleAndUserActions_AriaLabel: 'Context Title and User Actions Menu',

  // DocumentItem
  DocumentItem_SubTitleForUploadedDocument: '{{uploadedBy}} added on {{uploadedOn}}',
  DocumentItem_SubTitleRequired: 'Required',
  DocumentItem_SubTitleOptional: 'Optional',
  DocumentItem_SubTitleUploadingInProgress: 'Uploading...',
  DocumentItem_ViewButtonCaption: 'View',
  DocumentItem_UploadFileError: 'Could not upload selected file',
  DocumentItem_GetFileError: 'Could not retrieve document information',
  DocumentItem_DeleteFileError: 'Could not delete selected document',

  // DrumDrawer
  DrumDrawer_AddTitle: 'Add Drum',
  DrumDrawer_EditTitle: 'Edit Drum',
  DrumDrawer_PilesTitle: 'Piles',
  DrumDrawer_EmptyDrumTitle: 'Empty Drum',
  DrumDrawer_FilledDrumTitle: 'Filled Drum',
  DrumDrawer_SealedDrumTitle: 'Sealed Drum',
  DrumDrawer_LinkPilesLabel: 'Link Piles',
  DrumDrawer_EmptyDrumPhotoLabel: 'Photo of Empty Drum',
  DrumDrawer_FilledDrumPhotoLabel: 'Photo of Filled Drum',
  DrumDrawer_SealedDrumPhotoLabel: 'Photo of Sealed Drum',
  DrumDrawer_RequiredLabel: 'Required',
  DrumDrawer_SaveButtonCaption: 'Save',
  DrumDrawer_ReferenceNumberLabel: 'Reference Number',
  DrumDrawer_RobinsonSealNumberLabel: 'Robinson Seal Number',
  DrumDrawer_CoprocoSealNumberLabel: 'Coproco Seal Number',
  DrumDrawer_GrossWeightLabel: 'Gross Weight',
  DrumDrawer_NetWeightLabel: 'Net Weight',
  DrumDrawer_SearchFieldPlaceholder: 'Search by Pile ID',
  DrumDrawer_SelectPilesMessage: 'Select pile(s) for drum',
  DrumDrawer_PilesSelectedMessage: '{{count}} pile(s) selected',
  DrumDrawer_NoPilesSelectedErrorMessage: 'No piles have been selected',
  DrumDrawer_AddedPhotoValidSummary: '1 photo',
  DrumDrawer_AddDrumDrawerAccessibleFormTitle: 'Add Drum Form',
  DrumDrawer_EmptyDrumPhotoThumbnailAlternateText: 'Photo of an Empty Drum',
  DrumDrawer_EmptyDrumPhotoInputAriaLabel: 'Photo of Empty Drum',
  DrumDrawer_EmptyDrumPhotoCameraButtonAriaLabel: 'Add Photo',
  DrumDrawer_EmptyDrumPhotoFormAccessibilityTitle: 'Add Photo of an Empty Drum',
  DrumDrawer_FilledDrumPhotoThumbnailAlternateText: 'Photo of a Filled Drum',
  DrumDrawer_FilledDrumPhotoInputAriaLabel: 'Photo of Filled Drum',
  DrumDrawer_FilledDrumPhotoCameraButtonAriaLabel: 'Add Photo',
  DrumDrawer_FilledDrumPhotoFormAccessibilityTitle: 'Add Photo of a Filled Drum',
  DrumDrawer_ErrorLocatingExistingDocuments: 'Error locating existing documents on server',
  DrumDrawer_BackButtonAriaLabel: 'Go back to the main form',
  DrumDrawer_SealedDrumPhotoThumbnailAlternateText: 'Photo of a Sealed Drum',
  DrumDrawer_SealedDrumPhotoInputAriaLabel: 'Photo of a Sealed Drum',
  DrumDrawer_SealedDrumButtonAriaLabel: 'Add photo',
  DrumDrawer_SealedDrumFormAccessibilityTitle: 'Add Photo of a Sealed Drum',
  DrumDrawer_SealedDrumPhotoBackButtonAriaLabel: 'Go back to the main form',
  DrumDrawer_AddDrawerMainFormCloseButtonAriaLabel: 'Close without saving',
  DrumDrawer_AddDrawerMainFormAccessibilityTitle: 'Main add Drum form',
  DrumDrawer_SelectPilesStageBackButtonAriaLabel: 'Go back to the main form',
  DrumDrawer_SelectPilesFormAccessibilityTitle: 'Select constituent piles',

  // DrumProcessingPage
  DrumProcessingPage_Title: 'Drum Processing',
  DrumProcessingPage_BackButton: 'Back',
  DrumProcessingPage_AddButton: 'Add Drum',
  DrumProcessingPage_FinishButton: 'Finish',
  DrumProcessingPage_WillAppearHere: "Processed drums will appear here. To start, click 'Add Drum'",
  DrumProcessingPage_ReferenceNumberTableColumnTitle: 'Reference Number',
  DrumProcessingPage_RobinsonSealNumberTableColumnTitle: 'Robinson Seal Number',
  DrumProcessingPage_CoprocoSealNumberTableColumnTitle: 'Coproco Seal Number',
  DrumProcessingPage_GrossWeightTableColumnTitle: 'Gross Weight (kg)',
  DrumProcessingPage_NetWeightTableColumnTitle: 'Net Weight (kg)',
  DrumProcessingPage_ConfirmationDialogTitle: 'Confirm Drums Processing Finished',
  DrumProcessingPage_ConfirmationDialogDrumsProcessedItemLabel: 'Drums processed',
  DrumProcessingPage_ConfirmationDialogDrumsProcessedErrorMessage: 'Not all drums have been processed',
  DrumProcessingPage_ConfirmationDialogPilesConsumedItemLabel: 'Piles consumed',
  DrumProcessingPage_ConfirmationDialogPilesConsumedSuccessMessage: 'All',
  DrumProcessingPage_ConfirmationDialogPilesConsumedErrorMessage: 'Not all piles have been consumed',
  DrumProcessingPage_ConfirmationDialogFinishButtonCaption: 'Finish Processing',
  DrumProcessingPage_ConfirmationDialogCancelButtonCaption: 'Cancel',
  DrumProcessingPage_CouldNotCreateOrUpdateDrum_ErrorMessage: 'Could not create or update a drum',
  DrumProcessingPage_CouldNotGetDrums_ErrorMessage: 'Could not retrieve drums',
  DrumProcessingPage_CouldNotDeleteDrum_ErrorMessage: 'Could not delete a drum',
  DrumProcessingPage_CouldNotFinishDrumsProcessing_ErrorMessage: 'Could not finish drums processing',

  // DrumsProcessingManifestItem
  DrumsProcessingManifestItem_Title: 'Drums',
  DrumsProcessingManifestItem_EditButtonCaption: 'Edit',
  DrumsProcessingManifestItem_DrumsProcessedSectionTitle: 'Number of drums',

  // EditShipmentPage
  EditShipmentPage_Title: 'Edit Shipment',
  EditShipmentPage_SaveButtonCaption: 'Save',
  EditShipmentPage_CancelButtonCaption: 'Cancel',
  EditShipmentPage_DeleteButtonCaption: 'Delete',
  EditShipmentPage_ShipmentIDLabelCaption: 'Shipment ID',
  EditShipmentPage_ConfirmDeleteShipmentDialogTitle: 'Confirm Delete Shipment',
  EditShipmentPage_ConfirmDeleteShipmentDialogContent: 'Are you sure you want to delete this shipment?',
  EditShipmentPage_CouldNotGetShipment_ErrorMessage: 'Could not retrieve shipment',
  EditShipmentPage_CouldNotUpdateShipment_ErrorMessage: 'Could not update shipment',
  EditShipmentPage_CouldNotDeleteShipment_ErrorMessage: 'Could not delete shipment',

  // EditUserForm
  EditUserForm_ResetPasswordButtonCaption: 'Reset Password',
  EditUserForm_FullNameTextFieldLabel: 'Full Name',
  EditUserForm_EmailTextFieldLabel: 'E-mail',
  EditUserForm_RolesCheckboxSectionLabel: 'Roles',

  // EditUserPage
  EditUserPage_Title: 'Edit User',
  EditUserPage_SaveButtonCaption: 'Save',
  EditUserPage_SavingInProgressButtonCaption: 'Saving',
  EditUserPage_CancelButtonCaption: 'Cancel',
  EditUserPage_DeleteButtonCaption: 'Delete',
  EditUserPage_GetUserDataError: 'Could not retrieve user data',
  EditUserPage_SaveUserDataError: 'Could not save user data',

  // FormValidationError
  FormValidationError_FieldRequired: 'Required field',
  FormValidationError_InvalidEmail: 'Invalid e-mail address',
  FormValidationError_MinimumLengthError: 'Minimum length is {{length}} characters',
  FormValidationError_MaximumLengthError: 'Maximum length is {{length}} characters',
  FormValidationError_MinimumValueError: 'Minimum value is: {{value}}',
  FormValidationError_MaximumValueError: 'Maximum value is: {{value}}',
  FormValidationError_MultipleFilesSelectedError: 'Only one file is allowed',
  FormValidationError_FileTypeNotSupportedError: 'File type not supported',
  FormValidationError_FileTooLargeError: 'File size limit exceeded',

  // FreightForwarderConfirmationDialog
  FreightForwarderConfirmationDialog_Title: 'Confirm Container Processing Finished',
  FreightForwarderConfirmationDialog_CancelButtonCaption: 'Cancel',
  FreightForwarderConfirmationDialog_ConfirmButtonCaption: 'Finish Container Processing',
  FreightForwarderConfirmationDialog_FreightForwarderConfirmationLabel: 'Freight forwarder confirmation',
  FreightForwarderConfirmationDialog_FreightForwarderConfirmationMessage:
    'Yes, I confirm that all documents were handed to the freight forwarder',

  // FreightForwarderManifestItem
  FreightForwarderManifestItem_Title: 'Freight Forwarder Information',
  FreightForwarderManifestItem_EditButtonCaption: 'Edit',
  FreightForwarderManifestItem_TruckInformationSectionTitle: 'Truck information',
  FreightForwarderManifestItem_TruckRegistrationNoLabel: 'Registration No. Truck',
  FreightForwarderManifestItem_TruckTrailerNoLabel: 'Trailer',
  FreightForwarderManifestItem_TruckDriverNameLabel: 'Driver',
  FreightForwarderManifestItem_TruckDriverPassportLabel: 'Psp',

  // FreightForwardingInformationPage
  FreightForwardingInformationPage_Title: 'Freight Forwarding Information',
  FreightForwardingInformationPage_SaveButton: 'Save',
  FreightForwardingInformationPage_CancelButton: 'Cancel',
  FreightForwardingInformationPage_TruckInformation: 'Truck Information',
  FreightForwardingInformationPage_RegistrationNo: 'Registration No. Truck',
  FreightForwardingInformationPage_Trailer: 'Trailer',
  FreightForwardingInformationPage_DriverFirstName: 'Driver First Name',
  FreightForwardingInformationPage_DriverLastName: 'Driver Last Name',
  FreightForwardingInformationPage_DriverPsp: 'Driver Psp',
  FreightForwardingInformationPage_FreightForwarder: 'Freight Forwarder',
  FreightForwardingInformationPage_GetContainerDetailsError: 'Could not retrieve container details',
  FreightForwardingInformationPage_SaveFreightForwarderInformationError: 'Could not save freight forwarder information',

  // IoTDeviceInformationManifestItem
  IoTDeviceInformationManifestItem_Title: 'IoT Device Information',
  IoTDeviceInformationManifestItem_EditButtonCaption: 'View',
  IoTDeviceInformationManifestItem_DeviceDetailsTitle: 'IoT Device Details',
  IoTDeviceInformationManifestItem_IdLabel: 'ID',
  IoTDeviceInformationManifestItem_StatusLabel: 'Status',
  IoTDeviceInformationManifestItem_LocationLabel: 'Location',
  IoTDeviceInformationManifestItem_LastUpdateLabel: 'Last update',
  IoTDeviceInformationManifestItem_SensorReadingsTitle: 'Sensor readings',
  IoTDeviceInformationManifestItem_VibrationLabel: 'Vibration',
  IoTDeviceInformationManifestItem_TemperatureLabel: 'Temperature',
  IoTDeviceInformationManifestItem_LightLabel: 'Light',
  IoTDeviceInformationManifestItem_MovementLabel: 'Movement',
  IoTDeviceInformationManifestItem_TriggerLevelsTitle: 'Trigger Levels',

  // IoTDeviceInformationPage
  IoTDeviceInformationPage_Title: 'IoT Device Information',
  IoTDeviceInformationPage_CancelButton: 'Back',
  IoTDeviceInformationPage_SaveButton: 'Save',
  // IoTDeviceInformationPage_: '',
  // IoTDeviceInformationPage_: '',

  // LoginProgress
  LoginProgress_InProgressTitle: 'Logging in user...',
  LoginProgress_CouldNotLogInUserTitle: 'Could not log in user',
  LoginProgress_ContactSysAdminMessage: 'Please contact your system administrator',

  // NavigationDropMenu
  NavigationDropMenu_MenuButtonAriaLabel: 'Page Navigation Menu',
  NavigationDropMenu_MenuIconTitle: 'Page Navigation Menu Icon',

  // NavigationHeader
  NavigationHeader_CurrentUserDetailsAriaLabel: 'Current User Details',
  NavigationHeader_AfrimetLogoTitle: 'SAFE Logo',
  NavigationHeader_UserFullNameAriaLabel: 'User Full Name',
  NavigationHeader_UserEmailAriaLabel: 'User Email',
  NavigationHeader_UserAvatarAriaLabel: 'User Avatar',
  NavigationHeader_ShipmentsNavButton: 'Shipments',
  NavigationHeader_UsersNavButton: 'Users',
  NavigationHeader_HeaderAriaLabel: 'SAFE App Navigation, Logo, and User Details',

  // Paths
  Paths_ShipmentsPageDisplayName: 'Shipments',
  Paths_UsersPageDisplayName: 'Users',

  // PhotoInput
  PhotoInput_RemoveButtonTitle: 'Remove',
  PhotoInput_ViewButtonTitle: 'View',
  PhotoInput_DownloadPhotoTitle: 'Download',
  PhotoInput_CouldNotLoadPhotoErrorMessage: 'Could not load photo',

  // PileDrawer
  PileDrawer_Save: 'Save',
  PileDrawer_PileId: 'Pile ID',
  PileDrawer_AddPile: 'Add Pile',
  PileDrawer_EditPile: 'Edit Pile',
  PileDrawer_SelectedBags: 'Selected Bags',
  PileDrawer_SearchFieldPlaceholder: 'Search by bag seal',
  PileDrawer_SelectBagsMessage: 'Select bag(s) for pile',
  PileDrawer_BagsSelectedMessage: '{{count}} bags selected',
  PileDrawer_CloseDrawerIconButtonAriaLabel: 'Close Drawer',
  PileDrawer_AddBagDrawerAccessibleFormTitle: 'Add Pile Form',
  PileDrawer_EditBagDrawerAccessibleFormTitle: 'Edit Pile Form',

  // PileProcessingPage
  PileProcessingPage_Title: 'Piles',
  PileProcessingPage_BackButton: 'Back',
  PileProcessingPage_AddButton: 'Add Pile',
  PileProcessingPage_WillAppearHere: "Processed piles will appear here. To start, click 'Add Pile'",
  PileProcessingPage_PileIDTableColumnTitle: 'Pile ID',
  PileProcessingPage_BagsIDsTableColumnTitle: 'Bags / Seal Mines',
  PileProcessingPage_RetrievingPilesError: 'Could not retrieve piles',
  PileProcessingPage_CouldNotDeletePileError: 'Could not delete pile',
  PileProcessingPage_CouldNotCreateOrUpdatePileError: 'Could not create or update pile',

  // PilesConsumedConfirmationDialog
  PilesConsumedConfirmationDialog_Title: 'Confirm Piles Consumed',
  PilesConsumedConfirmationDialog_OKButtonCaption: 'OK',
  PilesConsumedConfirmationDialog_CancelButtonCaption: 'Cancel',
  PilesConsumedConfirmationDialog_TableCaption: 'Please tick off all of the piles that are consumed',

  // PilesManifestItem
  PilesManifestItem_Title: 'Piles',
  PilesManifestItem_EditButtonCaption: 'Edit',
  PilesManifestItem_BagsCountLabel: 'Count',

  // PilesProcessingManifestItem
  PilesProcessingManifestItem_Title: 'Piles',
  PilesProcessingManifestItem_EditButtonCaption: 'Edit',
  PilesProcessingManifestItem_PilesProcessedSectionTitle: 'Number of piles',
  PilesProcessingManifestItem_SamplingInformationSectionTitle: 'Sampling Information',
  PilesProcessingManifestItem_RobinsonReferenceNoLabel: 'Robinson Reference No.',
  PilesProcessingManifestItem_ContactLabel: 'Contact',

  // PilesProcessingPage
  PilesProcessingPage_Title: 'Piles Processing',
  PilesProcessingPage_BackButton: 'Back',
  PilesProcessingPage_FinishButton: 'Finish',
  PilesProcessingPage_SamplingInformationItemTitle: 'Sampling Information',
  PilesProcessingPage_SamplingInformationItem_EditButton: 'Edit',
  PilesProcessingPage_DocumentsItemTitle: 'Documents',
  PilesProcessingPage_PilesItemTitle: 'Piles',
  PilesProcessingPage_PilesItem_EditButton: 'Edit',
  PilesProcessingPage_ConfirmationDialogTitle: 'Confirm Piles Sampling Finished',
  PilesProcessingPage_ConfirmationDialogDocumentsItemLabel: 'Documents',
  PilesProcessingPage_ConfirmationDialogDocumentsSuccessMessage: 'Required documents uploaded',
  PilesProcessingPage_ConfirmationDialogDocumentsMissingItemLabel: 'Documents missing',
  PilesProcessingPage_ConfirmationDialogPilesProcessedItemLabel: 'Piles processed',
  PilesProcessingPage_ConfirmationDialogSingularPileItem: 'pile',
  PilesProcessingPage_ConfirmationDialogPluralPilesItem: 'piles',
  PilesProcessingPage_ConfirmationDialogPilesProcessedErrorMessage: 'Not all bags have been emptied',
  PilesProcessingPage_ConfirmationDialogFinishButtonCaption: 'Finish Processing',
  PilesProcessingPage_ConfirmationDialogCancelButtonCaption: 'Cancel',
  PilesProcessingPage_RetrievingPilesDetailsError: 'Could not retrieve piles details',
  PilesProcessingPage_FinishPilesProcessingError: 'Could not finish piles processing',
  PilesProcessingPage_RetrievingSamplingDocumentsError: 'Could not retrieve sampling documents',

  // SamplingInformationPage
  SamplingInformationPage_Title: 'Sampling Information',
  SamplingInformationPage_CancelButtonCaption: 'Cancel',
  SamplingInformationPage_SaveButtonCaption: 'Save',
  SamplingInformationPage_RobinsonReferenceNoLabel: 'Robinson Reference No.',
  SamplingInformationPage_ContactFirstNameLabel: 'Contact First Name',
  SamplingInformationPage_ContactLastNameLabel: 'Contact Last Name',
  SamplingInformationPage_RetrievingSamplingInformationError: 'Could not retrieve sampling information',
  SamplingInformationPage_SavingSamplingInformationError: 'Could not save sampling information',

  // SamplingManifestItem
  SamplingManifestItem_Title: 'Sampling Information',
  SamplingManifestItem_EditButtonCaption: 'Edit',
  SamplingManifestItem_RobinsonReferenceNoLabel: 'Robinson Reference No.',
  SamplingManifestItem_ContactLabel: 'Contact',

  // ShipmentActivity
  ShipmentActivity_BagsActivityStateIconTitle: 'Bags Activity State Icon',
  ShipmentActivity_DrumsActivityStateIconTitle: 'Drums Activity State Icon',
  ShipmentActivity_FreightForwarderActivityStateIconTitle: 'Freight Forwarder Activity State Icon',
  ShipmentActivity_ContainerActivityStateIconTitle: 'Container Activity State Icon',
  ShipmentActivity_ShipmentActivityStateIconTitle: 'Shipment Activity State Icon',
  ShipmentActivity_TruckArrivedActivityStateIconTitle: 'Truck Arrived Activity State Icon',
  ShipmentActivity_TruckActivityStateIconTitle: 'Truck Activity State Icon',
  ShipmentActivity_OverriddenCaption: 'overridden',
  ShipmentActivity_OverrideReasonCaption: 'Override reason',
  ShipmentActivity_RetrievingActivitiesError: 'Could not retrieve shipment activity',

  // ShipmentDetailsPage
  ShipmentDetailsPage_Title: 'Ship',
  ShipmentDetailsPage_CancelButton: 'Cancel',
  ShipmentDetailsPage_SaveButton: 'Save',
  ShipmentDetailsPage_SailDateInputLabel: 'Sail Date',
  ShipmentDetailsPage_ShipInformationSectionTitle: 'Ship Information',
  ShipmentDetailsPage_ShipRegistrationNoInputLabel: 'Ship Registration Number',
  ShipmentDetailsPage_ShipNameInputLabel: 'Ship Name',
  ShipmentDetailsPage_BillOfLadingIdInputLabel: 'Bill Of Lading ID',
  ShipmentDetailsPage_ContainerIdInputLabel: 'Container ID',
  ShipmentDetailsPage_WarehouseInformationSectionTitle: 'Warehouse Information',
  ShipmentDetailsPage_WarehouseContactFirstNameInputLabel: 'Contact First Name',
  ShipmentDetailsPage_WarehouseContactLastNameInputLabel: 'Contact Last Name',
  ShipmentDetailsPage_WarehouseContactCompanyInputLabel: 'Contact Company',
  ShipmentDetailsPage_WarehouseAgentInputLabel: 'Agent E-mail',
  ShipmentDetailsPage_CarrierInputLabel: 'Carrier',
  ShipmentDetailsPage_GetShipmentDetailsError: 'Could not retrieve shipment details',
  ShipmentDetailsPage_SaveShipmentInformationError: 'Could not save shipment information',

  // ShipmentDocuments
  ShipmentDocuments_RequiredDocumentsTitle: 'Required documents',
  ShipmentDocuments_BagProcessingDocumentsTitle: 'Bag Processing',
  ShipmentDocuments_CEEC1DocumentItemTitle: 'CEEC 1',
  ShipmentDocuments_SAEMAPECertificateDocumentItemTitle: 'SAEMAPE Certificate',
  ShipmentDocuments_TransportCertificateDocumentItemTitle: 'Transport Certificate',
  ShipmentDocuments_iTSCiExcelTagListDocumentItemTitle: 'iTSCi Excel Tag List',
  ShipmentDocuments_TaxDocumentItemTitle: 'Tax Documentation',
  ShipmentDocuments_FineDocumentItemTitle: 'Fine Documentation',
  ShipmentDocuments_PileSamplingDocumentsTitle: 'Pile Sampling',
  ShipmentDocuments_RobinsonInspectionReportDocumentItemTitle: 'Robinson Inspection Report',
  ShipmentDocuments_RobinsonFinalAnalysisReportDocumentItemTitle: 'Robinson Final Analysis Report',
  ShipmentDocuments_ContainerProcessingDocumentsTitle: 'Container Processing',
  ShipmentDocuments_C1FormDocumentItemTitle: 'C1 Form',
  ShipmentDocuments_FacteurCommercialeDocumentItemTitle: 'Facteur Commerciale',
  ShipmentDocuments_ExportLicenceDocumentItemTitle: 'Export Licence',
  ShipmentDocuments_CommerceExtDocumentItemTitle: 'Commerce Ext',
  ShipmentDocuments_FicheComptoirDocumentItemTitle: 'Fiche Comptoir',
  ShipmentDocuments_CGADocumentItemTitle: 'CGA',
  ShipmentDocuments_ContainerPackingListDocumentItemTitle: 'Container Packing List',
  ShipmentDocuments_DGDADocumentItemTitle: 'DGDA',
  ShipmentDocuments_AfrimetShiptFileDocumentItemTitle: 'SAFE Shipt File',
  ShipmentDocuments_TruckManifestDocumentItemTitle: 'Truck Manifest',
  ShipmentDocuments_CustomsDocumentsTitle: 'Customs',
  ShipmentDocuments_LaboPriveeItemTitle: 'Labo Privee',
  ShipmentDocuments_CEECFinalItemTitle: 'CEEC Final',
  ShipmentDocuments_VesselPackingListDocumentItemTitle: 'Vessel Packing List',
  ShipmentDocuments_ForkliftWeightRecordDocumentItemTitle: 'Forklift Weight Record Documentation',
  ShipmentDocuments_BillOfLadingDocumentItemTitle: 'Bill Of Lading',
  ShipmentDocuments_AdditionalDocumentsTitle: 'Additional Documents',
  ShipmentDocuments_COPDocumentItemTitle: 'COP',
  ShipmentDocuments_DataSafetySheetDocumentItemTitle: 'Data Safety Sheet',
  ShipmentDocuments_PoliceOfMinesDocumentItemTitle: 'Police Of Mines',
  ShipmentDocuments_OffloadSheetDocumentItemTitle: 'Offload Sheet',
  ShipmentDocuments_TechnicalSpecificationDocumentItemTitle: 'Technical Specification',
  ShipmentDocuments_RetrievingDocumentsError: 'Could not retrieve all shipment documents',

  // ShipmentInformationManifestItem
  ShipmentInformationManifestItemInOverviewPage_Title: 'Shipment',
  ShipmentInformationManifestItemInProcessingPage_Title: 'Shipment Information',
  ShipmentInformationManifestItem_EditButtonCaption: 'Edit',
  ShipmentInformationManifestItem_SailDateSectionTitle: 'Sail Date',
  ShipmentInformationManifestItem_ShipInformationSectionTitle: 'Ship information',
  ShipmentInformationManifestItem_ShipRegistrationNoLabel: 'Registration No. Ship',
  ShipmentInformationManifestItem_ShipNameLabel: 'Ship Name',
  ShipmentInformationManifestItem_BillOfLadingIdLabel: 'Bill Of Lading ID',
  ShipmentInformationManifestItem_ContainerIdLabel: 'Container ID',
  ShipmentInformationManifestItem_WarehouseInformationSectionTitle: 'Warehouse information',
  ShipmentInformationManifestItem_ContactLabel: 'Contact',
  ShipmentInformationManifestItem_AgentLabel: 'Agent',

  // ShipmentOverview
  ShipmentOverview_NextStepsTitle: 'Next steps',
  ShipmentOverview_ManifestTitle: 'Manifest',
  ShipmentOverview_BagsHeaderItemTitle: 'Bags',
  ShipmentOverview_PilesHeaderItemTitle: 'Piles',
  ShipmentOverview_DrumsHeaderItemTitle: 'Drums',
  ShipmentOverview_ContainerHeaderItemTitle: 'Containers',
  ShipmentOverview_ShipmentHeaderItemTitle: 'Shipment',
  ShipmentOverview_HeaderItemButtonCaption: 'Start',
  ShipmentOverview_FinishedStepsLabel: 'Finished steps will appear here',

  // ShipmentsList
  ShipmentsList_ViewDetailsButtonCaption: 'View details',
  ShipmentsList_LastUpdatedLabel: 'Last updated ',
  ShipmentsList_TransportInformationCellTitle: 'Transport Information',
  ShipmentsList_TruckRegistrationNoLabel: 'Registration No. Truck: ',
  ShipmentsList_TrailerRegistrationNoLabel: 'Trailer: ',
  ShipmentsList_DriverNameLabel: 'Driver: ',
  ShipmentsList_DriverPassportLabel: 'Psp: ',
  ShipmentsList_DeliveryDestinationCellTitle: 'Delivery Destination',
  ShipmentsList_ContactLabel: 'Contact',
  ShipmentsList_AgentLabel: 'Agent',
  ShipmentsList_PortDepartureCellTitle: 'Port Departure Date',
  ShipmentsList_FilterOptionCaption_ShipmentID: 'Shipment ID',
  ShipmentsList_FilterOptionCaption_SailDate: 'Shipment Sail Date',
  ShipmentsList_SearchPlaceholder_ShipmentID: 'Search by Shipment ID',

  // ShipmentsPage
  ShipmentsPage_Title: 'Shipments',
  ShipmentsPage_NewShipmentButtonCaption: 'New shipment',
  ShipmentsPage_WillAppearHere: "Shipments will appear here. To start, click 'New shipment'",
  ShipmentsPage_CreatingNewShipmentCaption: 'Creating new shipment...',
  ShipmentsPage_CouldNotRetrieveShipmentsError: 'Could not retrieve shipments',
  ShipmentsPage_CouldNotCreateShipmentsError: 'Could not create a shipment',

  // ShipmentProcessingPage
  ShipmentProcessingPage_Title: 'Shipment',
  ShipmentProcessingPage_BackButton: 'Back',
  ShipmentProcessingPage_FinishButton: 'Finish',
  ShipmentProcessingPage_ShipmentInformationItemTitle: 'Shipment Information',
  ShipmentProcessingPage_ShipmentInformationItem_EditButton: 'Edit',
  ShipmentProcessingPage_DocumentsItemTitle: 'Documents',
  ShipmentProcessingPage_ConfirmationDialogTitle: 'Confirm Ship Sailed',
  ShipmentProcessingPage_ConfirmationDialogConfirmButtonCaption: 'Confirm',
  ShipmentProcessingPage_ConfirmationDialogCancelButtonCaption: 'Cancel',
  ShipmentProcessingPage_ConfirmationDialogDocumentsItemLabel: 'Documents',
  ShipmentProcessingPage_ConfirmationDialogDocumentsMissingItemLabel: 'Documents missing',
  ShipmentProcessingPage_ConfirmationDialogDocumentsSuccessMessage: 'Required documents uploaded',
  ShipmentProcessingPage_GetShipmentDetailsError: 'Could not retrieve shipment details',
  ShipmentProcessingPage_ConfirmShipSailedError: 'Could not confirm ship sailed',
  ShipmentProcessingPage_GetShipmentDocumentsError: 'Could not retrieve shipment documents',

  // TruckArrivalProcessingPage
  TruckArrivalProcessingPage_Title: 'Truck Arrival Processing',
  TruckArrivalProcessingPage_SaveButton: 'Save',
  TruckArrivalProcessingPage_CancelButton: 'Cancel',
  TruckArrivalProcessingPage_RegistrationNo: 'Registration No. Truck',
  TruckArrivalProcessingPage_Trailer: 'Trailer',
  TruckArrivalProcessingPage_DriverFirstName: 'Driver First Name',
  TruckArrivalProcessingPage_DriverLastName: 'Driver Last Name',
  TruckArrivalProcessingPage_DriverPsp: 'Driver Psp',
  TruckArrivalProcessingPage_CoprocoStaffWitnessFirstName: 'First Name',
  TruckArrivalProcessingPage_CoprocoStaffWitnessLastName: 'Last Name',
  TruckArrivalProcessingPage_CeecOfficerWitnessFirstName: 'First Name',
  TruckArrivalProcessingPage_CeecOfficerWitnessLastName: 'Last Name',
  TruckArrivalProcessingPage_DivisionOfMineWitnessFirstName: 'First Name',
  TruckArrivalProcessingPage_DivisionOfMineWitnessLastName: 'Last Name',
  TruckArrivalProcessingPage_TruckInformation: 'Truck Information',
  TruckArrivalProcessingPage_Witnesses: 'Witnesses',
  TruckArrivalProcessingPage_RetrievingTruckDetailsError: 'Could not retrieve truck details',
  TruckArrivalProcessingPage_SavingTruckDetailsError: 'Could not save truck details',

  // TruckManifestItem
  TruckManifestItem_Title: 'Arrival Information',
  TruckManifestItem_EditButtonCaption: 'Edit',
  TruckManifestItem_TruckInformationSectionTitle: 'Truck information',
  TruckManifestItem_TruckRegistrationNoLabel: 'Registration No. Truck',
  TruckManifestItem_TruckTrailerNoLabel: 'Trailer',
  TruckManifestItem_TruckDriverNameLabel: 'Driver',
  TruckManifestItem_TruckDriverPassportLabel: 'Psp',
  TruckManifestItem_WitnessesSectionTitle: 'Witnesses',

  // UserEdit
  UserEdit_Title: 'Edit User',
  UserEdit_CancelButtonLabel: 'Cancel',
  UserEdit_DeleteButtonLabel: 'Delete',
  UserEdit_SaveButtonLabel: 'Save',
  UserEdit_ResetPasswordButtonLabel: 'Reset Password',
  UserEdit_UserDoesNotExistError: 'User with ID: {{userId}} does not exist',
  UserEdit_FullNameTextFieldLabel: 'Full Name',
  UserEdit_EmailTextFieldLabel: 'E-mail',
  UserEdit_RolesCheckboxSectionLabel: 'Roles',

  // UsersList
  UsersList_RolesFilter: 'Roles',
  UsersList_NameColumnTitle: 'Name',
  UsersList_RolesColumnTitle: 'Roles',
  UsersList_SearchFieldPlaceholder: 'Search by name',

  // UsersPage
  UsersPage_Title: 'Users',
  UsersPage_CouldNotGetUsersError: 'Could not get users',

  // ViewDocumentPage
  ViewDocumentPage_DownloadButtonCaption: 'Download',
  ViewDocumentPage_BackButtonCaption: 'Back',
  ViewDocumentPage_PreviewUnavailableMessage: "Document preview is unavailable. Click 'Download' button to download a file.",
  ViewDocumentPage_RetrievingDocumentDetailsError: 'Could not retrieve document details',

  // ViewShipmentPage
  ViewShipmentPage_FinishButtonCaption: 'Finish',
  ViewShipmentPage_BackButtonCaption: 'Back',
  ViewShipmentPage_EditButtonCaption: 'Edit',
  ViewShipmentPage_UpdateSubtitle: 'Updated',
  ViewShipmentPage_OverviewTabLabel: 'Overview',
  ViewShipmentPage_DocumentsTabLabel: 'Documents',
  ViewShipmentPage_ActivityTabLabel: 'Activity',
  ViewShipmentPage_RetrievingShipmentDetailsError: 'Could not retrieve all shipment details',
  ViewShipmentPage_CompletingShipmentError: 'Could not complete shipment',
  ViewShipmentPage_ConfirmationDialogTitle: 'Confirm Shipment Complete',
  ViewShipmentPage_ConfirmationDialogConfirmButtonCaption: 'Complete Shipment',
  ViewShipmentPage_ConfirmationDialogCancelButtonCaption: 'Cancel',
  ViewShipmentPage_ConfirmationDialogDocumentsItemLabel: 'Documents',
  ViewShipmentPage_ConfirmationDialogDocumentsMissingItemLabel: 'Documents missing',
  ViewShipmentPage_ConfirmationDialogDocumentsSuccessMessage: 'All required documents uploaded',
  ViewShipmentPage_RetrievingDocumentsError: 'Could not retrieve all shipment documents',
};
