import { Representative, RepresentativeWrite } from 'models/representative';
import { State } from 'models/state';

export class Shipment {
  // eslint-disable-next-line no-useless-constructor
  constructor(
    public id: number,
    public shipmentReference: string,
    public state: State,
    public lastUpdated: Date,
    public arrivalTruckDetails: TruckDetails,
    public samplingReference: string,
    public samplingTechnician: Representative,
    public shipRegistrationNumber: string,
    public shipName: string,
    public sailDate: Date,
    public billOfLadingId: string,
    public shipContainerId: string,
    public warehouseRepresentative: Representative,
    public carrier: string,
  ) {}
}

export class ShipmentWrite {
  // eslint-disable-next-line no-useless-constructor
  constructor(public shipmentReference: string) {}
}

export const shipmentStates = {
  Future: 'Future',
  New: 'New',
  BagsProcessed: 'Bags Processed',
  DrumsProcessed: 'Drums Processed',
  ContainersProcessed: 'Containers Processed',
  WithFreightForwarder: 'With Freight Forwarder',
  Sailed: 'Sailed',
  Complete: 'Complete',
} as const;

export class TruckDetails {
  // eslint-disable-next-line no-useless-constructor
  constructor(
    public id: number,
    public state: State,
    public truckLicensePlate: string,
    public trailerLicensePlate: string,
    public driverFirstName: string,
    public driverLastName: string,
    public driverPassportNumber: string,
    public witnesses: Representative[],
  ) {}
}

export class ShipmentDepartureWrite {
  // eslint-disable-next-line no-useless-constructor
  constructor(
    public shipRegistrationNumber: string,
    public shipName: string,
    public sailDate: Date,
    public billOfLadingId: string,
    public shipContainerId: string,
    public warehouseRepresentative: RepresentativeWrite,
    public carrier?: string,
  ) {}
}

export class ShipmentSummary {
  // eslint-disable-next-line no-useless-constructor
  constructor(
    public id: number,
    public state: State,
    public shipmentReference: string,
    public lastUpdated: Date,
    public bagsSummary: ShipmentSummaryBags,
    public pilesSummary: ShipmentSummaryPiles,
    public drumsSummary: ShipmentSummaryDrums,
    public containerSummary: ShipmentSummaryContainer,
    public shipmentSummary: ShipmentSummaryShipment,
  ) {}
}

export class ShipmentSummaryBags {
  // eslint-disable-next-line no-useless-constructor
  constructor(
    public count: number,
    public totalExpectedWeightInKg: number,
    public totalActualWeightInKg: number,
    public truck: TruckDetails,
    public witnesses: Representative[],
  ) {}
}

export class ShipmentSummaryPiles {
  // eslint-disable-next-line no-useless-constructor
  constructor(
    public count: number,
    public samplingReference: string,
    public samplingTechnician: Representative,
    public sampledCount: number,
    public consumedCount: number,
  ) {}
}

export class ShipmentSummaryDrums {
  // eslint-disable-next-line no-useless-constructor
  constructor(public count: number) {}
}

export class ShipmentSummaryContainer {
  // eslint-disable-next-line no-useless-constructor
  constructor(
    public id: number,
    public state: State,
    public tagBoxId: string,
    public leopardLockId: string,
    public containerSealId: string,
    public truckLicensePlate: string,
    public trailerLicensePlate: string,
    public driverFirstName: string,
    public driverLastName: string,
    public driverPassportNumber: string,
  ) {}
}

export class ShipmentSummaryShipment {
  // eslint-disable-next-line no-useless-constructor
  constructor(
    public shipRegistrationNumber: string,
    public shipName: string,
    public sailDate: Date,
    public billOfLadingId: string,
    public shipContainerId: string,
    public warehouseRepresentative: Representative,
  ) {}
}
