import { State } from 'models/state';
import { shipmentStates } from 'models/shipment';

import { Chip, Icon } from '@mui/material';
import { backgroundColor, cursor, onlyComputedCombineClassnames, textColor, typedUtilityClassnames } from 'style/compoundClassnames';

import { ReactComponent as ShipmentStateIcon } from 'icons/shipmentState.svg';
import { ReactComponent as ShipmentStateSuccessIcon } from 'icons/shipmentStateSuccess.svg';
import { ReactComponent as ShipmentStateWarningIcon } from 'icons/shipmentStateWarning.svg';

// TODO: there is no use case for this icon yet (background color is: bg-error-600)
// import { ReactComponent as ShipmentStateErrorIcon } from 'icons/shipmentStateError.svg';

const baseIndicatorClassNames = typedUtilityClassnames('body2', cursor('cursor-default'));
const getIndicatorClassNames = (state: State): string | undefined =>
  onlyComputedCombineClassnames(
    {
      [backgroundColor('bg-warning-100')]: state.isOverridden,
      [backgroundColor('bg-normal-50')]: state.name === shipmentStates.New || state.name === shipmentStates.Future,
      [backgroundColor('bg-success-600')]: state.name !== shipmentStates.New && state.name !== shipmentStates.Future && !state.isOverridden,
      [textColor('text-black')]: state.name === shipmentStates.New || state.name === shipmentStates.Future || state.isOverridden,
      [textColor('text-white')]: state.name !== shipmentStates.New && state.name !== shipmentStates.Future && !state.isOverridden,
    },
    baseIndicatorClassNames,
  );

const getStatusIcon = (state: State) => {
  switch (state.name) {
    case shipmentStates.New:
    case shipmentStates.Future:
      return <></>;
    case shipmentStates.BagsProcessed:
    case shipmentStates.DrumsProcessed:
    case shipmentStates.ContainersProcessed:
    case shipmentStates.WithFreightForwarder:
    case shipmentStates.Sailed:
    case shipmentStates.Complete:
      return state.isOverridden ? <ShipmentStateWarningIcon /> : <ShipmentStateSuccessIcon />;
  }
};

export interface ShipmentStateIndicatorProps {
  shipmentState: State;
}

export const ShipmentStateIndicator = ({ shipmentState }: ShipmentStateIndicatorProps): JSX.Element => {
  const handleDelete = () => {}; // TODO: this is used to display second icon on the chip component

  return (
    <Chip
      icon={
        <Icon sx={{ background: 'white', pt: '5px', pl: '5px', borderRadius: 8 }}>
          <ShipmentStateIcon />
        </Icon>
      }
      label={shipmentState.name}
      sx={{ '& .MuiChip-deleteIcon': { cursor: 'default' } }}
      className={getIndicatorClassNames(shipmentState)}
      deleteIcon={getStatusIcon(shipmentState)}
      onDelete={handleDelete}
    />
  );
};
