import { cloneElement, ReactElement } from 'react';
import {
  onlyComputedCombineClassnames,
  typedUtilityClassnames,
  textAlign,
  backgroundColor,
  display,
  gridTemplateColumns,
  textColor,
  width,
  gridColumnStart,
  alignItems,
  justifySelf,
  padding,
  gridTemplateRows,
  gap,
  gridRowStart,
  justifyContent,
  height,
  overflow,
  gridColumn,
  flexDirection,
  flexGrow,
  minHeight,
  borderWidth,
  borderColor,
  textOverflow,
  textTransform,
} from 'style/compoundClassnames';

import { s } from 'i18n/strings';

type LeftRightGroupTotalCounts = {
  leftGroup: number;
  rightGroup: number;
  total: number;
};

const countLeftRightGroupsAndTotal = (left1: boolean, left2: boolean, right1: boolean, right2: boolean): LeftRightGroupTotalCounts => {
  const leftButtonsCount = (left1 ? 1 : 0) + (left2 ? 1 : 0);
  const rightButtonsCount = (right1 ? 1 : 0) + (right2 ? 1 : 0);
  const buttonCount = leftButtonsCount + rightButtonsCount;
  return { leftGroup: leftButtonsCount, rightGroup: rightButtonsCount, total: buttonCount };
};

const getButtonGroupsSharedClassNames = (total: number, leftGroup: number, rightGroup: number) =>
  typedUtilityClassnames(
    width('w-full'),
    alignItems('items-start', 'lg:items-center'),
    height(
      { 'h-28': leftGroup === 2 && rightGroup === 2 },
      { 'h-14': (leftGroup === 1 && rightGroup === 1) || total < 2 },
      'sm:h-14',
      'lg:h-16',
    ),
    display('flex'),
    flexDirection('flex-col', 'sm:flex-row'),
    gap('gap-2'),
    overflow('overflow-hidden'),
    padding('pb-2', 'lg:pb-0'),
    textOverflow('text-ellipsis'),
  );

const staticContentsLeftButtonGroupClassNames = typedUtilityClassnames(
  gridRowStart('row-start-2', 'sm:row-start-2', 'lg:row-start-1'),
  justifySelf('sm:justify-self-start'),
  justifyContent('sm:justify-start'),
);

const getLeftAndRightButtonGroupGridColumn = (total: number, currentSidesGroupCount: number) =>
  gridColumn({ [gridColumn('sm:col-span-2', 'lg:col-span-1')]: total === 3 && currentSidesGroupCount === 2 });

const getAllContentsLeftButtonGroupClassNames = ({ total, leftGroup, rightGroup }: LeftRightGroupTotalCounts) =>
  typedUtilityClassnames(
    staticContentsLeftButtonGroupClassNames,
    getButtonGroupsSharedClassNames(total, leftGroup, rightGroup),
    padding({ 'pb-2': !!rightGroup, 'sm:pb-0': !!rightGroup, 'sm:pr-2': !!rightGroup, 'lg:pr-0': !!rightGroup }),
    getLeftAndRightButtonGroupGridColumn(total, leftGroup),
  );

const staticContentsRightButtonGroupClassNames = typedUtilityClassnames(
  gridRowStart('row-start-3', 'sm:row-start-2', 'lg:row-start-1'),
  gridColumnStart('lg:col-start-3'),
  justifySelf('sm:justify-self-end'),
  justifyContent('sm:justify-end'),
);

const getAllContentsRightButtonGroupClassNames = ({ total, leftGroup, rightGroup }: LeftRightGroupTotalCounts) =>
  typedUtilityClassnames(
    staticContentsRightButtonGroupClassNames,
    getButtonGroupsSharedClassNames(total, leftGroup, rightGroup),
    getLeftAndRightButtonGroupGridColumn(total, rightGroup),
  );

const staticContentsTitleContainerClassNames = typedUtilityClassnames(
  height('h-16'),
  gridRowStart('row-start-1'),
  gridColumnStart('col-start-1', 'sm:col-start-1', 'lg:col-start-2'),
  display('flex'),
  flexDirection('flex-col'),
  justifyContent('justify-center'),
  alignItems('items-center'),
  gridColumn('sm:col-span-2', 'lg:col-span-1'),
);

const getAllContentsTitleContainerClassNames = ({ total }: LeftRightGroupTotalCounts) =>
  typedUtilityClassnames(
    staticContentsTitleContainerClassNames,
    gridColumn({ [gridColumn('sm:col-span-3', 'lg:col-span-1')]: total === 3 }),
  );

const contextTitleLightClassNames = typedUtilityClassnames(
  'headline5',
  textAlign('text-center'),
  textColor('text-primary-900'),
  padding('p-2'),
);

const contextSubTitleLightClassNames = typedUtilityClassnames(
  'navBlockFont',
  textAlign('text-center'),
  textColor('text-onSurface-mediumEmphasis'),
  textTransform('uppercase'),
);

const contextTitleDarkClassNames = typedUtilityClassnames('headline5', textAlign('text-center'), textColor('text-white'), padding('p-2'));

const contextSubTitleDarkClassNames = typedUtilityClassnames(
  'navBlockFont',
  textAlign('text-center'),
  textColor('text-primary-100'), // TODO: missing color 'text-onPrimary-mediumEmphasis': #FFFFFF 74%
  textTransform('uppercase'),
);

const staticHeaderBaseSubsectionLightClassNames = typedUtilityClassnames(
  backgroundColor('bg-white'),
  display('grid'),
  alignItems('items-start', 'sm:items-center'),
);

const staticHeaderBaseSubsectionDarkClassNames = typedUtilityClassnames(
  backgroundColor('bg-primary-800'),
  display('grid'),
  alignItems('items-start', 'sm:items-center'),
);

const getAllHeaderBaseSubsectionClassNames = ({ total, rightGroup, leftGroup }: LeftRightGroupTotalCounts) =>
  typedUtilityClassnames(
    gridTemplateColumns(
      'grid-cols-1',
      { 'sm:grid-cols-2': (rightGroup === 1 && leftGroup === 1) || total > 2, 'sm:grid-cols-3': total === 3 },
      'lg:grid-cols-1fmc1f',
    ),
    gridTemplateRows({ 'grid-rows-mc3': total > 1, 'grid-rows-mc2': total === 1 }, 'sm:grid-rows-mc2', 'lg:grid-rows-1'),
  );

const sharedButtonClassNames = typedUtilityClassnames(
  flexGrow('grow', 'lg:grow-0'),
  width('w-full', 'lg:w-fit'),
  alignItems('items-center'),
);

const getMainContainerSectionElementClassNames = (showContextHeaderContentSpacer: boolean) =>
  typedUtilityClassnames(
    'headers',
    minHeight({ 'min-h-20': showContextHeaderContentSpacer, 'min-h-16': !showContextHeaderContentSpacer }),
    borderWidth({ 'border-b-16': showContextHeaderContentSpacer }),
    borderColor({ 'border-card-100': showContextHeaderContentSpacer }),
  );

export interface _ContextHeaderBaseProps {
  contextTitle: string;
  contextTitleClassNames?: string;
  contextSubTitle?: string;
  contextSubTitleClassNames?: string;
  headerBaseSubsectionClassNames?: string;
  buttonsSharedClassNames?: string;
  ariaLabel: string;
  leftButtonOne?: ReactElement;
  leftButtonTwo?: ReactElement;
  rightButtonOne?: ReactElement;
  rightButtonTwo?: ReactElement;
  showContextHeaderContentSpacer: boolean;
}

const _ContextHeaderBase = ({
  contextTitle,
  contextTitleClassNames,
  contextSubTitle,
  contextSubTitleClassNames,
  ariaLabel,
  headerBaseSubsectionClassNames,
  buttonsSharedClassNames,
  leftButtonOne,
  leftButtonTwo,
  rightButtonOne,
  rightButtonTwo,
  showContextHeaderContentSpacer = false,
}: _ContextHeaderBaseProps): JSX.Element => {
  const buttonsCounts = countLeftRightGroupsAndTotal(!!leftButtonOne, !!leftButtonTwo, !!rightButtonOne, !!rightButtonTwo);
  const { rightGroup } = buttonsCounts;

  return (
    <>
      <section
        aria-label={ariaLabel}
        className={onlyComputedCombineClassnames(
          headerBaseSubsectionClassNames,
          getMainContainerSectionElementClassNames(showContextHeaderContentSpacer),
          getAllHeaderBaseSubsectionClassNames(buttonsCounts),
        )}
      >
        {(leftButtonOne || leftButtonTwo) && (
          <div className={getAllContentsLeftButtonGroupClassNames(buttonsCounts)}>
            {leftButtonOne &&
              cloneElement(leftButtonOne, {
                buttonClassNames: onlyComputedCombineClassnames(leftButtonOne.props.buttonClassNames, buttonsSharedClassNames),
              })}
            {leftButtonTwo &&
              cloneElement(leftButtonTwo, {
                buttonClassNames: onlyComputedCombineClassnames(leftButtonTwo.props.buttonClassNames, buttonsSharedClassNames),
              })}
          </div>
        )}
        <div className={getAllContentsTitleContainerClassNames(buttonsCounts)}>
          <h1 className={contextTitleClassNames}>{contextTitle}</h1>
          {contextSubTitle ? <p className={contextSubTitleClassNames}>{contextSubTitle}</p> : <div />}
        </div>
        {rightGroup > 0 && (
          <div className={getAllContentsRightButtonGroupClassNames(buttonsCounts)}>
            {rightButtonOne &&
              cloneElement(rightButtonOne, {
                buttonClassNames: onlyComputedCombineClassnames(rightButtonOne.props.buttonClassNames, buttonsSharedClassNames),
              })}
            {rightButtonTwo &&
              cloneElement(rightButtonTwo, {
                buttonClassNames: onlyComputedCombineClassnames(rightButtonTwo.props.buttonClassNames, buttonsSharedClassNames),
              })}
          </div>
        )}
      </section>
    </>
  );
};

// TODO: rename to LightContextHeader
export const ContextHeader = ({
  contextTitle,
  contextSubTitle,
  contextSubTitleClassNames = '',
  leftButtonOne,
  leftButtonTwo,
  rightButtonOne,
  rightButtonTwo,
  buttonsSharedClassNames = '',
  showContextHeaderContentSpacer = false,
}: Omit<_ContextHeaderBaseProps, 'ariaLabel'>): JSX.Element => {
  return (
    <>
      <_ContextHeaderBase
        contextTitle={contextTitle}
        contextTitleClassNames={contextTitleLightClassNames}
        contextSubTitle={contextSubTitle}
        contextSubTitleClassNames={onlyComputedCombineClassnames(contextSubTitleLightClassNames, contextSubTitleClassNames)}
        ariaLabel={s.ContextHeader_ContextTitleAndUserActions_AriaLabel}
        headerBaseSubsectionClassNames={staticHeaderBaseSubsectionLightClassNames}
        buttonsSharedClassNames={onlyComputedCombineClassnames(buttonsSharedClassNames, sharedButtonClassNames)}
        leftButtonOne={leftButtonOne}
        leftButtonTwo={leftButtonTwo}
        rightButtonOne={rightButtonOne}
        rightButtonTwo={rightButtonTwo}
        showContextHeaderContentSpacer={showContextHeaderContentSpacer}
      />
    </>
  );
};

export const DarkContextHeader = ({
  contextTitle,
  contextSubTitle,
  leftButtonOne,
  leftButtonTwo,
  rightButtonOne,
  rightButtonTwo,
  buttonsSharedClassNames = '',
  showContextHeaderContentSpacer = false,
}: Omit<_ContextHeaderBaseProps, 'ariaLabel'>): JSX.Element => {
  return (
    <>
      <_ContextHeaderBase
        contextTitle={contextTitle}
        contextTitleClassNames={contextTitleDarkClassNames}
        contextSubTitle={contextSubTitle}
        contextSubTitleClassNames={contextSubTitleDarkClassNames}
        ariaLabel={s.ContextHeader_ContextTitleAndUserActions_AriaLabel}
        headerBaseSubsectionClassNames={staticHeaderBaseSubsectionDarkClassNames}
        buttonsSharedClassNames={onlyComputedCombineClassnames(buttonsSharedClassNames, sharedButtonClassNames)}
        leftButtonOne={leftButtonOne}
        leftButtonTwo={leftButtonTwo}
        rightButtonOne={rightButtonOne}
        rightButtonTwo={rightButtonTwo}
        showContextHeaderContentSpacer={showContextHeaderContentSpacer}
      />
    </>
  );
};
