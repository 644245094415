import { PrimaryButton, buttonTypes } from 'components/buttons';
import { CircularIconButton } from 'components/icon-buttons';
import { s } from 'i18n/strings';
import type { ReactNode } from 'react';
import {
  alignItems,
  display,
  gridColumn,
  gridColumnStart,
  gridRowStart,
  gridTemplateColumns,
  gridTemplateRows,
  height,
  justifySelf,
  onlyComputedCombineClassnames,
  padding,
  textAlign,
  textColor,
  typedUtilityClassnames,
  width,
} from 'style/compoundClassnames';

import { ReactComponent as BackIcon } from 'icons/back.svg';
import { ReactComponent as CloseIcon } from 'icons/close.svg';

export interface _DrawerFormBaseProps {
  children: ReactNode;
  title: string;
  formAccessibilityTitle: string;
  formId: string;
  handleSubmit?: () => void;
  backOnClick?: () => void;
  closeOnClick?: () => void;
  showPrimarySubmitButton?: boolean;
  primarySubmitDisabled?: boolean;
  formClassNames?: string;
  key?: string | number;
  closeButtonAriaLabel?: string;
  backButtonAriaLabel?: string;
}
const backIconButtonClassNames = typedUtilityClassnames(width('w-fit'), padding('p-4'), justifySelf('justify-self-start'));
const closeIconButtonClassNames = typedUtilityClassnames(width('w-fit'), padding('p-4'), justifySelf('justify-self-end'));
const drawerContainerClassNames = typedUtilityClassnames(
  height('h-full'),
  width('w-80'),
  display('grid'),
  gridTemplateRows('grid-rows-mc2fr1'),
);
const drawerTitleContainerClassNames = typedUtilityClassnames(
  display('grid'),
  gridTemplateColumns('grid-cols-6'),
  alignItems('items-center'),
  padding('p-1'),
);
const drawerTitleClassNames = typedUtilityClassnames(
  'headline5',
  textColor('text-primary-900'),
  gridColumnStart('col-start-2'),
  gridColumn('col-span-4'),
  textAlign('text-center'),
);
const saveButtonClassNames = typedUtilityClassnames(gridRowStart('row-start-3'), width('w-full'));
const submitButtonContainerClassNames = typedUtilityClassnames(padding('p-6'), display('flex'), alignItems('items-end'));

const getFormClassNames = (formClassNames?: string) =>
  onlyComputedCombineClassnames(typedUtilityClassnames(width('w-inherit'), padding('px-6')), formClassNames);

export const _DrawerFormBase = ({
  title,
  children,
  backOnClick,
  closeOnClick,
  formAccessibilityTitle,
  formId,
  showPrimarySubmitButton = true,
  primarySubmitDisabled,
  handleSubmit,
  formClassNames,
  key,
  closeButtonAriaLabel,
  backButtonAriaLabel,
}: _DrawerFormBaseProps): JSX.Element => {
  return (
    <div className={drawerContainerClassNames} key={key}>
      <nav className={drawerTitleContainerClassNames}>
        {backOnClick && backButtonAriaLabel && (
          <CircularIconButton
            disabled={false}
            onClick={backOnClick}
            ariaLabel={backButtonAriaLabel}
            buttonClassNames={backIconButtonClassNames}
          >
            <BackIcon />
          </CircularIconButton>
        )}
        <h1 className={drawerTitleClassNames}>{title}</h1>
        {closeOnClick && closeButtonAriaLabel && (
          <CircularIconButton
            disabled={false}
            onClick={closeOnClick}
            ariaLabel={closeButtonAriaLabel}
            buttonClassNames={closeIconButtonClassNames}
          >
            <CloseIcon />
          </CircularIconButton>
        )}
      </nav>
      <form title={formAccessibilityTitle} id={formId} onSubmit={handleSubmit} className={getFormClassNames(formClassNames)}>
        {children}
      </form>
      {showPrimarySubmitButton && (
        <div className={submitButtonContainerClassNames}>
          <PrimaryButton
            label={s.BagDrawer_Save}
            type={buttonTypes.submit}
            disabled={primarySubmitDisabled}
            buttonClassNames={saveButtonClassNames}
            form={formId}
          />
        </div>
      )}
    </div>
  );
};
