import { ChangeEvent, useState } from 'react';
import { s } from 'i18n/strings';
import {
  alignItems,
  backgroundColor,
  borderColor,
  borderRadius,
  borderWidth,
  display,
  flexDirection,
  justifyContent,
  margin,
  onlyComputedCombineClassnames,
  padding,
  resize,
  typedUtilityClassnames,
} from 'style/compoundClassnames';
import { _ConfirmationDialogBase } from 'components/dialogs/_base';
import { FormControlLabel, Checkbox, Icon } from '@mui/material';
import { ReactComponent as WarningIcon } from 'icons/warning.svg';

const warningIconStyle = {
  background: '#9D5D00',
  pt: '3px',
  pl: '6px',
  borderRadius: 8,
  height: '1rem',
  width: '1rem',
};

const dialogItemContainerClassNames = typedUtilityClassnames(
  'body2',
  display('flex'),
  margin('my-4'),
  padding('pr-4', 'py-4'),
  alignItems('items-center'),
  borderRadius('rounded'),
);
const successDialogItemContainerClassNames = onlyComputedCombineClassnames(
  dialogItemContainerClassNames,
  typedUtilityClassnames(backgroundColor('bg-card-200')),
);
const errorDialogItemContainerClassNames = onlyComputedCombineClassnames(
  dialogItemContainerClassNames,
  typedUtilityClassnames(backgroundColor('bg-warning-100')),
);
const dialogItemTitleClassNames = typedUtilityClassnames('label', padding('px-4'));
const warningIconContainerClassNames = typedUtilityClassnames(padding('pl-4', 'pt-1'));
const overrideContainerClassNames = typedUtilityClassnames(
  display('flex'),
  justifyContent('justify-center'),
  flexDirection('flex-col'),
  padding('pt-2'),
);
const overrideTitleClassNames = typedUtilityClassnames('headline5');
const overrideCheckboxContainerClassNames = typedUtilityClassnames(padding('py-2'));
const overrideLabelClassNames = typedUtilityClassnames('label');
const overrideReasonTextAreaClassNames = typedUtilityClassnames(
  'body2',
  borderWidth('border'),
  borderRadius('rounded'),
  borderColor('border-onSurface-disabled'),
  resize('resize-none'),
  padding('px-2', 'py-1'),
  margin('mt-1'),
);

export interface ConfirmationDialogItem {
  label: string;
  successCondition: boolean;
  successMessage: string;
  errorMessage: string;
}

const areOutstandingIssues = (items: ConfirmationDialogItem[]) => {
  return items.filter((i) => !i.successCondition).length > 0;
};

export interface ActionConfirmationDialogProps {
  open: boolean;
  title: string;
  items: ConfirmationDialogItem[];
  confirmButtonCaption: string;
  handleConfirm: (isOverridden: boolean, overrideNote: string) => void;
  cancelButtonCaption: string;
  handleCancel: () => void;
  allowOverride?: boolean;
}

export const ActionConfirmationDialog = ({
  open,
  title,
  items,
  confirmButtonCaption,
  handleConfirm,
  cancelButtonCaption,
  handleCancel,
  allowOverride,
}: ActionConfirmationDialogProps): JSX.Element => {
  const [overrideConfirmed, setOverrideConfirmed] = useState(false);
  const [overrideNote, setOverrideNote] = useState<string>('');

  const handleChange = () => {
    setOverrideConfirmed(!overrideConfirmed);
  };

  const handleNoteChange = (event: ChangeEvent<HTMLTextAreaElement>) => {
    setOverrideNote(event.target.value);
  };

  const canConfirm = () => {
    return (
      !areOutstandingIssues(items) || (areOutstandingIssues(items) && overrideConfirmed && overrideNote !== '' && overrideNote.length >= 3)
    );
  };

  return (
    <_ConfirmationDialogBase
      open={open}
      title={title}
      primaryButtonCaption={confirmButtonCaption}
      primaryButtonAction={() => handleConfirm(overrideConfirmed, overrideNote)}
      primaryButtonDisabled={!canConfirm()}
      secondaryButtonCaption={cancelButtonCaption}
      secondaryButtonAction={handleCancel}
      secondaryButtonDisabled={false}
    >
      {items &&
        items.map((item, index) => (
          <div key={index} className={item.successCondition ? successDialogItemContainerClassNames : errorDialogItemContainerClassNames}>
            {!item.successCondition && (
              <div className={warningIconContainerClassNames}>
                <Icon sx={warningIconStyle}>
                  <WarningIcon />
                </Icon>
              </div>
            )}
            <span className={dialogItemTitleClassNames}>{item.label}</span>
            {item.successCondition ? item.successMessage : item.errorMessage}
          </div>
        ))}

      {allowOverride && areOutstandingIssues(items) && (
        <div className={overrideContainerClassNames}>
          <div className={overrideTitleClassNames}>{s.ActionConfirmationDialog_ReasoningTitle}</div>
          <FormControlLabel
            className={overrideCheckboxContainerClassNames}
            control={<Checkbox checked={overrideConfirmed} onChange={handleChange} />}
            label={<div className={overrideLabelClassNames}>{s.ActionConfirmationDialog_ConfirmCheckboxLabel}</div>}
          />
          <div className={overrideLabelClassNames}>{s.ActionConfirmationDialog_OverrideReasonLabel}</div>
          <textarea
            className={overrideReasonTextAreaClassNames}
            rows={3}
            onChange={handleNoteChange}
            value={overrideNote}
            minLength={3}
            maxLength={1024}
          />
        </div>
      )}
    </_ConfirmationDialogBase>
  );
};
