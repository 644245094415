import { useEffect, useState } from 'react';
import { useParams } from 'react-router';

import { User } from 'models/user';
import { Document, DocumentInfo, documentTypes } from 'models/document';
import { ShipmentSummary, ShipmentSummaryPiles } from 'models/shipment';
import { Bag, BagStates } from 'models/bag';
import { Pile, PileStates } from 'models/pile';
import { DrumWrite } from 'models/drum';
import { drumsService } from 'services/drumsService';
import { permissionsService } from 'services/permissionsService';
import { shipmentsService } from 'services/shipmentsService';
import { bagsService } from 'services/bagsService';
import { useAccessToken, useLoggedInUser, useAppNavigation, useServerError } from 'hooks';

import { s } from 'i18n/strings';

import { display, flexDirection, justifyContent, onlyComputedCombineClassnames, typedUtilityClassnames } from 'style/compoundClassnames';

import { ContextHeader } from 'components/headers';
import { PrimaryButton, SecondaryButton } from 'components/buttons';
import { HeaderItem } from 'components/HeaderItem';
import { Spinner } from 'components/Spinner';
import { DocumentItem } from 'components/DocumentItem';
import { PilesManifestItem, SamplingManifestItem } from 'components/manifest-items';
import { ActionConfirmationDialog } from 'components/dialogs';
import { Notification } from 'components/Notification';

import { ReactComponent as SamplingIcon } from 'icons/sampling.svg';
import { ReactComponent as DocumentsIcon } from 'icons/documents.svg';
import { ReactComponent as PilesIcon } from 'icons/piles.svg';

const mainContentClassNames = onlyComputedCombineClassnames(
  typedUtilityClassnames('mainLayoutPadding'),
  display('flex'),
  justifyContent('justify-center'),
  flexDirection('flex-col'),
);

const pilesProcessingDocuments: DocumentInfo[] = [
  { title: s.ShipmentDocuments_RobinsonInspectionReportDocumentItemTitle, type: documentTypes.RobinsonInspectionReport, required: true },
  {
    title: s.ShipmentDocuments_RobinsonFinalAnalysisReportDocumentItemTitle,
    type: documentTypes.RobinsonFinalAnalysisReport,
    required: true,
  },
];

const anyPiles = (piles: Pile[] | null) => piles != null && piles.length > 0;
const allPilesSampledOrConsumed = (piles: Pile[] | null) =>
  piles != null && piles.length > 0 && piles.every((p) => p.state.name === PileStates.Sampled || p.state.name === PileStates.Consumed);
const allPilesOverridden = (piles: Pile[] | null) => piles != null && piles.length > 0 && piles.every((p) => p.state.isOverridden);

const canEditSamplingInformation = (user: User | null, piles: Pile[] | null) =>
  user != null && permissionsService.canEditPile(user) && anyPiles(piles);

const samplingInformationProvided = (pilesSummary: ShipmentSummaryPiles | null) =>
  pilesSummary != null && pilesSummary.samplingReference != null && pilesSummary.samplingTechnician != null;

const canEditDocument = (user: User | null, piles: Pile[] | null) =>
  user != null &&
  ((permissionsService.canEditPile(user) && anyPiles(piles) && !allPilesSampledOrConsumed(piles)) ||
    (permissionsService.canOverridePile(user) && allPilesOverridden(piles)));

const allBagsEmptied = (bags: Bag[]) => bags.every((b) => b.state.name === BagStates.Emptied);

const canFinishPilesProcessing = (user: User | null, shipmentSummary: ShipmentSummary | null, piles: Pile[] | null) =>
  user != null &&
  permissionsService.canEditPile(user) &&
  shipmentSummary != null &&
  samplingInformationProvided(shipmentSummary.pilesSummary) &&
  !allPilesSampledOrConsumed(piles);

export const PilesProcessingPage = () => {
  const { shipmentId } = useParams<{ shipmentId: string }>();
  const accessToken = useAccessToken();
  const user = useLoggedInUser();

  const { navigateShipments, navigateSamplingInformation, navigatePileProcessing, navigateViewShipment } = useAppNavigation();
  const { handleServerError } = useServerError();

  const [shipmentSummary, setShipmentSummary] = useState<ShipmentSummary | null>(null);
  const [documents, setDocuments] = useState<Document[] | null>(null);
  const [bags, setBags] = useState<Bag[]>([]);
  const [piles, setPiles] = useState<Pile[] | null>(null);

  const [missingDocuments, setMissingDocuments] = useState<DocumentInfo[]>([]);
  const handleMissingDocuments = (documents: Document[]) => {
    const missingDocuments: DocumentInfo[] = [];
    missingDocuments.push(
      ...pilesProcessingDocuments.filter((d) => d.required && !documents.map((item) => item.documentType.id).includes(d.type)),
    );
    setMissingDocuments(missingDocuments);
  };

  const [documentUploadStarted, setDocumentUploadStarted] = useState(false);

  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  const [openConfirmationDialog, setOpenConfirmationDialog] = useState(false);
  const handleOpenConfirmationDialog = () => setOpenConfirmationDialog(true);
  const handleCloseConfirmationDialog = () => setOpenConfirmationDialog(false);

  useEffect(() => {
    (async () => {
      try {
        setError(null);
        setIsLoading(true);
        // TODO: factor out accessToken null checking into a base API class to run at every call, then remove access token null checking from all components
        if (accessToken && shipmentId) {
          const shipmentSummaryRequest = shipmentsService.getSummary(accessToken, parseInt(shipmentId));
          const documentsRequest = shipmentsService.getAllDocuments(accessToken, parseInt(shipmentId));
          const bagsRequest = bagsService.getAllForShipment(accessToken, parseInt(shipmentId));
          const pilesRequest = shipmentsService.getPiles(accessToken, parseInt(shipmentId));

          const [shipmentSummary, documents, bags, piles] = await Promise.all([
            shipmentSummaryRequest,
            documentsRequest,
            bagsRequest,
            pilesRequest,
          ]);

          setShipmentSummary(shipmentSummary);

          if (documents) {
            setDocuments(documents);
            handleMissingDocuments(documents);
          }

          setBags(bags);
          setPiles(piles);
        }
      } catch (error) {
        const errorMessage = handleServerError(error);
        setError(`${s.PilesProcessingPage_RetrievingPilesDetailsError}: ${errorMessage}`);
      } finally {
        setIsLoading(false);
      }
    })();
  }, [accessToken, handleServerError, shipmentId]);

  const finishDrumsProcessing = async (piles: Pile[] | null) => {
    if (accessToken && shipmentId && piles) {
      const newDrum = new DrumWrite(
        parseInt(shipmentId),
        piles.map(p => p.id),
        `REF_DUMMY_DRUM_${shipmentId}`, // referenceNumber
        `ROB_DUMMY_DRUM_${shipmentId}`, // robinsonSealNumber
        `COP_DUMMY_DRUM_${shipmentId}`, // coprocoSealNumber
        1, // grossWeight
        1  // netWeight
      );

      // create drum with no photos attached
      await drumsService.create(accessToken, newDrum);
    }
  };

  const handleFinishPilesProcessing = async (isOverridden: boolean, overrideNote: string) => {
    try {
      if (accessToken && shipmentId && canFinishPilesProcessing(user, shipmentSummary, piles)) {
        if (isOverridden) {
          await shipmentsService.finishPilesSamplingWithOverride(accessToken, parseInt(shipmentId), overrideNote);
        } else {
          await shipmentsService.finishPilesSampling(accessToken, parseInt(shipmentId));
        }

        // workaround for bypassing drums processing in the workflow
        await finishDrumsProcessing(piles);

        handleCloseConfirmationDialog();
        navigateViewShipment(shipmentId);
      }
    } catch (error) {
      handleCloseConfirmationDialog();
      const errorMessage = handleServerError(error);
      setError(`${s.PilesProcessingPage_FinishPilesProcessingError}: ${errorMessage}`);
    }
  };

  const handleBack = () => {
    shipmentId ? navigateViewShipment(shipmentId) : navigateShipments();
  };

  const handleEditSamplingInformation = () => {
    if (shipmentId && canEditSamplingInformation(user, piles)) {
      navigateSamplingInformation(shipmentId);
    }
  };

  const handleDocumentUploadStarted = (uploadStarted: boolean) => {
    setDocumentUploadStarted(uploadStarted);
  };

  const handleDocumentUploaded = async () => {
    try {
      if (accessToken && shipmentId) {
        const documents = await shipmentsService.getAllDocuments(accessToken, parseInt(shipmentId));
        if (documents) {
          setDocuments(documents);
          handleMissingDocuments(documents);
        }
      }
    } catch (error) {
      const errorMessage = handleServerError(error);
      setError(`${s.PilesProcessingPage_RetrievingSamplingDocumentsError}: ${errorMessage}`);
    }
  };

  const handleEditPiles = () => {
    if (shipmentId) {
      navigatePileProcessing(shipmentId);
    }
  };

  return (
    <>
      {isLoading ? (
        <Spinner />
      ) : (
        <>
          <ContextHeader
            contextTitle={s.PilesProcessingPage_Title}
            contextSubTitle={shipmentSummary?.shipmentReference}
            leftButtonOne={<SecondaryButton label={s.PilesProcessingPage_BackButton} onClick={handleBack} />}
            rightButtonOne={
              <PrimaryButton
                label={s.PilesProcessingPage_FinishButton}
                onClick={handleOpenConfirmationDialog}
                disabled={!canFinishPilesProcessing(user, shipmentSummary, piles)}
              />
            }
            showContextHeaderContentSpacer={false}
          />
          {shipmentSummary && (
            <div className={mainContentClassNames}>
              <Notification message={error!} severity="error" onClose={() => setError(null)} />
              {shipmentSummary.pilesSummary.count === 0 ? (
                <HeaderItem
                  title={s.PilesProcessingPage_PilesItemTitle}
                  icon={<PilesIcon />}
                  actionButton={<SecondaryButton label={s.PilesProcessingPage_PilesItem_EditButton} onClick={handleEditPiles} />}
                  actionButtonVisible={true}
                />
              ) : (
                <PilesManifestItem pilesSummary={shipmentSummary.pilesSummary} editOnClick={handleEditPiles} />
              )}
              {!shipmentSummary.pilesSummary.samplingReference ? (
                <HeaderItem
                  title={s.PilesProcessingPage_SamplingInformationItemTitle}
                  icon={<SamplingIcon />}
                  actionButton={
                    <SecondaryButton
                      label={s.PilesProcessingPage_SamplingInformationItem_EditButton}
                      disabled={!canEditSamplingInformation(user, piles)}
                      onClick={handleEditSamplingInformation}
                    />
                  }
                  actionButtonVisible={true}
                />
              ) : (
                <SamplingManifestItem
                  samplingInformation={shipmentSummary.pilesSummary}
                  canEdit={canEditSamplingInformation(user, piles)}
                  editOnClick={handleEditSamplingInformation}
                />
              )}
              <HeaderItem title={s.PilesProcessingPage_DocumentsItemTitle} icon={<DocumentsIcon />} />
              {pilesProcessingDocuments.map((doc, index) => (
                <DocumentItem
                  key={index}
                  title={doc.title}
                  canEdit={canEditDocument(user, piles) && !documentUploadStarted}
                  shipmentId={shipmentSummary.id}
                  entityId={shipmentSummary.id}
                  documentTypeId={doc.type}
                  documentRequired={doc.required}
                  document={documents && documents.find((d) => d.documentType.id === doc.type)}
                  handleUploadStarted={handleDocumentUploadStarted}
                  handleUploadStatusChange={handleDocumentUploaded}
                />
              ))}
            </div>
          )}
        </>
      )}

      {user && (
        <ActionConfirmationDialog
          open={openConfirmationDialog}
          title={s.PilesProcessingPage_ConfirmationDialogTitle}
          items={[
            {
              label:
                missingDocuments.length > 0
                  ? s.PilesProcessingPage_ConfirmationDialogDocumentsMissingItemLabel
                  : s.PilesProcessingPage_ConfirmationDialogDocumentsItemLabel,
              successCondition: missingDocuments.length === 0,
              successMessage: s.PilesProcessingPage_ConfirmationDialogDocumentsSuccessMessage,
              errorMessage: missingDocuments.map((d) => d.title).join(', '),
            },
            {
              label: s.PilesProcessingPage_ConfirmationDialogPilesProcessedItemLabel,
              successCondition: shipmentSummary != null && shipmentSummary.pilesSummary.count > 0 && allBagsEmptied(bags),
              successMessage: `${shipmentSummary?.pilesSummary.count} ${
                shipmentSummary?.pilesSummary.count === 1
                  ? s.PilesProcessingPage_ConfirmationDialogSingularPileItem
                  : s.PilesProcessingPage_ConfirmationDialogPluralPilesItem
              }`,
              errorMessage: s.PilesProcessingPage_ConfirmationDialogPilesProcessedErrorMessage,
            },
          ]}
          confirmButtonCaption={s.PilesProcessingPage_ConfirmationDialogFinishButtonCaption}
          handleConfirm={handleFinishPilesProcessing}
          cancelButtonCaption={s.PilesProcessingPage_ConfirmationDialogCancelButtonCaption}
          handleCancel={handleCloseConfirmationDialog}
          allowOverride={permissionsService.canOverridePile(user) && allBagsEmptied(bags)}
        />
      )}
    </>
  );
};
