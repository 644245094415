import type { MouseEventHandler, ReactElement } from 'react';
import { _ButtonBase, _ButtonBaseProps } from 'components/buttons/_base/_ButtonBase';
import { typedUtilityClassnames, backgroundColor, textColor, onlyComputedCombineClassnames } from 'style/compoundClassnames';

export interface TertiaryButtonProps extends Partial<Pick<_ButtonBaseProps, 'type'>> {
  disabled?: boolean;
  onClick?: MouseEventHandler;
  label: string | ReactElement;
}

const enabledClasses = typedUtilityClassnames(textColor('text-primary-500'), backgroundColor('hover:bg-primary-50'));
const disabledClasses = typedUtilityClassnames(textColor('text-onSurface-disabled'));
const commonClasses = typedUtilityClassnames(backgroundColor('bg-white'));

export const TertiaryButton = ({ disabled = false, onClick, label, type = 'button' }: TertiaryButtonProps): JSX.Element => (
  <>
    <_ButtonBase
      label={label}
      type={type}
      onClick={onClick}
      disabled={disabled}
      buttonClassNames={onlyComputedCombineClassnames({ [enabledClasses]: !disabled, [disabledClasses]: disabled }, commonClasses)}
    />
  </>
);
