import { State } from 'models/state';

export class Drum {
  // eslint-disable-next-line no-useless-constructor
  constructor(
    public id: number,
    public state: State,
    public referenceNumber: string,
    public grossWeightInKg: number,
    public netWeightInKg: number,
    public robinsonSealNumber: string,
    public coprocoSealNumber: string,
    public pileIds: number[],
    public hasEmptyPhoto: boolean,
    public hasFilledPhoto: boolean,
    public hasSealedPhoto: boolean,
  ) {}
}

export class DrumWrite {
  // eslint-disable-next-line no-useless-constructor
  constructor(
    public shipmentId: number,
    public pileIds: number[],
    public referenceNumber?: string,
    public robinsonSealNumber?: string,
    public coprocoSealNumber?: string,
    public grossWeightInKg?: number,
    public netWeightInKg?: number,
  ) {}
}

export const DrumStates = {
  New: 'New',
  Filled: 'Filled',
  Sealed: 'Sealed',
  Loaded: 'Loaded',
} as const;
