import { _DrawerFormBase } from 'components/drawer-forms/_base';
import { LinkPilesDrumTabPanel } from 'components/drawer-forms';

import type { LinkPilesDrumTabPanelProps } from 'components/drawer-forms';
import { alignItems, display, gridRowStart, justifyContent, padding, typedUtilityClassnames } from 'style/compoundClassnames';
import { s } from 'i18n/strings';

export interface DrumDrawerFormSelectPilesProps extends LinkPilesDrumTabPanelProps {
  title: string;
  shipmentId: string;
  onBack: () => void;
}

const selectPilesFormId = 'selectPilesForm';
const pilesCounterClassNames = typedUtilityClassnames(
  'body2',
  gridRowStart('row-start-3'),
  display('flex'),
  justifyContent('justify-center'),
  alignItems('items-end'),
  padding('py-6'),
);

export const DrumDrawerFormSelectPiles = ({
  title,
  piles,
  drum,
  onSelectedPiles,
  selectedPiles,
  canEdit,
  onBack,
  selectedPilesMainFormFieldIsDirty,
}: // TODO: Implement piles list as form input.
DrumDrawerFormSelectPilesProps): JSX.Element => {
  return (
    <>
      <_DrawerFormBase
        formId={selectPilesFormId}
        title={title}
        backOnClick={onBack}
        backButtonAriaLabel={s.DrumDrawer_SelectPilesStageBackButtonAriaLabel}
        formAccessibilityTitle={s.DrumDrawer_SelectPilesFormAccessibilityTitle}
        showPrimarySubmitButton={false}
      >
        <LinkPilesDrumTabPanel
          piles={piles}
          drum={drum}
          selectedPiles={selectedPiles}
          onSelectedPiles={onSelectedPiles}
          canEdit={canEdit}
          selectedPilesMainFormFieldIsDirty={selectedPilesMainFormFieldIsDirty}
        />
      </_DrawerFormBase>
      <div className={pilesCounterClassNames}>
        {selectedPiles.length
          ? s.DrumDrawer_PilesSelectedMessage.replace('{{count}}', selectedPiles.length.toString())
          : s.DrumDrawer_SelectPilesMessage}
      </div>
    </>
  );
};
