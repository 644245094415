import { apiRequest } from 'services/request';
import { Bag, BagWrite } from 'models/bag';
import { Document } from 'models/document';

const BaseUrl = window.__RUNTIME_CONFIG__.REACT_APP_API_BASE_URL;

export const bagsService = {
  async getById(accessToken: string, id: number) {
    return await apiRequest.get<Bag>(`${BaseUrl}/bags/${id}`, accessToken);
  },
  async getAllForTruck(accessToken: string, truckId: number) {
    return await apiRequest.get<Bag[]>(`${BaseUrl}/bags/truck/${truckId}`, accessToken);
  },
  async getAllForShipment(accessToken: string, shipmentId: number) {
    return await apiRequest.get<Bag[]>(`${BaseUrl}/bags/shipment/${shipmentId}`, accessToken);
  },
  async create(accessToken: string, bag: BagWrite) {
    return await apiRequest.post<Bag, BagWrite>(`${BaseUrl}/bags`, accessToken, bag);
  },
  async update(accessToken: string, id: number, bag: BagWrite) {
    return await apiRequest.put<Bag, BagWrite>(`${BaseUrl}/bags?id=${id}`, accessToken, bag);
  },
  async delete(accessToken: string, id: number) {
    await apiRequest.delete(`${BaseUrl}/bags?id=${id}`, accessToken);
  },
  async getDocuments(accessToken: string, id: number) {
    return await apiRequest.get<Document[]>(`${BaseUrl}/bags/${id}/documents`, accessToken);
  },
};
