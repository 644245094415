import { useState, useEffect } from 'react';

import { User, UserRole } from 'models/user';
import { usersService } from 'services/usersService';
import { permissionsService } from 'services/permissionsService';
import { useAccessToken, useLoggedInUser, useAppNavigation, useServerError } from 'hooks';

import { s } from 'i18n/strings';

import { onlyComputedCombineClassnames, typedUtilityClassnames } from 'style/compoundClassnames';

import { ContextHeader } from 'components/headers';
import { UsersList } from 'components/UsersList';
import { Spinner } from 'components/Spinner';
import { Notification } from 'components/Notification';

export const UsersPage = () => {
  const accessToken = useAccessToken();
  const user = useLoggedInUser();

  const { navigateEditUser } = useAppNavigation();
  const { handleServerError } = useServerError();

  const [users, setUsers] = useState<User[] | null>(null);
  const [allUserRoles, setAllUserRoles] = useState<UserRole[] | null>(null);

  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    (async () => {
      try {
        setError(null);
        setIsLoading(true);
        // TODO: factor out accessToken null checking into a base API class to run at every call, then remove access token null checking from all components
        if (accessToken != null) {
          const roles = await usersService.getRoles(accessToken);
          setAllUserRoles(roles);

          const users = await usersService.getAll(accessToken);
          setUsers(users);
        }
      } catch (error) {
        const errorMessage = handleServerError(error);
        setError(`${s.UsersPage_CouldNotGetUsersError}: ${errorMessage}`);
      } finally {
        setIsLoading(false);
      }
    })();
  }, [accessToken, handleServerError]);

  const handleUserSelect = (id: string) => {
    if (user && permissionsService.canEditUser(user)) {
      navigateEditUser(id);
    }
  };

  return (
    <>
      <ContextHeader contextTitle={s.UsersPage_Title} showContextHeaderContentSpacer />
      <div className={onlyComputedCombineClassnames(typedUtilityClassnames('mainLayoutPadding'))}>
        <Notification message={error!} severity="error" onClose={() => setError(null)} />
        {isLoading ? (
          <Spinner />
        ) : (
          users && allUserRoles && <UsersList users={users} allUserRoles={allUserRoles} onSelectedUser={handleUserSelect} />
        )}
      </div>
    </>
  );
};
