import { PhotoInput, UncontrolledTextInput } from 'components/form-components/inputs';
import { s } from 'i18n/strings';
// import { Table, TableBody, TableRow, TableCell, Checkbox, TableContainer } from '@mui/material';
import type {
  FieldError,
  FieldErrors,
  Path,
  SubmitHandler,
  UseFormGetValues,
  UseFormRegister,
  UseFormSetValue,
  UseFormTrigger,
  FieldName,
  UseFormHandleSubmit,
} from 'react-hook-form';

import {
  display,
  onlyComputedCombineClassnames,
  textColor,
  typedUtilityClassnames,
  gridTemplateColumns,
  gridTemplateRows,
  flexDirection,
  padding,
  gap,
  gridRowStart,
} from 'style/compoundClassnames';

import type { UseSingleDocumentInputCacheManagerReturn } from 'hooks/useSingleDocumentInputCache';

import { ExclusivelyStringKeyedRecordWithOptional } from 'utility-types';
import type { Drum } from 'models/drum';
import { FieldValuesFromFieldErrors } from '@hookform/error-message';
import { Dispatch, SetStateAction } from 'react';
import {
  ContainerDrumFormStatePhotosOnly,
  ContainerDrumFormStatePhotosOnlyKeys,
  ContainerDrumFormStateWithPhotos,
  containerDrumFormStateWithPhotosFieldNames,
} from 'pages/ContainerDrumsPage';

// const selectedDrumsCaptionClassNames = typedUtilityClassnames('body1');
const sectionsSharedClassNames = typedUtilityClassnames(
  display('grid'),
  gridTemplateColumns('lg:grid-cols-2', 'grid-cols-1'),
  gap('gap-x-6'),
);
const formSectionOneClassNames = typedUtilityClassnames(sectionsSharedClassNames, gridTemplateRows('grid-rows-mc4', 'lg:grid-rows-mc3'));
const formSectionTwoClassNames = typedUtilityClassnames(sectionsSharedClassNames, gridTemplateRows('grid-rows-mc5', 'lg:grid-rows-mc4'));
const sealedContainerSectionTextInputLabelContainersSharedClassNames = typedUtilityClassnames(gridRowStart('lg:row-start-2'));
const sectionsTitlesSharedClassNames = typedUtilityClassnames('headline5', textColor('text-primary-900'), padding('pb-6'));
const formClassNames = typedUtilityClassnames('nonExpandingMainLayoutContentSection');
const firstSectionLastTwoPhotoInputsSharedClassNames = typedUtilityClassnames(gridRowStart('lg:row-start-3'));
const secondSectionFirstTwoPhotoInputsSharedClassNames = typedUtilityClassnames(gridRowStart('lg:row-start-3'));
const firstSectionFirstInputClassNames = typedUtilityClassnames(gridRowStart('lg:row-start-2'));
const secondSectionLastPhotoInputClassNames = typedUtilityClassnames(gridRowStart('lg:row-start-4'));

const getHandleDestagePhoto = function <TFormState extends ExclusivelyStringKeyedRecordWithOptional<TFormState>>(
  handleDestageDocument: () => void,
  setValue: UseFormSetValue<TFormState>,
  getValues: UseFormGetValues<TFormState>,
  fieldName: keyof ContainerDrumFormStatePhotosOnly & Path<TFormState>,
  wasEverExistingDocument: boolean | null,
): () => void {
  return () => {
    handleDestageDocument();
    const newValue = getValues(fieldName);
    setValue(fieldName, newValue, { shouldDirty: !!wasEverExistingDocument });
  };
};

const getPhotoInputOnChange = function <TFormState extends ExclusivelyStringKeyedRecordWithOptional<TFormState>>(
  fieldName: keyof ContainerDrumFormStatePhotosOnly & Path<TFormState>,
  setPhotosAwaitingStaging: Dispatch<SetStateAction<ContainerDrumFormStatePhotosOnlyKeys[]>>,
  trigger: UseFormTrigger<TFormState>,
): () => void {
  return () => {
    setPhotosAwaitingStaging((prev) => [...prev, fieldName]);
    trigger(fieldName);
  };
};

const getPhotoInput = function <
  TFormState extends ExclusivelyStringKeyedRecordWithOptional<TFormState>,
  TFieldName extends FieldName<FieldValuesFromFieldErrors<FieldErrors<TFormState>>> &
    Path<TFormState> &
    FieldName<FieldValuesFromFieldErrors<FieldErrors<ContainerDrumFormStatePhotosOnly>>>,
>(
  name: TFieldName,
  label: string,
  register: UseFormRegister<TFormState>,
  disallowEdit: boolean,
  isSaving: boolean,
  photoManager: UseSingleDocumentInputCacheManagerReturn,
  errors: FieldErrors<TFormState>,
  setValue: UseFormSetValue<ContainerDrumFormStateWithPhotos>,
  getValues: UseFormGetValues<ContainerDrumFormStateWithPhotos>,
  setPhotosAwaitingStaging: Dispatch<SetStateAction<(keyof ContainerDrumFormStatePhotosOnly)[]>>,
  getOnChange: typeof getPhotoInputOnChange,
  trigger: UseFormTrigger<TFormState>,
  previewAlternativeText: string,
  buttonAriaLabel: string,
  inputAndActionButtonsContainerClassNames: string,
  fieldError?: FieldError,
) {
  const inputAndActionButtonsSharedClassNames = typedUtilityClassnames(display('flex'), flexDirection('flex-col'), padding('pb-6'));
  return (
    <div className={onlyComputedCombineClassnames(inputAndActionButtonsSharedClassNames, inputAndActionButtonsContainerClassNames)}>
      <PhotoInput<TFormState, TFieldName>
        {...register(name, {
          onChange: getOnChange(name, setPhotosAwaitingStaging, trigger),
          disabled: disallowEdit, // TODO: is this needed?
        })}
        photoURL={photoManager.documentURL}
        label={label}
        previewAlternativeText={previewAlternativeText}
        buttonAriaLabel={buttonAriaLabel}
        readOnly={disallowEdit}
        isSaving={isSaving}
        showValidationErrors={!!fieldError}
        validationErrors={errors}
        documentStagedForUpload={photoManager.documentStagedForUpload}
        hasError={photoManager.documentDownloadError != null && photoManager.documentDownloadError !== undefined}
        onRemove={getHandleDestagePhoto(
          photoManager.handleDestageDocument,
          setValue,
          getValues,
          name,
          photoManager.wasEverExistingDocument,
        )}
        onDownload={photoManager.handleDownloadDocument}
      />
    </div>
  );
};

export interface ContainerDrumsFormProps {
  formId: string;
  drums: Drum[];
  disallowEdit: boolean;
  isSaving: boolean;

  onSubmit: SubmitHandler<ContainerDrumFormStateWithPhotos>;
  handleSubmit: UseFormHandleSubmit<ContainerDrumFormStateWithPhotos>;
  register: UseFormRegister<ContainerDrumFormStateWithPhotos>;

  tagBoxIdInputError: FieldError | undefined;
  tagBoxPhotoInputError: FieldError | undefined;
  emptyContainerPhotoInputError: FieldError | undefined;
  filledContainerPhotoInputError: FieldError | undefined;
  containerSealIdInputError: FieldError | undefined;
  containerSealIdPhotoInputError: FieldError | undefined;
  leopardLockIdInputError: FieldError | undefined;
  leopardLockIdPhotoInputError: FieldError | undefined;
  sealedContainerPhotoInputError: FieldError | undefined;

  errors: FieldErrors<ContainerDrumFormStateWithPhotos>;

  setValue: UseFormSetValue<ContainerDrumFormStateWithPhotos>;
  getValues: UseFormGetValues<ContainerDrumFormStateWithPhotos>;
  setPhotosAwaitingStaging: Dispatch<SetStateAction<(keyof ContainerDrumFormStatePhotosOnly)[]>>;
  trigger: UseFormTrigger<ContainerDrumFormStateWithPhotos>;

  tagBoxPhotoManager: UseSingleDocumentInputCacheManagerReturn;
  emptyContainerPhotoManager: UseSingleDocumentInputCacheManagerReturn;
  filledContainerPhotoManager: UseSingleDocumentInputCacheManagerReturn;
  containerSealIdPhotoManager: UseSingleDocumentInputCacheManagerReturn;
  leopardLockIdPhotoManager: UseSingleDocumentInputCacheManagerReturn;
  sealedContainerPhotoManager: UseSingleDocumentInputCacheManagerReturn;
}

export const ContainerDrumsForm = ({
  formId,
  drums,
  disallowEdit,
  isSaving,
  onSubmit,
  handleSubmit,
  register,
  tagBoxIdInputError,
  tagBoxPhotoInputError,
  emptyContainerPhotoInputError,
  filledContainerPhotoInputError,
  containerSealIdInputError,
  containerSealIdPhotoInputError,
  leopardLockIdInputError,
  leopardLockIdPhotoInputError,
  sealedContainerPhotoInputError,
  errors,
  tagBoxPhotoManager,
  setValue,
  getValues,
  setPhotosAwaitingStaging,
  trigger,
  emptyContainerPhotoManager,
  filledContainerPhotoManager,
  containerSealIdPhotoManager,
  leopardLockIdPhotoManager,
  sealedContainerPhotoManager,
}: ContainerDrumsFormProps) => {
  return (
    <form id={formId} onSubmit={handleSubmit(onSubmit)} className={formClassNames}>
      <section className={formSectionOneClassNames}>
        <UncontrolledTextInput<ContainerDrumFormStateWithPhotos, typeof containerDrumFormStateWithPhotosFieldNames.tagBoxId>
          readOnly={disallowEdit}
          label={s.ContainerDrumsPage_TagBoxIDCaption}
          {...register(containerDrumFormStateWithPhotosFieldNames.tagBoxId, {
            disabled: disallowEdit,
          })}
          hasError={!!tagBoxIdInputError}
          errors={errors}
          isRequired={true}
        />
        {getPhotoInput<ContainerDrumFormStateWithPhotos, typeof containerDrumFormStateWithPhotosFieldNames.tagBoxPhoto>(
          containerDrumFormStateWithPhotosFieldNames.tagBoxPhoto,
          s.ContainerDrumsPage_TagBoxPhotoInputLabel,
          register,
          disallowEdit,
          isSaving,
          tagBoxPhotoManager,
          errors,
          setValue,
          getValues,
          setPhotosAwaitingStaging,
          getPhotoInputOnChange,
          trigger,
          '',
          '',
          firstSectionFirstInputClassNames,
          tagBoxPhotoInputError,
        )}
        {getPhotoInput<ContainerDrumFormStateWithPhotos, typeof containerDrumFormStateWithPhotosFieldNames.emptyContainerPhoto>(
          containerDrumFormStateWithPhotosFieldNames.emptyContainerPhoto,
          s.ContainerDrumsPage_EmptyContainerPhotoInputLabel,
          register,
          disallowEdit,
          isSaving,
          emptyContainerPhotoManager,
          errors,
          setValue,
          getValues,
          setPhotosAwaitingStaging,
          getPhotoInputOnChange,
          trigger,
          '',
          '',
          firstSectionLastTwoPhotoInputsSharedClassNames,
          emptyContainerPhotoInputError,
        )}
        {getPhotoInput<ContainerDrumFormStateWithPhotos, typeof containerDrumFormStateWithPhotosFieldNames.filledContainerPhoto>(
          containerDrumFormStateWithPhotosFieldNames.filledContainerPhoto,
          s.ContainerDrumsPage_FilledContainerPhotoInputLabel,
          register,
          disallowEdit,
          isSaving,
          filledContainerPhotoManager,
          errors,
          setValue,
          getValues,
          setPhotosAwaitingStaging,
          getPhotoInputOnChange,
          trigger,
          '',
          '',
          firstSectionLastTwoPhotoInputsSharedClassNames,
          filledContainerPhotoInputError,
        )}
      </section>
      <section className={formSectionTwoClassNames}>
        <h2 className={sectionsTitlesSharedClassNames}>{s.ContainerDrumsPage_SealedContainerSectionTitle}</h2>
        <div className={sealedContainerSectionTextInputLabelContainersSharedClassNames}>
          <UncontrolledTextInput<ContainerDrumFormStateWithPhotos, typeof containerDrumFormStateWithPhotosFieldNames.containerSealId>
            readOnly={disallowEdit}
            label={s.ContainerDrumsPage_ContainerSealIdInputLabel}
            {...register(containerDrumFormStateWithPhotosFieldNames.containerSealId, {
              disabled: disallowEdit,
            })}
            hasError={!!containerSealIdInputError}
            errors={errors}
            isRequired={false}
          />
        </div>
        <div className={sealedContainerSectionTextInputLabelContainersSharedClassNames}>
          <UncontrolledTextInput<ContainerDrumFormStateWithPhotos, typeof containerDrumFormStateWithPhotosFieldNames.leopardLockId>
            readOnly={disallowEdit}
            label={s.ContainerDrumsPage_LeopardLockIdInputLabel}
            {...register(containerDrumFormStateWithPhotosFieldNames.leopardLockId, {
              disabled: disallowEdit,
            })}
            hasError={!!leopardLockIdInputError}
            errors={errors}
            isRequired={false}
          />
        </div>
        {getPhotoInput<ContainerDrumFormStateWithPhotos, typeof containerDrumFormStateWithPhotosFieldNames.containerSealIdPhoto>(
          containerDrumFormStateWithPhotosFieldNames.containerSealIdPhoto,
          s.ContainerDrumsPage_ContainerSealIdPhotoInputLabel,
          register,
          disallowEdit,
          isSaving,
          containerSealIdPhotoManager,
          errors,
          setValue,
          getValues,
          setPhotosAwaitingStaging,
          getPhotoInputOnChange,
          trigger,
          '',
          '',
          secondSectionFirstTwoPhotoInputsSharedClassNames,
          containerSealIdPhotoInputError,
        )}
        {getPhotoInput<ContainerDrumFormStateWithPhotos, typeof containerDrumFormStateWithPhotosFieldNames.leopardLockIdPhoto>(
          containerDrumFormStateWithPhotosFieldNames.leopardLockIdPhoto,
          s.ContainerDrumsPage_LeopardLockIdPhotoInputLabel,
          register,
          disallowEdit,
          isSaving,
          leopardLockIdPhotoManager,
          errors,
          setValue,
          getValues,
          setPhotosAwaitingStaging,
          getPhotoInputOnChange,
          trigger,
          '',
          '',
          secondSectionFirstTwoPhotoInputsSharedClassNames,
          leopardLockIdPhotoInputError,
        )}
        {getPhotoInput<ContainerDrumFormStateWithPhotos, typeof containerDrumFormStateWithPhotosFieldNames.sealedContainerPhoto>(
          containerDrumFormStateWithPhotosFieldNames.sealedContainerPhoto,
          s.ContainerDrumsPage_SealedContainerPhotoInputLabel,
          register,
          disallowEdit,
          isSaving,
          sealedContainerPhotoManager,
          errors,
          setValue,
          getValues,
          setPhotosAwaitingStaging,
          getPhotoInputOnChange,
          trigger,
          '',
          '',
          secondSectionLastPhotoInputClassNames,
          sealedContainerPhotoInputError,
        )}
      </section>

      {/* <h2 className={sectionsTitlesSharedClassNames}>{s.ContainerDrumsPage_DrumsSectionTitle}</h2>
      <TableContainer>
        <div className={selectedDrumsCaptionClassNames} hidden>
          {s.ContainerDrumsPage_SelectedDrumsCaption}
        </div>
        <Table>
          <TableBody>
            {drums?.map((drum) => {
              return (
                <TableRow key={drum.id} hover role="checkbox" selected={true}>
                  <TableCell padding="checkbox">
                    <Checkbox checked={true} disabled={true} />
                  </TableCell>
                  <TableCell scope="row">{drum.referenceNumber}</TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer> */}
    </form>
  );
};
