import { shipmentStates, ShipmentSummary } from 'models/shipment';
import { useAppNavigation } from 'hooks/useAppNavigation';
import { useLoggedInUser } from 'hooks/useLoggedInUser';
import { permissionsService } from 'services/permissionsService';

import { s } from 'i18n/strings';

import {
  display,
  flexDirection,
  justifyContent,
  margin,
  onlyComputedCombineClassnames,
  textColor,
  textTransform,
  typedUtilityClassnames,
} from 'style/compoundClassnames';

import { HeaderItem } from 'components/HeaderItem';
import { PrimaryButton } from 'components/buttons';
import {
  BagsProcessingManifestItem,
  PilesProcessingManifestItem,
  // DrumsProcessingManifestItem,
  ContainerProcessingManifestItem,
  ShipmentInformationManifestItem,
} from 'components/manifest-items';

import { ReactComponent as BagsIcon } from 'icons/bags.svg';
import { ReactComponent as PilesIcon } from 'icons/piles.svg';
// import { ReactComponent as DrumsIcon } from 'icons/drums.svg';
import { ReactComponent as ContainerIcon } from 'icons/container.svg';
import { ReactComponent as ShipmentIcon } from 'icons/shipment.svg';

const mainContentClassNames = onlyComputedCombineClassnames(
  typedUtilityClassnames('mainLayoutPadding'),
  display('flex'),
  justifyContent('justify-center'),
  flexDirection('flex-col'),
);
const shipmentOverviewSectionTitleClassNames = typedUtilityClassnames(
  'navBlockFont',
  textColor('text-onSurface-mediumEmphasis'),
  textTransform('uppercase'),
);
const nextStepsSectionClassNames = typedUtilityClassnames(margin('mb-8'));
const shipmentOverviewFinishedStepsLabelClassNames = typedUtilityClassnames(
  'body1',
  textColor('text-onSurface-mediumEmphasis'),
  display('flex'),
  justifyContent('justify-center'),
  margin('mt-10'),
);

export interface ShipmentOverviewProps {
  shipmentSummary: ShipmentSummary;
}

export const ShipmentOverview = ({ shipmentSummary }: ShipmentOverviewProps) => {
  const bagsStarted = shipmentSummary.bagsSummary.truck != null;
  const bagsFinished = shipmentSummary.state.name === shipmentStates.BagsProcessed;
  const pilesStarted =
    shipmentSummary.pilesSummary.count > 0 ||
    (shipmentSummary.pilesSummary.samplingReference != null && shipmentSummary.pilesSummary.samplingTechnician != null);
  const pilesFinished =
    shipmentSummary.pilesSummary.count > 0 &&
    shipmentSummary.pilesSummary.count === shipmentSummary.pilesSummary.sampledCount + shipmentSummary.pilesSummary.consumedCount;
  // const drumsStarted = shipmentSummary.drumsSummary.count > 0;
  // const drumsFinished = shipmentSummary.state.name === shipmentStates.DrumsProcessed;
  const containerStarted = shipmentSummary.containerSummary.tagBoxId != null && shipmentSummary.containerSummary.tagBoxId.length > 0;
  const containerFinished = shipmentSummary.state.name === shipmentStates.WithFreightForwarder;
  const shipmentStarted = shipmentSummary.shipmentSummary.shipRegistrationNumber !== null;

  const user = useLoggedInUser();

  const {
    navigateBagsProcessing,
    navigatePilesProcessing,
    // navigateDrumProcessing,
    navigateContainerProcessing,
    navigateShipmentProcessing,
  } = useAppNavigation();

  const canStartBagsProcessing = () => {
    if (!user) {
      return false;
    }

    return permissionsService.canEditBag(user);
  };

  const handleBagsEdit = () => {
    if (shipmentSummary.id) {
      navigateBagsProcessing(shipmentSummary.id);
    }
  };

  const canStartPilesProcessing = () => {
    if (!user) {
      return false;
    }

    return permissionsService.canEditPile(user) && bagsFinished;
  };

  const handlePilesEdit = () => {
    if (shipmentSummary.id) {
      navigatePilesProcessing(shipmentSummary.id);
    }
  };

  // const canStartDrumsProcessing = () => {
  //   if (!user) {
  //     return false;
  //   }

  //   return permissionsService.canEditDrum(user) && pilesFinished;
  // };

  // const handleDrumsEdit = () => {
  //   if (shipmentSummary.id) {
  //     navigateDrumProcessing(shipmentSummary.id);
  //   }
  // };

  const canStartContainerProcessing = () => {
    if (!user) {
      return false;
    }

    // return permissionsService.canEditContainer(user) && drumsFinished;
    return permissionsService.canEditContainer(user) && pilesFinished;
  };

  const handleContainerEdit = () => {
    if (shipmentSummary.id) {
      navigateContainerProcessing(shipmentSummary.id);
    }
  };

  const canStartShipmentProcessing = () => {
    if (!user) {
      return false;
    }

    return permissionsService.canEditShipment(user) && containerFinished;
  };

  const handleShipmentEdit = () => {
    if (shipmentSummary.id) {
      navigateShipmentProcessing(shipmentSummary.id);
    }
  };

  return (
    <div className={mainContentClassNames}>
      {/* Next steps section */}
      {!shipmentStarted && (
        <div className={nextStepsSectionClassNames}>
          {<div className={shipmentOverviewSectionTitleClassNames}>{s.ShipmentOverview_NextStepsTitle}</div>}
          {!bagsStarted && (
            <HeaderItem
              title={s.ShipmentOverview_BagsHeaderItemTitle}
              icon={<BagsIcon />}
              actionButton={
                <PrimaryButton
                  label={s.ShipmentOverview_HeaderItemButtonCaption}
                  onClick={handleBagsEdit}
                  disabled={!canStartBagsProcessing()}
                />
              }
              actionButtonVisible={true}
            />
          )}
          {!pilesStarted && (
            <HeaderItem
              title={s.ShipmentOverview_PilesHeaderItemTitle}
              icon={<PilesIcon />}
              actionButton={
                <PrimaryButton
                  label={s.ShipmentOverview_HeaderItemButtonCaption}
                  onClick={handlePilesEdit}
                  disabled={!canStartPilesProcessing()}
                />
              }
              actionButtonVisible={bagsStarted}
            />
          )}
          {/* {!drumsStarted && (
            <HeaderItem
              title={s.ShipmentOverview_DrumsHeaderItemTitle}
              icon={<DrumsIcon />}
              actionButton={
                <PrimaryButton
                  label={s.ShipmentOverview_HeaderItemButtonCaption}
                  onClick={handleDrumsEdit}
                  disabled={!canStartDrumsProcessing()}
                />
              }
              actionButtonVisible={pilesStarted}
            />
          )} */}
          {!containerStarted && (
            <HeaderItem
              title={s.ShipmentOverview_ContainerHeaderItemTitle}
              icon={<ContainerIcon />}
              actionButton={
                <PrimaryButton
                  label={s.ShipmentOverview_HeaderItemButtonCaption}
                  onClick={handleContainerEdit}
                  disabled={!canStartContainerProcessing()}
                />
              }
              // actionButtonVisible={drumsStarted}
              actionButtonVisible={pilesStarted}
            />
          )}
          {!shipmentStarted && (
            <HeaderItem
              title={s.ShipmentOverview_ShipmentHeaderItemTitle}
              icon={<ShipmentIcon />}
              actionButton={
                <PrimaryButton
                  label={s.ShipmentOverview_HeaderItemButtonCaption}
                  onClick={handleShipmentEdit}
                  disabled={!canStartShipmentProcessing()}
                />
              }
              actionButtonVisible={containerStarted}
            />
          )}
        </div>
      )}

      {/* Manifest section */}
      <div className={shipmentOverviewSectionTitleClassNames}>{s.ShipmentOverview_ManifestTitle}</div>
      {shipmentStarted && (
        <ShipmentInformationManifestItem
          shipmentSummary={shipmentSummary.shipmentSummary}
          isInOverview={true}
          canEdit={true}
          editOnClick={handleShipmentEdit}
        />
      )}
      {containerStarted && (
        <ContainerProcessingManifestItem containerSummary={shipmentSummary.containerSummary} editOnClick={handleContainerEdit} />
      )}
      {/* {drumsStarted && <DrumsProcessingManifestItem drumsSummary={shipmentSummary.drumsSummary} editOnClick={handleDrumsEdit} />} */}
      {pilesStarted && <PilesProcessingManifestItem pilesSummary={shipmentSummary.pilesSummary} editOnClick={handlePilesEdit} />}
      {bagsStarted ? (
        <BagsProcessingManifestItem bagsSummary={shipmentSummary.bagsSummary} editOnClick={handleBagsEdit} />
      ) : (
        <div className={shipmentOverviewFinishedStepsLabelClassNames}>{s.ShipmentOverview_FinishedStepsLabel}</div>
      )}
    </div>
  );
};
