export class State<TName extends string = string> {
  // eslint-disable-next-line no-useless-constructor
  constructor(public id: number, public name: TName, public kind: string, public isOverridden: boolean, public overrideReason: string) {}
}

export class ActionWrite {
  // eslint-disable-next-line no-useless-constructor
  constructor(public action: string, public overrideDetails: OverrideWrite) {}
}

export class OverrideWrite {
  // eslint-disable-next-line no-useless-constructor
  constructor(public confirmed: boolean, public reason: string) {}
}

export const stateKinds = {
  Shipment: 'Shipment',
  Truck: 'Truck',
  Container: 'Container',
} as const;
