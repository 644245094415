import { useState, useEffect } from 'react';
import { useParams } from 'react-router';
import { useForm, SubmitHandler } from 'react-hook-form';

import { Container, ContainerFreightForwardWrite } from 'models/container';
import { Shipment } from 'models/shipment';
import { User } from 'models/user';
import { shipmentsService } from 'services/shipmentsService';
import { permissionsService } from 'services/permissionsService';
import { containerService } from 'services/containerService';
import { useAccessToken, useLoggedInUser, useAppNavigation, useServerError } from 'hooks';

import { s } from 'i18n/strings';

import { display, justifyContent, typedUtilityClassnames } from 'style/compoundClassnames';

import { ContextHeader } from 'components/headers';
import { buttonTypes, PrimaryButton, SecondaryButton } from 'components/buttons';
import { Spinner } from 'components/Spinner';
import { Notification } from 'components/Notification';
import {
  FreightForwarderForm,
  FreightForwarderFormState,
  freightForwarderFormStateFieldNames,
  freightForwarderFormStateSchema,
} from 'components/form-components/forms/FreightForwarderForm';

import { zodResolver } from '@hookform/resolvers/zod';

const formContentContainerClassNames = typedUtilityClassnames('mainLayoutPadding', display('flex'), justifyContent('justify-center'));

const freightForwarderFormId = 'freightForwarderForm';

const canEditFreightForwarderForm = (user: User | null, isSavingForm: boolean) => {
  if (!user || isSavingForm) {
    return false;
  }

  return permissionsService.canEditContainer(user) || permissionsService.canOverrideContainer(user);
};

export const FreightForwardingInformationPage = (): JSX.Element => {
  const { shipmentId } = useParams<{ shipmentId: string }>();
  const accessToken = useAccessToken();
  const user = useLoggedInUser();

  const { navigateContainerProcessing, navigateShipments } = useAppNavigation();
  const { handleServerError } = useServerError();

  const [shipment, setShipment] = useState<Shipment | null>(null);
  const [container, setContainer] = useState<Container | null>(null);

  const [isLoading, setIsLoading] = useState(true);
  const [isSavingForm, setIsSavingForm] = useState(false);
  const [error, setError] = useState<string | null>(null);

  const {
    register,
    formState: {
      errors,
      errors: {
        [freightForwarderFormStateFieldNames.registrationNo]: registrationNoInputError,
        [freightForwarderFormStateFieldNames.trailer]: trailerInputError,
        [freightForwarderFormStateFieldNames.driverFirstName]: driverFirstNameInputError,
        [freightForwarderFormStateFieldNames.driverLastName]: driverLastNameInputError,
        [freightForwarderFormStateFieldNames.driverPassportNumber]: driverPassportNumberInputError,
        [freightForwarderFormStateFieldNames.freightForwarder]: freightForwarderInputError,
      },
      isDirty,
    },
    handleSubmit,
    reset,
  } = useForm<FreightForwarderFormState>({
    resolver: zodResolver(freightForwarderFormStateSchema),
    defaultValues: {
      [freightForwarderFormStateFieldNames.registrationNo]: '',
      [freightForwarderFormStateFieldNames.trailer]: '',
      [freightForwarderFormStateFieldNames.driverFirstName]: '',
      [freightForwarderFormStateFieldNames.driverLastName]: '',
      [freightForwarderFormStateFieldNames.driverPassportNumber]: '',
      [freightForwarderFormStateFieldNames.freightForwarder]: '',
    },
  });

  useEffect(() => {
    (async () => {
      try {
        setError(null);
        setIsLoading(true);
        // TODO: factor out accessToken null checking into a base API class to run at every call, then remove access token null checking from all components
        if (shipmentId && accessToken) {
          const shipmentRequest = shipmentsService.getById(accessToken, parseInt(shipmentId));
          const containerRequest = shipmentsService.getContainer(accessToken, parseInt(shipmentId));

          const [shipment, container] = await Promise.all([shipmentRequest, containerRequest]);

          setShipment(shipment);
          setContainer(container);

          reset({
            [freightForwarderFormStateFieldNames.registrationNo]: container.truckLicensePlate || '',
            [freightForwarderFormStateFieldNames.trailer]: container.trailerLicensePlate || '',
            [freightForwarderFormStateFieldNames.driverFirstName]: container.driverFirstName || '',
            [freightForwarderFormStateFieldNames.driverLastName]: container.driverLastName || '',
            [freightForwarderFormStateFieldNames.driverPassportNumber]: container.driverPassportNumber || '',
            [freightForwarderFormStateFieldNames.freightForwarder]: container.freightForwarder || '',
          });
        }
      } catch (error) {
        const errorMessage = handleServerError(error);
        setError(`${s.FreightForwardingInformationPage_GetContainerDetailsError}: ${errorMessage}`);
      } finally {
        setIsLoading(false);
      }
    })();
  }, [accessToken, handleServerError, reset, shipmentId]);

  const onSubmit: SubmitHandler<FreightForwarderFormState> = async (freightForwarderState) => {
    // TODO: check if user can save the truck
    try {
      setIsSavingForm(true);
      // TODO: factor out accessToken null checking into a base API class to run at every call, then remove access token null checking from all components
      if (accessToken && shipmentId && container) {
        const freightForwarder = new ContainerFreightForwardWrite(
          freightForwarderState.registrationNo,
          freightForwarderState.trailer,
          freightForwarderState.driverFirstName,
          freightForwarderState.driverLastName,
          freightForwarderState.driverPassportNumber,
        );

        if (freightForwarderState.freightForwarder) {
          freightForwarder.freightForwarder = freightForwarderState.freightForwarder;
        }

        await containerService.updateFreightForwarder(accessToken, container.id, freightForwarder);
        shipmentId ? navigateContainerProcessing(shipmentId) : navigateShipments();
      }
    } catch (error) {
      const errorMessage = handleServerError(error);
      setError(`${s.FreightForwardingInformationPage_SaveFreightForwarderInformationError}: ${errorMessage}`);
    } finally {
      setIsSavingForm(false);
    }
  };

  const handleCancel = () => {
    shipmentId ? navigateContainerProcessing(shipmentId) : navigateShipments();
  };

  const canEdit = canEditFreightForwarderForm(user, isSavingForm);

  return (
    <>
      {isLoading ? (
        <Spinner />
      ) : (
        <>
          <ContextHeader
            contextTitle={s.FreightForwardingInformationPage_Title}
            contextSubTitle={shipment?.shipmentReference}
            leftButtonOne={<SecondaryButton label={s.FreightForwardingInformationPage_CancelButton} onClick={handleCancel} />}
            rightButtonOne={
              <PrimaryButton
                label={s.FreightForwardingInformationPage_SaveButton}
                type={buttonTypes.submit}
                form={freightForwarderFormId}
                disabled={!canEdit || !isDirty}
              />
            }
            showContextHeaderContentSpacer
          />
          <Notification message={error!} severity="error" onClose={() => setError(null)} />
          <section className={formContentContainerClassNames}>
            <FreightForwarderForm
              formId={freightForwarderFormId}
              canEdit={canEdit}
              onSubmit={onSubmit}
              handleSubmit={handleSubmit}
              register={register}
              registrationNoInputError={registrationNoInputError}
              trailerInputError={trailerInputError}
              driverFirstNameInputError={driverFirstNameInputError}
              driverLastNameInputError={driverLastNameInputError}
              driverPassportNumberInputError={driverPassportNumberInputError}
              freightForwarderInputError={freightForwarderInputError}
              errors={errors}
            />
          </section>
        </>
      )}
    </>
  );
};
