import { CircularProgress } from '@mui/material';
import { display, justifyContent, margin, typedUtilityClassnames } from 'style/compoundClassnames';

const spinnerAreaClassNames = typedUtilityClassnames(display('flex'), justifyContent('justify-center'), margin('mt-4'));

export interface SpinnerProps {
  size?: number;
}

export const Spinner = ({ size }: SpinnerProps) => {
  return (
    <div className={spinnerAreaClassNames}>
      <CircularProgress size={size ?? 40} />
    </div>
  );
};
