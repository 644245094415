import { User, UserRole } from 'models/user';
import { PaginatedList } from 'models/paginatedList';
import { apiRequest } from './request';

const BaseUrl = window.__RUNTIME_CONFIG__.REACT_APP_API_BASE_URL;

export const usersService = {
  async getById(accessToken: string, userId: string) {
    return await apiRequest.get<User>(`${BaseUrl}/users/${userId}`, accessToken);
  },
  async getAll(accessToken: string) {
    return await apiRequest.get<User[]>(`${BaseUrl}/users/all`, accessToken);
  },
  async getPaged(accessToken: string, pageIndex: number, pageSize: number) {
    return await apiRequest.get<PaginatedList<User>>(`${BaseUrl}/users?pageIndex=${pageIndex}&pageSize=${pageSize}`, accessToken);
  },
  async getRoles(accessToken: string) {
    return await apiRequest.get<UserRole[]>(`${BaseUrl}/users/roles`, accessToken);
  },
  async updateUserRoles(accessToken: string, userId: string, roles: UserRole[]) {
    return await apiRequest.put<User, UserRole[]>(`${BaseUrl}/users?id=${userId}`, accessToken, roles);
  },
};
