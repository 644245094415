import {
  borderColor,
  borderRadius,
  borderWidth,
  onlyComputedCombineClassnames,
  outlineColor,
  padding,
  textAlign,
  textColor,
  typedUtilityClassnames,
} from 'style/compoundClassnames';
import { FormValidationError } from 'components/form-components';
import { fixedForwardRef } from 'utilities/forwardRefFix';

import { ChangeEventHandler, FocusEventHandler, HTMLInputTypeAttribute, Ref, useEffect, useRef } from 'react';
import type { FieldErrors, FieldName } from 'react-hook-form';
import type { MIMEPrefix } from 'models/document';
import type { ExclusivelyStringKeyedRecordWithOptional } from 'utility-types';
import type { FieldValuesFromFieldErrors } from '@hookform/error-message';

const textInputBaseLabelClassNames = typedUtilityClassnames('label', padding('pb-2'));
const textInputBaseClassNames = typedUtilityClassnames(
  outlineColor('focus:outline-black'),
  borderWidth('border'),
  borderRadius('rounded'),
  textAlign('text-right'),
);

const getInputClassNames = (
  inputElementClassNames: string,
  showsErrors: boolean,
  hasError: boolean | undefined,
  readonly: boolean,
): string | undefined =>
  onlyComputedCombineClassnames(
    {
      [outlineColor('focus:outline-black')]: showsErrors ? !hasError : true,
      [outlineColor('focus:outline-error-600')]: showsErrors ? hasError : false,
      [borderColor('border-error-600')]: showsErrors ? hasError : false,
      [borderColor('border-onSurface-disabled')]: showsErrors ? !hasError : true,
      [textColor('text-onSurface-disabled')]: readonly,
      [borderColor('border-onSurface-disabled')]: readonly,
    },
    textInputBaseClassNames,
    inputElementClassNames,
  );

export type HTMLInputType = Extract<HTMLInputTypeAttribute, 'file' | 'text' | 'checkbox'>;

export const inputTypeNames: { [Key in HTMLInputType]: Key } = {
  file: 'file',
  text: 'text',
  checkbox: 'checkbox',
} as const;

export type HTMLPhotoCaptureType = keyof typeof photoInputCaptureTypes;

export const photoInputCaptureTypes = {
  environment: 'environment',
  user: 'user',
} as const;

const preventDefaultClickHandler = (e: MouseEvent) => {
  e.preventDefault();
};

export interface _FormInputBaseProps<
  TFormState extends ExclusivelyStringKeyedRecordWithOptional<TFormState>,
  TFieldName extends FieldName<FieldValuesFromFieldErrors<FieldErrors<TFormState>>>,
> {
  onChange: ChangeEventHandler<HTMLInputElement>;
  onBlur: FocusEventHandler<HTMLInputElement>;

  type: HTMLInputType;
  name: TFieldName;
  label: string;
  ariaLabelOnly?: boolean;
  value?: string;
  defaultValue?: string;
  preventLabelClickDefault?: boolean;

  readOnly: boolean;
  isRequired: boolean;

  showsErrors: boolean;
  errors?: FieldErrors<TFormState>;
  hasError?: boolean;

  labelClassNames?: string;
  inputElementClassNames?: string;
  errorsDisplayContainerClassNames?: string;

  previewAlternativeText?: string;
  photoInputCaptureType?: HTMLPhotoCaptureType;
  fileAccept?: MIMEPrefix;
  fileAcceptTransform?: (prefix: MIMEPrefix) => string;
  isChecked?: boolean;
}

export const _FormInputBaseRefRecipient = function <
  TFormState extends ExclusivelyStringKeyedRecordWithOptional<TFormState>,
  TFieldName extends FieldName<FieldValuesFromFieldErrors<FieldErrors<TFormState>>>,
>(
  {
    onChange,
    onBlur,
    type,
    name,
    label,
    showsErrors = true,
    errors,
    labelClassNames = '',
    inputElementClassNames = '',
    errorsDisplayContainerClassNames = '',
    value,
    defaultValue,
    hasError,
    isRequired,
    photoInputCaptureType,
    fileAccept,
    fileAcceptTransform,
    readOnly = false,
    preventLabelClickDefault = false,
    ariaLabelOnly = false,
    isChecked,
  }: _FormInputBaseProps<TFormState, TFieldName>,
  ref: Ref<HTMLInputElement> | null,
): JSX.Element {
  const labelRef = useRef<HTMLLabelElement>(null);

  useEffect(() => {
    if (!labelRef?.current || !preventLabelClickDefault) {
      return;
    }
    const element = labelRef.current;

    labelRef.current.addEventListener('click', preventDefaultClickHandler);

    return () => {
      element.removeEventListener('click', preventDefaultClickHandler);
    };
  }, [labelRef, preventLabelClickDefault]);

  return (
    <>
      {!ariaLabelOnly && (
        <label ref={labelRef} className={onlyComputedCombineClassnames(textInputBaseLabelClassNames, labelClassNames)} htmlFor={name}>
          {label}
        </label>
      )}
      <input
        id={name}
        type={type}
        name={name}
        ref={ref}
        value={value}
        defaultValue={defaultValue}
        className={getInputClassNames(inputElementClassNames, showsErrors, hasError, readOnly)}
        accept={fileAccept && fileAcceptTransform ? fileAcceptTransform(fileAccept) : fileAccept}
        onChange={onChange}
        onBlur={onBlur}
        readOnly={readOnly}
        capture={photoInputCaptureType}
        aria-invalid={hasError && showsErrors ? 'true' : 'false'}
        aria-required={isRequired}
        aria-label={ariaLabelOnly ? label : undefined}
        checked={isChecked}
      />
      {showsErrors && (
        <FormValidationError<TFormState>
          errorsDisplayContainerClassNames={errorsDisplayContainerClassNames}
          name={name}
          associatedInputId={name}
          errors={errors}
        />
      )}
    </>
  );
};

export const _FormInputBase = fixedForwardRef(_FormInputBaseRefRecipient);
