import { Listbox, Popover } from '@headlessui/react';
import { s } from 'i18n/strings';
import { ReactComponent as Chevron } from 'icons/chevron.svg';
import { languages } from 'i18n/languages';

import { useState } from 'react';
import {
  alignItems,
  backgroundColor,
  borderColor,
  borderRadius,
  display,
  dropShadow,
  flexDirection,
  fontSize,
  fontWeight,
  height,
  inset,
  justifyContent,
  justifySelf,
  lineHeight,
  margin,
  padding,
  position,
  textAlign,
  textColor,
  typedUtilityClassnames,
  width,
  zIndex,
  tailwindClassNameConvertNegative,
  boxShadow,
} from 'style/compoundClassnames';

export interface AccountSettingsDropMenuProps {
  fullName: string;
  emailAddress: string;
  logoutHandler: () => void;
}

const avatarSpanElementClassNames = typedUtilityClassnames(
  height('h-8'),
  width('w-8'),
  lineHeight('leading-8'),
  textAlign('text-center'),
  backgroundColor('bg-secondary-500'),
  borderRadius('rounded-2xl'),
  fontWeight('font-bold'),
  textColor('text-white'),
  fontSize('text-base'),
);

const rootMenuAccountSettingsClassName = typedUtilityClassnames(
  position('relative'),
  width('w-fit'),
  justifySelf('justify-self-end'),
  borderRadius('rounded'),
  backgroundColor('hover:bg-primary-400', 'focus:bg-primary-400'),
  margin('my-auto'),
  padding('p-2'),
);

const userDetailsSectionElementClassNames = typedUtilityClassnames(
  textColor('text-white'),
  display('hidden', 'md:flex'),
  flexDirection('flex-col'),
  justifyContent('justify-center'),
  textAlign('text-right'),
  padding('pr-2'),
);

const menuItemsContainerClassNames = typedUtilityClassnames(
  position('absolute'),
  zIndex('z-50'),
  inset('right-0'),
  height('h-fit'),
  backgroundColor('bg-white'),
  margin('mt-2'),
  width('w-52'),
  borderRadius('rounded'),
  boxShadow('shadow-lg'),
);

const languageListOptionsClassNames = typedUtilityClassnames(
  position('absolute'),
  backgroundColor('bg-white'),
  padding('py-4'),
  borderRadius('rounded'),
  width('w-44'),
  dropShadow('drop-shadow-lg'),
  tailwindClassNameConvertNegative(inset('top-4')),
);

const getLanguageListIndividualOptionClassNames = ({ active }: { active: boolean }) =>
  typedUtilityClassnames(
    'overlayBlackStates',
    'subtitle1',
    backgroundColor({ 'bg-overlayBlack-focused': active }),
    textAlign('text-left'),
    padding('p-4'),
  );

const currentUserDetailsSectionClassNames = typedUtilityClassnames(display('flex'), alignItems('items-center'), flexDirection('flex-row'));
const fullNameDisplayClassNames = typedUtilityClassnames('subtitle2');
const emailAddressTextDisplayClassNames = typedUtilityClassnames('caption', textColor('text-primary-100'));
const languageSelectorInnerItemsOverallContainerClassNames = typedUtilityClassnames(position('relative'), padding('px-4', 'pb-4'));
const languageSelectorLabelClassNames = typedUtilityClassnames('label', padding('py-2'), display('block'));
const languageSelectorButtonClassNames = typedUtilityClassnames(
  'overlayBlackStates',
  width('w-full'),
  'borders',
  'subtitle1',
  borderColor('border-outlineColour'),
  textAlign('text-left'),
  padding('py-4', 'pl-4', 'pr-2.5'),
);
const languageSelectorButtonInnerTextIconContainerClassNames = typedUtilityClassnames(
  display('flex'),
  alignItems('items-center'),
  justifyContent('justify-between'),
);
const languageSelectorIconClassNames = typedUtilityClassnames(height('h-5'), width('w-5'), 'icons');
const menuDividerClassNames = typedUtilityClassnames(width('w-full'), textColor('text-outlineColour'));
const logoutButtonClassNames = typedUtilityClassnames('buttons', width('w-full'), textAlign('text-left'), padding('p-4'));

export const AccountSettingsDropMenu = ({ fullName, emailAddress, logoutHandler }: AccountSettingsDropMenuProps): JSX.Element => {
  const [selectedLanguage, setSelectedLanguage] = useState(languages[0]);
  return (
    <Popover as="menu" className={rootMenuAccountSettingsClassName}>
      <Popover.Button aria-label={s.AccountSettingsDropMenu_AccountSettingsMenuButtonAriaLabel}>
        <section aria-label={s.NavigationHeader_CurrentUserDetailsAriaLabel} className={currentUserDetailsSectionClassNames}>
          <hgroup className={userDetailsSectionElementClassNames}>
            <p aria-label={s.NavigationHeader_UserFullNameAriaLabel} className={fullNameDisplayClassNames}>
              {fullName}
            </p>
            <h3 aria-label={s.NavigationHeader_UserEmailAriaLabel} className={emailAddressTextDisplayClassNames}>
              {emailAddress}
            </h3>
          </hgroup>
          <span title={s.NavigationHeader_UserAvatarAriaLabel} className={avatarSpanElementClassNames}>
            {fullName.slice(0, 1)}
          </span>
        </section>
      </Popover.Button>
      <Popover.Panel className={menuItemsContainerClassNames}>
        <>
          <Listbox value={selectedLanguage} onChange={setSelectedLanguage}>
            <div className={languageSelectorInnerItemsOverallContainerClassNames}>
              <Listbox.Label className={languageSelectorLabelClassNames}>{s.AccountSettingsDropMenu_LanguageSelectorLabel}</Listbox.Label>
              <Listbox.Button className={languageSelectorButtonClassNames}>
                <div className={languageSelectorButtonInnerTextIconContainerClassNames}>
                  <span>{selectedLanguage}</span>
                  <Chevron className={languageSelectorIconClassNames} aria-hidden="true" />
                </div>
              </Listbox.Button>
              <Listbox.Options className={languageListOptionsClassNames}>
                {languages.map((language) => (
                  <Listbox.Option className={getLanguageListIndividualOptionClassNames} key={language} value={language}>
                    {language}
                  </Listbox.Option>
                ))}
              </Listbox.Options>
            </div>
          </Listbox>
        </>
        <hr className={menuDividerClassNames} />
        <button className={logoutButtonClassNames} onClick={logoutHandler}>
          {s.AccountSettingsDropMenu_LogoutButton}
        </button>
      </Popover.Panel>
    </Popover>
  );
};
