import { State } from 'models/state';

export class Bag {
  // eslint-disable-next-line no-useless-constructor
  constructor(
    public id: number,
    public state: State,
    public itsciSealNumber: string,
    public mineWeightInKg: number,
    public warehouseWeightInKg: number,
    public documentCount: number,
    public pileId: number,
  ) {}
}

export class BagWrite {
  // eslint-disable-next-line no-useless-constructor
  constructor(public truckId: number, public itsciSealNumber: string, public mineWeightInKg: number, public warehouseWeightInKg?: number) {}
}

export const BagStates = {
  New: 'New',
  Arrived: 'Arrived',
  Weighed: 'Weighed',
  Emptied: 'Emptied',
} as const;
