import { apiRequest } from './request';
import { Shipment, ShipmentWrite, ShipmentSummary, ShipmentDepartureWrite } from 'models/shipment';
import { Bag } from 'models/bag';
import { PaginatedList } from 'models/paginatedList';
import { Activity } from 'models/activity';
import { Document } from 'models/document';
import { Pile } from 'models/pile';
import { SamplingWrite } from 'models/sampling';
import { Drum } from 'models/drum';
import { Container } from 'models/container';
import { ActionWrite, OverrideWrite } from 'models/state';
import { SensorData, SensorReadingHistoricalItem } from 'models/sensorData';

const BaseUrl = window.__RUNTIME_CONFIG__.REACT_APP_API_BASE_URL;

const ShipmentActions = {
  BagsWeighed: 'BagsWeighed',
  BagsProcessed: 'BagsProcessed',
  PilesSampled: 'PilesSampled',
  DrumsProcessed: 'DrumsProcessed',
  ContainerProcessed: 'ContainerProcessed',
  FreightForwarderConfirmed: 'FreightForwarderConfirmed',
  ShipmentSailed: 'ShipmentSailed',
  ShipmentComplete: 'ShipmentComplete',
} as const;

export const shipmentsService = {
  async getById(accessToken: string, id: number) {
    return await apiRequest.get<Shipment>(`${BaseUrl}/shipments/${id}`, accessToken);
  },
  async getAll(accessToken: string) {
    return await apiRequest.get<Shipment[]>(`${BaseUrl}/shipments/all`, accessToken);
  },
  async getPaged(accessToken: string, pageIndex: number, pageSize: number) {
    return await apiRequest.get<PaginatedList<Shipment>>(`${BaseUrl}/shipments?pageIndex=${pageIndex}&pageSize=${pageSize}`, accessToken);
  },
  async getSummary(accessToken: string, id: number) {
    return await apiRequest.get<ShipmentSummary>(`${BaseUrl}/shipments/${id}/summary`, accessToken);
  },
  async create(accessToken: string, shipment: ShipmentWrite) {
    return await apiRequest.post<Shipment, ShipmentWrite>(`${BaseUrl}/shipments`, accessToken, shipment);
  },
  async update(accessToken: string, id: number, shipment: ShipmentWrite) {
    return await apiRequest.put<Shipment, ShipmentWrite>(`${BaseUrl}/shipments?id=${id}`, accessToken, shipment);
  },
  async delete(accessToken: string, id: number) {
    await apiRequest.delete(`${BaseUrl}/shipments?id=${id}`, accessToken);
  },
  async getAllActivity(accessToken: string, id: number) {
    return await apiRequest.get<Activity[]>(`${BaseUrl}/shipments/${id}/activity/all`, accessToken);
  },
  async getAllDocuments(accessToken: string, id: number) {
    return await apiRequest.get<Document[]>(`${BaseUrl}/shipments/${id}/documents/all`, accessToken);
  },
  async getBags(accessToken: string, id: number) {
    return await apiRequest.get<Bag[]>(`${BaseUrl}/shipments/${id}/bags`, accessToken);
  },
  async finishBagsWeighing(accessToken: string, id: number) {
    return await apiRequest.postWithoutResponseBody<string>(`${BaseUrl}/shipments/${id}/action`, accessToken, ShipmentActions.BagsWeighed);
  },
  async finishBagsProcessing(accessToken: string, id: number) {
    return await apiRequest.postWithoutResponseBody<string>(
      `${BaseUrl}/shipments/${id}/action`,
      accessToken,
      ShipmentActions.BagsProcessed,
    );
  },
  async finishBagsProcessingWithOverride(accessToken: string, id: number, overrideReason: string) {
    const action = new ActionWrite(ShipmentActions.BagsProcessed, new OverrideWrite(true, overrideReason));
    return await apiRequest.postWithoutResponseBody<ActionWrite>(`${BaseUrl}/shipments/${id}/actionoverride`, accessToken, action);
  },
  async getPiles(accessToken: string, id: number) {
    return await apiRequest.get<Pile[]>(`${BaseUrl}/shipments/${id}/piles`, accessToken);
  },
  async updateSamplingInformation(accessToken: string, id: number, samplingInformation: SamplingWrite) {
    return await apiRequest.put<Shipment, SamplingWrite>(`${BaseUrl}/shipments/${id}/sampling`, accessToken, samplingInformation);
  },
  async finishPilesSampling(accessToken: string, id: number) {
    return await apiRequest.postWithoutResponseBody<string>(`${BaseUrl}/shipments/${id}/action`, accessToken, ShipmentActions.PilesSampled);
  },
  async finishPilesSamplingWithOverride(accessToken: string, id: number, overrideReason: string) {
    const action = new ActionWrite(ShipmentActions.PilesSampled, new OverrideWrite(true, overrideReason));
    return await apiRequest.postWithoutResponseBody<ActionWrite>(`${BaseUrl}/shipments/${id}/actionoverride`, accessToken, action);
  },
  async getDrums(accessToken: string, id: number) {
    return await apiRequest.get<Drum[]>(`${BaseUrl}/shipments/${id}/drums`, accessToken);
  },
  async finishDrumsProcessing(accessToken: string, id: number) {
    return await apiRequest.postWithoutResponseBody<string>(
      `${BaseUrl}/shipments/${id}/action`,
      accessToken,
      ShipmentActions.DrumsProcessed,
    );
  },
  async getContainer(accessToken: string, id: number) {
    return await apiRequest.get<Container>(`${BaseUrl}/shipments/${id}/container`, accessToken);
  },
  async finishContainerProcessing(accessToken: string, id: number) {
    return await apiRequest.postWithoutResponseBody<string>(
      `${BaseUrl}/shipments/${id}/action`,
      accessToken,
      ShipmentActions.ContainerProcessed,
    );
  },
  async finishContainerProcessingWithOverride(accessToken: string, id: number, overrideReason: string) {
    const action = new ActionWrite(ShipmentActions.ContainerProcessed, new OverrideWrite(true, overrideReason));
    return await apiRequest.postWithoutResponseBody<ActionWrite>(`${BaseUrl}/shipments/${id}/actionoverride`, accessToken, action);
  },
  async confirmWithFreightForwarder(accessToken: string, id: number) {
    return await apiRequest.postWithoutResponseBody<string>(
      `${BaseUrl}/shipments/${id}/action`,
      accessToken,
      ShipmentActions.FreightForwarderConfirmed,
    );
  },
  async updateShipmentDepartureInfo(accessToken: string, id: number, shipmentDeparture: ShipmentDepartureWrite) {
    await apiRequest.put(`${BaseUrl}/shipments/${id}/departure`, accessToken, shipmentDeparture);
  },
  async confirmShipSailed(accessToken: string, id: number) {
    return await apiRequest.postWithoutResponseBody<string>(
      `${BaseUrl}/shipments/${id}/action`,
      accessToken,
      ShipmentActions.ShipmentSailed,
    );
  },
  async confirmShipSailedWithOverride(accessToken: string, id: number, overrideReason: string) {
    const action = new ActionWrite(ShipmentActions.ShipmentSailed, new OverrideWrite(true, overrideReason));
    return await apiRequest.postWithoutResponseBody<ActionWrite>(`${BaseUrl}/shipments/${id}/actionoverride`, accessToken, action);
  },
  async completeShipment(accessToken: string, id: number) {
    return await apiRequest.postWithoutResponseBody<string>(
      `${BaseUrl}/shipments/${id}/action`,
      accessToken,
      ShipmentActions.ShipmentComplete,
    );
  },
  async completeShipmentWithOverride(accessToken: string, id: number, overrideReason: string) {
    const action = new ActionWrite(ShipmentActions.ShipmentComplete, new OverrideWrite(true, overrideReason));
    return await apiRequest.postWithoutResponseBody<ActionWrite>(`${BaseUrl}/shipments/${id}/actionoverride`, accessToken, action);
  },
  async getSensorData(accessToken: string, id: number) {
    return await apiRequest.get<SensorData>(`${BaseUrl}/shipments/${id}/sensorData`, accessToken);
  },
  async getHistoricalSensorData(accessToken: string, id: number) {
    return await apiRequest.get<SensorReadingHistoricalItem[]>(`${BaseUrl}/shipments/${id}/sensorData/history`, accessToken);
  },
};
