import type { MouseEventHandler, ReactElement } from 'react';
import { _ButtonBase, _ButtonBaseProps } from 'components/buttons/_base/_ButtonBase';
import {
  typedUtilityClassnames,
  backgroundColor,
  borderColor,
  borderStyle,
  borderWidth,
  textColor,
  onlyComputedCombineClassnames,
} from 'style/compoundClassnames';

export interface SecondaryButtonProps extends Partial<Pick<_ButtonBaseProps, 'type'>> {
  disabled?: boolean;
  onClick?: MouseEventHandler<HTMLButtonElement>;
  label: string | ReactElement;
  buttonClassNames?: string;
}

const enabledClasses = typedUtilityClassnames(
  textColor('text-black'),
  backgroundColor('hover:bg-primary-50'),
  borderColor('border-black'),
);
const disabledClasses = typedUtilityClassnames(textColor('text-onSurface-disabled'), borderColor('border-onSurface-disabled'));
const commonClasses = typedUtilityClassnames(backgroundColor('bg-white'), borderStyle('border-solid'), borderWidth('border'));

export const SecondaryButton = ({
  disabled = false,
  onClick,
  label,
  type = 'button',
  buttonClassNames,
}: SecondaryButtonProps): JSX.Element => (
  <>
    <_ButtonBase
      label={label}
      type={type}
      onClick={onClick}
      disabled={disabled}
      buttonClassNames={onlyComputedCombineClassnames(
        { [enabledClasses]: !disabled, [disabledClasses]: disabled },
        commonClasses,
        buttonClassNames,
      )}
    />
  </>
);
