import { ChangeEvent, MouseEvent, useEffect, useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';

import { Bag, BagStates } from 'models/bag';
import { Pile, PileWrite } from 'models/pile';
import { bagsService } from 'services/bagsService';
import { pilesService } from 'services/pilesService';
import { useAccessToken, useServerError } from 'hooks';

import { s } from 'i18n/strings';

import { Checkbox, InputAdornment, Table, TableBody, TableCell, TableContainer, TableRow, TextField } from '@mui/material';
import {
  borderColor,
  borderRadius,
  borderWidth,
  display,
  flexDirection,
  height,
  inset,
  justifyContent,
  outlineColor,
  padding,
  position,
  textAlign,
  textColor,
  typedUtilityClassnames,
  width,
} from 'style/compoundClassnames';

import { _DrawerFormBase } from 'components/drawer-forms/_base';
import { Spinner } from 'components/Spinner';

import { ReactComponent as SearchIcon } from 'icons/search.svg';

const textInputAndLabelContainerClassNames = typedUtilityClassnames(display('flex'), flexDirection('flex-col'), padding('pb-6'));
const textInputLabelClassNames = typedUtilityClassnames('label', padding('pb-2'));
const textInputClassNames = typedUtilityClassnames(
  'headline5',
  height('h-14'),
  padding('p-4'),
  outlineColor('focus:outline-black'),
  borderWidth('border'),
  borderColor('border-onSurface-disabled'),
  borderRadius('rounded'),
  textAlign('text-right'),
  textColor('disabled:text-onSurface-disabled'),
);
const searchPlaceholderClassNames = typedUtilityClassnames('subtitle1', width('w-full'), padding('pb-1'));
const searchPlaceholderIconClassNames = typedUtilityClassnames('icons', width('w-5'));
const bagsCounterClassNames = typedUtilityClassnames(
  'body2',
  display('flex'),
  justifyContent('justify-center'),
  width('w-72'),
  position('absolute'),
  inset('bottom-24'),
);
const bagsErrorClassNames = typedUtilityClassnames(display('flex'), justifyContent('justify-center'), textColor('text-error-600'));

const pileFormId = 'pileForm';

interface PileFormState {}

export interface PileDrawerFormProps {
  shipmentId: string;
  onSaveSuccess: () => Promise<void>;
  onSaveFailed: (error: unknown) => Promise<void>;
  onClose: () => void;
  pile: Pile | null;
  canEdit: boolean;
}

export const PileDrawerForm = ({ shipmentId, onSaveSuccess, onSaveFailed, onClose, pile, canEdit }: PileDrawerFormProps): JSX.Element => {
  const accessToken = useAccessToken();
  const { handleServerError } = useServerError();

  const [bags, setBags] = useState<Bag[] | null>(null);
  const [filteredBags, setFilteredBags] = useState<Bag[] | null>(null);

  const [selectedBagsIds, setSelectedBagsIds] = useState<readonly string[]>([]);
  const [savedSelectedBagsIds, setSavedSelectedBagsIds] = useState<readonly string[]>([]);

  const [selectedBagsChanged, setSelectedBagsChanged] = useState(false);

  const [isLoading, setIsLoading] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [error, setError] = useState<string | null>(null);

  const { handleSubmit } = useForm<PileFormState>();

  const onSubmit: SubmitHandler<PileFormState> = async () => {
    try {
      setError(null);
      if (bags && selectedBagsIds.length > 0) {
        setIsSaving(true);

        const selectedBags = bags.filter((b) => selectedBagsIds.includes(b.itsciSealNumber));
        const newPile = new PileWrite(
          selectedBags.map((b) => b.id),
          [],
        );

        // TODO: factor out accessToken null checking into a base API class to run at every call, then remove access token null checking from all components
        if (accessToken) {
          if (pile) {
            await pilesService.update(accessToken, pile.id, newPile);
          } else {
            pile = await pilesService.create(accessToken, newPile);
          }
        }

        onSaveSuccess();
      }
    } catch (error) {
      onSaveFailed(error);
    } finally {
      setIsSaving(false);
    }
  };

  useEffect(() => {
    (async () => {
      try {
        setIsLoading(true);
        // TODO: factor out accessToken null checking into a base API class to run at every call, then remove access token null checking from all components
        if (accessToken && shipmentId) {
          let bags = await bagsService.getAllForShipment(accessToken, parseInt(shipmentId));
          if (bags) {
            if (!pile) {
              bags = bags.filter((b) => b.state.name === BagStates.Weighed);
            }
            setBags(bags);
            setFilteredBags(bags);
          }

          if (pile) {
            setSelectedBagsIds(pile.bags.map((b) => b.itsciSealNumber));
            setSavedSelectedBagsIds(pile.bags.map((b) => b.itsciSealNumber));
          }
        }
      } catch (error) {
        const errorMessage = handleServerError(error);
        setError(errorMessage);
      } finally {
        setIsLoading(false);
      }
    })();
  }, [accessToken, handleServerError, pile, shipmentId]);

  const handleSearchRequest = (event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    const filteredBags = bags?.filter((bag) => {
      return bag.itsciSealNumber.toLowerCase().includes(event.target.value.toLowerCase());
    });
    setFilteredBags(filteredBags!);
  };

  const isBagNotSelectable = (bag: Bag, selected: boolean) => {
    return !selected && bag.state.name === BagStates.Emptied && !savedSelectedBagsIds.includes(bag.itsciSealNumber);
  };

  const handleClick = (_: MouseEvent<HTMLTableRowElement>, bag: Bag, itemSelected: boolean) => {
    if (!canEdit || isBagNotSelectable(bag, itemSelected)) {
      return;
    }

    const selectedIndex = selectedBagsIds.indexOf(bag.itsciSealNumber);
    let newSelectedBagId: readonly string[] = [];

    if (selectedIndex === -1) {
      newSelectedBagId = newSelectedBagId.concat(selectedBagsIds, bag.itsciSealNumber);
    } else if (selectedIndex === 0) {
      newSelectedBagId = newSelectedBagId.concat(selectedBagsIds.slice(1));
    } else if (selectedIndex === selectedBagsIds.length - 1) {
      newSelectedBagId = newSelectedBagId.concat(selectedBagsIds.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelectedBagId = newSelectedBagId.concat(selectedBagsIds.slice(0, selectedIndex), selectedBagsIds.slice(selectedIndex + 1));
    }

    setSelectedBagsIds(newSelectedBagId);
    setSelectedBagsChanged(true);
  };

  const isSelected = (name: string) => selectedBagsIds.indexOf(name) !== -1;

  return (
    <_DrawerFormBase
      formId={pileFormId}
      title={pile ? s.PileDrawer_EditPile : s.PileDrawer_AddPile}
      formAccessibilityTitle={pile ? s.PileDrawer_EditBagDrawerAccessibleFormTitle : s.PileDrawer_AddBagDrawerAccessibleFormTitle}
      handleSubmit={handleSubmit(onSubmit)}
      closeOnClick={onClose}
      primarySubmitDisabled={isSaving || !selectedBagsChanged || selectedBagsIds.length === 0}
      closeButtonAriaLabel={s.PileDrawer_CloseDrawerIconButtonAriaLabel}
    >
      {pile && (
        <div className={textInputAndLabelContainerClassNames}>
          <div className={textInputLabelClassNames}>{s.PileDrawer_PileId}</div>
          <input defaultValue={pile.reference} disabled className={textInputClassNames} />
        </div>
      )}

      {isLoading ? (
        <Spinner />
      ) : (
        <>
          {error ? (
            <div className={bagsErrorClassNames}>{error}</div>
          ) : (
            <>
              <TableContainer>
                <div className={textInputLabelClassNames}>{s.PileDrawer_SelectedBags}</div>
                <TextField
                  variant="outlined"
                  size="small"
                  placeholder={s.PileDrawer_SearchFieldPlaceholder}
                  className={searchPlaceholderClassNames}
                  onChange={(searchedValue) => handleSearchRequest(searchedValue)}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <SearchIcon className={searchPlaceholderIconClassNames} />
                      </InputAdornment>
                    ),
                  }}
                />

                <Table>
                  <TableBody>
                    {filteredBags?.map((bag) => {
                      const isItemSelected = isSelected(bag.itsciSealNumber);
                      return (
                        <TableRow
                          key={bag.id}
                          hover
                          role="checkbox"
                          selected={isItemSelected}
                          onClick={(event) => handleClick(event, bag, isItemSelected)}
                        >
                          <TableCell padding="checkbox">
                            <Checkbox checked={isItemSelected} disabled={!canEdit || isBagNotSelectable(bag, isItemSelected)} />
                          </TableCell>
                          <TableCell scope="row">{bag.itsciSealNumber}</TableCell>
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              </TableContainer>

              <div className={bagsCounterClassNames}>
                {selectedBagsIds.length
                  ? s.PileDrawer_BagsSelectedMessage.replace('{{count}}', selectedBagsIds.length.toString())
                  : s.PileDrawer_SelectBagsMessage}
              </div>
            </>
          )}
        </>
      )}
    </_DrawerFormBase>
  );
};
