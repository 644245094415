import { InstitutionNames } from 'models/representative';
import { s } from 'i18n/strings';
import { FieldError, FieldErrors, SubmitHandler, UseFormHandleSubmit, UseFormRegister } from 'react-hook-form';
import {
  display,
  gap,
  gridRowStart,
  gridTemplateColumns,
  gridTemplateRows,
  margin,
  padding,
  textColor,
  typedUtilityClassnames,
} from 'style/compoundClassnames';
import { getRequiredInputLength } from 'utilities/formValidation/stringValidation';
import { ExclusivelyStringKeyedRecordAsNonOptional, FormFieldNames } from 'utility-types';
import { z } from 'zod';
import { UncontrolledTextInput } from 'components/form-components/inputs';
import { ReactComponent as TruckIcon } from 'icons/truck.svg';
import { ReactComponent as WitnessesIcon } from 'icons/witnesses.svg';

const sectionsSharedClassNames = typedUtilityClassnames(
  display('grid'),
  gridTemplateColumns('lg:grid-cols-2', 'grid-cols-1'),
  gap('gap-x-6'),
);
const formClassNames = typedUtilityClassnames('nonExpandingMainLayoutContentSection');
const formSectionClassNames = typedUtilityClassnames(sectionsSharedClassNames, gridTemplateRows('grid-rows-mc4', 'lg:grid-rows-mc3'));
const sectionsTitlesSharedClassNames = typedUtilityClassnames('headline5', display('flex'), textColor('text-primary-900'), padding('pb-6'));
const sectionsTitleIconContainerClassNames = typedUtilityClassnames(margin('mt-1'), padding('pr-3'));
const sectionFirstRowSharedClassNames = typedUtilityClassnames(
  'navBlockFont',
  gridRowStart('lg:row-start-2'),
  textColor('text-onSurface-mediumEmphasis'),
  padding('pb-2'),
);
const sectionSecondRowSharedClassNames = typedUtilityClassnames(gridRowStart('lg:row-start-3'));
const sectionThirdRowSharedClassNames = typedUtilityClassnames(
  'navBlockFont',
  gridRowStart('lg:row-start-4'),
  textColor('text-onSurface-mediumEmphasis'),
  padding('pb-2'),
);
const sectionFourthRowSharedClassNames = typedUtilityClassnames(gridRowStart('lg:row-start-5'));
const sectionFifthRowSharedClassNames = typedUtilityClassnames(
  'navBlockFont',
  gridRowStart('lg:row-start-6'),
  textColor('text-onSurface-mediumEmphasis'),
  padding('pb-2'),
);
const sectionSixthRowSharedClassNames = typedUtilityClassnames(gridRowStart('lg:row-start-7'));

const truckRequiredFieldsValidation = (minLength: number, maxLength: number) =>
  getRequiredInputLength(
    s.FormValidationError_FieldRequired,
    minLength,
    s.FormValidationError_MinimumLengthError.replace('{{length}}', minLength.toString()),
    maxLength,
    s.FormValidationError_MaximumLengthError.replace('{{length}}', maxLength.toString()),
  );

export const truckFormStateSchema = z.object({
  registrationNo: truckRequiredFieldsValidation(5, 20),
  trailer: truckRequiredFieldsValidation(5, 20),
  driverFirstName: truckRequiredFieldsValidation(1, 50),
  driverLastName: truckRequiredFieldsValidation(1, 50),
  driverPassportNumber: truckRequiredFieldsValidation(1, 30),
  coprocoStaffWitnessFirstName: truckRequiredFieldsValidation(1, 50),
  coprocoStaffWitnessLastName: truckRequiredFieldsValidation(1, 50),
  ceecOfficerWitnessFirstName: truckRequiredFieldsValidation(1, 50),
  ceecOfficerWitnessLastName: truckRequiredFieldsValidation(1, 50),
  divisionOfMineWitnessFirstName: truckRequiredFieldsValidation(1, 50),
  divisionOfMineWitnessLastName: truckRequiredFieldsValidation(1, 50),
});

export type TruckFormState = z.infer<typeof truckFormStateSchema>;

export const truckFormStateFieldNames: ExclusivelyStringKeyedRecordAsNonOptional<FormFieldNames<TruckFormState>> = {
  registrationNo: 'registrationNo',
  trailer: 'trailer',
  driverFirstName: 'driverFirstName',
  driverLastName: 'driverLastName',
  driverPassportNumber: 'driverPassportNumber',
  coprocoStaffWitnessFirstName: 'coprocoStaffWitnessFirstName',
  coprocoStaffWitnessLastName: 'coprocoStaffWitnessLastName',
  ceecOfficerWitnessFirstName: 'ceecOfficerWitnessFirstName',
  ceecOfficerWitnessLastName: 'ceecOfficerWitnessLastName',
  divisionOfMineWitnessFirstName: 'divisionOfMineWitnessFirstName',
  divisionOfMineWitnessLastName: 'divisionOfMineWitnessLastName',
} as const;

export interface TruckArrivalFormProps {
  formId: string;
  canEdit: boolean;
  onSubmit: SubmitHandler<TruckFormState>;
  handleSubmit: UseFormHandleSubmit<TruckFormState>;
  register: UseFormRegister<TruckFormState>;
  registrationNoInputError: FieldError | undefined;
  trailerInputError: FieldError | undefined;
  driverFirstNameInputError: FieldError | undefined;
  driverLastNameInputError: FieldError | undefined;
  driverPassportNumberInputError: FieldError | undefined;
  coprocoStaffWitnessFirstNameInputError: FieldError | undefined;
  coprocoStaffWitnessLastNameInputError: FieldError | undefined;
  ceecOfficerWitnessFirstNameInputError: FieldError | undefined;
  ceecOfficerWitnessLastNameInputError: FieldError | undefined;
  divisionOfMineWitnessFirstNameInputError: FieldError | undefined;
  divisionOfMineWitnessLastNameInputError: FieldError | undefined;
  errors: FieldErrors<TruckFormState>;
}

export const TruckArrivalForm = ({
  formId,
  canEdit,
  onSubmit,
  handleSubmit,
  register,
  registrationNoInputError,
  trailerInputError,
  driverFirstNameInputError,
  driverLastNameInputError,
  driverPassportNumberInputError,
  coprocoStaffWitnessFirstNameInputError,
  coprocoStaffWitnessLastNameInputError,
  ceecOfficerWitnessFirstNameInputError,
  ceecOfficerWitnessLastNameInputError,
  divisionOfMineWitnessFirstNameInputError,
  divisionOfMineWitnessLastNameInputError,
  errors,
}: TruckArrivalFormProps): JSX.Element => {
  return (
    <form id={formId} onSubmit={handleSubmit(onSubmit)} className={formClassNames}>
      <section className={formSectionClassNames}>
        <div className={sectionsTitlesSharedClassNames}>
          <div className={sectionsTitleIconContainerClassNames}>
            <TruckIcon />
          </div>
          {s.TruckArrivalProcessingPage_TruckInformation}
        </div>
        <div className={sectionSecondRowSharedClassNames}>
          <UncontrolledTextInput<TruckFormState, typeof truckFormStateFieldNames.registrationNo>
            readOnly={!canEdit}
            label={s.TruckArrivalProcessingPage_RegistrationNo}
            {...register(truckFormStateFieldNames.registrationNo, { disabled: !canEdit })}
            hasError={!!registrationNoInputError}
            errors={errors}
            isRequired={true}
          />
        </div>
        <div className={sectionSecondRowSharedClassNames}>
          <UncontrolledTextInput<TruckFormState, typeof truckFormStateFieldNames.trailer>
            readOnly={!canEdit}
            label={s.TruckArrivalProcessingPage_Trailer}
            {...register(truckFormStateFieldNames.trailer, { disabled: !canEdit })}
            hasError={!!trailerInputError}
            errors={errors}
            isRequired={true}
          />
        </div>
        <div className={sectionFourthRowSharedClassNames}>
          <UncontrolledTextInput<TruckFormState, typeof truckFormStateFieldNames.driverFirstName>
            readOnly={!canEdit}
            label={s.TruckArrivalProcessingPage_DriverFirstName}
            {...register(truckFormStateFieldNames.driverFirstName, { disabled: !canEdit })}
            hasError={!!driverFirstNameInputError}
            errors={errors}
            isRequired={true}
          />
        </div>
        <div className={sectionFourthRowSharedClassNames}>
          <UncontrolledTextInput<TruckFormState, typeof truckFormStateFieldNames.driverLastName>
            readOnly={!canEdit}
            label={s.TruckArrivalProcessingPage_DriverLastName}
            {...register(truckFormStateFieldNames.driverLastName, { disabled: !canEdit })}
            hasError={!!driverLastNameInputError}
            errors={errors}
            isRequired={true}
          />
        </div>
        <div className={sectionSixthRowSharedClassNames}>
          <UncontrolledTextInput<TruckFormState, typeof truckFormStateFieldNames.driverPassportNumber>
            readOnly={!canEdit}
            label={s.TruckArrivalProcessingPage_DriverPsp}
            {...register(truckFormStateFieldNames.driverPassportNumber, { disabled: !canEdit })}
            hasError={!!driverPassportNumberInputError}
            errors={errors}
            isRequired={true}
          />
        </div>
      </section>

      <section className={formSectionClassNames}>
        <div className={sectionsTitlesSharedClassNames}>
          <div className={sectionsTitleIconContainerClassNames}>
            <WitnessesIcon />
          </div>
          {s.TruckArrivalProcessingPage_Witnesses}
        </div>
        <div className={sectionFirstRowSharedClassNames}>{InstitutionNames.CoprocoStaff}</div>
        <div className={sectionSecondRowSharedClassNames}>
          <UncontrolledTextInput<TruckFormState, typeof truckFormStateFieldNames.coprocoStaffWitnessFirstName>
            readOnly={!canEdit}
            label={s.TruckArrivalProcessingPage_CoprocoStaffWitnessFirstName}
            {...register(truckFormStateFieldNames.coprocoStaffWitnessFirstName, { disabled: !canEdit })}
            hasError={!!coprocoStaffWitnessFirstNameInputError}
            errors={errors}
            isRequired={true}
          />
        </div>
        <div className={sectionSecondRowSharedClassNames}>
          <UncontrolledTextInput<TruckFormState, typeof truckFormStateFieldNames.coprocoStaffWitnessLastName>
            readOnly={!canEdit}
            label={s.TruckArrivalProcessingPage_CoprocoStaffWitnessLastName}
            {...register(truckFormStateFieldNames.coprocoStaffWitnessLastName, { disabled: !canEdit })}
            hasError={!!coprocoStaffWitnessLastNameInputError}
            errors={errors}
            isRequired={true}
          />
        </div>
        <div className={sectionThirdRowSharedClassNames}>{InstitutionNames.CeecOfficer}</div>
        <div className={sectionFourthRowSharedClassNames}>
          <UncontrolledTextInput<TruckFormState, typeof truckFormStateFieldNames.ceecOfficerWitnessFirstName>
            readOnly={!canEdit}
            label={s.TruckArrivalProcessingPage_CeecOfficerWitnessFirstName}
            {...register(truckFormStateFieldNames.ceecOfficerWitnessFirstName, { disabled: !canEdit })}
            hasError={!!ceecOfficerWitnessFirstNameInputError}
            errors={errors}
            isRequired={true}
          />
        </div>
        <div className={sectionFourthRowSharedClassNames}>
          <UncontrolledTextInput<TruckFormState, typeof truckFormStateFieldNames.ceecOfficerWitnessLastName>
            readOnly={!canEdit}
            label={s.TruckArrivalProcessingPage_CeecOfficerWitnessLastName}
            {...register(truckFormStateFieldNames.ceecOfficerWitnessLastName, { disabled: !canEdit })}
            hasError={!!ceecOfficerWitnessLastNameInputError}
            errors={errors}
            isRequired={true}
          />
        </div>
        <div className={sectionFifthRowSharedClassNames}>{InstitutionNames.DivisionOfMine}</div>
        <div className={sectionSixthRowSharedClassNames}>
          <UncontrolledTextInput<TruckFormState, typeof truckFormStateFieldNames.divisionOfMineWitnessFirstName>
            readOnly={!canEdit}
            label={s.TruckArrivalProcessingPage_DivisionOfMineWitnessFirstName}
            {...register(truckFormStateFieldNames.divisionOfMineWitnessFirstName, { disabled: !canEdit })}
            hasError={!!divisionOfMineWitnessFirstNameInputError}
            errors={errors}
            isRequired={true}
          />
        </div>
        <div className={sectionSixthRowSharedClassNames}>
          <UncontrolledTextInput<TruckFormState, typeof truckFormStateFieldNames.divisionOfMineWitnessLastName>
            readOnly={!canEdit}
            label={s.TruckArrivalProcessingPage_DivisionOfMineWitnessLastName}
            {...register(truckFormStateFieldNames.divisionOfMineWitnessLastName, { disabled: !canEdit })}
            hasError={!!divisionOfMineWitnessLastNameInputError}
            errors={errors}
            isRequired={true}
          />
        </div>
      </section>
    </form>
  );
};
