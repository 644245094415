import { height, typedUtilityClassnames, padding, onlyComputedCombineClassnames, display, flexDirection } from 'style/compoundClassnames';
import { fixedForwardRef } from 'utilities/forwardRefFix';
import { inputTypeNames, _FormInputBase } from 'components/form-components/inputs/_base';

import type { FieldErrors, FieldName } from 'react-hook-form';
import type { ChangeEventHandler, FocusEventHandler, Ref } from 'react';
import type { ExclusivelyStringKeyedRecordWithOptional } from 'utility-types';
import type { FieldValuesFromFieldErrors } from '@hookform/error-message';

export interface _TextInputBaseProps<
  TFormState extends ExclusivelyStringKeyedRecordWithOptional<TFormState>,
  TFieldName extends FieldName<FieldValuesFromFieldErrors<FieldErrors<TFormState>>>,
> {
  onChange: ChangeEventHandler<HTMLInputElement>;
  onBlur: FocusEventHandler<HTMLInputElement>;
  label: string;
  name: TFieldName;
  showsErrors?: boolean;
  errors: FieldErrors<TFormState>;
  value?: string;
  defaultValue?: string;
  labelClassNames?: string;
  inputElementClassNames?: string;
  textInputAndLabelContainerClassNames?: string;
  hasError?: boolean;
  readOnly?: boolean;
  isRequired: boolean;
}

const textInputAndLabelBaseContainerClassNames = typedUtilityClassnames(display('flex'), flexDirection('flex-col'));
const textInputBaseClassNames = typedUtilityClassnames('headline5', height('h-14'), padding('p-4'));

const _TextInputBaseRefRecipient = function <
  TFormState extends ExclusivelyStringKeyedRecordWithOptional<TFormState>,
  TFieldName extends FieldName<FieldValuesFromFieldErrors<FieldErrors<TFormState>>>,
>(
  {
    onChange,
    onBlur,
    name,
    label,
    showsErrors = true,
    errors,
    labelClassNames = '',
    inputElementClassNames = '',
    textInputAndLabelContainerClassNames = '',
    value,
    defaultValue,
    hasError,
    isRequired,
    readOnly = false,
  }: _TextInputBaseProps<TFormState, TFieldName>,
  ref: Ref<HTMLInputElement> | null,
): JSX.Element {
  return (
    <div className={onlyComputedCombineClassnames(textInputAndLabelBaseContainerClassNames, textInputAndLabelContainerClassNames)}>
      <_FormInputBase<TFormState, TFieldName>
        labelClassNames={labelClassNames}
        inputElementClassNames={onlyComputedCombineClassnames(textInputBaseClassNames, inputElementClassNames)}
        errors={errors}
        label={label}
        name={name}
        value={value}
        defaultValue={defaultValue}
        type={inputTypeNames.text}
        onChange={onChange}
        onBlur={onBlur}
        ref={ref}
        readOnly={readOnly}
        isRequired={isRequired}
        hasError={hasError}
        showsErrors={showsErrors}
      />
    </div>
  );
};

export const _TextInputBase = fixedForwardRef(_TextInputBaseRefRecipient);
