import { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';

import { User, UserRole } from 'models/user';
import { usersService } from 'services/usersService';
import { useAccessToken, useAppNavigation, useServerError } from 'hooks';

import { s } from 'i18n/strings';

import { display, flexDirection, justifyContent, typedUtilityClassnames } from 'style/compoundClassnames';

import { EditUserForm } from 'components/EditUserForm';
import { Spinner } from 'components/Spinner';
import { ContextHeader } from 'components/headers';
import { DestructiveButton, PrimaryButton, SecondaryButton } from 'components/buttons';
import { Notification } from 'components/Notification';

const mainContentClassNames = typedUtilityClassnames(
  'mainLayoutPadding',
  display('flex'),
  justifyContent('justify-center'),
  flexDirection('flex-col'),
);
const formContentContainerClassNames = typedUtilityClassnames(display('flex'), justifyContent('justify-center'));

export const EditUserPage = () => {
  const { userId } = useParams<{ userId: string }>();
  const accessToken = useAccessToken();

  const { navigateUsers } = useAppNavigation();
  const { handleServerError } = useServerError();

  const [checkedRoles, setCheckedRoles] = useState<boolean[] | null>(null);
  const [allUserRoles, setAllUserRoles] = useState<UserRole[] | null>(null);
  const [user, setUser] = useState<User | null>(null);

  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    (async () => {
      try {
        setError(null);
        setIsLoading(true);
        // TODO: factor out accessToken null checking into a base API class to run at every call, then remove access token null checking from all components
        if (accessToken && userId) {
          const userRolesRequest = usersService.getRoles(accessToken);
          const userRequest = usersService.getById(accessToken, userId);

          const [roles, user] = await Promise.all([userRolesRequest, userRequest]);

          setAllUserRoles(roles);
          setUser(user);

          user?.roles && setCheckedRoles(roles.map((role) => user.roles.map((r) => r.id).includes(role.id)));
        }
      } catch (error) {
        const errorMessage = handleServerError(error);
        setError(`${s.EditUserPage_GetUserDataError}: ${errorMessage}`);
      } finally {
        setIsLoading(false);
      }
    })();
  }, [accessToken, handleServerError, userId]);

  const [saveAllowed, setSaveAllowed] = useState(false);
  const [savingInProgress, setSavingInProgress] = useState(false);

  const handleSaveUser = async () => {
    try {
      setSaveAllowed(false);
      setSavingInProgress(true);
      // TODO: factor out accessToken null checking into a base API class to run at every call, then remove access token null checking from all components
      if (accessToken && user && allUserRoles && checkedRoles) {
        const selectedUserRoles = allUserRoles.filter((r) => checkedRoles[r.id - 1]);
        await usersService.updateUserRoles(accessToken, user.id, selectedUserRoles);
        navigateUsers();
      }
    } catch (error) {
      const errorMessage = handleServerError(error);
      setError(`${s.EditUserPage_SaveUserDataError}: ${errorMessage}`);
    } finally {
      setSavingInProgress(false);
    }
  };

  const handleCancel = () => {
    navigateUsers();
  };

  const handleDeleteUser = () => {};

  return (
    <>
      <ContextHeader
        contextTitle={s.EditUserPage_Title}
        leftButtonOne={<SecondaryButton label={s.EditUserPage_CancelButtonCaption} onClick={handleCancel} />}
        rightButtonOne={<DestructiveButton label={s.EditUserPage_DeleteButtonCaption} onClick={handleDeleteUser} disabled={true} />}
        rightButtonTwo={<PrimaryButton label={s.EditUserPage_SaveButtonCaption} onClick={handleSaveUser} disabled={!saveAllowed} />}
        showContextHeaderContentSpacer
      />
      <div className={mainContentClassNames}>
        <Notification message={error!} severity="error" onClose={() => setError(null)} />
        {isLoading ? (
          <Spinner />
        ) : (
          user &&
          allUserRoles &&
          checkedRoles && (
            <section className={formContentContainerClassNames}>
              <EditUserForm
                user={user}
                allUserRoles={allUserRoles}
                checkedRoles={checkedRoles}
                setCheckedRoles={setCheckedRoles}
                setSaveAllowed={setSaveAllowed}
                savingInProgress={savingInProgress}
              />
            </section>
          )
        )}
      </div>
    </>
  );
};
