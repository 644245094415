import { apiRequest } from 'services/request';
import { Pile, PileWrite } from 'models/pile';

const BaseUrl = window.__RUNTIME_CONFIG__.REACT_APP_API_BASE_URL;

const PileActions = {
  PileConsumed: 'PileConsumed',
} as const;

export const pilesService = {
  async getById(accessToken: string, id: number) {
    return await apiRequest.get<Pile>(`${BaseUrl}/piles/${id}`, accessToken);
  },
  async create(accessToken: string, pile: PileWrite) {
    return await apiRequest.post<Pile, PileWrite>(`${BaseUrl}/piles`, accessToken, pile);
  },
  async update(accessToken: string, id: number, pile: PileWrite) {
    return await apiRequest.put<Pile, PileWrite>(`${BaseUrl}/piles?id=${id}`, accessToken, pile);
  },
  async delete(accessToken: string, id: number) {
    await apiRequest.delete(`${BaseUrl}/piles?id=${id}`, accessToken);
  },
  async consumePile(accessToken: string, id: number) {
    await apiRequest.postWithoutResponseBody<string>(`${BaseUrl}/piles/${id}/action`, accessToken, PileActions.PileConsumed);
  },
};
