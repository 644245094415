import { useCallback, useEffect, useState } from 'react';
import {
  SingleDocumentInputCacheDocumentPendingDeletion,
  SingleDocumentInputCacheDocumentStagedForUpload,
  SingleDocumentInputCacheHandleDelete,
  UseSingleDocumentInputCacheManagerReturn,
  SingleDocumentInputCacheUpload,
  useSingleDocumentInputCache,
} from 'hooks/useSingleDocumentInputCache';
import { documentTypes } from 'models/document';
import { DrumDrawerFormAddPhotoEvidence } from 'components/drawer-forms/DrumDrawerFormAddPhotoEvidence';
import { DrumDrawerFormAddDrumMain, DrumDrawerFormSelectPiles } from 'components/drawer-forms';
import { zodResolver } from '@hookform/resolvers/zod';
import { z } from 'zod';
import { useForm } from 'react-hook-form';
import { getOptionalInputValue } from 'utilities/formValidation/numberValidation';
import { getOptionalInputLength } from 'utilities/formValidation/stringValidation';
import { drumsService } from 'services/drumsService';
import { useAccessToken, useServerError } from 'hooks';

import { s } from 'i18n/strings';

import type { Dispatch, SetStateAction } from 'react';
import type { Pile } from 'models/pile';
import type { Drum } from 'models/drum';
import type { Document } from 'models/document';
import { ExclusivelyStringKeyedRecordWithOptional, FormFieldNames } from 'utility-types';
import type { Control, UseFormGetValues, UseFormHandleSubmit, UseFormRegister, UseFormReset } from 'react-hook-form';

export type AddDrumDrawerFormStage = typeof addDrumDrawerFormStages[keyof typeof addDrumDrawerFormStages];

const trueString = 'true';

export const addDrumDrawerFormStages = {
  addDrumMain: 0,
  selectPiles: 1,
  emptyDrumEvidence: 2,
  filledDrumEvidence: 3,
  sealedDrumEvidence: 4,
} as const;

// Handle preserving state on navigate to other stage.
const handleNavigateToOtherStage = function <TFormState extends ExclusivelyStringKeyedRecordWithOptional<TFormState>>(
  getValues: UseFormGetValues<TFormState>,
  reset: UseFormReset<TFormState>,
  canEdit: boolean,
) {
  const existingFormValues = getValues();
  if (!canEdit || !existingFormValues) {
    return;
  }
  reset(existingFormValues, { keepDirty: true });
};

const getNavigateToStage = function <TFormState extends ExclusivelyStringKeyedRecordWithOptional<TFormState>>(
  setStage: Dispatch<SetStateAction<AddDrumDrawerFormStage>>,
  getValues: UseFormGetValues<TFormState>,
  reset: UseFormReset<TFormState>,
  canEdit: boolean,
) {
  return {
    [addDrumDrawerFormStages.addDrumMain]: () => {
      handleNavigateToOtherStage(getValues, reset, canEdit);
      setStage(addDrumDrawerFormStages.addDrumMain);
    },
    [addDrumDrawerFormStages.selectPiles]: () => {
      setStage(addDrumDrawerFormStages.selectPiles);
      handleNavigateToOtherStage(getValues, reset, canEdit);
    },
    [addDrumDrawerFormStages.emptyDrumEvidence]: () => {
      setStage(addDrumDrawerFormStages.emptyDrumEvidence);
      handleNavigateToOtherStage(getValues, reset, canEdit);
    },
    [addDrumDrawerFormStages.filledDrumEvidence]: () => {
      setStage(addDrumDrawerFormStages.filledDrumEvidence);
      handleNavigateToOtherStage(getValues, reset, canEdit);
    },
    [addDrumDrawerFormStages.sealedDrumEvidence]: () => {
      setStage(addDrumDrawerFormStages.sealedDrumEvidence);
      handleNavigateToOtherStage(getValues, reset, canEdit);
    },
  };
};

type DrumDrawerFormStageNavigator = ReturnType<typeof getNavigateToStage>;

const drumNumbersValidation = getOptionalInputLength(
  3,
  s.FormValidationError_MinimumLengthError.replace('{{length}}', '3'),
  30,
  s.FormValidationError_MaximumLengthError.replace('{{length}}', '30'),
);

const weightFieldValidation = getOptionalInputValue(
  0.1,
  s.FormValidationError_MinimumValueError.replace('{{value}}', '0.1'),
  999,
  s.FormValidationError_MaximumValueError.replace('{{value}}', '999'),
);

const pilesFieldValidation = z
  .string()
  .array()
  .refine((value) => value.length >= 1, s.FormValidationError_FieldRequired)
  .transform((value) => {
    const transformed = value.map((e) => parseInt(e));
    return transformed;
  });

const drumPhotoEvidenceFieldsValidation = z.string().array().optional();

const addDrumMainFormStateSchema = z.object({
  referenceNumber: drumNumbersValidation,
  robinsonSealNumber: drumNumbersValidation,
  coprocoSealNumber: drumNumbersValidation,
  grossWeight: weightFieldValidation,
  netWeight: weightFieldValidation,
  pileIds: pilesFieldValidation,
  emptyDrumPhotoUrl: drumPhotoEvidenceFieldsValidation,
  filledDrumPhotoUrl: drumPhotoEvidenceFieldsValidation,
  sealedDrumPhotoUrl: drumPhotoEvidenceFieldsValidation,
});

export type AddDrumFormState = z.infer<typeof addDrumMainFormStateSchema>;

export const addDrumFormFieldNames: FormFieldNames<Required<AddDrumFormState>> = {
  referenceNumber: 'referenceNumber',
  robinsonSealNumber: 'robinsonSealNumber',
  coprocoSealNumber: 'coprocoSealNumber',
  grossWeight: 'grossWeight',
  netWeight: 'netWeight',
  pileIds: 'pileIds',
  emptyDrumPhotoUrl: 'emptyDrumPhotoUrl',
  filledDrumPhotoUrl: 'filledDrumPhotoUrl',
  sealedDrumPhotoUrl: 'sealedDrumPhotoUrl',
} as const;

const addDrumMainFormId = 'addDrumMain';
const emptyDrumEvidenceFormId = 'emptyDrumEvidence';
const filledDrumEvidenceFormId = 'filledDrumEvidence';
const sealedDrumEvidenceFormId = 'sealedDrumEvidence';

const getFormStage = (
  stage: number,
  shipmentId: string,
  piles: Pile[],
  selectedPileIds: number[],
  pileIdsAwaitingValidation: boolean,
  drum: Drum | null,
  onSaveSuccess: () => Promise<void>,
  onSaveFailed: (error: unknown) => Promise<void>,
  onClose: () => void,
  navigateToStage: DrumDrawerFormStageNavigator,
  canEdit: boolean,
  handleSelectedPiles: (ids: number[]) => void,
  emptyDrumPhotoManager: UseSingleDocumentInputCacheManagerReturn,
  filledDrumPhotoManager: UseSingleDocumentInputCacheManagerReturn,
  sealedDrumPhotoManager: UseSingleDocumentInputCacheManagerReturn,
  control: Control<AddDrumFormState>,
  handleSubmit: UseFormHandleSubmit<AddDrumFormState>,
  register: UseFormRegister<AddDrumFormState>,
  getValues: UseFormGetValues<AddDrumFormState>,
  selectedPilesMainFormFieldIsDirty: boolean,
  emptyEvidencePendingDeletion: SingleDocumentInputCacheDocumentPendingDeletion,
  filledEvidencePendingDeletion: SingleDocumentInputCacheDocumentPendingDeletion,
  sealedEvidencePendingDeletion: SingleDocumentInputCacheDocumentPendingDeletion,
  emptyEvidenceDelete: SingleDocumentInputCacheHandleDelete,
  filledEvidenceDelete: SingleDocumentInputCacheHandleDelete,
  sealedEvidenceDelete: SingleDocumentInputCacheHandleDelete,
  emptyEvidenceStagedForUpload: SingleDocumentInputCacheDocumentStagedForUpload,
  filledEvidenceStagedForUpload: SingleDocumentInputCacheDocumentStagedForUpload,
  sealedEvidenceStagedForUpload: SingleDocumentInputCacheDocumentStagedForUpload,
  uploadEmptyEvidence: SingleDocumentInputCacheUpload,
  uploadFilledEvidence: SingleDocumentInputCacheUpload,
  uploadSealedEvidence: SingleDocumentInputCacheUpload,
  onCompletedAll: () => void,
  setIsSaving: (isSaving: boolean) => void,
  isSaving: boolean,
  isLoadingDocumentInfo: boolean,
  checkedForExistingDocument: boolean,
  existingDocumentsInfo: Document[] | null,
  // setArePhotoUploadsPending: (arePhotosUploading: boolean | null) => void,
  setPendingPhotoUploadsCount: (count: number) => void,
): JSX.Element => {
  switch (stage) {
    case addDrumDrawerFormStages.addDrumMain:
      return (
        <DrumDrawerFormAddDrumMain
          title={drum ? s.DrumDrawer_EditTitle : s.DrumDrawer_AddTitle}
          key={addDrumDrawerFormStages.addDrumMain}
          shipmentId={shipmentId}
          piles={piles}
          selectedPileIds={selectedPileIds}
          pileIdsAwaitingValidation={pileIdsAwaitingValidation}
          drum={drum}
          onSaveSuccess={onSaveSuccess}
          onSaveFailed={onSaveFailed}
          onClose={onClose}
          onPilesClick={navigateToStage[addDrumDrawerFormStages.selectPiles]}
          onEmptyDrumPhotoClick={navigateToStage[addDrumDrawerFormStages.emptyDrumEvidence]}
          onFilledDrumPhotoClick={navigateToStage[addDrumDrawerFormStages.filledDrumEvidence]}
          onSealedDrumPhotoClick={navigateToStage[addDrumDrawerFormStages.sealedDrumEvidence]}
          canEdit={canEdit}
          formAccessibilityTitle={s.DrumDrawer_AddDrumDrawerAccessibleFormTitle}
          formId={addDrumMainFormId}
          control={control}
          handleSubmit={handleSubmit}
          register={register}
          getValues={getValues}
          emptyEvidencePendingDeletion={emptyEvidencePendingDeletion}
          filledEvidencePendingDeletion={filledEvidencePendingDeletion}
          sealedEvidencePendingDeletion={sealedEvidencePendingDeletion}
          emptyEvidenceDelete={emptyEvidenceDelete}
          filledEvidenceDelete={filledEvidenceDelete}
          sealedEvidenceDelete={sealedEvidenceDelete}
          emptyEvidenceStagedForUpload={emptyEvidenceStagedForUpload}
          filledEvidenceStagedForUpload={filledEvidenceStagedForUpload}
          sealedEvidenceStagedForUpload={sealedEvidenceStagedForUpload}
          uploadEmptyEvidence={uploadEmptyEvidence}
          uploadFilledEvidence={uploadFilledEvidence}
          uploadSealedEvidence={uploadSealedEvidence}
          onCompletedAll={onCompletedAll}
          setIsSaving={setIsSaving}
          isSaving={isSaving}
          // setArePhotoUploadsPending={setArePhotoUploadsPending}
          setPendingPhotoUploadsCount={setPendingPhotoUploadsCount}
        />
      );
    case addDrumDrawerFormStages.selectPiles:
      return (
        <DrumDrawerFormSelectPiles
          title={s.DrumDrawer_PilesTitle}
          key={addDrumDrawerFormStages.selectPiles}
          shipmentId={shipmentId}
          piles={piles}
          drum={drum}
          selectedPiles={selectedPileIds}
          onSelectedPiles={handleSelectedPiles}
          onBack={navigateToStage[addDrumDrawerFormStages.addDrumMain]}
          canEdit={canEdit}
          selectedPilesMainFormFieldIsDirty={selectedPilesMainFormFieldIsDirty}
        />
      );
    case addDrumDrawerFormStages.emptyDrumEvidence:
      return (
        <DrumDrawerFormAddPhotoEvidence
          title={s.DrumDrawer_EmptyDrumTitle}
          key={addDrumDrawerFormStages.emptyDrumEvidence}
          photoManager={emptyDrumPhotoManager}
          backOnClick={navigateToStage[addDrumDrawerFormStages.addDrumMain]}
          formId={emptyDrumEvidenceFormId}
          previewAlternativeText={s.DrumDrawer_EmptyDrumPhotoThumbnailAlternateText}
          photoInputAriaLabel={s.DrumDrawer_EmptyDrumPhotoInputAriaLabel}
          photoButtonAriaLabel={s.DrumDrawer_EmptyDrumPhotoCameraButtonAriaLabel}
          formAccessibilityTitle={s.DrumDrawer_EmptyDrumPhotoFormAccessibilityTitle}
          backButtonAriaLabel={s.DrumDrawer_BackButtonAriaLabel}
          drum={drum}
          isExistingPhoto={!!drum?.hasEmptyPhoto}
          isLoadingDocumentInfo={isLoadingDocumentInfo}
          checkedForExistingDocument={checkedForExistingDocument}
          existingPhotoInfo={existingDocumentsInfo?.find((d) => d.documentType.id === documentTypes.PhotoEmptyDrum)}
          canEdit={canEdit}
        />
      );
    case addDrumDrawerFormStages.filledDrumEvidence:
      return (
        <DrumDrawerFormAddPhotoEvidence
          title={s.DrumDrawer_FilledDrumTitle}
          key={addDrumDrawerFormStages.filledDrumEvidence}
          photoManager={filledDrumPhotoManager}
          backOnClick={navigateToStage[addDrumDrawerFormStages.addDrumMain]}
          formId={filledDrumEvidenceFormId}
          previewAlternativeText={s.DrumDrawer_FilledDrumPhotoThumbnailAlternateText}
          photoInputAriaLabel={s.DrumDrawer_FilledDrumPhotoInputAriaLabel}
          photoButtonAriaLabel={s.DrumDrawer_FilledDrumPhotoCameraButtonAriaLabel}
          formAccessibilityTitle={s.DrumDrawer_FilledDrumPhotoFormAccessibilityTitle}
          backButtonAriaLabel={s.DrumDrawer_BackButtonAriaLabel}
          drum={drum}
          isExistingPhoto={!!drum?.hasFilledPhoto}
          isLoadingDocumentInfo={isLoadingDocumentInfo}
          checkedForExistingDocument={checkedForExistingDocument}
          existingPhotoInfo={existingDocumentsInfo?.find((d) => d.documentType.id === documentTypes.PhotoFilledDrum)}
          canEdit={canEdit}
        />
      );
    case addDrumDrawerFormStages.sealedDrumEvidence:
      return (
        <DrumDrawerFormAddPhotoEvidence
          title={s.DrumDrawer_SealedDrumTitle}
          key={addDrumDrawerFormStages.sealedDrumEvidence}
          photoManager={sealedDrumPhotoManager}
          backOnClick={navigateToStage[addDrumDrawerFormStages.addDrumMain]}
          formId={sealedDrumEvidenceFormId}
          previewAlternativeText={s.DrumDrawer_SealedDrumPhotoThumbnailAlternateText}
          photoInputAriaLabel={s.DrumDrawer_SealedDrumPhotoInputAriaLabel}
          photoButtonAriaLabel={s.DrumDrawer_SealedDrumButtonAriaLabel}
          formAccessibilityTitle={s.DrumDrawer_SealedDrumFormAccessibilityTitle}
          backButtonAriaLabel={s.DrumDrawer_SealedDrumPhotoBackButtonAriaLabel}
          drum={drum}
          isExistingPhoto={!!drum?.hasSealedPhoto}
          isLoadingDocumentInfo={isLoadingDocumentInfo}
          checkedForExistingDocument={checkedForExistingDocument}
          existingPhotoInfo={existingDocumentsInfo?.find((d) => d.documentType.id === documentTypes.PhotoSealedDrum)}
          canEdit={canEdit}
        />
      );
    default:
      return <></>;
  }
};

export interface AddDrumDrawerFormStageManagerProps {
  shipmentId: string;
  piles: Pile[];
  drum: Drum | null;
  onSaveSuccess: () => Promise<void>;
  onSaveFailed: (error: unknown) => Promise<void>;
  onClose: () => void;
  canEdit: boolean;
}

export const AddDrumDrawerFormStageManager = ({
  shipmentId,
  piles,
  drum,
  onSaveSuccess,
  onSaveFailed,
  onClose,
  canEdit,
}: AddDrumDrawerFormStageManagerProps): JSX.Element => {
  const accessToken = useAccessToken();
  const { handleServerError } = useServerError();

  const [stage, setStage] = useState<AddDrumDrawerFormStage>(addDrumDrawerFormStages.addDrumMain);
  const [successfulUploadCount, setSuccessfulUploadCount] = useState<number>(0);
  const [isSaving, setIsSaving] = useState(false);
  const [checkedForExistingDocuments, setCheckedForExistingDocuments] = useState(false);
  const [isLoadingDocumentInfo, setIsLoadingDocumentInfo] = useState(false);
  const [existingDocumentsInfo, setExistingDocumentsInfo] = useState<Document[] | null>(null);
  // const [arePhotoUploadsPending, setArePhotoUploadsPending] = useState<boolean | null>(null);
  const [pendingPhotoUploadsCount, setPendingPhotoUploadsCount] = useState<number>(0);

  const onCompletedAll = useCallback(
    async (error?: unknown | null) => {
      if (error) {
        console.error(error);
        await onSaveFailed(error);
      } else {
        await onSaveSuccess();
        return;
      }
      setIsSaving(false);
    },
    [onSaveFailed, onSaveSuccess, setIsSaving],
  );

  const onPhotoEvidenceUploadSuccess = useCallback(async () => {
    if (successfulUploadCount === pendingPhotoUploadsCount - 1) {
      // all photos have now completed successfully
      await onCompletedAll();
      return;
    }
    setSuccessfulUploadCount((prev) => prev + 1);
  }, [successfulUploadCount, pendingPhotoUploadsCount, onCompletedAll]);

  const onPhotoEvidenceUploadError = useCallback(
    async (error: unknown) => {
      console.error(error);
      await onCompletedAll(error);
    },
    [onCompletedAll],
  );

  const emptyDrumPhotoManager = useSingleDocumentInputCache(onPhotoEvidenceUploadSuccess, onPhotoEvidenceUploadError);
  const filledDrumPhotoManager = useSingleDocumentInputCache(onPhotoEvidenceUploadSuccess, onPhotoEvidenceUploadError);
  const sealedDrumPhotoManager = useSingleDocumentInputCache(onPhotoEvidenceUploadSuccess, onPhotoEvidenceUploadError);

  const [selectedPileIds, setSelectedPileIds] = useState<number[]>(drum ? drum.pileIds : []);
  const [pileIdsAwaitingValidation, setPileIdsAwaitingValidation] = useState(false);

  const {
    control,
    handleSubmit,
    register,
    setValue,
    reset,
    getValues,
    formState: {
      dirtyFields: { [addDrumFormFieldNames.pileIds]: selectedPilesMainFormFieldIsDirty },
    },
  } = useForm<AddDrumFormState>({
    defaultValues: {
      [addDrumFormFieldNames.referenceNumber]: drum?.referenceNumber || '',
      [addDrumFormFieldNames.robinsonSealNumber]: drum?.robinsonSealNumber || '',
      [addDrumFormFieldNames.coprocoSealNumber]: drum?.coprocoSealNumber || '',
      [addDrumFormFieldNames.grossWeight]: drum?.grossWeightInKg || 0,
      [addDrumFormFieldNames.netWeight]: drum?.netWeightInKg || 0,
      [addDrumFormFieldNames.pileIds]: drum?.pileIds ? drum.pileIds : [],
      [addDrumFormFieldNames.emptyDrumPhotoUrl]: drum?.hasEmptyPhoto ? [trueString] : [],
      [addDrumFormFieldNames.filledDrumPhotoUrl]: drum?.hasFilledPhoto ? [trueString] : [],
      [addDrumFormFieldNames.sealedDrumPhotoUrl]: drum?.hasSealedPhoto ? [trueString] : [],
    },
    resolver: zodResolver(addDrumMainFormStateSchema),
    shouldUnregister: true,
  });

  // Handle discovery of existing documents.
  useEffect(() => {
    (async () => {
      if (!drum || (!drum.hasEmptyPhoto && !drum.hasFilledPhoto && !drum.hasSealedPhoto) || !accessToken) {
        // There is no existing drum, or neither of the photos, so no existing documents exist.
        return;
      }

      if (checkedForExistingDocuments) {
        // Existing documents do exist, however they have already been found, nothing left to do here.
        return;
      }

      setIsLoadingDocumentInfo(true);

      try {
        // Existing documents exist, and at least one has not been checked.
        const documents = await drumsService.getDocuments(accessToken, drum.id);
        if (!documents?.length) {
          // There is an existing drum, but no found existing documents. This is an error as this drum indicated that it one or both photos.
          // TODO: Handle this error.
          console.error(s.DrumDrawer_ErrorLocatingExistingDocuments);
          setIsLoadingDocumentInfo(false);
          setCheckedForExistingDocuments(true);
          return;
        }
        setExistingDocumentsInfo(documents);
        setIsLoadingDocumentInfo(false);
        setCheckedForExistingDocuments(true);
      } catch (error) {
        handleServerError(error);
        // TODO: Raise to UI.
        setIsLoadingDocumentInfo(false);
        setCheckedForExistingDocuments(true);
      }
    })();
  }, [
    accessToken,
    checkedForExistingDocuments,
    drum,
    emptyDrumPhotoManager,
    filledDrumPhotoManager,
    sealedDrumPhotoManager,
    handleServerError,
  ]);

  // TODO: factor out re-used logic.
  useEffect(() => {
    // Handle setting the main form values for empty drum photo.
    if (!emptyDrumPhotoManager.documentStagedForUpload) {
      return;
    }
    // There is a photo set for empty drum, so set the main form to reflect this.
    emptyDrumPhotoManager?.documentURL &&
      setValue(addDrumFormFieldNames.emptyDrumPhotoUrl, [emptyDrumPhotoManager.documentURL], { shouldDirty: true });
  }, [emptyDrumPhotoManager.documentStagedForUpload, emptyDrumPhotoManager.documentURL, setValue]);

  // TODO: factor out re-used logic.
  useEffect(() => {
    // Handle setting the main form values for filled drum photo.
    if (!filledDrumPhotoManager.documentStagedForUpload) {
      return;
    }
    // There is a photo set for filled drum, so set the main form to reflect this.
    filledDrumPhotoManager?.documentURL &&
      setValue(addDrumFormFieldNames.filledDrumPhotoUrl, [filledDrumPhotoManager.documentURL], { shouldDirty: true });
  }, [filledDrumPhotoManager.documentStagedForUpload, filledDrumPhotoManager.documentURL, setValue]);

  useEffect(() => {
    // Handle setting the main form values for sealed drum photo.
    if (!sealedDrumPhotoManager.documentStagedForUpload) {
      return;
    }
    // There is a photo set for sealed drum, so set the main form to reflect this.
    sealedDrumPhotoManager?.documentURL &&
      setValue(addDrumFormFieldNames.sealedDrumPhotoUrl, [sealedDrumPhotoManager.documentURL], { shouldDirty: true });
  }, [sealedDrumPhotoManager.documentStagedForUpload, sealedDrumPhotoManager.documentURL, setValue]);

  useEffect(() => {
    // Handle destage empty drum photo after the remove button had been clicked.
    if (!emptyDrumPhotoManager.wasEverExistingDocument || emptyDrumPhotoManager.documentURL) {
      // There was never any empty photo set, so do not proceed, but also, if there was, and there is still document URL, then there is no indication to remove anything, so do noting.
      return;
    }
    // The photo has indeed been removed from the cache, so now we need to update the main form to reflect this.
    setValue(addDrumFormFieldNames.emptyDrumPhotoUrl, [], { shouldDirty: true });
  }, [emptyDrumPhotoManager.documentURL, emptyDrumPhotoManager.wasEverExistingDocument, setValue]);

  useEffect(() => {
    // Handle destage filled drum photo after the remove button had been clicked.
    if (!filledDrumPhotoManager.wasEverExistingDocument || filledDrumPhotoManager.documentURL) {
      // There was never any filled photo set, so do not proceed, but also, if there was, and there is still document URL, then there is no indication to remove anything, so do noting.
      return;
    }
    // The photo has indeed been removed from the cache, so now we need to update the main form to reflect this.
    setValue(addDrumFormFieldNames.filledDrumPhotoUrl, [], { shouldDirty: true });
  }, [filledDrumPhotoManager.documentURL, filledDrumPhotoManager.wasEverExistingDocument, setValue]);

  useEffect(() => {
    // Handle destage sealed drum photo after the remove button had been clicked.
    if (!sealedDrumPhotoManager.wasEverExistingDocument || sealedDrumPhotoManager.documentURL) {
      // There was never any sealed photo set, so do not proceed, but also, if there was, and there is still document URL, then there is no indication to remove anything, so do noting.
      return;
    }
    // The photo has indeed been removed from the cache, so now we need to update the main form to reflect this.
    setValue(addDrumFormFieldNames.sealedDrumPhotoUrl, [], { shouldDirty: true });
  }, [sealedDrumPhotoManager.documentURL, sealedDrumPhotoManager.wasEverExistingDocument, setValue]);

  const handleSelectedPiles = useCallback(
    (ids: number[]) => {
      setSelectedPileIds(ids);
      setPileIdsAwaitingValidation(true);
      setValue(addDrumFormFieldNames.pileIds, ids, { shouldDirty: true });
    },
    [setValue],
  );

  return (
    <>
      {getFormStage(
        stage,
        shipmentId,
        piles,
        selectedPileIds,
        pileIdsAwaitingValidation,
        drum,
        onSaveSuccess,
        onSaveFailed,
        onClose,
        getNavigateToStage(setStage, getValues, reset, canEdit),
        canEdit,
        handleSelectedPiles,
        emptyDrumPhotoManager,
        filledDrumPhotoManager,
        sealedDrumPhotoManager,
        control,
        handleSubmit,
        register,
        getValues,
        !!selectedPilesMainFormFieldIsDirty,
        emptyDrumPhotoManager.documentPendingDeletion,
        filledDrumPhotoManager.documentPendingDeletion,
        sealedDrumPhotoManager.documentPendingDeletion,
        emptyDrumPhotoManager.handleDeleteDocument,
        filledDrumPhotoManager.handleDeleteDocument,
        sealedDrumPhotoManager.handleDeleteDocument,
        emptyDrumPhotoManager.documentStagedForUpload,
        filledDrumPhotoManager.documentStagedForUpload,
        sealedDrumPhotoManager.documentStagedForUpload,
        emptyDrumPhotoManager.uploadDocument,
        filledDrumPhotoManager.uploadDocument,
        sealedDrumPhotoManager.uploadDocument,
        onCompletedAll,
        setIsSaving,
        isSaving,
        isLoadingDocumentInfo,
        checkedForExistingDocuments,
        existingDocumentsInfo,
        // setArePhotoUploadsPending,
        setPendingPhotoUploadsCount,
      )}
    </>
  );
};
