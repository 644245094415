import { useState, useEffect, useCallback, useMemo } from 'react';
import { useForm, SubmitHandler } from 'react-hook-form';

import { BagWrite } from 'models/bag';
import { documentTypes } from 'models/document';
import { bagsService } from 'services/bagsService';
import { useAccessToken, useServerError } from 'hooks';
import { useSingleDocumentInputCache } from 'hooks/useSingleDocumentInputCache';

import { s } from 'i18n/strings';

import { display, flexDirection, width, gridTemplateColumns, padding, gap, typedUtilityClassnames } from 'style/compoundClassnames';

import { _DrawerFormBase } from 'components/drawer-forms/_base';
import { PhotoInput, ControlledTextInput, UncontrolledTextInput } from 'components/form-components';
import { Spinner } from 'components/Spinner';

import { z } from 'zod';
import { zodResolver } from '@hookform/resolvers/zod';
import {
  getOptionalInputValue,
  getRequiredInputValue,
  optionalInputValueTransform,
  requiredInputValueTransform,
  inputValueOnChangeValidator,
} from 'utilities/formValidation/numberValidation';
import { getRequiredInputLength } from 'utilities/formValidation/stringValidation';

import type { Bag } from 'models/bag';
import type { FormFieldNames, ExclusivelyStringKeyedRecordAsNonOptional } from 'types/utility-types';

const formTextInputItemClassNames = typedUtilityClassnames(display('flex'), flexDirection('flex-col'));
const weightFieldsInputAndLabelContainerSharedClassNames = typedUtilityClassnames(width('w-full'));
const weightFieldsInputContainerClassNames = typedUtilityClassnames(
  display('grid'),
  gridTemplateColumns('grid-cols-2'),
  gap('gap-8'),
  width('w-full'),
);
const bagDrawerFormClassNames = typedUtilityClassnames(width('w-inherit'), padding('px-6'));

const bagStateSchema = z.object({
  bagId: getRequiredInputLength(
    s.FormValidationError_FieldRequired,
    3,
    s.FormValidationError_MinimumLengthError.replace('{{length}}', '3'),
    20,
    s.FormValidationError_MaximumLengthError.replace('{{length}}', '20'),
  ),
  expectedWeight: getRequiredInputValue(
    s.FormValidationError_FieldRequired,
    0.1,
    s.FormValidationError_MinimumValueError.replace('{{value}}', '0.1'),
    999,
    s.FormValidationError_MaximumValueError.replace('{{value}}', '999'),
  ),
  actualWeight: getOptionalInputValue(
    0.1,
    s.FormValidationError_MinimumValueError.replace('{{value}}', '0.1'),
    999,
    s.FormValidationError_MaximumValueError.replace('{{value}}', '999'),
  ),
});

const isErrorsEmpty = (errors: Record<string, unknown>) =>
  errors && Object.keys(errors).length === 0 && Object.getPrototypeOf(errors) === Object.prototype;

type BagFormState = z.infer<typeof bagStateSchema>;

interface BagFormWithPhotoState extends BagFormState {
  bagPhoto: FileList;
}

export const bagWithPhotoStateFieldNames: ExclusivelyStringKeyedRecordAsNonOptional<FormFieldNames<BagFormWithPhotoState>> = {
  bagId: 'bagId',
  expectedWeight: 'expectedWeight',
  actualWeight: 'actualWeight',
  bagPhoto: 'bagPhoto',
} as const;

const bagFormId = 'bagForm';

export interface BagDrawerFormProps {
  truckId: number;
  onSaveSuccess: () => Promise<void>;
  onSaveFailed: (error: unknown) => Promise<void>;
  onClose: () => Promise<void>;
  bag: Bag | null;
  canEdit: boolean;
}

export const BagDrawerForm = ({ truckId, onSaveSuccess, onSaveFailed, onClose, bag, canEdit }: BagDrawerFormProps): JSX.Element => {
  const accessToken = useAccessToken();
  const { handleServerError } = useServerError();

  const [isSaving, setIsSaving] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [checkedForExistingDocument, setCheckedForExistingDocument] = useState(false);
  const [photoAwaitingStaging, setPhotoAwaitingStaging] = useState(false);
  const [documentRetrievalError, setDocumentRetrievalError] = useState<string | null>(null);

  const onCompletedAll = useCallback(
    (error?: unknown | null) => {
      setIsSaving(false);
      if (error) {
        console.error(error);
        onSaveFailed(error);
      } else {
        onSaveSuccess();
      }
    },
    [onSaveFailed, onSaveSuccess],
  );

  const onUploadSuccess = useCallback(() => {
    onCompletedAll();
  }, [onCompletedAll]);

  const onUploadError = useCallback(
    (error: unknown) => {
      onCompletedAll(error);
    },
    [onCompletedAll],
  );

  const {
    setValidatedDocumentFromInput,
    documentValidatorDefinition,
    documentURL,
    setExistingDocument,
    uploadDocument,
    documentStagedForUpload,
    handleDestageDocument,
    handleDownloadDocument,
    documentPendingDeletion,
    handleDeleteDocument,
    wasEverExistingDocument,
    documentDownloadError,
  } = useSingleDocumentInputCache(onUploadSuccess, onUploadError);

  const bagStateWithPhotoSchema = useMemo(
    () => bagStateSchema.merge(z.object({ [bagWithPhotoStateFieldNames.bagPhoto]: documentValidatorDefinition })),
    [documentValidatorDefinition],
  );

  const {
    register,
    formState: {
      errors,
      errors: {
        [bagWithPhotoStateFieldNames.bagPhoto]: bagPhotoInputError,
        [bagWithPhotoStateFieldNames.bagId]: bagIdError,
        [bagWithPhotoStateFieldNames.expectedWeight]: bagExpectedWeightError,
        [bagWithPhotoStateFieldNames.actualWeight]: bagActualWeightError,
      },
      isDirty,
      dirtyFields: {
        [bagWithPhotoStateFieldNames.bagId]: bagIdIsDirty,
        [bagWithPhotoStateFieldNames.expectedWeight]: bagExpectedWeightIsDirty,
        [bagWithPhotoStateFieldNames.actualWeight]: bagActualWeightIsDirty,
      },
      isValidating,
    },
    handleSubmit,
    clearErrors,
    getValues,
    reset,
    trigger,
    control,
    setValue,
  } = useForm<BagFormWithPhotoState>({
    resolver: zodResolver(bagStateWithPhotoSchema),
    defaultValues: {
      [bagWithPhotoStateFieldNames.bagId]: bag?.itsciSealNumber ?? '',
      [bagWithPhotoStateFieldNames.expectedWeight]: bag?.mineWeightInKg || 0,
      [bagWithPhotoStateFieldNames.actualWeight]: bag?.warehouseWeightInKg || 0,
    },
  });

  useEffect(() => {
    const emptyFileList = getValues(bagWithPhotoStateFieldNames.bagPhoto);
    reset({ ...getValues(), [bagWithPhotoStateFieldNames.bagPhoto]: emptyFileList });
  }, [getValues, reset]);

  // Handles setting photo from input.
  useEffect(() => {
    (async () => {
      if (!photoAwaitingStaging) {
        return;
      }
      const bagPhotoInputValue = getValues(bagWithPhotoStateFieldNames.bagPhoto);
      if (!bagPhotoInputValue || bagPhotoInputValue.length === 0) {
        return;
      }

      if (!isValidating && !bagPhotoInputError) {
        setValidatedDocumentFromInput(bagPhotoInputValue[0]);
        clearErrors(bagWithPhotoStateFieldNames.bagPhoto);
      }
      setPhotoAwaitingStaging(false);
    })();
  }, [bagPhotoInputError, clearErrors, getValues, isValidating, photoAwaitingStaging, setValidatedDocumentFromInput]);

  // Handles checking if and existing document exists
  useEffect(() => {
    (async () => {
      if (checkedForExistingDocument) {
        return;
      }

      try {
        // TODO: factor out accessToken null checking into a base API class to run at every call, then remove access token null checking from all components
        if (!accessToken) {
          return;
        }

        if (!bag) {
          // This is a new bag, so nothing to be loaded
          setCheckedForExistingDocument(true);
          return;
        }

        const documents = await bagsService.getDocuments(accessToken, bag.id);
        if (!documents?.length) {
          // There is an existing bag, but no existing photo, so nothing to load
          setCheckedForExistingDocument(true);
          return;
        }

        // There is an existing bag, and an existing photo, so indicate that it must be found.
        const document = documents[0];
        setExistingDocument(document);
        setCheckedForExistingDocument(true);
      } catch (error) {
        const errorMessage = handleServerError(error);
        setDocumentRetrievalError(errorMessage);
      } finally {
        setIsLoading(false);
      }
    })();
  }, [accessToken, bag, checkedForExistingDocument, documentURL, handleServerError, setExistingDocument, truckId]);

  const onSubmit: SubmitHandler<BagFormWithPhotoState> = async ({ bagId, actualWeight, expectedWeight }) => {
    try {
      setIsSaving(true);

      if (bagIdIsDirty || bagActualWeightIsDirty || bagExpectedWeightIsDirty) {
        // TODO: factor out accessToken null checking into a base API class to run at every call, then remove access token null checking from all components
        if (!accessToken) {
          return;
        }

        const newBag = new BagWrite(truckId, bagId, expectedWeight);
        if (actualWeight) {
          newBag.warehouseWeightInKg = actualWeight;
        }
        if (bag) {
          await bagsService.update(accessToken, bag.id, newBag);
        } else {
          bag = await bagsService.create(accessToken, newBag);
        }
      }

      // Handles where an existing photo has been removed
      documentPendingDeletion && (await handleDeleteDocument());
      if (!documentStagedForUpload) {
        // Handles the case where a new bag is created without a photo attached.
        onCompletedAll();
        return;
      }

      // Handles where a photo has been added
      bag && documentStagedForUpload && (await uploadDocument(bag.id, documentTypes.PhotoBagSeal));
    } catch (error) {
      onCompletedAll(error);
    }
  };

  const handleDestagePhoto = useCallback(() => {
    handleDestageDocument();
    const newValue = getValues(bagWithPhotoStateFieldNames.bagPhoto);
    // Handles unsetting an existing photo, to ensure the form can be submitted
    setValue(bagWithPhotoStateFieldNames.bagPhoto, newValue, { shouldDirty: !!wasEverExistingDocument });
  }, [getValues, handleDestageDocument, setValue, wasEverExistingDocument]);

  const photoInputOnChange = useCallback(() => {
    setPhotoAwaitingStaging(true);
    trigger(bagWithPhotoStateFieldNames.bagPhoto);
  }, [setPhotoAwaitingStaging, trigger]);

  const disallowEdit = !canEdit || isSaving;

  return (
    <_DrawerFormBase
      formId={bagFormId}
      handleSubmit={handleSubmit(onSubmit)}
      primarySubmitDisabled={disallowEdit || !isErrorsEmpty(errors) || !isDirty}
      formClassNames={bagDrawerFormClassNames}
      title={bag ? s.BagDrawer_EditBagDrawerFormTitle : s.BagDrawer_AddBagDrawerFormTitle}
      closeOnClick={onClose}
      formAccessibilityTitle={bag ? s.BagDrawer_EditBagDrawerAccessibleFormTitle : s.BagDrawer_AddBagDrawerAccessibleFormTitle}
      closeButtonAriaLabel={s.BagDrawer_CloseDrawerIconButtonAriaLabel}
    >
      <UncontrolledTextInput<BagFormWithPhotoState, typeof bagWithPhotoStateFieldNames.bagId>
        textInputAndLabelContainerClassNames={formTextInputItemClassNames}
        readOnly={disallowEdit}
        label={s.BagDrawer_BagId}
        {...register(bagWithPhotoStateFieldNames.bagId, { disabled: disallowEdit })}
        hasError={!!bagIdError}
        errors={errors}
        isRequired={true}
      />
      <div className={weightFieldsInputContainerClassNames}>
        <ControlledTextInput<BagFormWithPhotoState, typeof bagWithPhotoStateFieldNames.expectedWeight>
          name={bagWithPhotoStateFieldNames.expectedWeight}
          readOnly={disallowEdit}
          onChangeValidator={inputValueOnChangeValidator}
          transform={requiredInputValueTransform}
          label={s.BagDrawer_ExpectedWeight}
          textInputAndLabelContainerClassNames={weightFieldsInputAndLabelContainerSharedClassNames}
          hasError={!!bagExpectedWeightError}
          errors={errors}
          control={control}
          isRequired={true}
        />
        <ControlledTextInput<BagFormWithPhotoState, typeof bagWithPhotoStateFieldNames.actualWeight>
          name={bagWithPhotoStateFieldNames.actualWeight}
          readOnly={disallowEdit}
          onChangeValidator={inputValueOnChangeValidator}
          transform={optionalInputValueTransform}
          label={s.BagDrawer_ActualWeight}
          textInputAndLabelContainerClassNames={weightFieldsInputAndLabelContainerSharedClassNames}
          hasError={!!bagActualWeightError}
          errors={errors}
          control={control}
          isRequired={true}
        />
      </div>
      {isLoading && bag ? (
        <Spinner />
      ) : (
        <>
          <PhotoInput<BagFormWithPhotoState, typeof bagWithPhotoStateFieldNames.bagPhoto>
            {...register(bagWithPhotoStateFieldNames.bagPhoto, {
              onChange: photoInputOnChange,
              disabled: disallowEdit, // TODO: is this needed?
            })}
            photoURL={documentURL}
            label={s.BagDrawer_BagPhoto}
            previewAlternativeText={s.BagDrawer_SelectedPhotoPreviewAlternateText}
            buttonAriaLabel={s.BagDrawer_PhotoInputButtonAriaLabel}
            readOnly={disallowEdit}
            showValidationErrors={!documentURL}
            validationErrors={errors}
            documentStagedForUpload={documentStagedForUpload}
            hasError={documentRetrievalError != null || (documentDownloadError != null && documentDownloadError !== undefined)}
            onRemove={handleDestagePhoto}
            onDownload={handleDownloadDocument}
          />
        </>
      )}
    </_DrawerFormBase>
  );
};
