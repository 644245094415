import { useEffect, useState } from 'react';
import { useParams } from 'react-router';

import { Shipment, shipmentStates } from 'models/shipment';
import { Document, DocumentInfo, documentTypes } from 'models/document';
import { Container, containerStates } from 'models/container';
import { User } from 'models/user';
import { shipmentsService } from 'services/shipmentsService';
import { containerService } from 'services/containerService';
import { permissionsService } from 'services/permissionsService';
import { useAccessToken, useLoggedInUser, useAppNavigation, useServerError } from 'hooks';

import { s } from 'i18n/strings';

import { onlyComputedCombineClassnames, typedUtilityClassnames } from 'style/compoundClassnames';

import { PrimaryButton, SecondaryButton } from 'components/buttons';
import { HeaderItem } from 'components/HeaderItem';
import { ContextHeader } from 'components/headers';
import { FreightForwarderManifestItem, ContainerDrumsManifestItem } from 'components/manifest-items';
import { DocumentItem } from 'components/DocumentItem';
import { Spinner } from 'components/Spinner';
import { ActionConfirmationDialog, FreightForwarderConfirmationDialog } from 'components/dialogs';
import { Notification } from 'components/Notification';

import { ReactComponent as TruckIcon } from 'icons/truck.svg';
import { ReactComponent as DocumentsIcon } from 'icons/documents.svg';
// import { ReactComponent as DrumsIcon } from 'icons/drums.svg';
import { ReactComponent as ContainerIcon } from 'icons/container.svg';

const containerDocuments: DocumentInfo[] = [
  { title: s.ShipmentDocuments_C1FormDocumentItemTitle, type: documentTypes.C1DeclarationForm, required: true },
  { title: s.ShipmentDocuments_FacteurCommercialeDocumentItemTitle, type: documentTypes.FactureCommerciale, required: true },
  { title: s.ShipmentDocuments_ExportLicenceDocumentItemTitle, type: documentTypes.ExportLicence, required: true },
  { title: s.ShipmentDocuments_CommerceExtDocumentItemTitle, type: documentTypes.CommerceExterieur, required: true },
  { title: s.ShipmentDocuments_FicheComptoirDocumentItemTitle, type: documentTypes.FicheComptoir, required: true },
  { title: s.ShipmentDocuments_CGADocumentItemTitle, type: documentTypes.CGA, required: true },
  { title: s.ShipmentDocuments_ContainerPackingListDocumentItemTitle, type: documentTypes.ContainerPackingList, required: true },
  { title: s.ShipmentDocuments_DGDADocumentItemTitle, type: documentTypes.DGDA, required: true },
  { title: s.ShipmentDocuments_AfrimetShiptFileDocumentItemTitle, type: documentTypes.AfrimetShipmentsFile, required: true },
  { title: s.ShipmentDocuments_TruckManifestDocumentItemTitle, type: documentTypes.TruckManifest, required: true },
];

const stringIsNullOrEmpty = (value: string) => value == null || value.length === 0;

const freightForwarderProvided = (container: Container | null) =>
  container != null &&
  !stringIsNullOrEmpty(container.truckLicensePlate) &&
  !stringIsNullOrEmpty(container.trailerLicensePlate) &&
  !stringIsNullOrEmpty(container.driverFirstName) &&
  !stringIsNullOrEmpty(container.driverLastName) &&
  !stringIsNullOrEmpty(container.driverPassportNumber);

const canEditFreightForwarderInformation = (user: User | null, container: Container | null) =>
  user != null && permissionsService.canEditContainer(user) && container != null;

const canEditContainerDocument = (user: User | null, container: Container | null) => {
  if (!user || !container) {
    return false;
  }

  const nonOverrideCondition = permissionsService.canEditContainer(user) && container.state.name !== containerStates.Done;
  const overrideCondition =
    permissionsService.canOverrideContainer(user) &&
    (container.state.name !== containerStates.Done || (container.state.name === containerStates.Done && container.state.isOverridden));

  return nonOverrideCondition || overrideCondition;
};

const canFinishContainerProcessing = (user: User | null, shipment: Shipment | null, container: Container | null) =>
  user != null &&
  permissionsService.canEditContainer(user) &&
  freightForwarderProvided(container) &&
  shipment != null &&
  // (shipment.state.name === shipmentStates.DrumsProcessed || shipment.state.name === shipmentStates.ContainersProcessed);
  (shipment.state.name === shipmentStates.BagsProcessed || shipment.state.name === shipmentStates.ContainersProcessed);

export const ContainerProcessingPage = () => {
  const { shipmentId } = useParams<{ shipmentId: string }>();
  const accessToken = useAccessToken();
  const user = useLoggedInUser();

  const { navigateViewShipment, navigateFreightForwardingInformation, navigateContainerDrums, navigateShipments } = useAppNavigation();
  const { handleServerError } = useServerError();

  const [shipment, setShipment] = useState<Shipment | null>(null);
  const [documents, setDocuments] = useState<Document[] | null>(null);
  const [container, setContainer] = useState<Container | null>(null);
  const [containerState, setContainerState] = useState<string>(containerStates.New);

  const [missingDocuments, setMissingDocuments] = useState<DocumentInfo[]>([]);
  const handleMissingDocuments = (documents: Document[]) => {
    const missingDocuments: DocumentInfo[] = [];
    missingDocuments.push(
      ...containerDocuments.filter((d) => d.required && !documents.map((item) => item.documentType.id).includes(d.type)),
    );
    setMissingDocuments(missingDocuments);
  };

  const [documentUploadStarted, setDocumentUploadStarted] = useState(false);

  const [isLoading, setIsLoading] = useState(true);
  const [isSaveInProgress, setIsSaveInProgress] = useState(false);
  const [error, setError] = useState<string | null>(null);

  const [openActionConfirmationDialog, setOpenActionConfirmationDialog] = useState(false);
  const handleOpenActionConfirmationDialog = () => setOpenActionConfirmationDialog(true);
  const handleCloseActionConfirmationDialog = () => setOpenActionConfirmationDialog(false);

  const [openFreightForwarderConfirmationDialog, setOpenFreightForwarderConfirmationDialog] = useState(false);
  const handleOpenFreightForwarderConfirmationDialog = () => setOpenFreightForwarderConfirmationDialog(true);
  const handleCloseFreightForwarderConfirmationDialog = () => setOpenFreightForwarderConfirmationDialog(false);

  useEffect(() => {
    (async () => {
      // TODO: Refactor out accessToken null checks to shared API client class that will throw if null for any call, then remove this from every component
      if (!accessToken || !shipmentId) {
        return;
      }

      try {
        setIsLoading(true);
        const shipmentRequest = shipmentsService.getById(accessToken, parseInt(shipmentId));
        const containerRequest = shipmentsService.getContainer(accessToken, parseInt(shipmentId));

        const [shipment, container] = await Promise.all([shipmentRequest, containerRequest]);
        if (!shipment || !container) {
          return;
        }

        setShipment(shipment);
        setContainer(container);
        setContainerState(container.state.name);

        const documents = await containerService.getDocuments(accessToken, container.id);
        setDocuments(documents);
        handleMissingDocuments(documents);
      } catch (error) {
        const errorMessage = handleServerError(error);
        setError(`${s.ContainerProcessingPage_RetrievingContainerDetailsError}: ${errorMessage}`);
      } finally {
        setIsLoading(false);
      }
    })();
  }, [accessToken, handleServerError, shipmentId]);

  const handleBack = () => {
    shipmentId ? navigateViewShipment(shipmentId) : navigateShipments();
  };

  const handleFinishContainerProcessingWithOverride = async (isOverridden: boolean, overrideNote: string) => {
    if (!canFinishContainerProcessing(user, shipment, container)) {
      return;
    }

    if (accessToken && shipmentId && shipment && container) {
      try {
        setIsSaveInProgress(true);
        await shipmentsService.finishContainerProcessingWithOverride(accessToken, parseInt(shipmentId), overrideNote);
        navigateViewShipment(shipmentId);
      } catch (error) {
        const errorMessage = handleServerError(error);
        setError(`${s.ContainerProcessingPage_FinishContainerProcessingError}: ${errorMessage}`);
      } finally {
        handleCloseActionConfirmationDialog();
        setIsSaveInProgress(false);
      }
    }
  };

  const handleFinishContainerProcessing = async (confirmedWithFreightForwarder: boolean) => {
    if (!canFinishContainerProcessing(user, shipment, container)) {
      return;
    }

    if (accessToken && shipmentId && shipment && container) {
      try {
        setIsSaveInProgress(true);

        if (container.state.name !== containerStates.Done) {
          await shipmentsService.finishContainerProcessing(accessToken, parseInt(shipmentId));
        }

        if (confirmedWithFreightForwarder && shipment.state.name !== shipmentStates.WithFreightForwarder) {
          await shipmentsService.confirmWithFreightForwarder(accessToken, parseInt(shipmentId));
        }

        navigateViewShipment(shipmentId);
      } catch (error) {
        const errorMessage = handleServerError(error);
        setError(`${s.ContainerProcessingPage_FinishContainerProcessingError}: ${errorMessage}`);
      } finally {
        handleCloseFreightForwarderConfirmationDialog();
        setIsSaveInProgress(false);
      }
    }
  };

  const handleEditFreightForwarderInformation = () => {
    if (canEditFreightForwarderInformation(user, container) && shipmentId) {
      navigateFreightForwardingInformation(shipmentId);
    }
  };

  const handleDocumentUploadStarted = (uploadStarted: boolean) => {
    setDocumentUploadStarted(uploadStarted);
  };

  const handleDocumentUploaded = async () => {
    try {
      if (accessToken && shipmentId) {
        const documents = await shipmentsService.getAllDocuments(accessToken, parseInt(shipmentId));
        if (documents) {
          setDocuments(documents);
          handleMissingDocuments(documents);
        }
      }
    } catch (error) {
      const errorMessage = handleServerError(error);
      setError(`${s.ContainerProcessingPage_GetShipmentDocumentsError}: ${errorMessage}`);
    }
  };

  const handleEditDrums = () => {
    if (shipmentId) {
      navigateContainerDrums(shipmentId);
    }
  };

  const handleFinish = () => {
    if (
      container != null &&
      ((container.state.name === containerStates.Sealed && missingDocuments.length === 0 && freightForwarderProvided(container)) ||
        container.state.name === containerStates.Done)
    ) {
      handleOpenFreightForwarderConfirmationDialog();
    } else {
      handleOpenActionConfirmationDialog();
    }
  };

  return (
    <>
      {isLoading ? (
        <Spinner />
      ) : (
        <>
          <ContextHeader
            contextTitle={s.ContainerProcessingPage_Title}
            contextSubTitle={`${s.ContainerProcessingPage_ContainerStateSubTitle} - ${containerState}`}
            leftButtonOne={<SecondaryButton label={s.ContainerProcessingPage_BackButton} onClick={handleBack} />}
            rightButtonOne={
              <PrimaryButton
                label={s.ContainerProcessingPage_FinishButton}
                onClick={handleFinish}
                disabled={!canFinishContainerProcessing(user, shipment, container)}
              />
            }
            showContextHeaderContentSpacer
          />
          <div className={onlyComputedCombineClassnames(typedUtilityClassnames('mainLayoutPadding'))}>
            <Notification message={error!} severity="error" onClose={() => setError(null)} />
            {!container ? (
              <HeaderItem
                title={s.ContainerProcessingPage_DrumsItemTitle}
                icon={<ContainerIcon />}
                actionButton={
                  <SecondaryButton label={s.ContainerProcessingPage_DrumsItem_EditButton} disabled={false} onClick={handleEditDrums} />
                }
                actionButtonVisible={true}
              />
            ) : (
              <ContainerDrumsManifestItem container={container!} editOnClick={handleEditDrums} />
            )}

            {!freightForwarderProvided(container) ? (
              <HeaderItem
                title={s.ContainerProcessingPage_FreightForwarderInformationItemTitle}
                icon={<TruckIcon />}
                actionButton={
                  <SecondaryButton
                    label={s.ContainerProcessingPage_FreightForwarderInformationItem_EditButton}
                    disabled={!canEditFreightForwarderInformation(user, container)}
                    onClick={handleEditFreightForwarderInformation}
                  />
                }
                actionButtonVisible={true}
              />
            ) : (
              <FreightForwarderManifestItem
                container={container!}
                canEdit={canEditFreightForwarderInformation(user, container)}
                editOnClick={handleEditFreightForwarderInformation}
              />
            )}

            <HeaderItem title={s.ContainerProcessingPage_DocumentsItemTitle} icon={<DocumentsIcon />} />
            {containerDocuments.map((doc, index) => (
              <DocumentItem
                key={index}
                title={doc.title}
                canEdit={canEditContainerDocument(user, container) && !documentUploadStarted}
                shipmentId={shipment?.id}
                entityId={container?.id}
                documentTypeId={doc.type}
                documentRequired={doc.required}
                document={documents && documents.find((d) => d.documentType.id === doc.type)}
                handleUploadStarted={handleDocumentUploadStarted}
                handleUploadStatusChange={handleDocumentUploaded}
              />
            ))}
          </div>
        </>
      )}

      {/* TODO: this dialog will appear only in context of container override */}
      {container && user && (
        <ActionConfirmationDialog
          open={openActionConfirmationDialog}
          title={s.ContainerProcessingPage_ActionConfirmationDialogTitle}
          items={[
            {
              label: s.ContainerProcessingPage_ActionConfirmationDialogContainerStateItemLabel,
              successCondition: container != null && container.state.name === containerStates.Sealed,
              successMessage: s.ContainerProcessingPage_ActionConfirmationDialogContainerStateSuccessMessage,
              errorMessage: s.ContainerProcessingPage_ActionConfirmationDialogContainerStateErrorMessage,
            },
            {
              label:
                missingDocuments.length > 0
                  ? s.ContainerProcessingPage_ActionConfirmationDialogDocumentsMissingItemLabel
                  : s.ContainerProcessingPage_ActionConfirmationDialogDocumentsSuccessMessage,
              successCondition: missingDocuments.length === 0,
              successMessage: s.PilesProcessingPage_ConfirmationDialogDocumentsSuccessMessage,
              errorMessage: missingDocuments.map((d) => d.title).join(', '),
            },
          ]}
          confirmButtonCaption={s.ContainerProcessingPage_ActionConfirmationDialogFinishButtonCaption}
          handleConfirm={handleFinishContainerProcessingWithOverride}
          cancelButtonCaption={s.ContainerProcessingPage_ActionConfirmationDialogCancelButtonCaption}
          handleCancel={handleCloseActionConfirmationDialog}
          allowOverride={permissionsService.canOverrideContainer(user) && container.state.name === containerStates.Sealed}
        />
      )}

      <FreightForwarderConfirmationDialog
        open={openFreightForwarderConfirmationDialog}
        container={container}
        handleConfirm={handleFinishContainerProcessing}
        confirmDisabled={isSaveInProgress}
        handleCancel={handleCloseFreightForwarderConfirmationDialog}
        cancelDisabled={isSaveInProgress}
      />
    </>
  );
};
