import { User, UserRoles } from 'models/user';

export const permissionsService = {
  isGlobalAdmin(user: User) {
    return user.roles.map((r) => r.name).includes(UserRoles.GlobalAdministrator);
  },

  isOfficeAdmin(user: User) {
    return user.roles.map((r) => r.name).includes(UserRoles.OfficeAdministrator);
  },

  isYardForeman(user: User) {
    return user.roles.map((r) => r.name).includes(UserRoles.YardForeman);
  },

  isDrumInspector(user: User) {
    return user.roles.map((r) => r.name).includes(UserRoles.DrumInspector);
  },

  isWarehouseForeman(user: User) {
    return user.roles.map((r) => r.name).includes(UserRoles.WarehouseForeman);
  },

  canEditUser(user: User) {
    return this.isGlobalAdmin(user) || this.isOfficeAdmin(user);
  },

  canAddShipment(user: User) {
    return this.isGlobalAdmin(user) || this.isOfficeAdmin(user);
  },

  canEditShipment(user: User) {
    return this.isGlobalAdmin(user) || this.isOfficeAdmin(user);
  },

  canDeleteShipment(user: User) {
    return this.isGlobalAdmin(user);
  },

  canOverrideShipment(user: User) {
    return this.isGlobalAdmin(user) || this.isOfficeAdmin(user);
  },

  canAddBag(user: User) {
    return this.isGlobalAdmin(user) || this.isYardForeman(user);
  },

  canEditBag(user: User) {
    return this.isGlobalAdmin(user) || this.isYardForeman(user);
  },

  canDeleteBag(user: User) {
    return this.isGlobalAdmin(user) || this.isYardForeman(user);
  },

  canAddTruck(user: User) {
    return this.isGlobalAdmin(user) || this.isYardForeman(user);
  },

  canEditTruck(user: User) {
    return this.isGlobalAdmin(user) || this.isYardForeman(user);
  },

  canOverrideTruck(user: User) {
    return this.isGlobalAdmin(user) || this.isOfficeAdmin(user) || this.isYardForeman(user) || this.isDrumInspector(user);
  },

  canAddDrum(user: User) {
    return this.isGlobalAdmin(user) || this.isDrumInspector(user) || this.isWarehouseForeman(user);
  },

  canEditDrum(user: User) {
    return this.isGlobalAdmin(user) || this.isDrumInspector(user) || this.isWarehouseForeman(user);
  },

  canDeleteDrum(user: User) {
    return this.isGlobalAdmin(user) || this.isDrumInspector(user) || this.isWarehouseForeman(user);
  },

  canAddPile(user: User) {
    return this.isGlobalAdmin(user) || this.isYardForeman(user) || this.isDrumInspector(user);
  },

  canEditPile(user: User) {
    return this.isGlobalAdmin(user) || this.isYardForeman(user) || this.isDrumInspector(user);
  },

  canDeletePile(user: User) {
    return this.isGlobalAdmin(user) || this.isYardForeman(user) || this.isDrumInspector(user);
  },

  canOverridePile(user: User) {
    return this.isGlobalAdmin(user) || this.isOfficeAdmin(user) || this.isDrumInspector(user) || this.isWarehouseForeman(user);
  },

  canAddContainer(user: User) {
    return this.isGlobalAdmin(user) || this.isWarehouseForeman(user);
  },

  canEditContainer(user: User) {
    return this.isGlobalAdmin(user) || this.isOfficeAdmin(user) || this.isWarehouseForeman(user);
  },

  canDeleteContainer(user: User) {
    return this.isGlobalAdmin(user) || this.isWarehouseForeman(user);
  },

  canOverrideContainer(user: User) {
    return this.isGlobalAdmin(user) || this.isOfficeAdmin(user) || this.isWarehouseForeman(user);
  },
};
