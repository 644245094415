import {
  borderWidth,
  display,
  height,
  rotate,
  typedUtilityClassnames,
  width,
  borderColor,
  gridTemplateColumns,
  onlyComputedCombineClassnames,
  gridColumnStart,
  position,
  inset,
  gridColumn,
  zIndex,
  alignItems,
  pointerEvents,
  textColor,
  justifySelf,
  backgroundColor,
  borderRadius,
  cursor,
} from 'style/compoundClassnames';
import { inputTypeNames, _FormInputBase, _FormInputBaseProps } from 'components/form-components/inputs/_base';
import { ExclusivelyStringKeyedRecordWithOptional } from 'utility-types';
import { FieldErrors, FieldName } from 'react-hook-form';
import { FieldValuesFromFieldErrors } from '@hookform/error-message';
import { Ref, useEffect, useRef, useState } from 'react';

import { ReactComponent as CheckedDark } from 'icons/checkedDark.svg';
import { ReactComponent as Plus } from 'icons/plus.svg';
import { ReactComponent as Chevron } from 'icons/chevron.svg';
import { fixedForwardRef } from 'utilities/forwardRefFix';
import { getMessageAsError } from 'components/form-components/FormValidationError';

export interface StageSummaryInputProps<
  TFormState extends ExclusivelyStringKeyedRecordWithOptional<TFormState>,
  TFieldName extends FieldName<FieldValuesFromFieldErrors<FieldErrors<TFormState>>>,
> extends Omit<
    _FormInputBaseProps<TFormState, TFieldName>,
    | 'type'
    | 'ariaLabelOnly'
    | 'value'
    | 'preventLabelClickDefault'
    | 'readOnly'
    | 'isRequired'
    | 'showsErrors'
    | 'errors'
    | 'fileAccept'
    | 'fileAcceptTransform'
    | 'previewAlternativeText'
    | 'photoInputCaptureType'
    | 'labelClassNames'
    | 'errorsDisplayContainerClassNames'
  > {
  onClick: () => void;

  name: TFieldName;
  label: string;
  id: string;
  nonValidSummary: string;
  validSummary: string;

  errors?: FieldErrors<TFormState>;
  hasError?: boolean;
  isDirty: boolean;

  inputElementClassNames?: string;
  textInputAndLabelContainerClassNames?: string;
  values: string[] | undefined;
  isLastInSequence?: boolean;
}

const iconsSharedClassNames = typedUtilityClassnames('icons', gridColumn('col-span-1'));
const leftButtonIconsSharedClassNames = onlyComputedCombineClassnames(
  typedUtilityClassnames(gridColumnStart('col-start-1')),
  iconsSharedClassNames,
  justifySelf('justify-self-center'),
);

const getUniqueIds = (uniqueId: string) => ({
  buttonLabelId: `button-label-${uniqueId}`,
  buttonDescriptionId: `button-description-${uniqueId}`,
});

const textSharedClassNames = typedUtilityClassnames(display('block'));

const getStageSummaryLabelClassNames = (hasError: boolean) =>
  onlyComputedCombineClassnames(typedUtilityClassnames(textSharedClassNames, 'caption'), {
    [textColor('text-onSurface-mediumEmphasis')]: !hasError,
    [textColor('text-error-600')]: hasError,
  });

const getButtonClassNames = (isFirstError: boolean, hasError: boolean) =>
  onlyComputedCombineClassnames(
    {
      [borderColor('border-error-600')]: isFirstError,
      [typedUtilityClassnames('overlayBlackStates')]: !hasError,
      [backgroundColor('bg-error-50')]: hasError,
      [borderWidth('focus:border-3')]: isFirstError,
      [borderRadius('rounded')]: isFirstError,
    },
    typedUtilityClassnames(width('w-full'), height('h-full'), zIndex('z-10'), cursor('cursor-pointer')),
  );

const iconsAndLabelContainerClassNames = typedUtilityClassnames(
  display('grid'),
  gridTemplateColumns('grid-cols-5'),
  position('absolute'),
  inset('top-0'),
  width('w-full'),
  height('h-full'),
  zIndex('z-0'),
  alignItems('items-center'),
  pointerEvents('pointer-events-none'),
);
const leftIconContainerClassNames = typedUtilityClassnames(gridColumn('col-span-3'), gridColumnStart('col-start-2'));
const stageTitleLabelClassNames = typedUtilityClassnames(textSharedClassNames, 'body1');
const getStageSummaryContainerClassNames = (isLastInSequence: boolean): string | undefined => {
  return onlyComputedCombineClassnames(
    borderWidth('border-t', { 'border-b': isLastInSequence }),
    typedUtilityClassnames(position('relative'), height('h-20'), 'drawerFormFullWidthOptions', borderColor('border-outlineColour')),
  );
};

const rightChevronIconClassNames = typedUtilityClassnames(rotate('rotate-n90'), height('h-6'), iconsSharedClassNames);
const hiddenInputsClassNames = typedUtilityClassnames(display('hidden'));
export const StageSummaryInputRefRecipient = function <
  TFormState extends ExclusivelyStringKeyedRecordWithOptional<TFormState>,
  TFieldName extends FieldName<FieldValuesFromFieldErrors<FieldErrors<TFormState>>>,
>(
  {
    values,
    id,
    hasError = false,
    isDirty,
    nonValidSummary,
    validSummary,
    label,
    isLastInSequence = false,
    onClick,
    name,
    errors,
    ...rest
  }: StageSummaryInputProps<TFormState, TFieldName>,
  ref: Ref<HTMLInputElement>,
): JSX.Element {
  const length = values?.length;
  const { buttonLabelId, buttonDescriptionId } = getUniqueIds(id);
  const hasValue = !!length;
  const buttonRef = useRef<HTMLButtonElement | null>(null);
  const [hasFirstError, setHasFirstError] = useState(false);

  useEffect(() => {
    if (!errors || !buttonRef?.current) {
      return;
    }
    const hasFirstError = Object.keys(errors).indexOf(name) === 0;

    if (hasFirstError) {
      buttonRef.current?.focus();
      setHasFirstError(true);
      return;
    }
    setHasFirstError(false);
  }, [errors, hasFirstError, name]);

  useEffect(() => {
    if (!hasError) {
      setHasFirstError(false);
    }
  }, [hasError, setHasFirstError]);

  return (
    <div className={getStageSummaryContainerClassNames(isLastInSequence)}>
      <div className={iconsAndLabelContainerClassNames}>
        {/* If the summary section has value, but is not dirty, this means there is an existing value provided. */}
        {(!isDirty || (!hasError && isDirty)) && hasValue ? (
          <CheckedDark className={leftButtonIconsSharedClassNames} />
        ) : (
          <Plus className={leftButtonIconsSharedClassNames} />
        )}
        <div className={leftIconContainerClassNames}>
          <label id={buttonLabelId} className={stageTitleLabelClassNames}>
            {label}
          </label>
          <label id={buttonDescriptionId} className={getStageSummaryLabelClassNames(hasError)}>
            {(hasError || !isDirty) && !hasValue ? (hasError ? getMessageAsError(nonValidSummary) : nonValidSummary) : validSummary}
          </label>
        </div>
        <Chevron className={rightChevronIconClassNames} />
      </div>
      <fieldset>
        {values?.map((value) => {
          return (
            <_FormInputBase<TFormState, TFieldName>
              {...rest}
              name={name}
              inputElementClassNames={hiddenInputsClassNames}
              key={value}
              readOnly
              isRequired
              ariaLabelOnly
              type={inputTypeNames.checkbox}
              showsErrors={false}
              label={value}
              value={value}
              ref={ref}
              errors={errors}
              isChecked={true}
            />
          );
        })}
      </fieldset>
      <button
        aria-labelledby={buttonLabelId}
        aria-describedby={buttonDescriptionId}
        onClick={onClick}
        className={getButtonClassNames(hasFirstError, hasError)}
        ref={buttonRef}
      />
    </div>
  );
};

export const StageSummaryInput = fixedForwardRef(StageSummaryInputRefRecipient);
