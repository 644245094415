import { NavigationPaths, NavigationPathsResolver } from 'navigation/paths';
import { useCallback } from 'react';
import { useNavigate } from 'react-router';

export const useAppNavigation = () => {
  const navigate = useNavigate();

  const navigateBack = useCallback(() => {
    navigate(-1);
  }, [navigate]);

  const navigateUsers = useCallback(() => {
    navigate(NavigationPaths.UsersPath);
  }, [navigate]);

  const navigateEditUser = useCallback(
    (userId: string) => {
      navigate(NavigationPathsResolver.EditUserPath(userId));
    },
    [navigate],
  );

  const navigateShipments = useCallback(() => {
    navigate(NavigationPaths.ShipmentsPath);
  }, [navigate]);

  const navigateViewShipment = useCallback(
    (shipmentId: string | number) => {
      navigate(NavigationPathsResolver.ViewShipmentPath(shipmentId));
    },
    [navigate],
  );

  const navigateEditShipment = useCallback(
    (shipmentId: string | number) => {
      navigate(NavigationPathsResolver.EditShipmentPath(shipmentId));
    },
    [navigate],
  );

  const navigateBagsProcessing = useCallback(
    (shipmentId: string | number) => {
      navigate(NavigationPathsResolver.BagsProcessingPath(shipmentId));
    },
    [navigate],
  );

  const navigateBagProcessing = useCallback(
    (shipmentId: string | number) => {
      navigate(NavigationPathsResolver.BagProcessingPath(shipmentId));
    },
    [navigate],
  );

  const navigateTruckProcessing = useCallback(
    (shipmentId: string | number) => {
      navigate(NavigationPathsResolver.TruckArrivalProcessingPath(shipmentId));
    },
    [navigate],
  );

  const navigatePilesProcessing = useCallback(
    (shipmentId: string | number) => {
      navigate(NavigationPathsResolver.PilesProcessingPath(shipmentId));
    },
    [navigate],
  );

  const navigateSamplingInformation = useCallback(
    (shipmentId: string | number) => {
      navigate(NavigationPathsResolver.SamplingInformationPath(shipmentId));
    },
    [navigate],
  );

  const navigatePileProcessing = useCallback(
    (shipmentId: string | number) => {
      navigate(NavigationPathsResolver.PileProcessingPath(shipmentId));
    },
    [navigate],
  );

  const navigateDrumProcessing = useCallback(
    (shipmentId: string | number) => {
      navigate(NavigationPathsResolver.DrumProcessingPath(shipmentId));
    },
    [navigate],
  );

  const navigateContainerProcessing = useCallback(
    (shipmentId: string | number) => {
      navigate(NavigationPathsResolver.ContainerProcessingPath(shipmentId));
    },
    [navigate],
  );

  const navigateFreightForwardingInformation = useCallback(
    (shipmentId: string | number) => {
      navigate(NavigationPathsResolver.FreightForwardingInformationPath(shipmentId));
    },
    [navigate],
  );

  const navigateContainerDrums = useCallback(
    (shipmentId: string | number) => {
      navigate(NavigationPathsResolver.ContainerDrumsPath(shipmentId));
    },
    [navigate],
  );

  const navigateShipmentProcessing = useCallback(
    (shipmentId: string | number) => {
      navigate(NavigationPathsResolver.ShipmentProcessingPath(shipmentId));
    },
    [navigate],
  );

  const navigateShipmentDetails = useCallback(
    (shipmentId: string | number) => {
      navigate(NavigationPathsResolver.ShipmentDetailsPath(shipmentId));
    },
    [navigate],
  );

  const navigateIoTDeviceInformation = useCallback(
    (shipmentId: string | number) => {
      navigate(NavigationPathsResolver.IoTDeviceInformation(shipmentId));
    },
    [navigate],
  );

  const navigateDocument = useCallback(
    (shipmentId: string | number, documentId: string | number) => {
      navigate(NavigationPathsResolver.ViewDocumentPath(shipmentId, documentId));
    },
    [navigate],
  );

  return {
    navigateBack,
    navigateUsers,
    navigateEditUser,
    navigateShipments,
    navigateViewShipment,
    navigateEditShipment,
    navigateBagsProcessing,
    navigateBagProcessing,
    navigateTruckProcessing,
    navigatePilesProcessing,
    navigateSamplingInformation,
    navigatePileProcessing,
    navigateDrumProcessing,
    navigateContainerProcessing,
    navigateFreightForwardingInformation,
    navigateContainerDrums,
    navigateShipmentProcessing,
    navigateShipmentDetails,
    navigateIoTDeviceInformation,
    navigateDocument,
  };
};
