import { useCallback, useState } from 'react';
import { onlyComputedCombineClassnames, typedUtilityClassnames } from 'style/compoundClassnames';
import { _TextInputBase } from 'components/form-components/inputs/_base';
import { useController } from 'react-hook-form';

import type { _TextInputBaseProps } from 'components/form-components/inputs/_base';
import type { ExclusivelyStringKeyedRecordWithOptional, ExclusivelyStringTypedKeys } from 'utility-types';
import type { Control, FieldErrors, Path, FieldName, PathValue } from 'react-hook-form';
import type { ChangeEventHandler } from 'react';
import type { FieldValuesFromFieldErrors } from '@hookform/error-message';

export type ControlledInputTransform<TFieldValue> = {
  toInput: (value: TFieldValue) => string;
  toOutput: (value: string) => TFieldValue;
};

const controlledInputElementClassNames = typedUtilityClassnames();
const controlledTextInputLabelClassNames = typedUtilityClassnames();

type FieldPath<TFormState extends ExclusivelyStringKeyedRecordWithOptional<TFormState>> = FieldName<
  FieldValuesFromFieldErrors<FieldErrors<TFormState>>
> &
  Path<TFormState> &
  ExclusivelyStringTypedKeys<TFormState>;

export interface ControlledTextInputProps<
  TFormState extends ExclusivelyStringKeyedRecordWithOptional<TFormState>,
  TFieldName extends FieldPath<TFormState>,
> extends Omit<_TextInputBaseProps<TFormState, TFieldName>, 'onChange' | 'onBlur' | 'ref'> {
  onChangeValidator: (value: string) => boolean;
  transform: ControlledInputTransform<PathValue<TFormState, TFieldName>>;
  errors: FieldErrors<TFormState>;
  control: Control<TFormState>;
  readOnly: boolean;
}
export const ControlledTextInput = function <
  TFormState extends ExclusivelyStringKeyedRecordWithOptional<TFormState>,
  TFieldName extends FieldPath<TFormState>,
>({
  name,
  label,
  transform: { toInput, toOutput },
  onChangeValidator,
  labelClassNames = '',
  inputElementClassNames = '',
  textInputAndLabelContainerClassNames = '',
  hasError,
  readOnly,
  errors,
  control,
  isRequired,
}: ControlledTextInputProps<TFormState, TFieldName>) {
  const {
    field: { value, ref, onChange, ...rest },
  } = useController({ control, name });
  const [currentValue, setCurrentValue] = useState(toInput(value));
  const handleOnChange = useCallback<ChangeEventHandler<HTMLInputElement>>(
    (e) => {
      const {
        target,
        target: { value: eventValue },
      } = e;
      const isValid = onChangeValidator(eventValue);
      if (isValid) {
        setCurrentValue(eventValue);
        onChange({ ...e, target: { ...target, value: toOutput(eventValue) } });
      }
    },
    [onChangeValidator, onChange, toOutput],
  );
  return (
    <_TextInputBase<TFormState, TFieldName>
      {...rest}
      labelClassNames={onlyComputedCombineClassnames(labelClassNames, controlledTextInputLabelClassNames)}
      textInputAndLabelContainerClassNames={textInputAndLabelContainerClassNames}
      name={name}
      label={label}
      value={currentValue}
      inputElementClassNames={onlyComputedCombineClassnames(inputElementClassNames, controlledInputElementClassNames)}
      onChange={handleOnChange}
      ref={ref}
      hasError={hasError}
      readOnly={readOnly}
      errors={errors}
      isRequired={isRequired}
    />
  );
};
