import { UncontrolledTextInput } from 'components/form-components/inputs';
import { s } from 'i18n/strings';
import { FieldError, FieldErrors, SubmitHandler, UseFormHandleSubmit, UseFormRegister } from 'react-hook-form';
import { display, gap, gridRowStart, gridTemplateColumns, gridTemplateRows, typedUtilityClassnames } from 'style/compoundClassnames';
import { getRequiredInputLength } from 'utilities/formValidation/stringValidation';
import { ExclusivelyStringKeyedRecordAsNonOptional, FormFieldNames } from 'utility-types';
import { z } from 'zod';

const sectionsSharedClassNames = typedUtilityClassnames(
  display('grid'),
  gridTemplateColumns('lg:grid-cols-2', 'grid-cols-1'),
  gap('gap-x-6'),
);
const formClassNames = typedUtilityClassnames('nonExpandingMainLayoutContentSection');
const formSectionClassNames = typedUtilityClassnames(sectionsSharedClassNames, gridTemplateRows('grid-rows-mc3', 'lg:grid-rows-mc2'));
const sectionFirstRowSharedClassNames = typedUtilityClassnames(gridRowStart('lg:row-start-1'));
const sectionSecondRowSharedClassNames = typedUtilityClassnames(gridRowStart('lg:row-start-2'));

const samplingInfoRequiredFieldsValidation = (minLength: number, maxLength: number) =>
  getRequiredInputLength(
    s.FormValidationError_FieldRequired,
    minLength,
    s.FormValidationError_MinimumLengthError.replace('{{length}}', minLength.toString()),
    maxLength,
    s.FormValidationError_MaximumLengthError.replace('{{length}}', maxLength.toString()),
  );

export const samplingInformationFormStateSchema = z.object({
  robinsonReferenceNo: samplingInfoRequiredFieldsValidation(5, 50),
  contactFirstName: samplingInfoRequiredFieldsValidation(1, 50),
  contactLastName: samplingInfoRequiredFieldsValidation(1, 50),
});

export type SamplingInformationFormState = z.infer<typeof samplingInformationFormStateSchema>;

export const samplingInformationFormStateFieldNames: ExclusivelyStringKeyedRecordAsNonOptional<
  FormFieldNames<SamplingInformationFormState>
> = {
  robinsonReferenceNo: 'robinsonReferenceNo',
  contactFirstName: 'contactFirstName',
  contactLastName: 'contactLastName',
} as const;

export interface SamplingInformationFormProps {
  formId: string;
  canEdit: boolean;

  onSubmit: SubmitHandler<SamplingInformationFormState>;
  handleSubmit: UseFormHandleSubmit<SamplingInformationFormState>;
  register: UseFormRegister<SamplingInformationFormState>;

  robinsonReferenceNoInputError: FieldError | undefined;
  contactFirstNameInputError: FieldError | undefined;
  contactLastNameInputError: FieldError | undefined;

  errors: FieldErrors<SamplingInformationFormState>;
}

export const SamplingInformationForm = ({
  formId,
  canEdit,
  onSubmit,
  handleSubmit,
  register,
  robinsonReferenceNoInputError,
  contactFirstNameInputError,
  contactLastNameInputError,
  errors,
}: SamplingInformationFormProps): JSX.Element => {
  return (
    <form id={formId} onSubmit={handleSubmit(onSubmit)} className={formClassNames}>
      <section className={formSectionClassNames}>
        <div className={sectionFirstRowSharedClassNames}>
          <UncontrolledTextInput<SamplingInformationFormState, typeof samplingInformationFormStateFieldNames.robinsonReferenceNo>
            readOnly={!canEdit}
            label={s.SamplingInformationPage_RobinsonReferenceNoLabel}
            {...register(samplingInformationFormStateFieldNames.robinsonReferenceNo, { disabled: !canEdit })}
            hasError={!!robinsonReferenceNoInputError}
            errors={errors}
            isRequired={true}
          />
        </div>
        <div className={sectionSecondRowSharedClassNames}>
          <UncontrolledTextInput<SamplingInformationFormState, typeof samplingInformationFormStateFieldNames.contactFirstName>
            readOnly={!canEdit}
            label={s.SamplingInformationPage_ContactFirstNameLabel}
            {...register(samplingInformationFormStateFieldNames.contactFirstName, { disabled: !canEdit })}
            hasError={!!contactFirstNameInputError}
            errors={errors}
            isRequired={true}
          />
        </div>
        <div className={sectionSecondRowSharedClassNames}>
          <UncontrolledTextInput<SamplingInformationFormState, typeof samplingInformationFormStateFieldNames.contactLastName>
            readOnly={!canEdit}
            label={s.SamplingInformationPage_ContactLastNameLabel}
            {...register(samplingInformationFormStateFieldNames.contactLastName, { disabled: !canEdit })}
            hasError={!!contactLastNameInputError}
            errors={errors}
            isRequired={true}
          />
        </div>
      </section>
    </form>
  );
};
