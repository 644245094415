// This is a temporary solution for storing session data. Target solution would be to store it one of state managers (Redux, Recoil).
export const sessionStorageService = {
  has(key: SessionStorageKey): boolean {
    const value = window.sessionStorage.getItem(key);
    if (value) {
      return true;
    }
    return false;
  },
  get<T>(key: SessionStorageKey): T {
    const value = window.sessionStorage.getItem(key);
    const json = JSON.parse(value!);
    return json as T;
  },
  set<T>(key: SessionStorageKey, value: T) {
    window.sessionStorage.setItem(key, JSON.stringify(value));
  },
  remove(key: SessionStorageKey) {
    window.sessionStorage.removeItem(key);
  },
  clear() {
    window.sessionStorage.clear();
  },
};

type SessionStorageKey = keyof typeof sessionStorageKeys;

export const sessionStorageKeys = {
  loggedInUserData: 'loggedInUserData',
} as const;
