import { useEffect, useState } from 'react';
import { useParams } from 'react-router';

import { Document as DocumentModel } from 'models/document';
import { Shipment } from 'models/shipment';
import { documentsService } from 'services/documentsService';
import { documentsLocalCacheService } from 'services/cacheService/documentsLocalCacheService';
import { shipmentsService } from 'services/shipmentsService';
import { useAccessToken, useAppNavigation, useServerError } from 'hooks';

import { s } from 'i18n/strings';

// import { Document, Page } from 'react-pdf/dist/esm/entry.webpack'; // TODO: this does not work with unit tests
import { Document, Page, pdfjs } from 'react-pdf'; // TODO: this works with below pdfjs.GlobalWorkerOptions.workerSrc configuration
import { saveAs } from 'file-saver';

import {
  backgroundColor,
  display,
  justifyContent,
  onlyComputedCombineClassnames,
  overflow,
  padding,
  textColor,
  typedUtilityClassnames,
} from 'style/compoundClassnames';

import { PrimaryButton, SecondaryButton } from 'components/buttons';
import { ContextHeader } from 'components/headers';
import { Spinner } from 'components/Spinner';
import { Notification } from 'components/Notification';

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`; // TODO: this is required in order PDF preview to work

const baseMainContentClassNames = typedUtilityClassnames('mainLayoutPadding');
const getMainContentClassNames = (documentLoaded: boolean): string | undefined =>
  onlyComputedCombineClassnames({
    [backgroundColor('bg-onSurface-mediumEmphasis')]: documentLoaded,
    [textColor('text-white')]: documentLoaded,
    [baseMainContentClassNames]: !documentLoaded,
    [padding('px-1', 'py-8')]: documentLoaded,
  });
const documentPreviewClassNames = typedUtilityClassnames(
  'body1',
  display('md:flex'),
  justifyContent('justify-center'),
  overflow('overflow-x-auto', 'overflow-y-hidden'),
);
const contextSubTitleClassNames = typedUtilityClassnames(display('md:flex', 'hidden'));

const documentPreviewOptions = {
  cMapUrl: 'cmaps/',
  cMapPacked: true,
  standardFontDataUrl: 'standard_fonts/',
};

export const ViewDocumentPage = () => {
  const { shipmentId, documentId } = useParams<{ shipmentId: string; documentId: string }>();
  const accessToken = useAccessToken();
  const { navigateBack } = useAppNavigation();
  const { handleServerError } = useServerError();

  const [shipment, setShipment] = useState<Shipment | null>(null);
  const [document, setDocument] = useState<DocumentModel | null>(null);
  const [documentContent, setDocumentContent] = useState<Blob | null>(null);

  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    (async () => {
      try {
        setError(null);
        setIsLoading(true);
        // TODO: factor out accessToken null checking into a base API class to run at every call, then remove access token null checking from all components
        if (accessToken && shipmentId && documentId) {
          const shipmentRequest = shipmentsService.getById(accessToken, parseInt(shipmentId));
          const documentRequest = documentsService.getById(accessToken, parseInt(documentId));

          const [shipment, document] = await Promise.all([shipmentRequest, documentRequest]);

          setShipment(shipment);
          setDocument(document);

          if (document) {
            let documentBlob: Blob | null = null;
            if (await documentsLocalCacheService.has(document.filename)) {
              documentBlob = await documentsLocalCacheService.get(document.filename);
            } else {
              if (accessToken) {
                documentBlob = await documentsService.download(accessToken, parseInt(documentId));
                await documentsLocalCacheService.set(document.filename, documentBlob);
              }
            }
            setDocumentContent(documentBlob);
          }
        }
      } catch (error) {
        const errorMessage = handleServerError(error);
        setError(`${s.ViewDocumentPage_RetrievingDocumentDetailsError}: ${errorMessage}`);
      } finally {
        setIsLoading(false);
      }
    })();
  }, [accessToken, documentId, handleServerError, shipmentId]);

  const handleDownloadDocument = async () => {
    if (document && documentContent) {
      saveAs(documentContent, document.filename);
    }
  };

  const handleBack = () => {
    navigateBack();
  };

  const [documentLoaded, setDocumentLoaded] = useState(false);
  const [numPages, setNumPages] = useState<number | null>(null);

  const onDocumentLoadSuccess = ({ numPages }: { numPages: number }) => {
    setNumPages(numPages);
    setDocumentLoaded(true);
  };

  return (
    <>
      {isLoading ? (
        <Spinner />
      ) : (
        shipment &&
        document && (
          <>
            <ContextHeader
              contextTitle={document.documentType.name}
              contextSubTitle={shipment.shipmentReference}
              contextSubTitleClassNames={contextSubTitleClassNames}
              leftButtonOne={<SecondaryButton label={s.ViewDocumentPage_BackButtonCaption} onClick={handleBack} />}
              rightButtonTwo={<PrimaryButton label={s.ViewDocumentPage_DownloadButtonCaption} onClick={handleDownloadDocument} />}
              showContextHeaderContentSpacer={!documentLoaded}
            />
            <div className={getMainContentClassNames(documentLoaded)}>
              <Notification message={error!} severity="error" onClose={() => setError(null)} />
              <div className={documentPreviewClassNames}>
                {documentContent && (
                  <Document
                    file={{ url: URL.createObjectURL(documentContent) }}
                    onLoadSuccess={onDocumentLoadSuccess}
                    error={s.ViewDocumentPage_PreviewUnavailableMessage}
                    options={documentPreviewOptions}
                  >
                    {Array.from(new Array(numPages), (el, index) => (
                      <Page key={`page_${index + 1}`} pageNumber={index + 1} />
                    ))}
                  </Document>
                )}
              </div>
            </div>
          </>
        )
      )}
    </>
  );
};
