import { SyntheticEvent, useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router';

import { Shipment } from 'models/shipment';
import { Pile, PileStates } from 'models/pile';
import type { User } from 'models/user';
import { permissionsService } from 'services/permissionsService';
import { shipmentsService } from 'services/shipmentsService';
import { pilesService } from 'services/pilesService';
import { useAccessToken, useLoggedInUser, useAppNavigation, useServerError } from 'hooks';

import { s } from 'i18n/strings';

import { Chip, IconButton, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';

import {
  backgroundColor,
  display,
  justifyContent,
  onlyComputedCombineClassnames,
  textColor,
  typedUtilityClassnames,
} from 'style/compoundClassnames';

import { PrimaryButton, SecondaryButton } from 'components/buttons';
import { ContextHeader } from 'components/headers';
import { RightDrawer } from 'components/drawers';
import { PileDrawerForm } from 'components/drawer-forms';
import { Spinner } from 'components/Spinner';
import { Notification } from 'components/Notification';

import { ReactComponent as TrashIcon } from 'icons/trash.svg';

const emptyPilesMessageClassNames = typedUtilityClassnames('body1', display('flex'), justifyContent('justify-center'));
const pileStateIndicatorClassNames = typedUtilityClassnames('body2', backgroundColor('bg-normal-50'), textColor('text-black'));

interface PilesTableProps {
  piles: Pile[];
  onSelected: (id: number) => void;
  canDelete: boolean;
  onDeleted: (event: SyntheticEvent, id: number) => void;
  canInteract: boolean;
}

const PilesTable = ({ piles, onSelected, canDelete, onDeleted, canInteract }: PilesTableProps): JSX.Element => {
  return (
    <TableContainer>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell />
            <TableCell className={typedUtilityClassnames('label')} align="left">
              {s.PileProcessingPage_PileIDTableColumnTitle}
            </TableCell>
            <TableCell className={typedUtilityClassnames('label')} align="right">
              {s.PileProcessingPage_BagsIDsTableColumnTitle}
            </TableCell>
            <TableCell />
            <TableCell />
          </TableRow>
        </TableHead>
        <TableBody className={typedUtilityClassnames('body1')}>
          {piles.map(({ id, state, reference, bags }) => (
            <TableRow key={id} hover={canInteract} onClick={() => canInteract && onSelected(id)}>
              <TableCell align="left" width="10">
                <Chip label={state.name} className={pileStateIndicatorClassNames} />
              </TableCell>
              <TableCell align="left" width="15%">
                {reference}
              </TableCell>
              <TableCell align="right" width="25%">
                {bags.map((b) => b.itsciSealNumber).join(', ')}
              </TableCell>
              <TableCell width="*" />
              <TableCell align="right" width="35rem" sx={{ mx: '0.5rem' }}>
                {canDelete && (
                  <IconButton onClick={(event) => onDeleted(event, id)} disabled={!canInteract}>
                    <TrashIcon />
                  </IconButton>
                )}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

const pilesProcessingFinished = (piles: Pile[] | null) =>
  piles != null && piles.length > 0 && piles.every((p) => p.state.name === PileStates.Sampled || p.state.name === PileStates.Consumed);

const canAddPile = (user: User | null, piles: Pile[] | null) =>
  user != null && permissionsService.canAddPile(user) && !pilesProcessingFinished(piles);

const canEditPile = (user: User | null, piles: Pile[] | null) =>
  user != null && permissionsService.canEditPile(user) && !pilesProcessingFinished(piles);

const canDeletePile = (user: User | null, piles: Pile[] | null) =>
  user != null && permissionsService.canDeletePile(user) && !pilesProcessingFinished(piles);

export const PileProcessingPage = () => {
  const { shipmentId } = useParams<{ shipmentId: string }>();
  const accessToken = useAccessToken();
  const user = useLoggedInUser();

  const { navigatePilesProcessing, navigateShipments } = useAppNavigation();
  const { handleServerError } = useServerError();

  const [shipment, setShipment] = useState<Shipment | null>(null);
  const [piles, setPiles] = useState<Pile[] | null>(null);
  const [selectedPile, setSelectedPile] = useState<Pile | null>(null);

  const [isLoading, setIsLoading] = useState(true);
  const [isActionInProgress, setIsActionInProgress] = useState(false);
  const [error, setError] = useState<string | null>(null);

  const [drawerOpen, setDrawerOpen] = useState<boolean>(false);

  useEffect(() => {
    (async () => {
      try {
        setError(null);
        setIsLoading(true);
        // TODO: Refactor out accessToken null checks to shared API client class that will throw if null for any call, then remove this from every component
        if (accessToken && shipmentId) {
          const shipmentRequest = shipmentsService.getById(accessToken, parseInt(shipmentId));
          const pilesRequest = shipmentsService.getPiles(accessToken, parseInt(shipmentId));
          const [shipment, piles] = await Promise.all([shipmentRequest, pilesRequest]);
          setShipment(shipment);
          setPiles(piles);
        }
      } catch (error) {
        const errorMessage = handleServerError(error);
        setError(`${s.PileProcessingPage_RetrievingPilesError}: ${errorMessage}`);
      } finally {
        setIsLoading(false);
      }
    })();
  }, [accessToken, handleServerError, shipmentId]);

  const refreshData = useCallback(async () => {
    setError(null);
    if (shipmentId && accessToken) {
      try {
        const piles = await shipmentsService.getPiles(accessToken, parseInt(shipmentId));
        setPiles(piles);
      } catch (error) {
        const errorMessage = handleServerError(error);
        setError(`${s.PileProcessingPage_RetrievingPilesError}: ${errorMessage}`);
      }
    }
  }, [accessToken, handleServerError, shipmentId]);

  const handleSave = async () => {
    setDrawerOpen(false);
    await refreshData();
  };

  const handleSaveError = async (error: unknown) => {
    setDrawerOpen(false);
    const errorMessage = handleServerError(error);
    setError(`${s.PileProcessingPage_CouldNotCreateOrUpdatePileError}: ${errorMessage}`);
  };

  const handleClose = () => {
    setDrawerOpen(false);
  };

  const handleAddPile = () => {
    if (canAddPile(user, piles)) {
      setSelectedPile(null);
      setDrawerOpen(true);
    }
  };

  const handleBack = () => {
    shipmentId ? navigatePilesProcessing(shipmentId) : navigateShipments();
  };

  const handlePileSelected = (pileId: number | undefined) => {
    if (piles && pileId) {
      const pile = piles.find((p) => p.id === pileId);
      setSelectedPile(pile!);
      setDrawerOpen(true);
    }
  };

  const handleDeletePile = async (event: SyntheticEvent, pileId: number | undefined) => {
    event.stopPropagation();

    try {
      // TODO: Refactor out accessToken null checks to shared API client class that will throw if null for any call, then remove this from every component
      if (accessToken && pileId && canDeletePile(user, piles)) {
        setIsActionInProgress(true);
        await pilesService.delete(accessToken, pileId);
        await refreshData();
      }
    } catch (error) {
      const errorMessage = handleServerError(error);
      setError(`${s.PileProcessingPage_CouldNotDeletePileError}: ${errorMessage}`);
    } finally {
      setIsActionInProgress(false);
    }
  };

  return (
    <>
      {isLoading ? (
        <Spinner />
      ) : (
        <>
          <ContextHeader
            contextTitle={s.PileProcessingPage_Title}
            contextSubTitle={shipment?.shipmentReference}
            leftButtonOne={<SecondaryButton label={s.PileProcessingPage_BackButton} onClick={handleBack} />}
            rightButtonOne={
              <PrimaryButton label={s.PileProcessingPage_AddButton} onClick={handleAddPile} disabled={!canAddPile(user, piles)} />
            }
            showContextHeaderContentSpacer
          />
          <div className={onlyComputedCombineClassnames(typedUtilityClassnames('mainLayoutPadding'))}>
            <Notification message={error!} severity="error" onClose={() => setError(null)} />
            {piles && piles.length > 0 && user ? (
              <PilesTable
                piles={piles}
                onSelected={handlePileSelected}
                canDelete={canDeletePile(user, piles)}
                onDeleted={handleDeletePile}
                canInteract={!isActionInProgress}
              />
            ) : (
              <div className={emptyPilesMessageClassNames}>{s.PileProcessingPage_WillAppearHere}</div>
            )}
          </div>
        </>
      )}

      {shipmentId && (
        <RightDrawer open={drawerOpen}>
          <PileDrawerForm
            shipmentId={shipmentId}
            onSaveSuccess={handleSave}
            onSaveFailed={handleSaveError}
            onClose={handleClose}
            pile={selectedPile}
            canEdit={selectedPile != null ? canEditPile(user, piles) : canAddPile(user, piles)}
          />
        </RightDrawer>
      )}
    </>
  );
};
