import { ShipmentSummaryPiles } from 'models/shipment';
import { _ManifestItemBase } from 'components/manifest-items/_base/_ManifestItemBase';
import { s } from 'i18n/strings';
import {
  display,
  gap,
  gridTemplateColumns,
  padding,
  textColor,
  textTransform,
  typedUtilityClassnames,
  width,
} from 'style/compoundClassnames';
import { ReactComponent as PilesIcon } from 'icons/piles.svg';

const contentLayoutClassNames = typedUtilityClassnames(
  display('grid'),
  gridTemplateColumns('xl:grid-cols-3', 'lg:grid-cols-3', 'md:grid-cols-3', 'grid-cols-1'),
  gap('gap-6'),
  width('w-full'),
);
const columnTitleClassNames = typedUtilityClassnames(
  'navBlockFont',
  textColor('text-onSurface-mediumEmphasis'),
  textTransform('uppercase'),
  padding('pb-2'),
);
const columnItemContainerClassNames = typedUtilityClassnames('body2');
const columnItemCaptionClassNames = typedUtilityClassnames('label');

export interface PilesProcessingManifestItemProps {
  pilesSummary: ShipmentSummaryPiles;
  editOnClick: () => void;
}

export const PilesProcessingManifestItem = ({ pilesSummary, editOnClick }: PilesProcessingManifestItemProps): JSX.Element => {
  return (
    <_ManifestItemBase
      title={s.PilesProcessingManifestItem_Title}
      icon={<PilesIcon />}
      actionButtonCaption={s.PilesProcessingManifestItem_EditButtonCaption}
      actionButtonOnClick={editOnClick}
      actionButtonDisabled={false}
      actionButtonVisible={true}
      content={
        <div className={contentLayoutClassNames}>
          <div className={columnItemContainerClassNames}>
            <div className={columnTitleClassNames}>{s.PilesProcessingManifestItem_PilesProcessedSectionTitle}</div>
            <div className={columnItemContainerClassNames}>{pilesSummary.count}</div>
          </div>

          {pilesSummary.samplingReference && (
            <div>
              <div className={columnTitleClassNames}>{s.PilesProcessingManifestItem_SamplingInformationSectionTitle}</div>
              <div className={columnItemContainerClassNames}>
                <span className={columnItemCaptionClassNames}>{s.PilesProcessingManifestItem_RobinsonReferenceNoLabel}: </span>
                {pilesSummary.samplingReference}
              </div>
              <div className={columnItemContainerClassNames}>
                <span className={columnItemCaptionClassNames}>{s.PilesProcessingManifestItem_ContactLabel}: </span>
                {pilesSummary.samplingTechnician?.firstName} {pilesSummary.samplingTechnician?.lastName}
              </div>
            </div>
          )}
        </div>
      }
    />
  );
};
