import { _ManifestItemBase } from 'components/manifest-items/_base/_ManifestItemBase';
import {
  typedUtilityClassnames,
  textTransform,
  textColor,
  // margin,
  padding,
  display,
  gridTemplateColumns,
  gap,
  width,
  alignItems,
} from 'style/compoundClassnames';
import { s } from 'i18n/strings';
import SensorsIcon from '@mui/icons-material/Sensors';
import { formatDate } from 'utilities';
import { SensorData } from 'models/sensorData';

import { ReactComponent as StateSuccessIcon } from 'icons/stateSuccess.svg';
// import { ReactComponent as StateWarningIcon } from 'icons/stateWarning.svg';
import { ReactComponent as StateErrorIcon } from 'icons/stateError.svg';

const contentLayoutClassNames = typedUtilityClassnames(
  display('grid'),
  gridTemplateColumns('xl:grid-cols-3', 'lg:grid-cols-3', 'md:grid-cols-3', 'grid-cols-1'),
  gap('gap-6'),
  width('w-full'),
);
const columnTitleClassNames = typedUtilityClassnames(
  'navBlockFont',
  textColor('text-onSurface-mediumEmphasis'),
  textTransform('uppercase'),
  padding('pb-2'),
);
const columnItemContainerClassNames = typedUtilityClassnames('body2', textColor('text-onSurface-highEmphasis'));
const columnItemCaptionClassNames = typedUtilityClassnames('label', textColor('text-onSurface-highEmphasis'));
const subItemClassNames = typedUtilityClassnames('caption', textColor('text-onSurface-mediumEmphasis'));

export interface IoTDeviceInformationManifestItemProps {
  sensorData: SensorData;
  canEdit: boolean;
  editOnClick: () => void;
}

export const IoTDeviceInformationManifestItem = ({
  sensorData,
  canEdit,
  editOnClick,
}: IoTDeviceInformationManifestItemProps): JSX.Element => {
  const getSensorReading = (sensorData: SensorData, type: string) => {
    const reading = sensorData.sensorReadings.filter((sr) => sr.type === type)[0];
    let value;
    switch (type) {
      case 'Vibration':
        value = reading.value;
        break;
      case 'Temperature':
        value = Number(reading.value).toFixed(1);
        break;
      case 'Light':
        value = reading.value;
        break;
      case 'Movement':
        value = Number(reading.value).toFixed(2);
        break;
      default:
        value = reading.value;
        break;
    }

    // return `${value} ${reading.unit} (updated on: ${formatDate.getDateAndTime(reading.updatedAt)})`;
    return `${value} ${reading.unit}`;
  };

  const getSensorTriggerLevels = (sensorData: SensorData, type: string) => {
    const reading = sensorData.sensorReadings.filter((sr) => sr.type === type)[0];
    const location = reading.triggerLevels.filter((tl) => tl.location === sensorData.location)[0];
    const value = `${location.minValue} - ${location.maxValue}`;
    return `${value} ${reading.unit}`;
  };

  const getSensorIcon = (sensorData: SensorData, type: string) => {
    const reading = sensorData.sensorReadings.filter((sr) => sr.type === type)[0];
    const location = reading.triggerLevels.filter((tl) => tl.location === sensorData.location)[0];

    if (reading.value < location.minValue || reading.value > location.maxValue) {
      return <StateErrorIcon />;
    } else {
      return <StateSuccessIcon />;
    }
  };

  return (
    <_ManifestItemBase
      title={s.IoTDeviceInformationManifestItem_Title}
      icon={<SensorsIcon fontSize="medium" />}
      actionButtonCaption={s.IoTDeviceInformationManifestItem_EditButtonCaption}
      actionButtonOnClick={editOnClick}
      actionButtonDisabled={!canEdit}
      actionButtonVisible={true}
      content={
        <div className={contentLayoutClassNames}>
          <div className={columnItemContainerClassNames}>
            <div className={columnTitleClassNames}>{s.IoTDeviceInformationManifestItem_DeviceDetailsTitle}</div>
            <div className={columnItemContainerClassNames}>
              <span className={columnItemCaptionClassNames}>{s.IoTDeviceInformationManifestItem_IdLabel}: </span>
              {sensorData.ioTDeviceID}
            </div>
            <div className={columnItemContainerClassNames}>
              <span className={columnItemCaptionClassNames}>{s.IoTDeviceInformationManifestItem_LocationLabel}: </span>
              {sensorData.location}
            </div>
            <div className={columnItemContainerClassNames}>
              <span className={columnItemCaptionClassNames}>{s.IoTDeviceInformationManifestItem_StatusLabel}: </span>
              {sensorData.status}
            </div>
            <div className={subItemClassNames}>
              {s.IoTDeviceInformationManifestItem_LastUpdateLabel}: {formatDate.getDateAndTime(sensorData.updatedAt)}
            </div>
          </div>

          <div className={columnItemContainerClassNames}>
            <div className={columnTitleClassNames}>{s.IoTDeviceInformationManifestItem_SensorReadingsTitle}</div>
            <div className={columnItemContainerClassNames}>
              <div className={typedUtilityClassnames(display('flex'), alignItems('items-center'), gap('gap-x-1'))}>
                <span className={typedUtilityClassnames(width('w-40'))}>
                  <span className={columnItemCaptionClassNames}>{s.IoTDeviceInformationManifestItem_VibrationLabel}: </span>
                  {getSensorReading(sensorData, 'Vibration')}
                </span>
                {getSensorIcon(sensorData, 'Vibration')}
              </div>
            </div>
            <div className={columnItemContainerClassNames}>
              <div className={typedUtilityClassnames(display('flex'), alignItems('items-center'), gap('gap-x-1'))}>
                <span className={typedUtilityClassnames(width('w-40'))}>
                  <span className={columnItemCaptionClassNames}>{s.IoTDeviceInformationManifestItem_TemperatureLabel}: </span>
                  {getSensorReading(sensorData, 'Temperature')}
                </span>
                {getSensorIcon(sensorData, 'Temperature')}
              </div>
            </div>
            <div className={columnItemContainerClassNames}>
              <div className={typedUtilityClassnames(display('flex'), alignItems('items-center'), gap('gap-x-1'))}>
                <span className={typedUtilityClassnames(width('w-40'))}>
                  <span className={columnItemCaptionClassNames}>{s.IoTDeviceInformationManifestItem_LightLabel}: </span>
                  {getSensorReading(sensorData, 'Light')}
                </span>
                {getSensorIcon(sensorData, 'Light')}
              </div>
            </div>
            <div className={columnItemContainerClassNames}>
              <div className={typedUtilityClassnames(display('flex'), alignItems('items-center'), gap('gap-x-1'))}>
                <span className={typedUtilityClassnames(width('w-40'))}>
                  <span className={columnItemCaptionClassNames}>{s.IoTDeviceInformationManifestItem_MovementLabel}: </span>
                  {getSensorReading(sensorData, 'Movement')}
                </span>
                {getSensorIcon(sensorData, 'Movement')}
              </div>
            </div>
          </div>

          <div className={columnItemContainerClassNames}>
            <div className={columnTitleClassNames}>{s.IoTDeviceInformationManifestItem_TriggerLevelsTitle}</div>
            <div className={columnItemContainerClassNames}>
              <span className={columnItemCaptionClassNames}>{s.IoTDeviceInformationManifestItem_VibrationLabel}: </span>
              {getSensorTriggerLevels(sensorData, 'Vibration')}
            </div>
            <div className={columnItemContainerClassNames}>
              <span className={columnItemCaptionClassNames}>{s.IoTDeviceInformationManifestItem_TemperatureLabel}: </span>
              {getSensorTriggerLevels(sensorData, 'Temperature')}
            </div>
            <div className={columnItemContainerClassNames}>
              <span className={columnItemCaptionClassNames}>{s.IoTDeviceInformationManifestItem_LightLabel}: </span>
              {getSensorTriggerLevels(sensorData, 'Light')}
            </div>
            <div className={columnItemContainerClassNames}>
              <span className={columnItemCaptionClassNames}>{s.IoTDeviceInformationManifestItem_MovementLabel}: </span>
              {getSensorTriggerLevels(sensorData, 'Movement')}
            </div>
          </div>
        </div>
      }
    />
  );
};
