import { useEffect, useState } from 'react';
import { Container, containerStates } from 'models/container';
import { s } from 'i18n/strings';
import { Checkbox, FormControlLabel } from '@mui/material';
import { padding, textColor, typedUtilityClassnames } from 'style/compoundClassnames';
import { _ConfirmationDialogBase } from 'components/dialogs/_base';

const freightForwarderConfirmationLabelClassNames = typedUtilityClassnames(
  'headline6',
  textColor('text-onSurface-highEmphasis'),
  padding('pt-2', 'pb-1'),
);
const checkboxContainerClassNames = typedUtilityClassnames(padding('py-1'));
const checkboxLabelClassNames = typedUtilityClassnames('label');

export interface FreightForwarderConfirmationDialogProps {
  open: boolean;
  container: Container | null;
  handleConfirm: (confirmedWithFreightForwarder: boolean) => void;
  confirmDisabled: boolean;
  handleCancel: () => void;
  cancelDisabled: boolean;
}

export const FreightForwarderConfirmationDialog = ({
  open,
  container,
  handleConfirm,
  confirmDisabled,
  handleCancel,
  cancelDisabled,
}: FreightForwarderConfirmationDialogProps): JSX.Element => {
  const [confirmed, setConfirmed] = useState(false);

  useEffect(() => {
    setConfirmed(container != null && container.state.name === containerStates.Done);
  }, [container]);

  const handleChange = () => {
    setConfirmed(!confirmed);
  };

  return (
    <_ConfirmationDialogBase
      open={open}
      title={s.FreightForwarderConfirmationDialog_Title}
      primaryButtonCaption={s.FreightForwarderConfirmationDialog_ConfirmButtonCaption}
      primaryButtonAction={() => handleConfirm(confirmed)}
      primaryButtonDisabled={confirmDisabled || (!confirmed && container != null && container.state.name === containerStates.Done)}
      secondaryButtonCaption={s.FreightForwarderConfirmationDialog_CancelButtonCaption}
      secondaryButtonAction={handleCancel}
      secondaryButtonDisabled={cancelDisabled}
    >
      <div className={freightForwarderConfirmationLabelClassNames}>
        {s.FreightForwarderConfirmationDialog_FreightForwarderConfirmationLabel}
      </div>
      <FormControlLabel
        className={checkboxContainerClassNames}
        control={<Checkbox checked={confirmed} onChange={handleChange} />}
        label={<div className={checkboxLabelClassNames}>{s.FreightForwarderConfirmationDialog_FreightForwarderConfirmationMessage}</div>}
      />
    </_ConfirmationDialogBase>
  );
};
