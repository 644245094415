import { useRef, useCallback, forwardRef, useState } from 'react';
import type { SyntheticEvent, Ref } from 'react';

import { MIMEPrefixes, universaliseMIMEPrefix } from 'models/document';

import type { ExclusivelyStringKeyedRecordWithOptional } from 'utility-types';
import type { FieldErrors, FieldName } from 'react-hook-form';
import type { FieldValuesFromFieldErrors } from '@hookform/error-message';

import { s } from 'i18n/strings';

import {
  alignItems,
  backgroundColor,
  borderRadius,
  display,
  gridTemplateRows,
  height,
  justifyContent,
  objectFit,
  overflow,
  padding,
  typedUtilityClassnames,
  width,
  gridRowStart,
  textColor,
} from 'style/compoundClassnames';
import { CircularIconButton } from 'components/icon-buttons/CircularIconButton';
import { inputTypeNames } from 'components/form-components/inputs';
import { _FormInputBase, photoInputCaptureTypes } from 'components/form-components/inputs/_base';

import { ReactComponent as AddPhotoIcon } from 'icons/addPhoto.svg';

import { Backdrop, Box, Button, IconButton, Stack } from '@mui/material';
import ClearIcon from '@mui/icons-material/HighlightOffTwoTone';
import ViewIcon from '@mui/icons-material/FitScreenRounded';
import DownloadIcon from '@mui/icons-material/Download';
// TODO: for more icons, go to: https://mui.com/material-ui/material-icons/

const rootContainerClassNames = typedUtilityClassnames(display('grid'), gridTemplateRows('grid-rows-mc3'));
const imageSelectionAreaClassNames = typedUtilityClassnames(
  width('w-full'),
  display('flex'),
  justifyContent('justify-center'),
  backgroundColor('bg-overlayBlack-hover'),
  height('h-40'),
  borderRadius('rounded'),
  alignItems('items-center'),
  overflow('overflow-hidden'),
  gridRowStart('row-start-2'),
);
const photoInputClassNames = typedUtilityClassnames(display('hidden'));
const labelClassNames = typedUtilityClassnames('label', padding('pb-2'), gridRowStart('row-start-1'));
const imageThumbnailClassNames = typedUtilityClassnames(objectFit('object-contain'), height('h-full'), width('w-full'));
const errorsDisplayContainerClassNames = typedUtilityClassnames(gridRowStart('row-start-3'));
const photoErrorMessageClassNames = typedUtilityClassnames(textColor('text-error-600'));

const getOverlayRemoveButtonStyles = (stagedForUpload: boolean) => {
  return {
    position: 'absolute',
    top: '50%',
    left: stagedForUpload ? '50%' : '40%',
    transform: 'translate(-50%, -50%)',
    color: '#d0334f',
  };
};
const getOverlayDownloadButtonStyles = (readOnly: boolean) => {
  return {
    position: 'absolute',
    top: '50%',
    left: readOnly ? '50%' : '60%',
    transform: 'translate(-50%, -50%)',
    color: '#e9eaee',
  };
};

export interface PhotoInputProps<
  TFormState extends ExclusivelyStringKeyedRecordWithOptional<TFormState>,
  TFieldName extends FieldName<FieldValuesFromFieldErrors<FieldErrors<TFormState>>>,
> {
  name: TFieldName;
  photoURL?: string;
  label: string;
  ariaLabelOnly?: boolean;
  previewAlternativeText: string;
  buttonAriaLabel: string;
  readOnly: boolean;
  isSaving?: boolean;
  isRequired?: boolean;
  showValidationErrors: boolean;
  validationErrors: FieldErrors<TFormState>;
  photoSelectedLabelClickDisabled?: boolean;
  documentStagedForUpload: boolean;
  hasError: boolean;
  onChange: (e: SyntheticEvent) => void;
  onBlur: (e: SyntheticEvent) => void;
  onRemove: () => void;
  onDownload: () => Promise<void>;
}

const PhotoInputRefRecipient = function <
  TFormState extends ExclusivelyStringKeyedRecordWithOptional<TFormState>,
  TFieldName extends FieldName<FieldValuesFromFieldErrors<FieldErrors<TFormState>>>,
>(
  {
    name,
    photoURL,
    label,
    ariaLabelOnly,
    previewAlternativeText,
    buttonAriaLabel,
    readOnly = false,
    isSaving = false,
    isRequired = false,
    showValidationErrors,
    validationErrors,
    photoSelectedLabelClickDisabled = true,
    documentStagedForUpload,
    hasError,
    onChange,
    onBlur,
    onRemove,
    onDownload,
  }: PhotoInputProps<TFormState, TFieldName>,
  ref: Ref<HTMLInputElement>,
): JSX.Element {
  const hiddenFileInput = useRef<HTMLInputElement | null>(null);

  const handleAddPhoto = useCallback(() => {
    if (!hiddenFileInput?.current) {
      return;
    }
    hiddenFileInput.current.click();
  }, [hiddenFileInput]);

  const [openPhotoView, setOpenPhotoView] = useState(false);
  const handleOpenPhotoView = () => setOpenPhotoView(true);
  const handleClosePhotoView = () => setOpenPhotoView(false);

  return (
    <div className={rootContainerClassNames}>
      <_FormInputBase<TFormState, TFieldName>
        onChange={onChange}
        onBlur={onBlur}
        type={inputTypeNames.file}
        name={name}
        label={label}
        ariaLabelOnly={ariaLabelOnly}
        preventLabelClickDefault={photoSelectedLabelClickDisabled}
        readOnly={!!photoURL || readOnly}
        isRequired={isRequired}
        aria-required={isRequired} // TODO: ???
        showsErrors={showValidationErrors}
        errors={validationErrors}
        labelClassNames={labelClassNames}
        inputElementClassNames={photoInputClassNames}
        errorsDisplayContainerClassNames={errorsDisplayContainerClassNames}
        photoInputCaptureType={photoInputCaptureTypes.environment}
        fileAccept={MIMEPrefixes.image}
        fileAcceptTransform={universaliseMIMEPrefix}
        ref={(e) => {
          ref instanceof Function && ref(e);
          hiddenFileInput.current = e;
        }}
      />
      <>
        <div className={imageSelectionAreaClassNames}>
          {photoURL ? (
            <Box sx={{ position: 'relative' }}>
              <img className={imageThumbnailClassNames} src={photoURL} alt={previewAlternativeText} />
              {!isSaving && !readOnly && (
                <IconButton
                  title={s.PhotoInput_RemoveButtonTitle}
                  onClick={onRemove}
                  sx={getOverlayRemoveButtonStyles(documentStagedForUpload)}
                >
                  <Box
                    sx={{
                      backgroundColor: 'rgb(0 0 0 / 0.6)',
                      '&:hover': {
                        backgroundColor: 'rgb(0 0 0 / 0.38)',
                      },
                      borderRadius: 8,
                      height: 'auto',
                      width: 'auto',
                    }}
                  >
                    <ClearIcon fontSize="large" sx={{ mt: '-5px' }} />
                  </Box>
                </IconButton>
              )}
              {!isSaving && !documentStagedForUpload && (
                <IconButton
                  title={s.PhotoInput_ViewButtonTitle}
                  onClick={handleOpenPhotoView}
                  sx={getOverlayDownloadButtonStyles(readOnly)}
                >
                  <Box
                    sx={{
                      backgroundColor: 'rgb(0 0 0 / 0.6)',
                      '&:hover': {
                        backgroundColor: 'rgb(0 0 0 / 0.38)',
                      },
                      borderRadius: 8,
                      height: 'auto',
                      width: 'auto',
                    }}
                  >
                    <ViewIcon fontSize="large" sx={{ mt: '-5px', padding: '2px' }} />
                  </Box>
                </IconButton>
              )}
            </Box>
          ) : hasError ? (
            // TODO: display error in a better way
            <div className={photoErrorMessageClassNames}>{s.PhotoInput_CouldNotLoadPhotoErrorMessage}</div>
          ) : (
            <CircularIconButton disabled={readOnly} ariaLabel={buttonAriaLabel} onClick={handleAddPhoto}>
              <AddPhotoIcon />
            </CircularIconButton>
          )}
        </div>
      </>
      <Backdrop open={openPhotoView} onClick={handleClosePhotoView}>
        <Stack>
          <Box component="img" src={photoURL} alt="preview" sx={{ display: 'flex', justifyContent: 'center', maxHeight: '90vh' }} />
          <Box sx={{ display: 'flex', justifyContent: 'center', paddingTop: '0.5rem' }}>
            <Button
              title={s.PhotoInput_DownloadPhotoTitle}
              variant="outlined"
              onClick={onDownload}
              sx={{
                borderRadius: 2,
                height: 'auto',
                width: '5rem',
                display: 'flex',
                justifyContent: 'center',
                color: 'white',
                borderColor: 'white',
                '&:hover': {
                  color: '#c2cae7',
                  borderColor: '#c2cae7',
                },
              }}
            >
              <DownloadIcon fontSize="large" />
            </Button>
          </Box>
        </Stack>
      </Backdrop>
    </div>
  );
};

export const PhotoInput = forwardRef(PhotoInputRefRecipient);
