import { ApiError } from 'models/apiError';

const maxRetries = 3;

export const serverResponses = {
  Offline: 'Server is not responding',
} as const;

async function handleResponseError(response: Response): Promise<ApiError | Error> {
  try {
    const json = await response.json();
    const error = json as ApiError;
    return error;
  } catch (e) {
    // fallback
    return new Error('HTTP request error - status: ' + response.status);
  }
}

async function fetchWithRetry(url: string, options: globalThis.RequestInit = {}, retryCount: number = 0) {
  try {
    const response = await fetch(url, options);
    return response;
  } catch {
    if (retryCount < maxRetries) {
      await fetchWithRetry(url, options, retryCount + 1);
    }

    throw new Error(serverResponses.Offline);
  }
}

async function requestWithoutResponseBody(url: string, config: globalThis.RequestInit = {}) {
  const response = await fetchWithRetry(url, config);
  if (!response.ok) {
    const error = await handleResponseError(response);
    throw error;
  }
}

async function requestWithResponseBody<TResponse>(url: string, config: globalThis.RequestInit = {}): Promise<TResponse> {
  const response = await fetchWithRetry(url, config);
  if (response.ok) {
    if (response.status === 204) {
      return null!;
    }
    const json = await response.json();
    return json as TResponse;
  } else {
    const error = await handleResponseError(response);
    throw error;
  }
}

async function requestWithBlobResponseBody(url: string, config: globalThis.RequestInit = {}): Promise<Blob> {
  const response = await fetchWithRetry(url, config);
  if (response.ok) {
    return await response.blob();
  } else {
    const error = await handleResponseError(response);
    throw error;
  }
}

export const apiRequest = {
  async get<TResponse>(url: string, accessToken: string) {
    return await requestWithResponseBody<TResponse>(url, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });
  },
  async post<TResponse, TBody>(url: string, accessToken: string, body: TBody) {
    return await requestWithResponseBody<TResponse>(url, {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${accessToken}`,
        'Content-Type': 'application/json;charset=UTF-8',
      },
      body: JSON.stringify(body),
    });
  },
  async postWithoutResponseBody<TBody>(url: string, accessToken: string, body: TBody) {
    await requestWithoutResponseBody(url, {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${accessToken}`,
        'Content-Type': 'application/json;charset=UTF-8',
      },
      body: JSON.stringify(body),
    });
  },
  async put<TResponse, TBody>(url: string, accessToken: string, body: TBody) {
    return await requestWithResponseBody<TResponse>(url, {
      method: 'PUT',
      headers: {
        Authorization: `Bearer ${accessToken}`,
        'Content-Type': 'application/json;charset=UTF-8',
      },
      body: JSON.stringify(body),
    });
  },
  async delete(url: string, accessToken: string) {
    await requestWithoutResponseBody(url, {
      method: 'DELETE',
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });
  },
  async download(url: string, accessToken: string) {
    return await requestWithBlobResponseBody(url, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });
  },
};
