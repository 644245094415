import { useState, useEffect, useCallback, useMemo } from 'react';
import { Document, DocumentTypeId, MIMEPrefix, MIMEPrefixes } from 'models/document';
import { documentsService } from 'services/documentsService';
import { CacheService } from 'services/cacheService/cacheService';
import { documentsLocalCacheService } from 'services/cacheService/documentsLocalCacheService';
import { useAccessToken, useServerError } from 'hooks';
import { BaseUrl } from 'navigation/paths';
import { s } from 'i18n/strings';
import { useTus } from 'use-tus';
import { saveAs } from 'file-saver';
import { z } from 'zod';

type DocumentInfo = { url: string; name: string; id?: number };

const getBlobFromURL = async (url: string) => {
  const result = await fetch(url);
  return await result.blob();
};

const getRequiredDocumentValidator = (
  acceptedMIMEPrefix: MIMEPrefix | null,
  sizeLimit: number | undefined,
  requiredValidationMessage: string,
  singleFileOnlyValidationMessage: string,
  fileTypeNotSupportedValidationMessage: string,
  fileTooLargeValidationMessage?: string,
) =>
  z
    .instanceof(FileList)
    .transform((files, ctx) => {
      const length = files.length;
      length === 0 && ctx.addIssue({ code: z.ZodIssueCode.custom, fatal: true, message: requiredValidationMessage });
      if (length > 1) {
        ctx.addIssue({
          code: z.ZodIssueCode.too_big,
          maximum: 1,
          fatal: true,
          inclusive: true,
          type: 'array',
          message: singleFileOnlyValidationMessage,
        });
      }

      return files[0];
    })
    .refine((file) => (acceptedMIMEPrefix ? file?.type.startsWith(acceptedMIMEPrefix) ?? true : true), {
      message: fileTypeNotSupportedValidationMessage,
    })
    .refine((file) => (sizeLimit ? file?.size === sizeLimit : true), { message: fileTooLargeValidationMessage });

const getOptionalDocumentValidator = (
  acceptedMIMEPrefix: MIMEPrefix | null,
  sizeLimit: number | undefined,
  singleFileOnlyValidationMessage: string,
  fileTypeNotSupportedValidationMessage: string,
  fileTooLargeValidationMessage?: string,
) =>
  z
    .instanceof(FileList)
    .transform((files, ctx) => {
      const length = files.length;
      if (length > 1) {
        ctx.addIssue({
          code: z.ZodIssueCode.too_big,
          maximum: 1,
          fatal: true,
          inclusive: true,
          type: 'array',
          message: singleFileOnlyValidationMessage,
        });
      }

      return files[0];
    })
    .refine((file) => (acceptedMIMEPrefix ? file?.type.startsWith(acceptedMIMEPrefix) ?? true : true), {
      message: fileTypeNotSupportedValidationMessage,
    })
    .refine((file) => (sizeLimit ? file?.size === sizeLimit : true), { message: fileTooLargeValidationMessage })
    .optional();

type GetRequiredDocumentValidator = typeof getRequiredDocumentValidator;
type GetOptionalDocumentValidator = typeof getOptionalDocumentValidator;

type RequiredDocumentValidator = ReturnType<GetRequiredDocumentValidator>;
type OptionalDocumentValidator = ReturnType<GetOptionalDocumentValidator>;

const getDocumentValidatorDefinition = (
  required: boolean,
  requiredDocumentValidator: RequiredDocumentValidator,
  optionalDocumentValidator: OptionalDocumentValidator,
): RequiredDocumentValidator | OptionalDocumentValidator => (required ? requiredDocumentValidator : optionalDocumentValidator);

export const useSingleDocumentInputCache = (
  onUploadSuccess: () => void,
  onUploadError: (error: unknown) => void,
  required: boolean = false,
  acceptedMIMEPrefix: MIMEPrefix | null = MIMEPrefixes.image,
  cacheService: CacheService<Blob> = documentsLocalCacheService,
  sizeLimit?: number,
): UseSingleDocumentInputCacheManagerReturn => {
  const { setUpload, isSuccess, error } = useTus({ autoStart: true });
  const accessToken = useAccessToken();
  const { handleServerError } = useServerError();

  const [documentInfo, setDocumentInfo] = useState<DocumentInfo | null>(null);
  const [documentContent, setDocumentContent] = useState<Blob | null>(null);
  const [documentStagedForUpload, setDocumentStagedForUpload] = useState<boolean>(false);
  const [existingDocument, setExistingDocument] = useState<Document | null>(null);
  const [documentPendingDeletion, setDocumentPendingDeletion] = useState<DocumentInfo | null>(null);
  const [wasEverExistingDocument, setWasEverExistingDocument] = useState<boolean | null>(null);
  const [isUploading, setIsUploading] = useState(false);
  const [determinedDocumentSource, setDeterminedDocumentSource] = useState(true);
  const [documentDownloadError, setDocumentDownloadError] = useState<string | null>(null);

  const cacheDocument = useCallback(async () => {
    (async () => {
      if (documentInfo) {
        const { url, name } = documentInfo;
        const blob = await getBlobFromURL(url);
        await cacheService.set(name, blob);
      }
    })();
  }, [cacheService, documentInfo]);

  const documentValidatorDefinition = useMemo(
    () =>
      getDocumentValidatorDefinition(
        required,
        getRequiredDocumentValidator(
          acceptedMIMEPrefix,
          sizeLimit,
          s.FormValidationError_FieldRequired,
          s.FormValidationError_MultipleFilesSelectedError,
          s.FormValidationError_FileTypeNotSupportedError,
          s.FormValidationError_FileTooLargeError,
        ),
        getOptionalDocumentValidator(
          acceptedMIMEPrefix,
          sizeLimit,
          s.FormValidationError_MultipleFilesSelectedError,
          s.FormValidationError_FileTypeNotSupportedError,
          s.FormValidationError_FileTooLargeError,
        ),
      ),
    [acceptedMIMEPrefix, required, sizeLimit],
  );

  const setValidatedDocumentFromInput = useCallback(
    (document: File, cacheNow: boolean = false) => {
      setDocumentInfo({ url: URL.createObjectURL(document), name: document.name });
      cacheNow && cacheDocument();
      setDocumentStagedForUpload(true);
    },
    [cacheDocument],
  );
  const cleanupDocumentURL = useCallback(() => documentInfo && URL.revokeObjectURL(documentInfo.url), [documentInfo]);

  // Handles setting if ever there was an existing document.
  useEffect(() => {
    setWasEverExistingDocument(true);
  }, [existingDocument, setWasEverExistingDocument]);

  // Handle initial load of document.
  useEffect(() => {
    (async () => {
      if (!existingDocument || documentInfo || documentPendingDeletion) {
        setDeterminedDocumentSource(true);
        return;
      }

      // Try to get locally stored version
      if (await cacheService.has(existingDocument.filename)) {
        const documentContent = await cacheService.get(existingDocument.filename);
        setDocumentContent(documentContent);
        documentContent &&
          setDocumentInfo({ url: URL.createObjectURL(documentContent), name: existingDocument.filename, id: existingDocument.id });
        setDeterminedDocumentSource(true);
      } else {
        // Download if locally stored version is unavailable.
        if (accessToken) {
          try {
            const documentContent = await documentsService.download(accessToken, existingDocument.id);
            setDocumentContent(documentContent);
            documentContent &&
              setDocumentInfo({ url: URL.createObjectURL(documentContent), name: existingDocument.filename, id: existingDocument.id });
            await cacheService.set(existingDocument.filename, documentContent);
            setDeterminedDocumentSource(true);
          } catch (error) {
            const errorMessage = handleServerError(error);
            setDocumentDownloadError(errorMessage);
          }
        }
      }
    })();
    return () => {
      cleanupDocumentURL();
    };
  }, [accessToken, cacheService, cleanupDocumentURL, documentInfo, documentPendingDeletion, existingDocument, handleServerError]);

  const uploadDocument = useCallback(
    async (entityId: number, documentTypeId: DocumentTypeId) => {
      if (accessToken && documentInfo) {
        const document = await getBlobFromURL(documentInfo.url);
        setIsUploading(true);
        setUpload(document, {
          endpoint: `${BaseUrl}/documents`,
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
          metadata: {
            filename: documentInfo.name,
            filetype: document.type,
            entityid: entityId.toString(),
            documenttypeid: documentTypeId.toString(),
          },
        });
      }
    },
    [accessToken, documentInfo, setUpload],
  );

  const handleDownloadDocument = useCallback(async () => {
    if (!documentInfo) {
      return;
    }

    if (documentContent) {
      saveAs(documentContent, documentInfo.name);
    } else {
      let documentBlob: Blob | null = null;
      if (await cacheService.has(documentInfo.name)) {
        documentBlob = await cacheService.get(documentInfo.name);
      } else {
        if (accessToken && documentInfo.id) {
          documentBlob = await documentsService.download(accessToken, documentInfo.id);
        }
      }

      documentBlob && saveAs(documentBlob, documentInfo.name);
    }
  }, [accessToken, cacheService, documentContent, documentInfo]);

  const deleteDocument = useCallback(
    async (documentId: number, documentName: string) => {
      if (!accessToken || !documentId) {
        return;
      }
      await documentsService.delete(accessToken, documentId);
      await cacheService.remove(documentName);
    },
    [accessToken, cacheService],
  );

  const handleDeleteDocument = useCallback(async () => {
    if (!accessToken || !documentPendingDeletion || !existingDocument) {
      return;
    }
    try {
      await deleteDocument(existingDocument.id, existingDocument.filename);
      setDocumentPendingDeletion(null);
      setExistingDocument(null);
    } catch (error) {}
  }, [accessToken, deleteDocument, documentPendingDeletion, existingDocument]);

  const handleDestageDocument = useCallback(async () => {
    if (!documentInfo) {
      return;
    }
    // Only set the document for deletion if it has been uploaded, it will only have an id if it has been uploaded.
    documentInfo.id && setDocumentPendingDeletion(documentInfo);
    setDocumentStagedForUpload(false);
    (await cacheService.has(documentInfo.name)) && (await cacheService.remove(documentInfo.name));
    cleanupDocumentURL();
    setDocumentInfo(null);
  }, [cacheService, cleanupDocumentURL, documentInfo]);

  // Handle completed upload
  useEffect(() => {
    if (!isUploading) {
      return;
    }
    if (isSuccess) {
      setIsUploading(false);
      onUploadSuccess();
      return;
    }
    if (error) {
      onUploadError(error);
      setIsUploading(false);
    }
  }, [error, isSuccess, isUploading, onUploadError, onUploadSuccess]);

  return {
    setValidatedDocumentFromInput,
    documentValidatorDefinition,
    documentURL: documentInfo?.url,
    setExistingDocument,
    uploadDocument,
    documentStagedForUpload,
    handleDestageDocument,
    handleDownloadDocument,
    documentPendingDeletion,
    handleDeleteDocument,
    wasEverExistingDocument,
    determinedDocumentSource,
    documentDownloadError,
  };
};

export interface UseSingleDocumentInputCacheManagerReturn {
  setValidatedDocumentFromInput: (document: File, cacheNow?: boolean) => void;
  documentValidatorDefinition: ReturnType<typeof getDocumentValidatorDefinition>;
  documentURL: string | undefined;
  setExistingDocument: (document: Document) => void;
  uploadDocument: (entityId: number, documentTypeId: DocumentTypeId) => Promise<void>;
  documentStagedForUpload: boolean;
  handleDestageDocument: () => void;
  handleDownloadDocument: () => Promise<void>;
  documentPendingDeletion: DocumentInfo | null;
  handleDeleteDocument: () => Promise<void>;
  wasEverExistingDocument: boolean | null;
  determinedDocumentSource: boolean;
  documentDownloadError: string | null | undefined;
}
export type SingleDocumentInputCacheDocumentValidatorDefinition = Pick<
  UseSingleDocumentInputCacheManagerReturn,
  'documentValidatorDefinition'
>;
export type SingleDocumentInputCacheDocumentPendingDeletion = UseSingleDocumentInputCacheManagerReturn['documentPendingDeletion'];
export type SingleDocumentInputCacheHandleDelete = UseSingleDocumentInputCacheManagerReturn['handleDeleteDocument'];
export type SingleDocumentInputCacheDocumentStagedForUpload = UseSingleDocumentInputCacheManagerReturn['documentStagedForUpload'];
export type SingleDocumentInputCacheUpload = UseSingleDocumentInputCacheManagerReturn['uploadDocument'];
export type SingleDocumentInputCacheSetValidatedDocumentFromInput =
  UseSingleDocumentInputCacheManagerReturn['setValidatedDocumentFromInput'];
