import type { ChangeEvent, MouseEvent } from 'react';
import { useState } from 'react';

import { User, UserRole } from 'models/user';

import { s } from 'i18n/strings';

import {
  Box,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
  TextField,
  InputAdornment,
  Select,
  MenuItem,
  FormControl,
} from '@mui/material';
import { SelectChangeEvent } from '@mui/material/Select';

import {
  typedUtilityClassnames,
  onlyComputedCombineClassnames,
  flexDirection,
  width,
  height,
  padding,
  minWidth,
  textColor,
  overflow,
  appearance,
  gap,
  display,
} from 'style/compoundClassnames';

import { ReactComponent as SearchIcon } from 'icons/search.svg';

export interface UsersListProps {
  users: User[];
  allUserRoles: UserRole[];
  onSelectedUser: (id: string) => void;
}

export const UsersList = ({ users, allUserRoles, onSelectedUser }: UsersListProps) => {
  const [filteredUsers, setFilteredUsers] = useState<User[]>(users);

  const [searchedUsers, setSearchedUsers] = useState<User[] | null>(null);
  const [roleFilteredUsers, setRoleFilteredUsers] = useState<User[] | null>(null);

  const [selectedRoles, setSelectedRoles] = useState<string[]>([]);

  const handleSearchRequest = (event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    const searchedUsers = users.filter((user) => {
      return user.fullName.toLowerCase().includes(event.target.value.toLowerCase());
    });
    setSearchedUsers(searchedUsers!);
    setFilteredUsers(roleFilteredUsers != null ? searchedUsers!.filter((v) => roleFilteredUsers?.includes(v)) : searchedUsers!);
  };

  const handleRoleSelect = (event: SelectChangeEvent<string[]>) => {
    const {
      target: { value },
    } = event;
    const selectedRoles = typeof value === 'string' ? value.split(',') : value;
    setSelectedRoles(selectedRoles);

    const roleFilteredUsers = users.filter((user) => {
      return selectedRoles.every((roleId) => user.roles.map((r) => r.id).includes(parseInt(roleId)));
    });
    setRoleFilteredUsers(roleFilteredUsers!);
    setFilteredUsers(searchedUsers != null ? roleFilteredUsers!.filter((v) => searchedUsers?.includes(v)) : roleFilteredUsers!);
  };

  type Order = 'asc' | 'desc';
  const [order, setOrder] = useState<Order>('asc');
  const [orderBy, setOrderBy] = useState<keyof User>('fullName');

  const descendingComparator = <User extends unknown>(a: User, b: User, orderBy: keyof User) => {
    if (b[orderBy] < a[orderBy]) {
      return -1;
    }
    if (b[orderBy] > a[orderBy]) {
      return 1;
    }
    return 0;
  };

  const getComparator = <Key extends keyof User>(order: Order, orderBy: Key): ((a: User, b: User) => number) => {
    return order === 'desc' ? (a, b) => descendingComparator(a, b, orderBy) : (a, b) => -descendingComparator(a, b, orderBy);
  };

  const handleRequestSort = (property: keyof User) => (_: MouseEvent) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const userRolesAsString = (user: User) => {
    return user.roles?.map((r) => r.name).join(', ');
  };

  return (
    <div className={onlyComputedCombineClassnames(typedUtilityClassnames(overflow('overflow-x-hidden'), padding('pt-2')))}>
      <TableContainer>
        {/* Search control */}
        <Box
          sx={{ display: 'flex', justifyContent: 'space-between' }}
          className={typedUtilityClassnames(flexDirection('flex-col', 'sm:flex-row'), gap('gap-2'), width('w-full'))}
        >
          <TextField
            variant="outlined"
            size="medium"
            placeholder={s.UsersList_SearchFieldPlaceholder}
            className={typedUtilityClassnames(width('w-full', 'sm:w-80'), 'subtitle1')}
            sx={{ width: '20rem' }}
            onChange={(searchedValue) => handleSearchRequest(searchedValue)}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon className={typedUtilityClassnames('icons', width('w-5'))} />
                </InputAdornment>
              ),
            }}
          />

          {/* Roles filter control */}
          <Box
            sx={{ display: 'flex' }}
            className={typedUtilityClassnames(flexDirection('flex-col', 'sm:flex-row'), width('w-full', 'sm:w-60'))}
          >
            <Typography className={typedUtilityClassnames('label', height('h-fit'), padding('p-4'))}>{s.UsersList_RolesFilter}</Typography>
            <FormControl className={typedUtilityClassnames(width('w-full', 'sm:w-72'))} size="medium">
              <Select multiple value={selectedRoles} onChange={handleRoleSelect}>
                {allUserRoles.map((role) => (
                  <MenuItem key={role.id} value={role.id}>
                    {role.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Box>
        </Box>

        {/* Big/desktop table */}
        <div
          className={typedUtilityClassnames(
            padding('pt-6'),
            overflow('overflow-x-auto'),
            appearance('appearance-none'),
            display('lg:flex', 'hidden'),
          )}
        >
          <Table className={typedUtilityClassnames(minWidth('min-w-min'))}>
            <TableHead>
              <TableRow>
                <TableCell className={typedUtilityClassnames('label')} sortDirection={order}>
                  <TableSortLabel active={true} direction={order} onClick={handleRequestSort('fullName')}>
                    {s.UsersList_NameColumnTitle}
                  </TableSortLabel>
                </TableCell>
                <TableCell className={typedUtilityClassnames('label')} align="left">
                  {s.UsersList_RolesColumnTitle}
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody className={typedUtilityClassnames('body1')}>
              {filteredUsers.sort(getComparator(order, 'fullName')).map((user) => (
                <TableRow key={user.id} onClick={() => onSelectedUser(user.id)} hover>
                  <TableCell scope="row" sx={{ width: '30%' }}>
                    <Typography>{user.fullName}</Typography>
                    <Typography className={typedUtilityClassnames(textColor('text-grey-700'))}>{user.emailAddress}</Typography>
                  </TableCell>
                  <TableCell align="left">{userRolesAsString(user)}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </div>

        {/* Small/mobile table */}
        <div
          className={typedUtilityClassnames(
            padding('pt-6'),
            overflow('overflow-x-auto'),
            appearance('appearance-none'),
            display('lg:hidden', 'flex'),
          )}
        >
          <Table className={typedUtilityClassnames(width('w-full'))}>
            <TableHead>
              <TableRow>
                <TableCell className={typedUtilityClassnames('label')} sortDirection={order}>
                  <TableSortLabel active={true} direction={order} onClick={handleRequestSort('fullName')}>
                    {s.UsersList_NameColumnTitle}
                  </TableSortLabel>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody className={typedUtilityClassnames('body1')}>
              {filteredUsers.sort(getComparator(order, 'fullName')).map((user) => (
                <TableRow key={user.id} onClick={() => onSelectedUser(user.id)} hover>
                  <TableCell scope="row" sx={{ width: '30%' }}>
                    <Typography>{user.fullName}</Typography>
                    <Typography className={typedUtilityClassnames(textColor('text-grey-700'))}>{user.emailAddress}</Typography>
                    {userRolesAsString(user) && (
                      <Typography className={typedUtilityClassnames('label', padding('pt-6'))}>{s.UsersList_RolesColumnTitle}</Typography>
                    )}
                    {userRolesAsString(user) && (
                      <Typography className={typedUtilityClassnames('body2')}>{userRolesAsString(user)}</Typography>
                    )}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </div>
      </TableContainer>
    </div>
  );
};
