import { ReactElement, useEffect, useState } from 'react';

import { stateKinds } from 'models/state';
import { shipmentStates, ShipmentSummary } from 'models/shipment';
import { TruckStates } from 'models/truck';
import { Activity } from 'models/activity';
import { shipmentsService } from 'services/shipmentsService';
import { useAccessToken, useServerError } from 'hooks';
import { formatDate } from 'utilities';

import { s } from 'i18n/strings';

import {
  alignItems,
  backgroundColor,
  borderColor,
  borderRadius,
  borderWidth,
  display,
  flexDirection,
  height,
  justifyContent,
  padding,
  position,
  typedUtilityClassnames,
  width,
  zIndex,
  overflow,
  inset,
  textColor,
  onlyComputedCombineClassnames,
  fontWeight,
} from 'style/compoundClassnames';

import { Spinner } from 'components/Spinner';
import { Notification } from 'components/Notification';

import { ReactComponent as ShipmentActivityStateIcon } from 'icons/activityStateShipment.svg';
import { ReactComponent as TruckActivityStateIcon } from 'icons/activityStateTruck.svg';
import { ReactComponent as TruckArrivedActivityStateIcon } from 'icons/activityStateTruckArrived.svg';
import { ReactComponent as BagsActivityStateIcon } from 'icons/activityStateBags.svg';
import { ReactComponent as DrumsActivityStateIcon } from 'icons/activityStateDrums.svg';
import { ReactComponent as ContainerActivityStateIcon } from 'icons/activityStateContainer.svg';
import { ReactComponent as FreightForwarderActivityStateIcon } from 'icons/activityStateFreightForwarder.svg';

const mainContentClassNames = onlyComputedCombineClassnames(
  typedUtilityClassnames('mainLayoutPadding'),
  display('flex'),
  justifyContent('justify-center'),
  flexDirection('flex-col'),
);
const stepperContainerClassNames = typedUtilityClassnames(display('flex'), flexDirection('flex-col'), alignItems('items-center'));
const stepContainerClassNames = typedUtilityClassnames(display('flex'), overflow('overflow-clip'));
const stepIconContainerClassNames = typedUtilityClassnames(
  backgroundColor('bg-primary-50'),
  height('h-8'),
  width('w-8'),
  borderRadius('rounded-2xl'),
  display('flex'),
  justifyContent('justify-center'),
  alignItems('items-center'),
  zIndex('z-10'),
);
const stepIconClassNames = typedUtilityClassnames();
const stepConnectorClassNames = typedUtilityClassnames(
  position('relative'),
  display('flex'),
  justifyContent('justify-center'),
  alignItems('items-center'),
);
const stepConnectorLineClassNames = typedUtilityClassnames(
  height('h-full'),
  borderWidth('border-l'),
  borderColor('border-onSurface-disabled'),
  position('absolute'),
  zIndex('z-0'),
);
const getStepConnectorLineClassNames = (isFirst: boolean, isLast: boolean) =>
  isFirst && isLast
    ? typedUtilityClassnames()
    : typedUtilityClassnames(stepConnectorLineClassNames, inset({ 'top-1/2': isFirst, 'bottom-1/2': isLast }));
const stepCaptionContainerClassNames = typedUtilityClassnames(
  display('flex'),
  justifyContent('justify-start'),
  flexDirection('flex-col'),
  padding('py-3', 'px-4'),
);
const stepTitleClassNames = typedUtilityClassnames('label', textColor('text-primary-600'));
const stepCaptionClassNames = typedUtilityClassnames('caption');
const stepEmployeeNameClassNames = typedUtilityClassnames(textColor('text-primary-400'));
const stepOverrideReasonContentClassNames = typedUtilityClassnames('caption', width('w-60'), padding('pt-2'));
const stepOverrideReasonCaptionClassNames = typedUtilityClassnames(fontWeight('font-bold'), textColor('text-primary-600'));

type ActivityState = {
  titleTransform: (name: string, kind: string) => string;
  icon: (classNames: string) => ReactElement;
};

const getActivityState = (name: string, kind: string, isOverridden: boolean) => {
  switch (kind) {
    case stateKinds.Shipment:
      switch (name) {
        case shipmentStates.BagsProcessed:
          return {
            titleTransform: (name: string) => (isOverridden ? `${name} (${s.ShipmentActivity_OverriddenCaption})` : name),
            icon: (classNames: string) => (
              <BagsActivityStateIcon className={classNames} title={s.ShipmentActivity_BagsActivityStateIconTitle} />
            ),
          } as ActivityState;
        case shipmentStates.DrumsProcessed:
          return {
            titleTransform: (name: string) => (isOverridden ? `${name} (${s.ShipmentActivity_OverriddenCaption})` : name),
            icon: (classNames: string) => (
              <DrumsActivityStateIcon className={classNames} title={s.ShipmentActivity_DrumsActivityStateIconTitle} />
            ),
          } as ActivityState;
        case shipmentStates.WithFreightForwarder:
          return {
            titleTransform: (name: string) => (isOverridden ? `${name} (${s.ShipmentActivity_OverriddenCaption})` : name),
            icon: (classNames: string) => (
              <FreightForwarderActivityStateIcon className={classNames} title={s.ShipmentActivity_FreightForwarderActivityStateIconTitle} />
            ),
          } as ActivityState;
        case shipmentStates.ContainersProcessed:
          return {
            titleTransform: (name: string) => (isOverridden ? `${name} (${s.ShipmentActivity_OverriddenCaption})` : name),
            icon: (classNames: string) => (
              <ContainerActivityStateIcon className={classNames} title={s.ShipmentActivity_ContainerActivityStateIconTitle} />
            ),
          } as ActivityState;
        default:
          return {
            titleTransform: (name: string) => (isOverridden ? `${name} (${s.ShipmentActivity_OverriddenCaption})` : name),
            icon: (classNames: string) => (
              <ShipmentActivityStateIcon className={classNames} title={s.ShipmentActivity_ShipmentActivityStateIconTitle} />
            ),
          } as ActivityState;
      }

    case stateKinds.Truck:
      switch (name) {
        case TruckStates.Arrived:
          return {
            titleTransform: (name: string, kind: string) =>
              isOverridden ? `${kind} ${name} (${s.ShipmentActivity_OverriddenCaption})` : `${kind} ${name}`,
            icon: (classNames: string) => (
              <TruckArrivedActivityStateIcon className={classNames} title={s.ShipmentActivity_TruckArrivedActivityStateIconTitle} />
            ),
          } as ActivityState;
        default:
          return {
            titleTransform: (name: string, kind: string) =>
              isOverridden ? `${kind} ${name} (${s.ShipmentActivity_OverriddenCaption})` : `${kind} ${name}`,
            icon: (classNames: string) => (
              <TruckActivityStateIcon className={classNames} title={s.ShipmentActivity_TruckActivityStateIconTitle} />
            ),
          } as ActivityState;
      }

    case stateKinds.Container:
      return {
        titleTransform: (name: string, kind: string) =>
          isOverridden ? `${kind} ${name} (${s.ShipmentActivity_OverriddenCaption})` : `${kind} ${name}`,
        icon: (classNames: string) => (
          <ContainerActivityStateIcon className={classNames} title={s.ShipmentActivity_ContainerActivityStateIconTitle} />
        ),
      } as ActivityState;

    default:
      return {
        titleTransform: (name: string) => (isOverridden ? `${name} (${s.ShipmentActivity_OverriddenCaption})` : name),
        icon: (classNames: string) => (
          <ShipmentActivityStateIcon className={classNames} title={s.ShipmentActivity_ShipmentActivityStateIconTitle} />
        ),
      } as ActivityState;
  }
};

interface ShipmentActivityStepProps {
  activity: Activity;
  parentSetLength: number;
  currentIndexInParentSet: number;
}

const ShipmentActivityStep = ({
  activity: {
    entityState: { name, kind, isOverridden, overrideReason },
    employeeName,
    transitionDate,
  },
  parentSetLength,
  currentIndexInParentSet,
}: ShipmentActivityStepProps): JSX.Element => {
  const { titleTransform, icon } = getActivityState(name, kind, isOverridden);

  return (
    <div className={stepContainerClassNames}>
      <div className={stepConnectorClassNames}>
        <div className={getStepConnectorLineClassNames(currentIndexInParentSet === 0, currentIndexInParentSet === parentSetLength - 1)} />
        <div className={stepIconContainerClassNames}>{icon(stepIconClassNames)}</div>
      </div>
      <div className={stepCaptionContainerClassNames}>
        <h3 className={stepTitleClassNames}>{titleTransform(name, kind)}</h3>
        <p className={stepCaptionClassNames}>
          <span className={stepEmployeeNameClassNames}>{employeeName}</span>&nbsp;on {formatDate.getActivityDate(transitionDate)}
        </p>
        {isOverridden && (
          <p className={stepOverrideReasonContentClassNames}>
            <span className={stepOverrideReasonCaptionClassNames}>{s.ShipmentActivity_OverrideReasonCaption}:</span> {overrideReason}
          </p>
        )}
      </div>
    </div>
  );
};

export interface ShipmentActivityProps {
  shipmentSummary: ShipmentSummary;
}

export const ShipmentActivity = ({ shipmentSummary }: ShipmentActivityProps): JSX.Element => {
  const accessToken = useAccessToken();
  const { handleServerError } = useServerError();

  const [activities, setActivities] = useState<Activity[] | null>(null);

  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    (async () => {
      try {
        setError(null);
        setIsLoading(true);
        if (accessToken) {
          const activities = await shipmentsService.getAllActivity(accessToken, shipmentSummary.id);
          setActivities(activities);
        }
      } catch (error) {
        const errorMessage = handleServerError(error);
        setError(`${s.ShipmentActivity_RetrievingActivitiesError}: ${errorMessage}`);
      } finally {
        setIsLoading(false);
      }
    })();
  }, [accessToken, handleServerError, shipmentSummary]);

  return (
    <>
      {isLoading ? (
        <Spinner />
      ) : (
        <div className={mainContentClassNames}>
          <Notification message={error!} severity="error" onClose={() => setError(null)} />
          <div className={stepperContainerClassNames}>
            {activities &&
              activities.map((a, i) => (
                <ShipmentActivityStep key={a.id} activity={a} parentSetLength={activities.length} currentIndexInParentSet={i} />
              ))}
          </div>
        </div>
      )}
    </>
  );
};
