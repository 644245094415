import { ChangeEvent, useState } from 'react';

import { s } from 'i18n/strings';

import { FieldError, FieldErrors, SubmitHandler, UseFormHandleSubmit, UseFormRegister } from 'react-hook-form';
import { z } from 'zod';
import { ExclusivelyStringKeyedRecordAsNonOptional, FormFieldNames } from 'utility-types';
import { getOptionalInputLength, getRequiredEmailInputLength, getRequiredInputLength } from 'utilities/formValidation/stringValidation';

import {
  borderColor,
  borderRadius,
  borderWidth,
  display,
  flexDirection,
  gap,
  gridRowStart,
  gridTemplateColumns,
  gridTemplateRows,
  height,
  margin,
  outlineColor,
  padding,
  textAlign,
  textColor,
  typedUtilityClassnames,
} from 'style/compoundClassnames';

import { UncontrolledTextInput } from 'components/form-components';

import { ReactComponent as ShipmentIcon } from 'icons/shipment.svg';
import { ReactComponent as WarehouseIcon } from 'icons/warehouse.svg';
import { formatDate } from 'utilities';

const sectionsSharedClassNames = typedUtilityClassnames(
  display('grid'),
  gridTemplateColumns('lg:grid-cols-2', 'grid-cols-1'),
  gap('gap-x-6'),
);
const formClassNames = typedUtilityClassnames('nonExpandingMainLayoutContentSection');
const formDateSectionClassNames = typedUtilityClassnames(sectionsSharedClassNames, margin('mb-8'));
const formSectionClassNames = typedUtilityClassnames(sectionsSharedClassNames, gridTemplateRows('grid-rows-mc4', 'lg:grid-rows-mc3'));
const sectionsTitlesSharedClassNames = typedUtilityClassnames('headline5', display('flex'), textColor('text-primary-900'), padding('pb-6'));
const sectionsTitleIconContainerClassNames = typedUtilityClassnames(margin('mt-1'), padding('pr-3'));
const sectionFirstRowSharedClassNames = typedUtilityClassnames(gridRowStart('lg:row-start-2'));
const sectionSecondRowSharedClassNames = typedUtilityClassnames(gridRowStart('lg:row-start-3'));
const sectionThirdRowSharedClassNames = typedUtilityClassnames(gridRowStart('lg:row-start-4'));
const sailDateInputAndLabelContainerClassNames = typedUtilityClassnames(display('flex'), flexDirection('flex-col'));
const sailDateInputLabelClassNames = typedUtilityClassnames('label', padding('pb-2'));
const sailDateInputClassNames = typedUtilityClassnames(
  'headline5',
  height('h-14'),
  padding('p-4'),
  outlineColor('focus:outline-black'),
  borderWidth('border'),
  borderColor('border-onSurface-disabled'),
  borderRadius('rounded'),
  textAlign('text-left'),
  textColor('disabled:text-onSurface-disabled'),
);

const shipRequiredFieldsValidation = (minLength: number, maxLength: number) =>
  getRequiredInputLength(
    s.FormValidationError_FieldRequired,
    minLength,
    s.FormValidationError_MinimumLengthError.replace('{{length}}', minLength.toString()),
    maxLength,
    s.FormValidationError_MaximumLengthError.replace('{{length}}', maxLength.toString()),
  );

const shipOptionalFieldsValidation = (minLength: number, maxLength: number) =>
  getOptionalInputLength(
    minLength,
    s.FormValidationError_MinimumLengthError.replace('{{length}}', minLength.toString()),
    maxLength,
    s.FormValidationError_MaximumLengthError.replace('{{length}}', maxLength.toString()),
  );

const shipRequiredEmailFieldValidation = (maxLength: number) =>
  getRequiredEmailInputLength(
    s.FormValidationError_FieldRequired,
    s.FormValidationError_InvalidEmail,
    maxLength,
    s.FormValidationError_MaximumLengthError.replace('{{length}}', maxLength.toString()),
  );

export const shipFormStateSchema = z.object({
  shipRegistrationNumber: shipRequiredFieldsValidation(5, 30),
  shipName: shipRequiredFieldsValidation(2, 50),
  billOfLadingId: shipRequiredFieldsValidation(5, 50),
  shipContainerId: shipRequiredFieldsValidation(5, 30),
  contactFirstName: shipRequiredFieldsValidation(1, 50),
  contactLastName: shipRequiredFieldsValidation(1, 50),
  contactCompany: shipRequiredFieldsValidation(1, 100),
  agent: shipRequiredEmailFieldValidation(100),
  carrier: shipOptionalFieldsValidation(3, 50),
});

export type ShipFormState = z.infer<typeof shipFormStateSchema>;

export const shipFormStateFieldNames: ExclusivelyStringKeyedRecordAsNonOptional<FormFieldNames<ShipFormState>> = {
  shipRegistrationNumber: 'shipRegistrationNumber',
  shipName: 'shipName',
  billOfLadingId: 'billOfLadingId',
  shipContainerId: 'shipContainerId',
  contactFirstName: 'contactFirstName',
  contactLastName: 'contactLastName',
  contactCompany: 'contactCompany',
  agent: 'agent',
  carrier: 'carrier',
} as const;

export interface ShipFormProps {
  formId: string;
  disallowEdit: boolean;
  onSubmit: SubmitHandler<ShipFormState>;
  handleSubmit: UseFormHandleSubmit<ShipFormState>;
  register: UseFormRegister<ShipFormState>;

  shipRegistrationNumberInputError: FieldError | undefined;
  shipNameInputError: FieldError | undefined;
  billOfLadingIdInputError: FieldError | undefined;
  shipmentContainerIdInputError: FieldError | undefined;
  contactFirstNameInputError: FieldError | undefined;
  contactLastNameInputError: FieldError | undefined;
  contactCompanyInputError: FieldError | undefined;
  agentInputError: FieldError | undefined;
  carrierInputError: FieldError | undefined;

  errors: FieldErrors<ShipFormState>;

  // TODO: this should be a part of the form
  sailDate: Date | null;
  sailDateChanged: (date: Date) => void;
}

export const ShipForm = ({
  formId,
  disallowEdit,
  onSubmit,
  handleSubmit,
  register,
  shipRegistrationNumberInputError,
  shipNameInputError,
  billOfLadingIdInputError,
  shipmentContainerIdInputError,
  contactFirstNameInputError,
  contactLastNameInputError,
  contactCompanyInputError,
  agentInputError,
  carrierInputError,
  errors,
  sailDate,
  sailDateChanged,
}: ShipFormProps): JSX.Element => {
  // TODO: sail date should be moved to form validation
  const [date, setDate] = useState<Date | null>(sailDate);

  const handleSailDateChange = (event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    const date = new Date(event.target.value);
    setDate(date);
    sailDateChanged(date);
  };

  return (
    <form id={formId} onSubmit={handleSubmit(onSubmit)} className={formClassNames}>
      <section className={formDateSectionClassNames}>
        <div className={sailDateInputAndLabelContainerClassNames}>
          <div className={sailDateInputLabelClassNames}>{s.ShipmentDetailsPage_SailDateInputLabel}</div>
          <input
            type="date"
            defaultValue={date ? formatDate.getDate(date) : undefined}
            min={formatDate.getDate(new Date())}
            className={sailDateInputClassNames}
            onChange={handleSailDateChange}
            disabled={disallowEdit}
          />
        </div>
      </section>
      <section className={formSectionClassNames}>
        <div className={sectionsTitlesSharedClassNames}>
          <div className={sectionsTitleIconContainerClassNames}>
            <ShipmentIcon />
          </div>
          {s.ShipmentDetailsPage_ShipInformationSectionTitle}
        </div>
        <div className={sectionFirstRowSharedClassNames}>
          <UncontrolledTextInput<ShipFormState, typeof shipFormStateFieldNames.shipRegistrationNumber>
            readOnly={disallowEdit}
            label={s.ShipmentDetailsPage_ShipRegistrationNoInputLabel}
            {...register(shipFormStateFieldNames.shipRegistrationNumber, { disabled: disallowEdit })}
            hasError={!!shipRegistrationNumberInputError}
            errors={errors}
            isRequired={true}
          />
        </div>
        <div className={sectionFirstRowSharedClassNames}>
          <UncontrolledTextInput<ShipFormState, typeof shipFormStateFieldNames.shipName>
            readOnly={disallowEdit}
            label={s.ShipmentDetailsPage_ShipNameInputLabel}
            {...register(shipFormStateFieldNames.shipName, { disabled: disallowEdit })}
            hasError={!!shipNameInputError}
            errors={errors}
            isRequired={true}
          />
        </div>
        <div className={sectionSecondRowSharedClassNames}>
          <UncontrolledTextInput<ShipFormState, typeof shipFormStateFieldNames.billOfLadingId>
            readOnly={disallowEdit}
            label={s.ShipmentDetailsPage_BillOfLadingIdInputLabel}
            {...register(shipFormStateFieldNames.billOfLadingId, { disabled: disallowEdit })}
            hasError={!!billOfLadingIdInputError}
            errors={errors}
            isRequired={true}
          />
        </div>
        <div className={sectionSecondRowSharedClassNames}>
          <UncontrolledTextInput<ShipFormState, typeof shipFormStateFieldNames.shipContainerId>
            readOnly={disallowEdit}
            label={s.ShipmentDetailsPage_ContainerIdInputLabel}
            {...register(shipFormStateFieldNames.shipContainerId, { disabled: disallowEdit })}
            hasError={!!shipmentContainerIdInputError}
            errors={errors}
            isRequired={true}
          />
        </div>
        <div className={sectionThirdRowSharedClassNames}>
          <UncontrolledTextInput<ShipFormState, typeof shipFormStateFieldNames.carrier>
            readOnly={disallowEdit}
            label={s.ShipmentDetailsPage_CarrierInputLabel}
            {...register(shipFormStateFieldNames.carrier, { disabled: disallowEdit })}
            hasError={!!carrierInputError}
            errors={errors}
            isRequired={false}
          />
        </div>
      </section>

      <section className={formSectionClassNames}>
        <div className={sectionsTitlesSharedClassNames}>
          <div className={sectionsTitleIconContainerClassNames}>
            <WarehouseIcon />
          </div>
          {s.ShipmentDetailsPage_WarehouseInformationSectionTitle}
        </div>
        <div className={sectionFirstRowSharedClassNames}>
          <UncontrolledTextInput<ShipFormState, typeof shipFormStateFieldNames.contactFirstName>
            readOnly={disallowEdit}
            label={s.ShipmentDetailsPage_WarehouseContactFirstNameInputLabel}
            {...register(shipFormStateFieldNames.contactFirstName, { disabled: disallowEdit })}
            hasError={!!contactFirstNameInputError}
            errors={errors}
            isRequired={true}
          />
        </div>
        <div className={sectionFirstRowSharedClassNames}>
          <UncontrolledTextInput<ShipFormState, typeof shipFormStateFieldNames.contactLastName>
            readOnly={disallowEdit}
            label={s.ShipmentDetailsPage_WarehouseContactLastNameInputLabel}
            {...register(shipFormStateFieldNames.contactLastName, { disabled: disallowEdit })}
            hasError={!!contactLastNameInputError}
            errors={errors}
            isRequired={true}
          />
        </div>
        <div className={sectionSecondRowSharedClassNames}>
          <UncontrolledTextInput<ShipFormState, typeof shipFormStateFieldNames.contactCompany>
            readOnly={disallowEdit}
            label={s.ShipmentDetailsPage_WarehouseContactCompanyInputLabel}
            {...register(shipFormStateFieldNames.contactCompany, { disabled: disallowEdit })}
            hasError={!!contactCompanyInputError}
            errors={errors}
            isRequired={true}
          />
        </div>
        <div className={sectionSecondRowSharedClassNames}>
          <UncontrolledTextInput<ShipFormState, typeof shipFormStateFieldNames.agent>
            readOnly={disallowEdit}
            label={s.ShipmentDetailsPage_WarehouseAgentInputLabel}
            {...register(shipFormStateFieldNames.agent, { disabled: disallowEdit })}
            hasError={!!agentInputError}
            errors={errors}
            isRequired={true}
          />
        </div>
      </section>
    </form>
  );
};
