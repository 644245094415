import { useState, useEffect } from 'react';
import { useMsal } from '@azure/msal-react';
import { loginRequest } from 'authConfig';

export const useAccessToken = () => {
  const { instance, accounts } = useMsal();

  const [accessToken, setAccessToken] = useState<string | null>(null);

  const account = accounts[0];

  useEffect(() => {
    (async () => {
      try {
        if (account != null) {
          const accessTokenResponse = await instance.acquireTokenSilent({ ...loginRequest, account });
          setAccessToken(accessTokenResponse.accessToken);
        }
      } catch (error) {
        console.error('Could not acquire access token');
        await instance.loginRedirect(loginRequest);
      }
    })();
  }, [accounts, account, instance]);

  return accessToken;
};
