import { useState, useEffect, useCallback } from 'react';
import { useRecoilValue } from 'recoil';

import { useMsal, MsalAuthenticationTemplate } from '@azure/msal-react';
import { AccountInfo, InteractionType } from '@azure/msal-browser';

import { Router } from 'navigation/routes';
import { User } from 'models/user';
import { usersService } from 'services/usersService';
import { sessionStorageService, sessionStorageKeys } from 'services/sessionStorageService';
import { useAccessToken, offlineState } from 'hooks';

import { LoginProgress } from 'components/LoginProgress';

import {
  backgroundColor,
  display,
  justifyContent,
  padding,
  textColor,
  textTransform,
  typedUtilityClassnames,
} from 'style/compoundClassnames';
import { s } from 'i18n/strings';

const offlineStateIndicatorClassNames = typedUtilityClassnames(
  'headline5',
  display('flex'),
  justifyContent('justify-center'),
  backgroundColor('bg-error-600'),
  textColor('text-white'),
  padding('p-1.5'),
  textTransform('uppercase'),
);

export const App = () => {
  const { accounts } = useMsal();
  const accessToken = useAccessToken();

  const [user, setUser] = useState<User | null>(null);
  const [isLoginComplete, setIsLoginComplete] = useState(true);
  const [isLoginError, setIsLoginError] = useState(false);

  const account = accounts[0];

  const offline = useRecoilValue(offlineState);

  const handleLogin = useCallback(
    async (accountInfo: AccountInfo) => {
      if (!sessionStorageService.has(sessionStorageKeys.loggedInUserData)) {
        setIsLoginComplete(false);
      } else {
        setUser(sessionStorageService.get<User>(sessionStorageKeys.loggedInUserData));
      }

      // always get latest user info on reload (in case of role change)
      const loggedInUser = new User(accountInfo.localAccountId, accountInfo.name!, accountInfo.username);
      if (accessToken != null) {
        const userDataFromApi = await usersService.getById(accessToken, accountInfo.localAccountId).catch((e) => {
          console.error(`Could not retrieve user data for ${accountInfo.localAccountId}`); // TODO: handle error in a proper way
          setIsLoginError(true);
          throw e;
        });

        if (userDataFromApi?.roles == null || userDataFromApi.roles.length === 0) {
          setIsLoginError(true);
        } else {
          loggedInUser.roles = userDataFromApi?.roles!;
          sessionStorageService.set<User>(sessionStorageKeys.loggedInUserData, loggedInUser);
          setUser(loggedInUser);
          setIsLoginComplete(true);
        }
      }
    },
    [accessToken],
  );

  useEffect(() => {
    if (account) {
      handleLogin(account).catch(console.error);
    } else {
      setIsLoginComplete(false);
    }
  }, [account, handleLogin]);

  return (
    <>
      <MsalAuthenticationTemplate interactionType={InteractionType.Redirect}>
        {offline && <div className={offlineStateIndicatorClassNames}>{s.App_OfflineCaption}</div>}
        {isLoginComplete && user ? <Router user={user} /> : <LoginProgress account={account} loginFailed={isLoginError} />}
      </MsalAuthenticationTemplate>
    </>
  );
};
