import { useEffect, useState, useCallback, useMemo } from 'react';
import { useParams } from 'react-router';
import { SubmitHandler, useForm } from 'react-hook-form';

import { Shipment } from 'models/shipment';
import { Drum } from 'models/drum';
import { Container, containerStates, ContainerWrite } from 'models/container';
import { documentTypes } from 'models/document';
import { User } from 'models/user';
import type { Document, DocumentTypeId } from 'models/document';
import { shipmentsService } from 'services/shipmentsService';
import { containerService } from 'services/containerService';
import { permissionsService } from 'services/permissionsService';
import { useAccessToken, useLoggedInUser, useAppNavigation, useServerError } from 'hooks';
import {
  SingleDocumentInputCacheSetValidatedDocumentFromInput,
  useSingleDocumentInputCache,
  UseSingleDocumentInputCacheManagerReturn,
} from 'hooks/useSingleDocumentInputCache';

import { s } from 'i18n/strings';

import { display, justifyContent, typedUtilityClassnames } from 'style/compoundClassnames';

import { ContainerDrumsForm } from 'components/form-components/forms';
import { buttonTypes, PrimaryButton, SecondaryButton } from 'components/buttons';
import { ContextHeader } from 'components/headers';
import { Spinner } from 'components/Spinner';
import { Notification } from 'components/Notification';

import { z } from 'zod';
import { zodResolver } from '@hookform/resolvers/zod';

import { getOptionalInputLength, getRequiredInputLength } from 'utilities/formValidation/stringValidation';
import { ExclusivelyStringKeyedRecordAsNonOptional, FormFieldNames } from 'utility-types';

const containerFormId = 'containerForm';

const containerRequiredFieldsValidation = (minLength: number, maxLength: number) =>
  getRequiredInputLength(
    s.FormValidationError_FieldRequired,
    minLength,
    s.FormValidationError_MinimumLengthError.replace('{{length}}', minLength.toString()),
    maxLength,
    s.FormValidationError_MaximumLengthError.replace('{{length}}', maxLength.toString()),
  );

const containerOptionalFieldsValidation = (minLength: number, maxLength: number) =>
  getOptionalInputLength(
    minLength,
    s.FormValidationError_MinimumLengthError.replace('{{length}}', minLength.toString()),
    maxLength,
    s.FormValidationError_MaximumLengthError.replace('{{length}}', maxLength.toString()),
  );

const containerDrumFormStateSchema = z.object({
  tagBoxId: containerRequiredFieldsValidation(1, 20),
  containerSealId: containerOptionalFieldsValidation(3, 20),
  leopardLockId: containerOptionalFieldsValidation(3, 20),
});

export type ContainerDrumFormState = z.infer<typeof containerDrumFormStateSchema>;
export type ContainerDrumFormStatePhotosOnly = {
  tagBoxPhoto: FileList | undefined;
  emptyContainerPhoto: FileList | undefined;
  filledContainerPhoto: FileList | undefined;
  containerSealIdPhoto: FileList | undefined;
  leopardLockIdPhoto: FileList | undefined;
  sealedContainerPhoto: FileList | undefined;
};
export type ContainerDrumFormStatePhotosOnlyKeys = keyof ContainerDrumFormStatePhotosOnly;
export type ContainerDrumFormStateWithPhotos = ContainerDrumFormState & ContainerDrumFormStatePhotosOnly;

export const containerDrumFormStateFieldNames: ExclusivelyStringKeyedRecordAsNonOptional<FormFieldNames<ContainerDrumFormState>> = {
  tagBoxId: 'tagBoxId',
  containerSealId: 'containerSealId',
  leopardLockId: 'leopardLockId',
} as const;

export const containerDrumFormStatePhotosOnlyFieldNames: ExclusivelyStringKeyedRecordAsNonOptional<
  FormFieldNames<ContainerDrumFormStatePhotosOnly>
> = {
  tagBoxPhoto: 'tagBoxPhoto',
  emptyContainerPhoto: 'emptyContainerPhoto',
  filledContainerPhoto: 'filledContainerPhoto',
  containerSealIdPhoto: 'containerSealIdPhoto',
  leopardLockIdPhoto: 'leopardLockIdPhoto',
  sealedContainerPhoto: 'sealedContainerPhoto',
} as const;

export const containerDrumFormStateWithPhotosFieldNames: typeof containerDrumFormStatePhotosOnlyFieldNames &
  typeof containerDrumFormStateFieldNames = {
  ...containerDrumFormStateFieldNames,
  ...containerDrumFormStatePhotosOnlyFieldNames,
} as const;

const canEditContainerDrumForm = (user: User | null, container: Container | null, isSavingForm: boolean) => {
  if (!user || isSavingForm) {
    return false;
  }

  return (
    permissionsService.canEditContainer(user) &&
    (!container || (container != null && container.state.name !== containerStates.Sealed && container.state.name !== containerStates.Done))
  );
};

type PhotoManagers = {
  readonly [PhotoManagerName: string]: UseSingleDocumentInputCacheManagerReturn;
};

const areAnyPhotosToDeleteOrUpload = (photoManagers: PhotoManagers): readonly [anyPhotosToDelete: boolean, anyPhotosToUpload: boolean] => {
  return [
    Object.entries(photoManagers)
      .map(([, { documentPendingDeletion }]) => !!documentPendingDeletion)
      .reduce((prev, curr) => prev || curr),
    Object.entries(photoManagers)
      .map(([, { documentStagedForUpload }]) => !!documentStagedForUpload)
      .reduce((prev, curr) => prev || curr),
  ] as const;
};

type PhotoManagersToDocumentTypeMap<
  TPhotoManagers extends PhotoManagers,
  // eslint-disable-next-line no-unused-vars
> = { readonly [_TPhotoManagerName in keyof TPhotoManagers]: DocumentTypeId };

const getAllHandleDeletionsToAwait = (photoManagers: PhotoManagers) => {
  return Object.values(photoManagers)
    .filter(({ documentPendingDeletion }) => documentPendingDeletion)
    .map(({ handleDeleteDocument }) => handleDeleteDocument());
};

const getAllHandleUploadsToAwait = function <TPhotoManagers extends PhotoManagers>(
  photoManagers: TPhotoManagers,
  photoManagerDocumentTypeMap: PhotoManagersToDocumentTypeMap<TPhotoManagers>,
  entityId: number,
) {
  return Object.entries(photoManagers)
    .filter(([, { documentStagedForUpload }]) => documentStagedForUpload)
    .map(([photoManagerName, { uploadDocument }]) => uploadDocument(entityId, photoManagerDocumentTypeMap[photoManagerName]));
};

const setAllExistingDocuments = function <TPhotoManagers extends PhotoManagers>(
  photoManagers: PhotoManagers,
  photoManagerDocumentTypeMap: PhotoManagersToDocumentTypeMap<TPhotoManagers>,
  existingDocuments: Document<DocumentTypeId>[],
) {
  const relevantDocumentTypes = new Set<DocumentTypeId>(Object.values(photoManagerDocumentTypeMap));
  const relevantDocuments: [DocumentTypeId, Document<DocumentTypeId>][] = [];
  existingDocuments.forEach((existingDocument) => {
    relevantDocumentTypes.has(existingDocument.documentType.id) &&
      relevantDocuments.push([existingDocument.documentType.id, existingDocument]);
  });
  const relevantExistingDocuments = new Map<DocumentTypeId, Document>(relevantDocuments);
  Object.entries(photoManagers).forEach(([photoManagerName, { setExistingDocument }]) => {
    const currentDocumentType = photoManagerDocumentTypeMap[photoManagerName];
    if (!relevantExistingDocuments.has(currentDocumentType)) {
      return;
    }
    const currentDocument = relevantExistingDocuments.get(currentDocumentType);
    if (!currentDocument) {
      return;
    }
    setExistingDocument(currentDocument);
  });
};

const formContentContainerClassNames = typedUtilityClassnames('mainLayoutPadding', display('flex'), justifyContent('justify-center'));

export const ContainerDrumsPage = () => {
  const { shipmentId } = useParams<{ shipmentId: string }>();
  const accessToken = useAccessToken();
  const user = useLoggedInUser();

  const { navigateContainerProcessing, navigateShipments } = useAppNavigation();
  const { handleServerError } = useServerError();

  const [shipment, setShipment] = useState<Shipment | null>(null);
  const [drums, setDrums] = useState<Drum[] | null>(null);
  const [container, setContainer] = useState<Container | null>(null);

  const [isLoading, setIsLoading] = useState(true);
  const [isSavingForm, setIsSavingForm] = useState(false);
  const [error, setError] = useState<string | null>(null);

  // TODO: workaround for race condition of completed uploads
  const [awaitingUploadsCount, setAwaitingUploadsCount] = useState<number>(0);
  const [successfulUploadsCount, setSuccessfulUploadsCount] = useState<number>(0);

  const [requestedInitialShipmentsDrumsAndContainers, setRequestedInitialShipmentsDrumsAndContainers] = useState(false);
  const [requestedInitialExistingDocuments, setRequestedInitialExistingDocuments] = useState(false);
  const [initialFetchCompleted, setInitialFetchCompleted] = useState(false);
  const [fetchInitialDocumentsCompleted, setFetchInitialDocumentsCompleted] = useState(false);
  const [completedAllInitialisation, setCompletedAllInitialisation] = useState(false);
  const [alreadySetFormDefaultsWithExistingValues, setAlreadySetFormDefaultsWithExistingValues] = useState(false);
  const [photosAwaitingStaging, setPhotosAwaitingStaging] = useState<ContainerDrumFormStatePhotosOnlyKeys[]>([]);

  const successfullyCompletedAll = useCallback(() => {
    setIsSavingForm(false);
    if (shipmentId) {
      navigateContainerProcessing(shipmentId);
    }
  }, [navigateContainerProcessing, shipmentId]);

  const onPhotoEvidenceUploadSuccess = useCallback(() => {
    // TODO: workaround for race condition of completed uploads
    if (successfulUploadsCount === awaitingUploadsCount - 1) {
      successfullyCompletedAll();
      return;
    }
    setSuccessfulUploadsCount((prev) => prev + 1);
  }, [awaitingUploadsCount, successfulUploadsCount, successfullyCompletedAll]);

  const onPhotoEvidenceUploadError = useCallback(
    (error: unknown) => {
      const errorMessage = handleServerError(error);
      setError(`${s.ContainerDrumsPage_UploadPhotoError}: ${errorMessage}`);
    },
    [handleServerError],
  );

  const tagBoxPhotoManager = useSingleDocumentInputCache(onPhotoEvidenceUploadSuccess, onPhotoEvidenceUploadError);
  const emptyContainerPhotoManager = useSingleDocumentInputCache(onPhotoEvidenceUploadSuccess, onPhotoEvidenceUploadError);
  const filledContainerPhotoManager = useSingleDocumentInputCache(onPhotoEvidenceUploadSuccess, onPhotoEvidenceUploadError);
  const containerSealIdPhotoManager = useSingleDocumentInputCache(onPhotoEvidenceUploadSuccess, onPhotoEvidenceUploadError);
  const leopardLockIdPhotoManager = useSingleDocumentInputCache(onPhotoEvidenceUploadSuccess, onPhotoEvidenceUploadError);
  const sealedContainerPhotoManager = useSingleDocumentInputCache(onPhotoEvidenceUploadSuccess, onPhotoEvidenceUploadError);

  const photoManagers = useMemo(
    () => ({
      tagBoxPhotoManager,
      emptyContainerPhotoManager,
      filledContainerPhotoManager,
      containerSealIdPhotoManager,
      leopardLockIdPhotoManager,
      sealedContainerPhotoManager,
    }),
    [
      containerSealIdPhotoManager,
      emptyContainerPhotoManager,
      filledContainerPhotoManager,
      leopardLockIdPhotoManager,
      sealedContainerPhotoManager,
      tagBoxPhotoManager,
    ],
  );

  const containerDrumFormStatePhotosOnlyPhotoManagers: Record<
    keyof ContainerDrumFormStatePhotosOnly,
    SingleDocumentInputCacheSetValidatedDocumentFromInput
  > = useMemo(
    () =>
      ({
        tagBoxPhoto: tagBoxPhotoManager.setValidatedDocumentFromInput,
        emptyContainerPhoto: emptyContainerPhotoManager.setValidatedDocumentFromInput,
        filledContainerPhoto: filledContainerPhotoManager.setValidatedDocumentFromInput,
        containerSealIdPhoto: containerSealIdPhotoManager.setValidatedDocumentFromInput,
        leopardLockIdPhoto: leopardLockIdPhotoManager.setValidatedDocumentFromInput,
        sealedContainerPhoto: sealedContainerPhotoManager.setValidatedDocumentFromInput,
      } as const),
    [
      containerSealIdPhotoManager.setValidatedDocumentFromInput,
      emptyContainerPhotoManager.setValidatedDocumentFromInput,
      filledContainerPhotoManager.setValidatedDocumentFromInput,
      leopardLockIdPhotoManager.setValidatedDocumentFromInput,
      sealedContainerPhotoManager.setValidatedDocumentFromInput,
      tagBoxPhotoManager.setValidatedDocumentFromInput,
    ],
  );

  const photoManagerToDocTypeMap: PhotoManagersToDocumentTypeMap<typeof photoManagers> = useMemo(
    () =>
      ({
        tagBoxPhotoManager: documentTypes.PhotoTagBox,
        emptyContainerPhotoManager: documentTypes.PhotoEmptyContainer,
        filledContainerPhotoManager: documentTypes.PhotoFilledContainer,
        containerSealIdPhotoManager: documentTypes.PhotoContainerSeal,
        leopardLockIdPhotoManager: documentTypes.PhotoContainerLeopardLock,
        sealedContainerPhotoManager: documentTypes.PhotoSealedContainer,
      } as const),
    [],
  );

  const containerDrumFormStateWithPhotosSchema = containerDrumFormStateSchema.merge(
    z.object({
      [containerDrumFormStateWithPhotosFieldNames.tagBoxPhoto]: tagBoxPhotoManager.documentValidatorDefinition,
      [containerDrumFormStateWithPhotosFieldNames.emptyContainerPhoto]: emptyContainerPhotoManager.documentValidatorDefinition,
      [containerDrumFormStateWithPhotosFieldNames.filledContainerPhoto]: filledContainerPhotoManager.documentValidatorDefinition,
      [containerDrumFormStateWithPhotosFieldNames.containerSealIdPhoto]: containerSealIdPhotoManager.documentValidatorDefinition,
      [containerDrumFormStateWithPhotosFieldNames.leopardLockIdPhoto]: leopardLockIdPhotoManager.documentValidatorDefinition,
      [containerDrumFormStateWithPhotosFieldNames.sealedContainerPhoto]: sealedContainerPhotoManager.documentValidatorDefinition,
    }),
  );

  const {
    register,
    formState: {
      errors,
      errors: {
        [containerDrumFormStateWithPhotosFieldNames.tagBoxId]: tagBoxIdInputError,
        [containerDrumFormStateWithPhotosFieldNames.tagBoxPhoto]: tagBoxPhotoInputError,
        [containerDrumFormStateWithPhotosFieldNames.emptyContainerPhoto]: emptyContainerPhotoInputError,
        [containerDrumFormStateWithPhotosFieldNames.filledContainerPhoto]: filledContainerPhotoInputError,
        [containerDrumFormStateWithPhotosFieldNames.containerSealId]: containerSealIdInputError,
        [containerDrumFormStateWithPhotosFieldNames.containerSealIdPhoto]: containerSealIdPhotoInputError,
        [containerDrumFormStateWithPhotosFieldNames.leopardLockId]: leopardLockIdInputError,
        [containerDrumFormStateWithPhotosFieldNames.leopardLockIdPhoto]: leopardLockIdPhotoInputError,
        [containerDrumFormStateWithPhotosFieldNames.sealedContainerPhoto]: sealedContainerPhotoInputError,
      },
      isDirty,
      isValidating,
    },
    handleSubmit,
    clearErrors,
    trigger,
    setValue,
    getValues,
    reset,
  } = useForm<ContainerDrumFormStateWithPhotos>({
    resolver: zodResolver(containerDrumFormStateWithPhotosSchema),
    defaultValues: {
      [containerDrumFormStateWithPhotosFieldNames.tagBoxId]: '',
      [containerDrumFormStateWithPhotosFieldNames.containerSealId]: '',
      [containerDrumFormStateWithPhotosFieldNames.leopardLockId]: '',
      [containerDrumFormStateWithPhotosFieldNames.tagBoxPhoto]: undefined,
      [containerDrumFormStateWithPhotosFieldNames.emptyContainerPhoto]: undefined,
      [containerDrumFormStateWithPhotosFieldNames.filledContainerPhoto]: undefined,
      [containerDrumFormStateWithPhotosFieldNames.containerSealIdPhoto]: undefined,
      [containerDrumFormStateWithPhotosFieldNames.leopardLockIdPhoto]: undefined,
      [containerDrumFormStateWithPhotosFieldNames.sealedContainerPhoto]: undefined,
    },
  });

  // Handle loading existing container with photos, if exists.
  useEffect(() => {
    const completedInitialLoadActions = () => {
      setIsLoading(false);
      setCompletedAllInitialisation(true);
    };
    (async () => {
      // TODO: Refactor out accessToken null checks to shared API client class that will throw if null for any call, then remove this from every component
      if (
        !accessToken ||
        !shipmentId ||
        (requestedInitialExistingDocuments && !requestedInitialExistingDocuments) ||
        (initialFetchCompleted && requestedInitialExistingDocuments && fetchInitialDocumentsCompleted) ||
        completedAllInitialisation
      ) {
        return;
      }
      !isLoading && setIsLoading(true);
      try {
        if (!requestedInitialShipmentsDrumsAndContainers) {
          // Request existing initial data for shipments, drums and container.
          setRequestedInitialShipmentsDrumsAndContainers(true);
          await (async () => {
            const [shipment, drums, container] = await Promise.all([
              shipmentsService.getById(accessToken, parseInt(shipmentId)),
              shipmentsService.getDrums(accessToken, parseInt(shipmentId)),
              shipmentsService.getContainer(accessToken, parseInt(shipmentId)),
            ]);
            shipment && setShipment(shipment);
            drums && setDrums(drums);
            container && setContainer(container);
            setInitialFetchCompleted(true);
          })();
        }
        if (!drums || !shipment) {
          // Wait until shipment, drums and containers request is complete before proceeding.
          return;
        }
        if (container) {
          if (!alreadySetFormDefaultsWithExistingValues) {
            // Once initial shipment, drums and containers request is complete, if there is a container and the existing non-photo fields have not been set as defaults already,
            // set the existing non-photo values as defaults for the form here.
            setAlreadySetFormDefaultsWithExistingValues(true);
            //
            reset({
              [containerDrumFormStateWithPhotosFieldNames.tagBoxId]: container.tagBoxId || '',
              [containerDrumFormStateWithPhotosFieldNames.containerSealId]: container.containerSealId || '',
              [containerDrumFormStateWithPhotosFieldNames.leopardLockId]: container.leopardLockId || '',
            });
          }
          if (!requestedInitialExistingDocuments) {
            // Once initial shipment, drums and containers request is complete, if there is a container and the request has not been initiated already,
            // get any of its existing documents.
            setRequestedInitialExistingDocuments(true);
            await (async () => {
              const existingDocuments = await containerService.getDocuments<DocumentTypeId>(accessToken, container.id);
              existingDocuments?.length && setAllExistingDocuments(photoManagers, photoManagerToDocTypeMap, existingDocuments);
              setFetchInitialDocumentsCompleted(true);
            })();
          }
        }
        if (initialFetchCompleted && isLoading) {
          if (!requestedInitialExistingDocuments) {
            // Initial fetching has completed and the page is still in a loading state, because we know we are not awaiting
            // any results from any document request (because there are no existing documents to be requested), the entire initialisation
            // process is now complete and the initialisation logic will not run again, after setting all complete flag.
            completedInitialLoadActions();
          } else {
            // This is the same above except there was a document to be awaited, and this process has now successfully completed, so set the
            // flag to not run this logic again and complete the process.
            fetchInitialDocumentsCompleted && completedInitialLoadActions();
          }
        }
      } catch (error) {
        const errorMessage = handleServerError(error);
        setError(`${s.ContainerDrumsPage_GetContainerError}: ${errorMessage}`);
      }
    })();
  }, [
    accessToken,
    completedAllInitialisation,
    container,
    drums,
    alreadySetFormDefaultsWithExistingValues,
    fetchInitialDocumentsCompleted,
    requestedInitialExistingDocuments,
    requestedInitialShipmentsDrumsAndContainers,
    initialFetchCompleted,
    isLoading,
    photoManagerToDocTypeMap,
    photoManagers,
    reset,
    shipment,
    shipmentId,
    handleServerError,
  ]);

  const onSubmit: SubmitHandler<ContainerDrumFormStateWithPhotos> = async (containerState) => {
    // TODO: check if user can save the truck
    try {
      setIsSavingForm(true);

      // TODO: factor out accessToken null checking into a base API class to run at every call, then remove access token null checking from all components
      if (!accessToken || !shipmentId || !drums) {
        return;
      }

      const containerWrite = new ContainerWrite(
        parseInt(shipmentId),
        drums.map((d) => d.id),
        containerState.tagBoxId,
      );

      if (containerState.leopardLockId) {
        containerWrite.leopardLockId = containerState.leopardLockId;
      }

      if (containerState.containerSealId) {
        containerWrite.containerSealId = containerState.containerSealId;
      }

      let containerId: number;
      // TODO: Refactor into useMultipleDocumentCache.
      if (container) {
        // Handle existing container update.
        await containerService.update(accessToken, container.id, containerWrite);
        containerId = container.id;
      } else {
        // Handle no existing container, create new container.
        const container = await containerService.create(accessToken, containerWrite);
        containerId = container.id;
        setContainer(container);
      }
      if (!containerId) {
        throw new Error(s.ContainerDrumsPage_UnknownContainerIdErrorMessage);
      }

      const [areAnyPhotosToDelete, areAnyPhotosToUpload] = areAnyPhotosToDeleteOrUpload(photoManagers);

      if (!areAnyPhotosToDelete && !areAnyPhotosToUpload) {
        // Submission completed successfully, close form and navigate back.
        successfullyCompletedAll();
        return;
      }

      // Handle all existing photo deletions, if any.
      const awaitingDeletions = getAllHandleDeletionsToAwait(photoManagers);
      // TODO: just in case there is a race condition in completed deletions
      for (const awaitingDeletion of awaitingDeletions) {
        await awaitingDeletion;
      }

      if (!areAnyPhotosToUpload) {
        // Handle only photos to delete.
        // TODO: Handle this case properly, if there are errors from the upload.
        successfullyCompletedAll();
        return;
      }
      // Handle all uploads, if any.
      const awaitingUploads = getAllHandleUploadsToAwait(photoManagers, photoManagerToDocTypeMap, containerId);
      // TODO: workaround for race condition of completed uploads
      setAwaitingUploadsCount(awaitingUploads.length);
      for (const awaitingUpload of awaitingUploads) {
        await awaitingUpload;
      }
    } catch (error) {
      const errorMessage = handleServerError(error);
      setError(`${s.ContainerDrumsPage_SaveContainerError}: ${errorMessage}`);
    }
  };

  // Handles setting photo from input.
  useEffect(() => {
    (async () => {
      if (!photosAwaitingStaging.length) {
        return;
      }
      const currentValues = getValues(photosAwaitingStaging);
      photosAwaitingStaging.forEach((photoFieldName, index) => {
        const currentPhotoValue = currentValues[index];
        if (!currentPhotoValue || currentPhotoValue.length === 0) {
          return;
        }
        if (!isValidating && !errors?.[photoFieldName]) {
          const currentPhotoManageSetValidatedDocumentFromInput = containerDrumFormStatePhotosOnlyPhotoManagers[photoFieldName];
          currentPhotoManageSetValidatedDocumentFromInput(currentPhotoValue[0]);
          clearErrors(photoFieldName);
        }
        index === currentValues.length - 1 && setPhotosAwaitingStaging([]);
      });
    })();
  }, [clearErrors, containerDrumFormStatePhotosOnlyPhotoManagers, errors, getValues, isValidating, photosAwaitingStaging]);

  const handleCancel = () => {
    shipmentId ? navigateContainerProcessing(shipmentId) : navigateShipments();
  };

  const disallowEdit = !canEditContainerDrumForm(user, container, isSavingForm);

  return (
    <>
      {isLoading ? (
        <Spinner />
      ) : (
        <>
          <ContextHeader
            contextTitle={s.ContainerDrumsPage_Title}
            contextSubTitle={shipment?.shipmentReference}
            leftButtonOne={<SecondaryButton label={s.ContainerDrumsPage_CancelButton} onClick={handleCancel} />}
            rightButtonOne={
              <PrimaryButton
                label={s.ContainerDrumsPage_SaveButton}
                type={buttonTypes.submit}
                disabled={disallowEdit || !isDirty}
                form={containerFormId}
              />
            }
            showContextHeaderContentSpacer
          />
          <Notification message={error!} severity="error" onClose={() => setError(null)} />
          {shipmentId && shipment && drums && (
            <section className={formContentContainerClassNames}>
              <ContainerDrumsForm
                formId={containerFormId}
                drums={drums}
                disallowEdit={disallowEdit}
                isSaving={isSavingForm}
                onSubmit={onSubmit}
                handleSubmit={handleSubmit}
                register={register}
                tagBoxIdInputError={tagBoxIdInputError}
                tagBoxPhotoInputError={tagBoxPhotoInputError}
                emptyContainerPhotoInputError={emptyContainerPhotoInputError}
                filledContainerPhotoInputError={filledContainerPhotoInputError}
                containerSealIdInputError={containerSealIdInputError}
                containerSealIdPhotoInputError={containerSealIdPhotoInputError}
                leopardLockIdInputError={leopardLockIdInputError}
                leopardLockIdPhotoInputError={leopardLockIdPhotoInputError}
                sealedContainerPhotoInputError={sealedContainerPhotoInputError}
                errors={errors}
                setValue={setValue}
                getValues={getValues}
                setPhotosAwaitingStaging={setPhotosAwaitingStaging}
                trigger={trigger}
                tagBoxPhotoManager={tagBoxPhotoManager}
                emptyContainerPhotoManager={emptyContainerPhotoManager}
                filledContainerPhotoManager={filledContainerPhotoManager}
                containerSealIdPhotoManager={containerSealIdPhotoManager}
                leopardLockIdPhotoManager={leopardLockIdPhotoManager}
                sealedContainerPhotoManager={sealedContainerPhotoManager}
              />
            </section>
          )}
        </>
      )}
    </>
  );
};
