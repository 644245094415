import { useCallback, useRef, ChangeEvent } from 'react';

import { useAccessToken } from 'hooks/useAccessToken';
import { useTus } from 'use-tus';

import { PrimaryButton } from 'components/buttons';

import { ReactComponent as UploadIcon } from 'icons/upload.svg';

const BaseUrl = window.__RUNTIME_CONFIG__.REACT_APP_API_BASE_URL;

export interface FileUploaderProps {
  canUpload: boolean;
  entityId: number | null | undefined;
  documentTypeId: number;
  onStarted: () => void;
  onSuccess: () => void;
  onError: (error: Error) => void;
}

export const FileUploader = ({ canUpload, entityId, documentTypeId, onStarted, onSuccess, onError }: FileUploaderProps): JSX.Element => {
  const accessToken = useAccessToken();
  const hiddenFileInput = useRef<HTMLInputElement | null>(null);
  const { setUpload } = useTus({ autoStart: true });

  const handleSetUpload = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      const files = event.target.files;
      if (!files?.length) {
        return;
      }

      const file = files[0];

      // TODO: Refactor out accessToken null checks to shared API client class that will throw if null for any call, then remove this from every component
      if (accessToken && entityId && file) {
        setUpload(file, {
          endpoint: `${BaseUrl}/documents`,
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
          metadata: {
            filename: file.name,
            filetype: file.type,
            entityid: entityId.toString(),
            documenttypeid: documentTypeId.toString(),
          },
          onSuccess,
          onError,
        });

        onStarted();
      }
    },
    [accessToken, setUpload, entityId, documentTypeId, onStarted, onSuccess, onError],
  );

  const handleUpload = () => {
    if (hiddenFileInput && hiddenFileInput.current) {
      hiddenFileInput.current.click();
    }
  };

  return (
    <>
      <PrimaryButton label={<UploadIcon />} onClick={handleUpload} disabled={!canUpload} />
      <input type="file" ref={hiddenFileInput} onChange={handleSetUpload} style={{ display: 'none' }}></input>
    </>
  );
};
