import { s } from 'i18n/strings';
import type { ExclusivelyStringKeyedRecordNonOptional, ExclusivelyStringTypedKeys } from 'types/utility-types';

export const NavigationPaths = {
  RootPath: '/',
  ShipmentsPath: '/shipments',
  ViewShipmentPath: '/shipments/:shipmentId',
  EditShipmentPath: '/shipments/:shipmentId/edit',
  BagsProcessingPath: '/shipments/:shipmentId/bags',
  BagProcessingPath: '/shipments/:shipmentId/bags/list',
  TruckArrivalProcessingPath: '/shipments/:shipmentId/bags/truck',
  PilesProcessingPath: '/shipments/:shipmentId/piles',
  SamplingInformationPath: '/shipments/:shipmentId/piles/sampling',
  PileProcessingPath: '/shipments/:shipmentId/piles/list',
  DrumProcessingPath: '/shipments/:shipmentId/drums',
  ContainerProcessingPath: '/shipments/:shipmentId/container',
  FreightForwardingInformationPath: '/shipments/:shipmentId/container/freight-forwarder',
  ContainerDrumsPath: '/shipments/:shipmentId/container/details',
  ShipmentProcessingPath: '/shipments/:shipmentId/shipment',
  ShipmentDetailsPath: '/shipments/:shipmentId/shipment/details',
  IoTDeviceInformationPath: '/shipments/:shipmentId/shipment/iot',
  ViewDocumentPath: '/shipments/:shipmentId/documents/:documentId',
  UsersPath: '/users',
  EditUserPath: '/users/:userId',
  AppStatusPath: '/status',
} as const;

export const NavigationPathsResolver = {
  EditUserPath(userId: string) {
    return NavigationPaths.EditUserPath.replace(':userId', userId);
  },
  ViewShipmentPath(shipmentId: string | number) {
    return NavigationPaths.ViewShipmentPath.replace(':shipmentId', shipmentId.toString());
  },
  EditShipmentPath(shipmentId: string | number) {
    return NavigationPaths.EditShipmentPath.replace(':shipmentId', shipmentId.toString());
  },
  BagsProcessingPath(shipmentId: string | number) {
    return NavigationPaths.BagsProcessingPath.replace(':shipmentId', shipmentId.toString());
  },
  BagProcessingPath(shipmentId: string | number) {
    return NavigationPaths.BagProcessingPath.replace(':shipmentId', shipmentId.toString());
  },
  TruckArrivalProcessingPath(shipmentId: string | number) {
    return NavigationPaths.TruckArrivalProcessingPath.replace(':shipmentId', shipmentId.toString());
  },
  PilesProcessingPath(shipmentId: string | number) {
    return NavigationPaths.PilesProcessingPath.replace(':shipmentId', shipmentId.toString());
  },
  SamplingInformationPath(shipmentId: string | number) {
    return NavigationPaths.SamplingInformationPath.replace(':shipmentId', shipmentId.toString());
  },
  PileProcessingPath(shipmentId: string | number) {
    return NavigationPaths.PileProcessingPath.replace(':shipmentId', shipmentId.toString());
  },
  DrumProcessingPath(shipmentId: string | number) {
    return NavigationPaths.DrumProcessingPath.replace(':shipmentId', shipmentId.toString());
  },
  ContainerProcessingPath(shipmentId: string | number) {
    return NavigationPaths.ContainerProcessingPath.replace(':shipmentId', shipmentId.toString());
  },
  FreightForwardingInformationPath(shipmentId: string | number) {
    return NavigationPaths.FreightForwardingInformationPath.replace(':shipmentId', shipmentId.toString());
  },
  ContainerDrumsPath(shipmentId: string | number) {
    return NavigationPaths.ContainerDrumsPath.replace(':shipmentId', shipmentId.toString());
  },
  ShipmentProcessingPath(shipmentId: string | number) {
    return NavigationPaths.ShipmentProcessingPath.replace(':shipmentId', shipmentId.toString());
  },
  ShipmentDetailsPath(shipmentId: string | number) {
    return NavigationPaths.ShipmentDetailsPath.replace(':shipmentId', shipmentId.toString());
  },
  IoTDeviceInformation(shipmentId: string | number) {
    return NavigationPaths.IoTDeviceInformationPath.replace(':shipmentId', shipmentId.toString());
  },
  ViewDocumentPath(shipmentId: string | number, documentId: string | number) {
    return NavigationPaths.ViewDocumentPath.replace(':shipmentId', shipmentId.toString()).replace(':documentId', documentId.toString());
  },
};

export const BaseUrl = window.__RUNTIME_CONFIG__.REACT_APP_API_BASE_URL;

type NavigationPathsType = typeof NavigationPaths;

export const topLevelPages: PathInfo<NavigationPathsType>[] = [
  { key: 'ShipmentsPath', path: '/shipments', pageDisplayName: s.Paths_ShipmentsPageDisplayName },
  { key: 'UsersPath', path: '/users', pageDisplayName: s.Paths_UsersPageDisplayName },
];

export type PathInfo<T extends Readonly<ExclusivelyStringKeyedRecordNonOptional<T>> = NavigationPathsType> = {
  path: T[keyof T];
  key: ExclusivelyStringTypedKeys<T>;
  pageDisplayName: string;
};
