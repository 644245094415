import { Employee } from 'models/employee';

export class Document<TDocumentTypeId extends number = number> {
  // eslint-disable-next-line no-useless-constructor
  constructor(
    public id: number,
    public documentType: DocumentType<TDocumentTypeId>,
    public uploadedBy: Employee,
    public uploadedOn: Date,
    public filename: string,
    public url: string,
  ) {}
}

export class DocumentType<TId extends number = number> {
  // eslint-disable-next-line no-useless-constructor
  constructor(public id: TId, public name: string, public entityKindId: number, public entityKindName: string) {}
}

export type DocumentInfo = {
  title: string;
  type: number;
  required: boolean;
};

export const MIMEPrefixes = { image: 'image/' } as const;

export const universaliseMIMEPrefix = (prefix: MIMEPrefix) => `${prefix}*`;

export type MIMEPrefix = typeof MIMEPrefixes[keyof typeof MIMEPrefixes];

export type DocumentTypeId = typeof documentTypes[keyof typeof documentTypes];

export const documentTypes = {
  AfrimetShipmentsFile: 1,
  DGDA: 2,
  CommerceExterieur: 3,
  CGA: 4,
  CEEC: 5,
  C1DeclarationForm: 6,
  COP: 7,
  DataSafetySheet: 8,
  iTSCiExcelTagList: 9,
  FactureCommerciale: 10,
  FicheComptoir: 11,
  RobinsonFinalAnalysisReport: 12,
  LaboPrivee: 13,
  ExportLicence: 14,
  TruckManifest: 15,
  ContainerPackingList: 16,
  PoliceOfMines: 17,
  RobinsonInspectionReport: 18,
  TechnicalSpecification: 19,
  TransportCertificate: 20,
  OffloadSheet: 21,
  SAEMAPECertificate: 22,
  TaxDocument: 23,
  FineDocument: 24,
  BillOfLading: 25,
  ForkliftWeightRecord: 26,
  CEECFinal: 27,
  VesselPackingList: 28,
  PhotoBagSeal: 101,
  PhotoFilledDrum: 102,
  PhotoSealedDrum: 103,
  PhotoEmptyContainer: 104,
  PhotoFilledContainer: 105,
  PhotoSealedContainer: 106,
  PhotoContainerSeal: 107,
  PhotoContainerLeopardLock: 108,
  PhotoEmptyDrum: 109,
  PhotoTagBox: 110,
} as const;
