import type { MouseEventHandler, ButtonHTMLAttributes, ReactElement } from 'react';
import type { NoUndefined } from 'utility-types';

import {
  onlyComputedCombineClassnames,
  typedUtilityClassnames,
  fontWeight,
  display,
  borderRadius,
  justifyItems,
  alignItems,
  padding,
  justifyContent,
  textOverflow,
  height,
} from 'style/compoundClassnames';

type HTMLButtonType = NoUndefined<Required<Pick<ButtonHTMLAttributes<HTMLButtonElement>, 'type'>>>['type'];

export const buttonTypes: { [Type in HTMLButtonType]: Type } = {
  button: 'button',
  submit: 'submit',
  reset: 'reset',
} as const;

export interface _ButtonBaseProps extends NoUndefined<Required<Pick<ButtonHTMLAttributes<HTMLButtonElement>, 'type'>>> {
  label: string | ReactElement;
  onClick?: MouseEventHandler;
  buttonClassNames: string;
  disabled: boolean;
  form?: string;
}

const typedButtonBaseClassNames = typedUtilityClassnames(
  'buttons',
  fontWeight('font-bold'),
  display('flex'),
  justifyContent('justify-center'),
  justifyItems('justify-items-center'),
  alignItems('items-center'),
  borderRadius('rounded'),
  padding('p-4'),
  textOverflow('truncate'),
  height('h-fit'),
);

export const _ButtonBase = ({ label, onClick, buttonClassNames, disabled, type, form }: _ButtonBaseProps): JSX.Element => (
  <button
    className={onlyComputedCombineClassnames(buttonClassNames, typedButtonBaseClassNames)}
    {...(disabled && { disabled })}
    type={type}
    onClick={onClick}
    form={form}
  >
    <span>{label}</span>
  </button>
);
