import { useCallback, useEffect } from 'react';
import { atom, useSetRecoilState } from 'recoil';
import { serverResponses } from 'services/request';
import { ErrorHelper } from 'utilities/errorHelper';

export const offlineState = atom({ key: 'offlineState', default: false });

export const useServerError = () => {
  const setOfflineState = useSetRecoilState(offlineState);

  useEffect(() => {
    setOfflineState(false);
  }, [setOfflineState]);

  const handleServerError = useCallback(
    (error: unknown) => {
      console.error(error);
      const errorMessage = ErrorHelper.formatErrorMessage(error);
      if (errorMessage === serverResponses.Offline) {
        setOfflineState(true);
      }
      return errorMessage;
    },
    [setOfflineState],
  );

  return {
    handleServerError,
  };
};
