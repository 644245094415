import { Drawer } from '@mui/material';
import { onlyComputedCombineClassnames, typedUtilityClassnames, width } from 'style/compoundClassnames';
import type { ReactNode } from 'react';

export interface RightDrawerProps {
  open: boolean;
  drawerClassNames?: string;
  children: ReactNode;
}

const getDrawerClassNames = (drawerClassNames?: string): string | undefined =>
  onlyComputedCombineClassnames(typedUtilityClassnames(width('w-80')), drawerClassNames);

export const RightDrawer = ({ open, children, drawerClassNames }: RightDrawerProps): JSX.Element => {
  return (
    <Drawer anchor="right" open={open} className={getDrawerClassNames(drawerClassNames)}>
      {children}
    </Drawer>
  );
};
