export default {
	"colourPrimary900": "#242424",
	"colourPrimary800": "#404040",
	"colourPrimary700": "#454545",
	"colourPrimary600": "#575757",
	"colourPrimary500": "#5e5e5e",
	"colourPrimary400": "#757575",
	"colourPrimary300": "#ababab",
	"colourPrimary200": "#c4c4c4",
	"colourPrimary100": "#dbdbdb",
	"colourPrimary50": "#e6e6e6",
	"colourSecondary900": "#94c11f",
	"colourSecondary800": "#9ec83a",
	"colourSecondary700": "#a9cf55",
	"colourSecondary600": "#b3d76f",
	"colourSecondary500": "#bede8a",
	"colourSecondary400": "#c8e6a5",
	"colourSecondary300": "#d3edb9",
	"colourSecondary200": "#ddf4ce",
	"colourSecondary100": "#e8fce2",
	"colourSecondary50": "#f2fffd",
	"colourWarning900": "hsl(36, 100%, 31%)",
	"colourWarning100": "hsl(47, 100%, 75%)",
	"colourError700": "#c42b1c",
	"colourError600": "#d0334f",
	"colourError200": "#e799a7",
	"colourError50": "#faebed",
	"colourSuccess900": "#0f7b0f",
	"colourSuccess600": "#44ac87",
	"colourSuccess200": "#dff6dd",
	"colourAttention700": "#005fb7",
	"colourAttention50": "#f6f6f6",
	"colourNormal50": "#bdbdbd",
	"colourWhite": "#fff",
	"colourBlack": "#000",
	"colourGrey700": "hsl(0, 0%, 40%)",
	"colourOutline": "rgba(0, 0, 0, .12)",
	"icons": "#212121",
	"colourCard200": "hsl(0, 0%, 87%)",
	"colourCard100": "hsl(0, 0%, 94%)",
	"onSurfaceHighEmphasis": "rgba(0, 0, 0, .87)",
	"onSurfaceDisabled": "rgba(0, 0, 0, .27)",
	"onSurfaceMediumEmphasis": "rgba(0, 0, 0, .6)",
	"overlayBlackHover": "rgba(0, 0, 0, .04)",
	"overlayBlackFocused": "rgba(0, 0, 0, .12)",
	"overlayBlackSelected": "rgba(0, 0, 0, .08)",
	"fontFamily": "'lato'"
}