import { Container } from 'models/container';
import { _ManifestItemBase } from 'components/manifest-items/_base/_ManifestItemBase';
import { s } from 'i18n/strings';
import { margin, padding, textColor, textTransform, typedUtilityClassnames } from 'style/compoundClassnames';
import { ReactComponent as TruckIcon } from 'icons/truck.svg';

const columnTitleClassNames = typedUtilityClassnames(
  'navBlockFont',
  textColor('text-onSurface-mediumEmphasis'),
  textTransform('uppercase'),
  padding('pb-2'),
);
const columnItemContainerClassNames = typedUtilityClassnames('body2', textColor('text-onSurface-highEmphasis'));
const columnItemCaptionClassNames = typedUtilityClassnames('label', textColor('text-onSurface-highEmphasis'));
const passportNumberClassNames = typedUtilityClassnames('caption', textColor('text-onSurface-mediumEmphasis'), margin('ml-12'));

export interface FreightForwarderManifestItemProps {
  container: Container;
  canEdit: boolean;
  editOnClick: () => void;
}

export const FreightForwarderManifestItem = ({ container, canEdit, editOnClick }: FreightForwarderManifestItemProps) => {
  return (
    <_ManifestItemBase
      title={s.FreightForwarderManifestItem_Title}
      icon={<TruckIcon />}
      actionButtonCaption={s.FreightForwarderManifestItem_EditButtonCaption}
      actionButtonOnClick={editOnClick}
      actionButtonDisabled={!canEdit}
      actionButtonVisible={true}
      content={
        <div>
          <div className={columnTitleClassNames}>{s.FreightForwarderManifestItem_TruckInformationSectionTitle}</div>
          <div className={columnItemContainerClassNames}>
            <span className={columnItemCaptionClassNames}>{s.FreightForwarderManifestItem_TruckRegistrationNoLabel}: </span>
            {container?.truckLicensePlate}
          </div>
          <div className={columnItemContainerClassNames}>
            <span className={columnItemCaptionClassNames}>{s.FreightForwarderManifestItem_TruckTrailerNoLabel}: </span>
            {container?.trailerLicensePlate}
          </div>
          <div className={columnItemContainerClassNames}>
            <span className={columnItemCaptionClassNames}>{s.FreightForwarderManifestItem_TruckDriverNameLabel}: </span>
            {container?.driverFirstName} {container?.driverLastName}
          </div>
          <div className={passportNumberClassNames}>
            {s.FreightForwarderManifestItem_TruckDriverPassportLabel}: {container?.driverPassportNumber}
          </div>
        </div>
      }
    />
  );
};
