import { useRef, useEffect, useState } from 'react';
import maplibregl, { LngLatLike } from 'maplibre-gl';
import 'maplibre-gl/dist/maplibre-gl.css';
import { height, margin, position, typedUtilityClassnames, width } from 'style/compoundClassnames';

export const Map = (): JSX.Element => {
  const mapContainer = useRef<HTMLDivElement | null>(null);
  const map = useRef<maplibregl.Map | null>(null);
  const [lng] = useState(0);
  const [lat] = useState(48.5);
  const [zoom] = useState(3.5);
  const [API_KEY] = useState('oL1YdhIGpIxmmQ9Ck13d');

  const burgos = [-3.696906, 42.34399]; // Burgos
  const bilbao = [-2.934985, 43.263012]; // Bilbao
  const rotterdam = [4.477733, 51.924419]; // Rotterdam
  const hamburg = [9.993682, 53.551086]; // Hamburg

  useEffect(() => {
    // stops map from intializing more than once
    if (map.current) {
      return;
    }

    if (mapContainer?.current) {
      map.current = new maplibregl.Map({
        container: mapContainer.current,
        style: `https://api.maptiler.com/maps/streets-v2/style.json?key=${API_KEY}`,
        center: [lng, lat],
        zoom,
      });

      map.current.addControl(new maplibregl.NavigationControl(), 'top-right');
      new maplibregl.Marker({ scale: 0.75 }).setLngLat(burgos as LngLatLike).addTo(map.current);
      new maplibregl.Marker({ scale: 0.75 }).setLngLat(bilbao as LngLatLike).addTo(map.current);
      new maplibregl.Marker({ scale: 0.75 }).setLngLat(rotterdam as LngLatLike).addTo(map.current);
      new maplibregl.Marker({ scale: 0.75 }).setLngLat(hamburg as LngLatLike).addTo(map.current);

      map?.current.on('load', () => {
        map?.current?.addSource('route', {
          type: 'geojson',
          data: {
            type: 'Feature',
            properties: {},
            geometry: {
              type: 'LineString',
              coordinates: [burgos, bilbao, rotterdam, hamburg],
            },
          },
        });

        map?.current?.addLayer({
          id: 'route',
          source: 'route',
          type: 'line',
          paint: {
            'line-width': 2,
            'line-color': '#007cbf',
          },
        });

        map?.current?.addSource('point', {
          type: 'geojson',
          data: {
            type: 'Feature',
            properties: {},
            geometry: {
              type: 'Point',
              coordinates: [1.146669, 48.21024],
            },
          },
        });

        map?.current?.addLayer({
          id: 'point',
          source: 'point',
          type: 'circle',
          paint: {
            'circle-color': '#007cbf',
            'circle-radius': 8,
          },
        });
      });

      // mapRef.current = map;
    }
  }, [API_KEY, lat, lng, zoom]);

  return (
    <div className={typedUtilityClassnames(position('relative'), width('w-4/5'), height('h-96'), margin('my-12'))}>
      <div ref={mapContainer} className={typedUtilityClassnames(position('absolute'), width('w-full'), height('h-full'))} />
    </div>
  );
};
