import type { ReactElement } from 'react';
import { cloneElement } from 'react';
import { Menu } from '@headlessui/react';
import {
  backgroundColor,
  borderRadius,
  boxShadow,
  display,
  flexDirection,
  height,
  onlyComputedCombineClassnames,
  position,
  typedUtilityClassnames,
  width,
  zIndex,
} from 'style/compoundClassnames';

export type _DropMenuRenderPropInput = { active: boolean; open: boolean };

export type _DropMenuItemRenderFunction = ({
  active,
  open,
}: Partial<_DropMenuRenderPropInput>) => ReactElement<{ className: string; key: number; disabled: boolean; [propName: string]: unknown }>;

export interface _DropMenuBaseProps {
  buttonContents: ReactElement;
  buttonAriaLabel: string;
  children: _DropMenuItemRenderFunction[];
  topLevelMenuClassNames?: string;
  itemsContainerClassNames?: string;
  activeItemClassNames?: string;
  nonActiveItemClassNames?: string;
}

const topLevelMenuLocalClassNames = typedUtilityClassnames(borderRadius('rounded'), position('relative'), width('w-fit'));

const itemsContainerLocalClassNames = typedUtilityClassnames(
  position('absolute'),
  zIndex('z-50'),
  height('h-fit'),
  backgroundColor('bg-white'),
  display('flex'),
  flexDirection('flex-col'),
  boxShadow('shadow-lg'),
  borderRadius('rounded'),
);

export const _DropMenuBase = ({
  buttonContents,
  buttonAriaLabel,
  children,
  topLevelMenuClassNames = '',
  itemsContainerClassNames = '',
  activeItemClassNames = '',
 nonActiveItemClassNames = '',
}: _DropMenuBaseProps) => (
  <Menu as="menu" className={onlyComputedCombineClassnames(topLevelMenuLocalClassNames, topLevelMenuClassNames)}>
    <Menu.Button aria-label={buttonAriaLabel}>{buttonContents}</Menu.Button>
    <Menu.Items className={onlyComputedCombineClassnames(itemsContainerLocalClassNames, itemsContainerClassNames)}>
      {children.map((child, i) => (
        <Menu.Item key={i}>
          {({ active }) => {
            const childElement = child({ active });
            const props = childElement.props;
            const { className } = childElement.props;
            return cloneElement(childElement, {
              ...props,
              className: onlyComputedCombineClassnames(className, { [activeItemClassNames]: active, [nonActiveItemClassNames]: !active }),
            });
          }}
        </Menu.Item>
      ))}
    </Menu.Items>
  </Menu>
);
