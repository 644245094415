import { z } from 'zod';

export const getRequiredInputLength = (
  requiredErrorMessage: string,
  minLength: number,
  belowMinLengthErrorMessage: string,
  maxLength: number,
  aboveMaxLengthErrorMessage: string,
) =>
  z
    .string()
    .refine((value) => value !== '', requiredErrorMessage)
    .refine((value) => value.length >= minLength, belowMinLengthErrorMessage)
    .refine((value) => value.length <= maxLength, aboveMaxLengthErrorMessage);

export const getOptionalInputLength = (
  minLength: number,
  belowMinLengthErrorMessage: string,
  maxLength: number,
  aboveMaxLengthErrorMessage: string,
) =>
  z
    .string()
    .superRefine((value, ctx) => {
      if (value.length === 0) {
        return undefined;
      }
      value.length < minLength &&
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message: belowMinLengthErrorMessage,
          fatal: true,
        });
      value.length > maxLength &&
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message: aboveMaxLengthErrorMessage,
          fatal: true,
        });
    })
    .optional();

export const getRequiredEmailInputLength = (
  requiredErrorMessage: string,
  invalidEmailAddressErrorMessage: string,
  maxLength: number,
  aboveMaxLengthErrorMessage: string,
) =>
  z
    .string()
    .min(1, { message: requiredErrorMessage })
    .max(maxLength, { message: aboveMaxLengthErrorMessage })
    .email({ message: invalidEmailAddressErrorMessage });
