import { useEffect, useState } from 'react';
import { useParams } from 'react-router';

import { shipmentStates, ShipmentSummary } from 'models/shipment';
import { Truck, TruckStates } from 'models/truck';
import { Document, DocumentInfo, documentTypes } from 'models/document';
import { User } from 'models/user';
import { shipmentsService } from 'services/shipmentsService';
import { truckService } from 'services/truckService';
import { permissionsService } from 'services/permissionsService';
import { useAccessToken, useLoggedInUser, useAppNavigation, useServerError } from 'hooks';

import { s } from 'i18n/strings';

import { display, flexDirection, justifyContent, onlyComputedCombineClassnames, typedUtilityClassnames } from 'style/compoundClassnames';

import { ContextHeader } from 'components/headers';
import { PrimaryButton, SecondaryButton } from 'components/buttons';
import { TruckManifestItem, BagsManifestItem } from 'components/manifest-items';
import { HeaderItem } from 'components/HeaderItem';
import { DocumentItem } from 'components/DocumentItem';
import { Spinner } from 'components/Spinner';
import { ActionConfirmationDialog } from 'components/dialogs';
import { Notification } from 'components/Notification';

import { ReactComponent as TruckIcon } from 'icons/truck.svg';
import { ReactComponent as DocumentsIcon } from 'icons/documents.svg';
import { ReactComponent as BagsIcon } from 'icons/bags.svg';

const mainContentClassNames = onlyComputedCombineClassnames(
  typedUtilityClassnames('mainLayoutPadding'),
  display('flex'),
  justifyContent('justify-center'),
  flexDirection('flex-col'),
);

const truckArrivalDocuments: DocumentInfo[] = [
  { title: s.ShipmentDocuments_CEEC1DocumentItemTitle, type: documentTypes.CEEC, required: true },
  { title: s.ShipmentDocuments_SAEMAPECertificateDocumentItemTitle, type: documentTypes.SAEMAPECertificate, required: true },
  { title: s.ShipmentDocuments_TransportCertificateDocumentItemTitle, type: documentTypes.TransportCertificate, required: true },
  { title: s.ShipmentDocuments_iTSCiExcelTagListDocumentItemTitle, type: documentTypes.iTSCiExcelTagList, required: true },
];

const truckOffloadedDocuments: DocumentInfo[] = [
  { title: s.ShipmentDocuments_TaxDocumentItemTitle, type: documentTypes.TaxDocument, required: true },
  { title: s.ShipmentDocuments_FineDocumentItemTitle, type: documentTypes.FineDocument, required: false },
];

const canEditArrivalInformation = (user: User | null) =>
  user != null && (permissionsService.canEditTruck(user) || permissionsService.canOverrideTruck(user));

const canEditArrivalDocument = (user: User | null, truck: Truck | null) =>
  user != null &&
  permissionsService.canEditTruck(user) &&
  truck != null &&
  (truck.state.name === TruckStates.New || truck.state.name === TruckStates.Arrived);

const canEditOffloadedDocument = (
  user: User | null,
  truck: Truck | null,
  shipmentSummary: ShipmentSummary | null,
  document: DocumentInfo,
) => {
  if (!user || !shipmentSummary || !truck) {
    return false;
  }

  if (!document.required) {
    return (
      permissionsService.canEditTruck(user) &&
      (truck.state.name === TruckStates.Inspected || truck.state.name === TruckStates.Offloaded) &&
      (shipmentSummary.state.name !== shipmentStates.Complete ||
        (shipmentSummary.state.name === shipmentStates.Complete && shipmentSummary.state.isOverridden))
    );
  }

  return (permissionsService.canEditTruck(user) && truck.state.name === TruckStates.Inspected) || truck.state.isOverridden;
};

const canEditBags = (truck: Truck | null) => truck != null && truck.state.name !== TruckStates.New;

const canFinishBagsProcessing = (user: User | null, truck: Truck | null) =>
  user != null && permissionsService.canEditTruck(user) && truck != null && truck.state.name === TruckStates.Inspected;

export const BagsProcessingPage = () => {
  const { shipmentId } = useParams<{ shipmentId: string }>();
  const accessToken = useAccessToken();
  const user = useLoggedInUser();

  const { navigateShipments, navigateViewShipment, navigateBagProcessing, navigateTruckProcessing } = useAppNavigation();
  const { handleServerError } = useServerError();

  const [shipmentSummary, setShipmentSummary] = useState<ShipmentSummary | null>(null);
  const [truck, setTruck] = useState<Truck | null>(null);
  const [truckState, setTruckState] = useState<string>(TruckStates.New);

  const [documents, setDocuments] = useState<Document[] | null>(null);
  const [missingDocuments, setMissingDocuments] = useState<DocumentInfo[]>([]);
  const handleMissingDocuments = (documents: Document[]) => {
    const missingDocuments: DocumentInfo[] = [];
    missingDocuments.push(
      ...truckArrivalDocuments.filter((d) => d.required && !documents.map((item) => item.documentType.id).includes(d.type)),
    );
    missingDocuments.push(
      ...truckOffloadedDocuments.filter((d) => d.required && !documents.map((item) => item.documentType.id).includes(d.type)),
    );
    setMissingDocuments(missingDocuments);
  };

  const [documentUploadStarted, setDocumentUploadStarted] = useState(false);

  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  const [openConfirmationDialog, setOpenConfirmationDialog] = useState(false);
  const handleOpenConfirmationDialog = () => setOpenConfirmationDialog(true);
  const handleCloseConfirmationDialog = () => setOpenConfirmationDialog(false);

  useEffect(() => {
    (async () => {
      try {
        setError(null);
        setIsLoading(true);
        // TODO: factor out accessToken null checking into a base API class to run at every call, then remove access token null checking from all components
        if (accessToken && shipmentId) {
          const shipmentSummary = await shipmentsService.getSummary(accessToken, parseInt(shipmentId));
          setShipmentSummary(shipmentSummary);

          if (shipmentSummary.bagsSummary.truck) {
            setTruck(shipmentSummary.bagsSummary.truck);
            setTruckState(shipmentSummary.bagsSummary.truck.state.name);

            const documents = await truckService.getDocuments(accessToken, shipmentSummary.bagsSummary.truck.id);
            if (documents) {
              setDocuments(documents);
              handleMissingDocuments(documents);
            }
          }
        }
      } catch (error) {
        const errorMessage = handleServerError(error);
        setError(`${s.BagsProcessingPage_RetrievingShipmentDetailsError}: ${errorMessage}`);
      } finally {
        setIsLoading(false);
      }
    })();
  }, [accessToken, handleServerError, shipmentId]);

  const handleBack = () => {
    shipmentId ? navigateViewShipment(shipmentId) : navigateShipments();
  };

  const handleFinishBagsProcessing = async (isOverridden: boolean, overrideNote: string) => {
    try {
      if (accessToken && shipmentId && canFinishBagsProcessing(user, truck)) {
        if (isOverridden) {
          await shipmentsService.finishBagsProcessingWithOverride(accessToken, parseInt(shipmentId), overrideNote);
        } else {
          await shipmentsService.finishBagsProcessing(accessToken, parseInt(shipmentId));
        }
        handleCloseConfirmationDialog();
        navigateViewShipment(shipmentId);
      }
    } catch (error) {
      handleCloseConfirmationDialog();
      const errorMessage = handleServerError(error);
      setError(`${s.BagsProcessingPage_FinishBagsProcessingError}: ${errorMessage}`);
    }
  };

  const handleEditArrivalInformation = () => {
    if (canEditArrivalInformation(user) && shipmentId) {
      navigateTruckProcessing(shipmentId);
    }
  };

  const handleDocumentUploadStarted = (uploadStarted: boolean) => {
    setDocumentUploadStarted(uploadStarted);
  };

  const handleDocumentUploaded = async () => {
    try {
      if (accessToken && truck) {
        const updatedTruck = await truckService.getById(accessToken, truck.id);
        if (updatedTruck) {
          setTruck(updatedTruck);
          setTruckState(updatedTruck.state.name);
        }

        const documents = await truckService.getDocuments(accessToken, truck.id);
        if (documents) {
          setDocuments(documents);
          handleMissingDocuments(documents);
        }
      }
    } catch (error) {
      const errorMessage = handleServerError(error);
      setError(`${s.BagsProcessingPage_RetrievingTruckDetailsError}: ${errorMessage}`);
    }
  };

  const handleEditBags = () => {
    if (canEditBags(truck) && shipmentId) {
      navigateBagProcessing(shipmentId);
    }
  };

  return (
    <>
      {isLoading ? (
        <Spinner />
      ) : (
        shipmentSummary &&
        user && (
          <>
            <ContextHeader
              contextTitle={s.BagsProcessingPage_Title}
              contextSubTitle={`${s.BagsProcessingPage_TruckSubTitle} - ${truckState}`}
              leftButtonOne={<SecondaryButton label={s.BagsProcessingPage_BackButton} onClick={handleBack} />}
              rightButtonOne={
                <PrimaryButton
                  label={s.BagsProcessingPage_FinishButton}
                  onClick={handleOpenConfirmationDialog}
                  disabled={!canFinishBagsProcessing(user, truck)}
                />
              }
              showContextHeaderContentSpacer={false}
            />
            <div className={mainContentClassNames}>
              <Notification message={error!} severity="error" onClose={() => setError(null)} />
              {!shipmentSummary.bagsSummary.truck ? (
                <HeaderItem
                  title={s.BagsProcessingPage_ArrivalInformationItemTitle}
                  icon={<TruckIcon />}
                  actionButton={
                    <SecondaryButton
                      label={s.BagsProcessingPage_ArrivalInformationItem_EditButton}
                      disabled={!canEditArrivalInformation(user)}
                      onClick={handleEditArrivalInformation}
                    />
                  }
                  actionButtonVisible={true}
                />
              ) : (
                <TruckManifestItem
                  truck={shipmentSummary.bagsSummary.truck}
                  canEdit={canEditArrivalInformation(user)}
                  editOnClick={handleEditArrivalInformation}
                />
              )}

              <HeaderItem title={s.BagsProcessingPage_DocumentsItemTitle} icon={<DocumentsIcon />} />
              {truckArrivalDocuments.map((doc, index) => (
                <DocumentItem
                  key={index}
                  title={doc.title}
                  canEdit={canEditArrivalDocument(user, truck) && !documentUploadStarted}
                  shipmentId={shipmentSummary.id}
                  entityId={shipmentSummary.bagsSummary.truck?.id}
                  documentTypeId={doc.type}
                  documentRequired={doc.required}
                  document={documents && documents.find((d) => d.documentType.id === doc.type)}
                  handleUploadStarted={handleDocumentUploadStarted}
                  handleUploadStatusChange={handleDocumentUploaded}
                />
              ))}
              {truckOffloadedDocuments.map((doc, index) => (
                <DocumentItem
                  key={index}
                  title={doc.title}
                  canEdit={canEditOffloadedDocument(user, truck, shipmentSummary, doc) && !documentUploadStarted}
                  shipmentId={shipmentSummary.id}
                  entityId={shipmentSummary.bagsSummary.truck?.id}
                  documentTypeId={doc.type}
                  documentRequired={doc.required}
                  document={documents && documents.find((d) => d.documentType.id === doc.type)}
                  handleUploadStarted={handleDocumentUploadStarted}
                  handleUploadStatusChange={handleDocumentUploaded}
                />
              ))}

              {shipmentSummary.bagsSummary.count === 0 ? (
                <HeaderItem
                  title={s.BagsProcessingPage_BagsItemTitle}
                  icon={<BagsIcon />}
                  actionButton={
                    <SecondaryButton
                      label={s.BagsProcessingPage_BagsItem_EditButton}
                      onClick={handleEditBags}
                      disabled={!canEditBags(truck)}
                    />
                  }
                  actionButtonVisible={true}
                />
              ) : (
                <BagsManifestItem bagsSummary={shipmentSummary.bagsSummary} editOnClick={handleEditBags} />
              )}

              <ActionConfirmationDialog
                open={openConfirmationDialog}
                title={s.BagsProcessingPage_ConfirmationDialogTitle}
                items={[
                  {
                    label: s.BagsProcessingPage_ConfirmationDialogShipmentItemLabel,
                    successCondition: shipmentSummary?.state.name === shipmentStates.New,
                    successMessage: s.BagsProcessingPage_ConfirmationDialogShipmentSuccessMessage,
                    errorMessage: s.BagsProcessingPage_ConfirmationDialogShipmentErrorMessage,
                  },
                  {
                    label:
                      missingDocuments.length > 0
                        ? s.BagsProcessingPage_ConfirmationDialogDocumentsMissingItemLabel
                        : s.BagsProcessingPage_ConfirmationDialogDocumentsItemLabel,
                    successCondition: missingDocuments.length === 0,
                    successMessage: s.BagsProcessingPage_ConfirmationDialogDocumentsSuccessMessage,
                    errorMessage: missingDocuments.map((d) => d.title).join(', '),
                  },
                ]}
                confirmButtonCaption={s.BagsProcessingPage_ConfirmationDialogFinishButtonCaption}
                handleConfirm={handleFinishBagsProcessing}
                cancelButtonCaption={s.BagsProcessingPage_ConfirmationDialogCancelButtonCaption}
                handleCancel={handleCloseConfirmationDialog}
                allowOverride={permissionsService.canOverrideTruck(user)}
              />
            </div>
          </>
        )
      )}
    </>
  );
};
