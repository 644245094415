import { ChangeEvent, useState } from 'react';
import { Shipment } from 'models/shipment';
import { formatDate } from 'utilities';
import { s } from 'i18n/strings';

import {
  alignItems,
  backgroundColor,
  borderColor,
  borderRadius,
  borderWidth,
  display,
  flexDirection,
  fontWeight,
  gap,
  gridColumnStart,
  gridRowStart,
  gridTemplateColumns,
  gridTemplateRows,
  justifyContent,
  margin,
  padding,
  textColor,
  textTransform,
  typedUtilityClassnames,
  width,
} from 'style/compoundClassnames';

import {
  Box,
  FormControl,
  InputAdornment,
  MenuItem,
  Select,
  SelectChangeEvent,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  TextField,
} from '@mui/material';

import { SecondaryButton } from 'components/buttons';
import { ShipmentStateIndicator } from 'components/ShipmentStateIndicator';

import { ReactComponent as TransportInformationIcon } from 'icons/transportInformation.svg';
import { ReactComponent as ShipmentInformationIcon } from 'icons/shipmentInformation.svg';
import { ReactComponent as DepartureInformationIcon } from 'icons/departureInformation.svg';
import { ReactComponent as SearchIcon } from 'icons/search.svg';

const contentLayoutClassNames = typedUtilityClassnames(
  display('grid'),
  gridTemplateColumns('xl:grid-cols-5', 'lg:grid-cols-3', 'md:grid-cols-3', 'grid-cols-1'),
  gridTemplateRows('xl:grid-rows-1', 'lg:grid-rows-2', 'md:grid-rows-2', 'grid-rows-1'),
  gap(
    '2xl:gap-x-6',
    'xl:gap-x-4',
    'lg:gap-x-2',
    'md:gap-x-2',
    'gap-x-2',
    '2xl:gap-y-6',
    'xl:gap-y-4',
    'lg:gap-y-2',
    'md:gap-y-2',
    'gap-y-6',
  ),
);
const shipmentItemContainerClassNames = typedUtilityClassnames(
  borderWidth('border'),
  borderRadius('rounded'),
  borderColor('border-card-100'),
);
const shipmentItemIdCaptionClassNames = typedUtilityClassnames('headline6', fontWeight('font-bold'));
const shipmentStateIndicatorContainerClassNames = typedUtilityClassnames(margin('my-2'));
const shipmentItemLastUpdatedCaptionClassNames = typedUtilityClassnames('caption', textColor('text-onSurface-mediumEmphasis'));
const shipmentItemColumnTitleClassNames = typedUtilityClassnames(display('flex'), alignItems('items-center'), margin('mb-2'));
const rowClassNames = typedUtilityClassnames(backgroundColor('bg-card-100'));
const cellTitleClassNames = typedUtilityClassnames(
  'navBlockFont',
  textColor('text-onSurface-mediumEmphasis'),
  textTransform('uppercase'),
  margin('ml-1'),
);
const cellLabelClassNames = typedUtilityClassnames('label', textColor('text-onSurface-highEmphasis'));
const cellValueClassNames = typedUtilityClassnames('body2', textColor('text-onSurface-highEmphasis'));
const cellPspValueClassNames = typedUtilityClassnames('caption', textColor('text-onSurface-mediumEmphasis'), margin('ml-12'));
const cellCompanyValueClassNames = typedUtilityClassnames(
  'caption',
  textColor('text-onSurface-mediumEmphasis'),
  margin('ml-14'),
  padding('pl-0.5'),
);
const filterOptionsContainerClassNames = typedUtilityClassnames(
  display('flex'),
  flexDirection('flex-col', 'sm:flex-row'),
  gap('gap-2'),
  width('w-full'),
  padding('pb-2'),
);
const dropDownContainerClassNames = typedUtilityClassnames(width('w-full', 'sm:w-72'));
const searchFieldClassNames = typedUtilityClassnames('subtitle1', width('w-full', 'sm:w-80'));
const searchIconClassNames = typedUtilityClassnames('icons', width('w-5'));

interface TransportInformationCellProps {
  shipment: Shipment;
}

const TransportInformationCell = ({ shipment }: TransportInformationCellProps) => {
  const truck = shipment.arrivalTruckDetails;

  return (
    truck && (
      <div>
        <div className={shipmentItemColumnTitleClassNames}>
          <TransportInformationIcon />
          <p className={cellTitleClassNames}>{s.ShipmentsList_TransportInformationCellTitle}</p>
        </div>
        <p className={cellValueClassNames}>
          <span className={cellLabelClassNames}>{s.ShipmentsList_TruckRegistrationNoLabel}</span>
          {truck.truckLicensePlate}
        </p>
        <p className={cellValueClassNames}>
          <span className={cellLabelClassNames}>{s.ShipmentsList_TrailerRegistrationNoLabel}</span>
          {truck.trailerLicensePlate}
        </p>
        <p className={cellValueClassNames}>
          <span className={cellLabelClassNames}>{s.ShipmentsList_DriverNameLabel}</span>
          {truck.driverFirstName} {truck.driverLastName}
        </p>
        <p className={cellPspValueClassNames}>
          {s.ShipmentsList_DriverPassportLabel}
          {truck.driverPassportNumber}
        </p>
      </div>
    )
  );
};

interface DeliveryDestinationCellProps {
  shipment: Shipment;
}

const DeliveryDestinationCell = ({ shipment }: DeliveryDestinationCellProps) => {
  const warehouseRepresentative = shipment.warehouseRepresentative;

  return (
    warehouseRepresentative && (
      <div>
        <div className={shipmentItemColumnTitleClassNames}>
          <ShipmentInformationIcon />
          <p className={cellTitleClassNames}>{s.ShipmentsList_DeliveryDestinationCellTitle}</p>
        </div>
        <p className={cellValueClassNames}>
          <span className={cellLabelClassNames}>{s.ShipmentsList_ContactLabel}: </span>
          {warehouseRepresentative.firstName} {warehouseRepresentative.lastName}
        </p>
        <p className={cellCompanyValueClassNames}>{warehouseRepresentative.institutionName}</p>
        <p className={cellValueClassNames}>
          <span className={cellLabelClassNames}>{s.ShipmentsList_AgentLabel}: </span>
          {warehouseRepresentative.emailAddress}
        </p>
      </div>
    )
  );
};

interface PortDepartureCellProps {
  shipment: Shipment;
}

const PortDepartureCell = ({ shipment }: PortDepartureCellProps) => {
  return (
    shipment.sailDate && (
      <div>
        <div className={shipmentItemColumnTitleClassNames}>
          <DepartureInformationIcon />
          <p className={cellTitleClassNames}>{s.ShipmentsList_PortDepartureCellTitle}</p>
        </div>
        <p className={cellLabelClassNames}>{formatDate.getDepartureDate(shipment.sailDate)}</p>
      </div>
    )
  );
};

export interface ShipmentsListProps {
  shipments: Shipment[];
  onView: (id: number) => void;
}

const filterOptions = [s.ShipmentsList_FilterOptionCaption_ShipmentID, s.ShipmentsList_FilterOptionCaption_SailDate];

export const ShipmentsList = ({ shipments, onView }: ShipmentsListProps): JSX.Element => {
  shipments.sort((s1, s2) => new Date(s2.lastUpdated).getTime() - new Date(s1.lastUpdated).getTime());

  const uniqueSailDates = Array.from(new Set(shipments.filter((s) => s.sailDate != null).map((s) => formatDate.getFilterDate(s.sailDate))));

  const [selectedOption, setSelectedOption] = useState<string>(s.ShipmentsList_FilterOptionCaption_ShipmentID);
  const [filteredShipments, setFilteredShipments] = useState<Shipment[]>(shipments);
  const [selectedDate, setSelectedDate] = useState<string>('');

  const handleOptionSelect = (event: SelectChangeEvent<string>) => {
    const {
      target: { value },
    } = event;

    setSelectedOption(value);
    setFilteredShipments(shipments);
    setSelectedDate('');
  };

  const handleDateSelect = (event: SelectChangeEvent<string>) => {
    const {
      target: { value },
    } = event;

    setSelectedDate(value);

    const selectedDate = new Date(value);

    const filteredShipments = shipments.filter((shipment) => {
      if (!shipment.sailDate) {
        return null;
      }

      const shipmentDate = new Date(shipment.sailDate);
      return shipmentDate.getFullYear() === selectedDate.getFullYear() && shipmentDate.getMonth() === selectedDate.getMonth();
    });

    setFilteredShipments(filteredShipments);
  };

  const handleSearchRequest = (event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    const searchedShipments = shipments.filter((shipment) => {
      return shipment.shipmentReference.toLowerCase().includes(event.target.value.toLowerCase());
    });
    setFilteredShipments(searchedShipments);
  };

  return (
    <TableContainer>
      <Box className={filterOptionsContainerClassNames}>
        {/* Search/filter options */}
        <FormControl className={dropDownContainerClassNames}>
          <Select value={selectedOption} onChange={handleOptionSelect}>
            {filterOptions.map((option, index) => (
              <MenuItem key={index} value={option}>
                {option}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        {selectedOption === s.ShipmentsList_FilterOptionCaption_ShipmentID && (
          <TextField
            variant="outlined"
            placeholder={s.ShipmentsList_SearchPlaceholder_ShipmentID}
            className={searchFieldClassNames}
            onChange={(searchedValue) => handleSearchRequest(searchedValue)}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon className={searchIconClassNames} />
                </InputAdornment>
              ),
            }}
          />
        )}
        {selectedOption === s.ShipmentsList_FilterOptionCaption_SailDate && (
          <FormControl className={dropDownContainerClassNames} disabled={uniqueSailDates.length === 0}>
            <Select value={selectedDate} onChange={handleDateSelect}>
              {uniqueSailDates.map((date, index) => (
                <MenuItem key={index} value={date}>
                  {date}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        )}
      </Box>

      {/* TODO: this was previous solution (not responsive) */}
      {/* <Table sx={{ borderSpacing: '0 0.5rem', borderCollapse: 'separate' }}>
        <TableBody>
          {filteredShipments.map((shipment) => (
            <TableRow key={shipment.id} className={rowClassNames}>
              <TableCell width="20%" sx={{ border: 0, verticalAlign: 'top' }}>
                <p className={shipmentItemIdCaptionClassNames}>{shipment.shipmentReference}</p>
                <div className={shipmentStateIndicatorContainerClassNames}>
                  <ShipmentStateIndicator shipmentState={shipment.state} />
                </div>
                <p className={shipmentItemLastUpdatedCaptionClassNames}>
                  {s.ShipmentsList_LastUpdatedLabel}
                  {formatDate.getLongDate(shipment.lastUpdated)}
                </p>
              </TableCell>
              <TableCell width="*" align="left" sx={{ border: 0, verticalAlign: 'top' }}>
                <TransportInformationCell shipment={shipment} />
              </TableCell>
              <TableCell width="*" align="left" sx={{ border: 0, verticalAlign: 'top' }}>
                <DeliveryDestinationCell shipment={shipment} />
              </TableCell>
              <TableCell width="*" align="left" sx={{ border: 0, verticalAlign: 'top' }}>
                <PortDepartureCell shipment={shipment} />
              </TableCell>
              <TableCell width="20rem" align="right" sx={{ border: 0, verticalAlign: 'top' }}>
                <SecondaryButton label={s.ShipmentsList_ViewDetailsButtonCaption} onClick={() => onView(shipment.id)} />
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table> */}

      <Table sx={{ borderSpacing: '0 0.5rem', borderCollapse: 'separate' }}>
        <TableBody>
          {filteredShipments.map((shipment) => (
            <TableRow key={shipment.id} className={rowClassNames}>
              <TableCell className={shipmentItemContainerClassNames}>
                <div className={contentLayoutClassNames}>
                  <div className={typedUtilityClassnames(gridRowStart('lg:row-start-1'))}>
                    <div className={shipmentItemIdCaptionClassNames}>{shipment.shipmentReference}</div>
                    <div className={shipmentStateIndicatorContainerClassNames}>
                      <ShipmentStateIndicator shipmentState={shipment.state} />
                    </div>
                    <div className={shipmentItemLastUpdatedCaptionClassNames}>
                      {s.ShipmentsList_LastUpdatedLabel}
                      {formatDate.getLongDate(shipment.lastUpdated)}
                    </div>
                  </div>

                  <div className={typedUtilityClassnames(gridRowStart('xl:row-start-1', 'lg:row-start-2', 'md:row-start-2'))}>
                    <TransportInformationCell shipment={shipment} />
                  </div>
                  <div className={typedUtilityClassnames(gridRowStart('xl:row-start-1', 'lg:row-start-2', 'md:row-start-2'))}>
                    <DeliveryDestinationCell shipment={shipment} />
                  </div>
                  <div className={typedUtilityClassnames(gridRowStart('xl:row-start-1', 'lg:row-start-2', 'md:row-start-2'))}>
                    <PortDepartureCell shipment={shipment} />
                  </div>
                  <div
                    className={typedUtilityClassnames(
                      display('flex'),
                      justifyContent('justify-end'),
                      gridColumnStart('xl:col-start-5', 'lg:col-start-3', 'md:col-start-3', 'col-start-1'),
                    )}
                  >
                    <SecondaryButton
                      label={s.ShipmentsList_ViewDetailsButtonCaption}
                      onClick={() => onView(shipment.id)}
                      buttonClassNames={typedUtilityClassnames(width('xl:w-inherit', 'lg:w-inherit', 'md:w-inherit', 'w-full'))}
                    />
                  </div>
                </div>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};
