import { ShipmentSummaryPiles } from 'models/shipment';
import { s } from 'i18n/strings';
import { _ManifestItemBase } from 'components/manifest-items/_base/_ManifestItemBase';
import { typedUtilityClassnames } from 'style/compoundClassnames';
import { ReactComponent as SamplingIcon } from 'icons/sampling.svg';

const columnItemContainerClassNames = typedUtilityClassnames('body2');
const columnItemCaptionClassNames = typedUtilityClassnames('label');

export interface SamplingManifestItemProps {
  samplingInformation: ShipmentSummaryPiles;
  canEdit: boolean;
  editOnClick: () => void;
}

export const SamplingManifestItem = ({ samplingInformation, canEdit, editOnClick }: SamplingManifestItemProps): JSX.Element => {
  return (
    <_ManifestItemBase
      title={s.SamplingManifestItem_Title}
      icon={<SamplingIcon />}
      actionButtonCaption={s.SamplingManifestItem_EditButtonCaption}
      actionButtonOnClick={editOnClick}
      actionButtonDisabled={!canEdit}
      actionButtonVisible={true}
      content={
        <div>
          <div className={columnItemContainerClassNames}>
            <span className={columnItemCaptionClassNames}>{s.SamplingManifestItem_RobinsonReferenceNoLabel}: </span>
            {samplingInformation.samplingReference}
          </div>
          <div className={columnItemContainerClassNames}>
            <span className={columnItemCaptionClassNames}>{s.SamplingManifestItem_ContactLabel}: </span>
            {samplingInformation.samplingTechnician.firstName} {samplingInformation.samplingTechnician.lastName}
          </div>
        </div>
      }
    />
  );
};
