import { ShipmentSummaryBags } from 'models/shipment';
import { InstitutionNames } from 'models/representative';
import { s } from 'i18n/strings';
import { _ManifestItemBase } from 'components/manifest-items/_base/_ManifestItemBase';
import {
  display,
  gap,
  gridTemplateColumns,
  margin,
  padding,
  textColor,
  textTransform,
  typedUtilityClassnames,
  width,
} from 'style/compoundClassnames';
import { ReactComponent as BagsIcon } from 'icons/bags.svg';

const contentLayoutClassNames = typedUtilityClassnames(
  display('grid'),
  gridTemplateColumns('xl:grid-cols-3', 'lg:grid-cols-3', 'md:grid-cols-3', 'grid-cols-1'),
  gap('gap-6'),
  width('w-full'),
);
const columnTitleClassNames = typedUtilityClassnames(
  'navBlockFont',
  textColor('text-onSurface-mediumEmphasis'),
  textTransform('uppercase'),
  padding('pb-2'),
);
const columnItemContainerClassNames = typedUtilityClassnames('body2', textColor('text-onSurface-highEmphasis'));
const columnItemCaptionClassNames = typedUtilityClassnames('label', textColor('text-onSurface-highEmphasis'));
const passportNumberClassNames = typedUtilityClassnames('caption', textColor('text-onSurface-mediumEmphasis'), margin('ml-12'));

export interface BagsProcessingManifestItemProps {
  bagsSummary: ShipmentSummaryBags;
  editOnClick: () => void;
}

export const BagsProcessingManifestItem = ({ bagsSummary, editOnClick }: BagsProcessingManifestItemProps) => {
  const truck = bagsSummary.truck;

  const coprocoStaffWitness = truck?.witnesses?.find((w) => w.institutionName === InstitutionNames.CoprocoStaff);
  const ceecOfficerWitness = truck?.witnesses?.find((w) => w.institutionName === InstitutionNames.CeecOfficer);
  const divisionOfMineWitness = truck?.witnesses?.find((w) => w.institutionName === InstitutionNames.DivisionOfMine);

  return (
    <_ManifestItemBase
      title={s.BagsProcessingManifestItem_Title}
      icon={<BagsIcon />}
      actionButtonCaption={s.BagsProcessingManifestItem_EditButtonCaption}
      actionButtonOnClick={editOnClick}
      actionButtonDisabled={false}
      actionButtonVisible={true}
      content={
        <div className={contentLayoutClassNames}>
          <div>
            <div className={columnTitleClassNames}>{s.BagsProcessingManifestItem_BagsProcessedSectionTitle}</div>
            <div className={columnItemContainerClassNames}>
              <span className={columnItemCaptionClassNames}>{s.BagsProcessingManifestItem_BagsProcessedCountLabel}: </span>
              {bagsSummary.count}
            </div>
            <div className={columnItemContainerClassNames}>
              <span className={columnItemCaptionClassNames}>{s.BagsProcessingManifestItem_BagsProcessedExpectedWeightLabel}: </span>
              {bagsSummary.totalExpectedWeightInKg} kg
            </div>
            <div className={columnItemContainerClassNames}>
              <span className={columnItemCaptionClassNames}>{s.BagsProcessingManifestItem_BagsProcessedActualWeightLabel}: </span>
              {bagsSummary.totalActualWeightInKg} kg
            </div>
          </div>

          <div>
            <div className={columnTitleClassNames}>{s.BagsProcessingManifestItem_IncomingTruckSectionTitle}</div>
            <div className={columnItemContainerClassNames}>
              <span className={columnItemCaptionClassNames}>{s.BagsProcessingManifestItem_IncomingTruckRegistrationNoLabel}: </span>
              {truck?.truckLicensePlate}
            </div>
            <div className={columnItemContainerClassNames}>
              <span className={columnItemCaptionClassNames}>{s.BagsProcessingManifestItem_IncomingTruckTrailerNoLabel}: </span>
              {truck?.trailerLicensePlate}
            </div>
            <div className={columnItemContainerClassNames}>
              <span className={columnItemCaptionClassNames}>{s.BagsProcessingManifestItem_IncomingTruckDriverNameLabel}: </span>
              {truck?.driverFirstName} {truck?.driverLastName}
            </div>
            <div className={passportNumberClassNames}>
              {s.BagsProcessingManifestItem_IncomingTruckDriverPassportLabel}: {truck?.driverPassportNumber}
            </div>
          </div>

          <div>
            <div className={columnTitleClassNames}>{s.BagsProcessingManifestItem_WitnessesSectionTitle}</div>
            <div className={columnItemContainerClassNames}>
              <span className={columnItemCaptionClassNames}>{InstitutionNames.CoprocoStaff}: </span>
              {coprocoStaffWitness?.firstName} {coprocoStaffWitness?.lastName}
            </div>
            <div className={columnItemContainerClassNames}>
              <span className={columnItemCaptionClassNames}>{InstitutionNames.CeecOfficer}: </span>
              {ceecOfficerWitness?.firstName} {ceecOfficerWitness?.lastName}
            </div>
            <div className={columnItemContainerClassNames}>
              <span className={columnItemCaptionClassNames}>{InstitutionNames.DivisionOfMine}: </span>
              {divisionOfMineWitness?.firstName} {divisionOfMineWitness?.lastName}
            </div>
          </div>
        </div>
      }
    />
  );
};
