export const InstitutionNames = {
  CoprocoStaff: 'Coproco Staff',
  CeecOfficer: 'CEEC Officer',
  DivisionOfMine: 'Division of mine',
  Robinson: 'Robinson',
} as const;

export class Representative {
  // eslint-disable-next-line no-useless-constructor
  constructor(
    public id: number,
    public firstName: string,
    public lastName: string,
    public fullName: string,
    public emailAddress: string,
    public institutionId: number,
    public institutionName: string,
  ) {}
}

export class RepresentativeWrite {
  // eslint-disable-next-line no-useless-constructor
  constructor(public firstName: string, public lastName: string, public institutionName: string, public emailAddress?: string) {}
}
