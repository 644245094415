import { Container, ContainerFreightForwardWrite, ContainerWrite } from 'models/container';
import { Document } from 'models/document';
import { apiRequest } from 'services/request';

const BaseUrl = window.__RUNTIME_CONFIG__.REACT_APP_API_BASE_URL;

export const containerService = {
  async getById(accessToken: string, id: number) {
    return await apiRequest.get<Container>(`${BaseUrl}/containers/${id}`, accessToken);
  },
  async create(accessToken: string, container: ContainerWrite) {
    return await apiRequest.post<Container, ContainerWrite>(`${BaseUrl}/containers`, accessToken, container);
  },
  async update(accessToken: string, id: number, container: ContainerWrite) {
    return await apiRequest.put<Container, ContainerWrite>(`${BaseUrl}/containers?id=${id}`, accessToken, container);
  },
  async updateFreightForwarder(accessToken: string, id: number, freightForwarder: ContainerFreightForwardWrite) {
    return await apiRequest.put<Container, ContainerFreightForwardWrite>(
      `${BaseUrl}/containers/${id}/freightforwarding`,
      accessToken,
      freightForwarder,
    );
  },
  async getDocuments<TDocumentTypeId extends number = number>(accessToken: string, id: number) {
    return await apiRequest.get<Document<TDocumentTypeId>[]>(`${BaseUrl}/containers/${id}/documents`, accessToken);
  },
};
