import { apiRequest } from 'services/request';
import { Truck, TruckWrite } from 'models/truck';
import { Bag } from 'models/bag';
import { Document } from 'models/document';

const BaseUrl = window.__RUNTIME_CONFIG__.REACT_APP_API_BASE_URL;

export const truckService = {
  async getById(accessToken: string, id: number) {
    return await apiRequest.get<Truck>(`${BaseUrl}/trucks/${id}`, accessToken);
  },
  async getAllForShipment(accessToken: string, shipmentId: number) {
    return await apiRequest.get<Truck[]>(`${BaseUrl}/trucks?shipmentId=${shipmentId}`, accessToken);
  },
  async getBags(accessToken: string, id: number) {
    return await apiRequest.get<Bag[]>(`${BaseUrl}/trucks/${id}/bags`, accessToken);
  },
  async create(accessToken: string, truck: TruckWrite) {
    return await apiRequest.post<Truck, TruckWrite>(`${BaseUrl}/trucks`, accessToken, truck);
  },
  async update(accessToken: string, id: number, truck: TruckWrite) {
    return await apiRequest.put<Truck, TruckWrite>(`${BaseUrl}/trucks?id=${id}`, accessToken, truck);
  },
  async getDocuments(accessToken: string, id: number) {
    return await apiRequest.get<Document[]>(`${BaseUrl}/trucks/${id}/documents`, accessToken);
  },
};
