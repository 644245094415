import { _DrawerFormBase, _DrawerFormBaseProps } from 'components/drawer-forms/_base';
import { ControlledTextInput, StageSummaryInput, UncontrolledTextInput } from 'components/form-components';
import { User } from 'models/user';
import { permissionsService } from 'services/permissionsService';
import { useAccessToken } from 'hooks/useAccessToken';
import { useLoggedInUser } from 'hooks/useLoggedInUser';
import { Control, SubmitHandler, UseFormGetValues, UseFormHandleSubmit, UseFormRegister, useFormState } from 'react-hook-form';

import { s } from 'i18n/strings';

import { optionalInputValueTransform, inputValueOnChangeValidator } from 'utilities/formValidation/numberValidation';

import { AddDrumDrawerFormStage, addDrumFormFieldNames, AddDrumFormState } from 'components/AddDrumDrawerFormStageManager';
import { Drum, DrumWrite } from 'models/drum';
import { display, gap, gridTemplateColumns, gridTemplateRows, padding, typedUtilityClassnames, width } from 'style/compoundClassnames';
import { useCallback, useState } from 'react';
import { drumsService } from 'services/drumsService';
import { PilesConsumedConfirmationDialog } from 'components/dialogs';
import { Pile, PileStates } from 'models/pile';
import { pilesService } from 'services/pilesService';
import { documentTypes } from 'models/document';
import {
  SingleDocumentInputCacheDocumentPendingDeletion,
  SingleDocumentInputCacheHandleDelete,
  SingleDocumentInputCacheDocumentStagedForUpload,
  SingleDocumentInputCacheUpload,
} from 'hooks/useSingleDocumentInputCache';

const weightFieldsInputContainerClassNames = typedUtilityClassnames(
  display('grid'),
  gridTemplateColumns('grid-cols-2'),
  gap('gap-8'),
  width('w-full'),
);

export interface DrumDrawerFormAddDrumMainProps
  extends Omit<_DrawerFormBaseProps, 'children' | 'showPrimarySubmitButton' | 'primarySubmitDisabled' | 'handleSubmit'> {
  title: string;
  key: AddDrumDrawerFormStage;
  shipmentId: string;
  selectedPileIds: number[];
  pileIdsAwaitingValidation: boolean;
  piles: Pile[] | null;
  drum: Drum | null;
  onSaveSuccess: () => void;
  onSaveFailed: (error: unknown) => void;
  onClose: () => void;
  onPilesClick: () => void;
  onEmptyDrumPhotoClick: () => void;
  onFilledDrumPhotoClick: () => void;
  onSealedDrumPhotoClick: () => void;
  canEdit: boolean;
  control: Control<AddDrumFormState>;
  handleSubmit: UseFormHandleSubmit<AddDrumFormState>;
  register: UseFormRegister<AddDrumFormState>;
  getValues: UseFormGetValues<AddDrumFormState>;
  emptyEvidencePendingDeletion: SingleDocumentInputCacheDocumentPendingDeletion;
  filledEvidencePendingDeletion: SingleDocumentInputCacheDocumentPendingDeletion;
  sealedEvidencePendingDeletion: SingleDocumentInputCacheDocumentPendingDeletion;
  emptyEvidenceDelete: SingleDocumentInputCacheHandleDelete;
  filledEvidenceDelete: SingleDocumentInputCacheHandleDelete;
  sealedEvidenceDelete: SingleDocumentInputCacheHandleDelete;
  emptyEvidenceStagedForUpload: SingleDocumentInputCacheDocumentStagedForUpload;
  filledEvidenceStagedForUpload: SingleDocumentInputCacheDocumentStagedForUpload;
  sealedEvidenceStagedForUpload: SingleDocumentInputCacheDocumentStagedForUpload;
  uploadEmptyEvidence: SingleDocumentInputCacheUpload;
  uploadFilledEvidence: SingleDocumentInputCacheUpload;
  uploadSealedEvidence: SingleDocumentInputCacheUpload;
  onCompletedAll: (error?: unknown | null) => void;
  setIsSaving: (isSaving: boolean) => void;
  isSaving: boolean;
  // setArePhotoUploadsPending: (arePhotosUploading: boolean | null) => void;
  setPendingPhotoUploadsCount: (count: number) => void;
}

const canEditDrum = (user: User | null, canEdit: boolean) => user != null && permissionsService.canEditDrum(user) && canEdit;

const filterPilesBySelected = (piles: Pile[], selectedPileIdsSet: Set<number> | null): Pile[] => {
  return piles?.filter((p) => {
    if (!selectedPileIdsSet) {
      return false;
    }
    return selectedPileIdsSet.has(p.id);
  });
};

const addDrumFormId = 'addDrumForm';

const stageSummaryContainerClassNames = typedUtilityClassnames(gridTemplateRows('grid-rows-3'), padding('pt-2'));

export const DrumDrawerFormAddDrumMain = ({
  title,
  shipmentId,
  drum,
  piles,
  onClose,
  onPilesClick,
  onEmptyDrumPhotoClick,
  onFilledDrumPhotoClick,
  onSealedDrumPhotoClick,
  canEdit,
  selectedPileIds,
  control,
  handleSubmit,
  register,
  emptyEvidencePendingDeletion,
  filledEvidencePendingDeletion,
  sealedEvidencePendingDeletion,
  emptyEvidenceDelete,
  filledEvidenceDelete,
  sealedEvidenceDelete,
  emptyEvidenceStagedForUpload,
  filledEvidenceStagedForUpload,
  sealedEvidenceStagedForUpload,
  uploadEmptyEvidence,
  uploadFilledEvidence,
  uploadSealedEvidence,
  getValues,
  onCompletedAll,
  setIsSaving,
  isSaving,
  // setArePhotoUploadsPending,
  setPendingPhotoUploadsCount,
}: DrumDrawerFormAddDrumMainProps): JSX.Element => {
  const accessToken = useAccessToken();
  const user = useLoggedInUser();

  const [selectedPileIdsSet, setSelectedPileIdsSet] = useState<Set<number> | null>(null);

  const [openConfirmationDialog, setOpenConfirmationDialog] = useState(false);
  const handleOpenConfirmationDialog = () => setOpenConfirmationDialog(true);
  const handleCloseConfirmationDialog = () => setOpenConfirmationDialog(false);

  const {
    errors,
    errors: {
      [addDrumFormFieldNames.referenceNumber]: referenceNumberFieldErrors,
      [addDrumFormFieldNames.robinsonSealNumber]: robinsonSealNumberFieldErrors,
      [addDrumFormFieldNames.coprocoSealNumber]: coprocoSealNumberFieldErrors,
      [addDrumFormFieldNames.grossWeight]: grossWeightFieldErrors,
      [addDrumFormFieldNames.netWeight]: netWeightFieldErrors,
      [addDrumFormFieldNames.pileIds]: pileIdsFieldErrors,
      [addDrumFormFieldNames.emptyDrumPhotoUrl]: emptyDrumPhotoUrlFieldErrors,
      [addDrumFormFieldNames.filledDrumPhotoUrl]: filledDrumPhotoUrlFieldErrors,
      [addDrumFormFieldNames.sealedDrumPhotoUrl]: sealedDrumPhotoUrlFieldErrors,
    },
    dirtyFields: {
      [addDrumFormFieldNames.pileIds]: pileIdsFieldIsDirty,
      [addDrumFormFieldNames.emptyDrumPhotoUrl]: emptyDrumPhotoUrlFieldIsDirty,
      [addDrumFormFieldNames.filledDrumPhotoUrl]: filledDrumPhotoUrlFieldIsDirty,
      [addDrumFormFieldNames.sealedDrumPhotoUrl]: sealedDrumPhotoUrlFieldIsDirty,
    },
  } = useFormState({ control });

  const saveDrum = useCallback(
    async (
      { pileIds, referenceNumber, robinsonSealNumber, coprocoSealNumber, grossWeight, netWeight }: AddDrumFormState,
      piles: Pile[],
    ) => {
      if (!accessToken) {
        return;
      }

      setIsSaving(true);

      let thisDrum = drum;
      try {
        const newDrum = new DrumWrite(
          parseInt(shipmentId),
          pileIds,
          referenceNumber || undefined,
          robinsonSealNumber || undefined,
          coprocoSealNumber || undefined,
          grossWeight || undefined,
          netWeight || undefined,
        );

        // TODO: this is a temporary workaround - if in editing drum mode and reference number has been removed, do not save that change.
        // Drum can be created without reference number, but then this value is generated by the API.
        // It then can be updated, but not removed as it's the primary drum identifier.
        if (thisDrum && !referenceNumber) {
          newDrum.referenceNumber = thisDrum.referenceNumber;
        }

        if (thisDrum?.id) {
          await drumsService.update(accessToken, thisDrum.id, newDrum);
        } else {
          thisDrum = await drumsService.create(accessToken, newDrum);
        }

        // Handles where an existing photo has been removed.
        emptyEvidencePendingDeletion && (await emptyEvidenceDelete());
        filledEvidencePendingDeletion && (await filledEvidenceDelete());
        sealedEvidencePendingDeletion && (await sealedEvidenceDelete());

        // Handles where a photo has been added.
        if (thisDrum) {
          emptyEvidenceStagedForUpload && (await uploadEmptyEvidence(thisDrum.id, documentTypes.PhotoEmptyDrum));
          filledEvidenceStagedForUpload && (await uploadFilledEvidence(thisDrum.id, documentTypes.PhotoFilledDrum));
          sealedEvidenceStagedForUpload && (await uploadSealedEvidence(thisDrum.id, documentTypes.PhotoSealedDrum));
          // setArePhotoUploadsPending(emptyEvidenceStagedForUpload && filledEvidenceStagedForUpload && sealedEvidenceStagedForUpload);

          const count = [emptyEvidenceStagedForUpload, filledEvidenceStagedForUpload, sealedEvidenceStagedForUpload].filter(Boolean).length;
          setPendingPhotoUploadsCount(count);
        }

        // Mark confirmed piles as consumed.
        await Promise.all(piles.map(async (p) => await pilesService.consumePile(accessToken, p.id)));

        // Handle case where there are no photos to be uploaded asynchronously
        if (!emptyEvidenceStagedForUpload && !filledEvidenceStagedForUpload && !sealedEvidenceStagedForUpload) {
          onCompletedAll();
          return;
        }
      } catch (error) {
        onCompletedAll(error);
      }
    },
    [
      accessToken,
      drum,
      setIsSaving,
      shipmentId,
      emptyEvidencePendingDeletion,
      emptyEvidenceDelete,
      filledEvidencePendingDeletion,
      filledEvidenceDelete,
      sealedEvidencePendingDeletion,
      sealedEvidenceDelete,
      emptyEvidenceStagedForUpload,
      filledEvidenceStagedForUpload,
      sealedEvidenceStagedForUpload,
      uploadEmptyEvidence,
      uploadFilledEvidence,
      uploadSealedEvidence,
      // setArePhotoUploadsPending,
      setPendingPhotoUploadsCount,
      onCompletedAll,
    ],
  );

  const onSubmit: SubmitHandler<AddDrumFormState> = async (drumFormState: AddDrumFormState) => {
    if (!piles) {
      return;
    }
    const selectedPileSet = new Set(drumFormState.pileIds);
    setSelectedPileIdsSet(selectedPileSet);
    const currentlySelectedPiles = piles.filter((p) => selectedPileSet.has(p.id));
    if (currentlySelectedPiles.every((p) => p.state.name === PileStates.Consumed)) {
      await saveDrum(drumFormState, []);
    } else {
      handleOpenConfirmationDialog();
    }
  };

  const handleConfirmPilesConsumed = useCallback(
    async (piles: Pile[]) => {
      const formValues = getValues();
      if (!formValues) {
        return;
      }
      await saveDrum(formValues, piles);
    },
    [getValues, saveDrum],
  );

  const [emptyDrumEvidence, filledDrumEvidence, sealedDrumEvidence, selectedPileIdsForHiddenInput] = getValues([
    addDrumFormFieldNames.emptyDrumPhotoUrl,
    addDrumFormFieldNames.filledDrumPhotoUrl,
    addDrumFormFieldNames.sealedDrumPhotoUrl,
    addDrumFormFieldNames.pileIds,
  ]);

  const disallowEdit = isSaving || !canEditDrum(user, canEdit);

  return (
    <>
      <_DrawerFormBase
        formId={addDrumFormId}
        handleSubmit={handleSubmit(onSubmit)}
        primarySubmitDisabled={disallowEdit}
        title={title}
        closeOnClick={onClose}
        closeButtonAriaLabel={s.DrumDrawer_AddDrawerMainFormCloseButtonAriaLabel}
        formAccessibilityTitle={s.DrumDrawer_AddDrawerMainFormAccessibilityTitle}
      >
        <section>
          <UncontrolledTextInput<AddDrumFormState, typeof addDrumFormFieldNames.referenceNumber>
            readOnly={disallowEdit}
            label={s.DrumDrawer_ReferenceNumberLabel}
            {...register(addDrumFormFieldNames.referenceNumber, { disabled: disallowEdit })}
            hasError={!!referenceNumberFieldErrors}
            errors={errors}
            isRequired={true}
          />
          <UncontrolledTextInput<AddDrumFormState, typeof addDrumFormFieldNames.robinsonSealNumber>
            readOnly={disallowEdit}
            label={s.DrumDrawer_RobinsonSealNumberLabel}
            {...register(addDrumFormFieldNames.robinsonSealNumber, { disabled: disallowEdit })}
            hasError={!!robinsonSealNumberFieldErrors}
            errors={errors}
            isRequired={true}
          />
          <UncontrolledTextInput<AddDrumFormState, typeof addDrumFormFieldNames.coprocoSealNumber>
            readOnly={disallowEdit}
            label={s.DrumDrawer_CoprocoSealNumberLabel}
            {...register(addDrumFormFieldNames.coprocoSealNumber, { disabled: disallowEdit })}
            hasError={!!coprocoSealNumberFieldErrors}
            errors={errors}
            isRequired={true}
          />
          <div className={weightFieldsInputContainerClassNames}>
            <ControlledTextInput<AddDrumFormState, typeof addDrumFormFieldNames.grossWeight>
              name={addDrumFormFieldNames.grossWeight}
              readOnly={disallowEdit}
              onChangeValidator={inputValueOnChangeValidator}
              transform={optionalInputValueTransform}
              label={s.DrumDrawer_GrossWeightLabel}
              hasError={!!grossWeightFieldErrors}
              errors={errors}
              control={control}
              isRequired={true}
            />
            <ControlledTextInput<AddDrumFormState, typeof addDrumFormFieldNames.netWeight>
              name={addDrumFormFieldNames.netWeight}
              readOnly={disallowEdit}
              onChangeValidator={inputValueOnChangeValidator}
              transform={optionalInputValueTransform}
              label={s.BagDrawer_ActualWeight}
              hasError={!!netWeightFieldErrors}
              errors={errors}
              control={control}
              isRequired={true}
            />
          </div>
        </section>
        <div className={stageSummaryContainerClassNames}>
          <StageSummaryInput<AddDrumFormState, typeof addDrumFormFieldNames.pileIds>
            onClick={onPilesClick}
            label={s.DrumDrawer_LinkPilesLabel}
            values={selectedPileIdsForHiddenInput.map((value) => value.toString())}
            hasError={!!pileIdsFieldErrors}
            id={'pile-ids-summary'}
            isDirty={!!pileIdsFieldIsDirty}
            nonValidSummary={s.DrumDrawer_RequiredLabel}
            validSummary={s.DrumDrawer_PilesSelectedMessage.replace('{{count}}', selectedPileIds.length.toString())}
            errors={errors}
            {...register(addDrumFormFieldNames.pileIds)}
          />
          <StageSummaryInput<AddDrumFormState, typeof addDrumFormFieldNames.emptyDrumPhotoUrl>
            onClick={onEmptyDrumPhotoClick}
            label={s.DrumDrawer_EmptyDrumPhotoLabel}
            values={emptyDrumEvidence}
            hasError={!!emptyDrumPhotoUrlFieldErrors}
            id={'empty-summary'}
            isDirty={!!emptyDrumPhotoUrlFieldIsDirty}
            nonValidSummary={s.DrumDrawer_RequiredLabel}
            validSummary={s.DrumDrawer_AddedPhotoValidSummary}
            errors={errors}
            {...register(addDrumFormFieldNames.emptyDrumPhotoUrl)}
          />
          <StageSummaryInput<AddDrumFormState, typeof addDrumFormFieldNames.filledDrumPhotoUrl>
            onClick={onFilledDrumPhotoClick}
            label={s.DrumDrawer_FilledDrumPhotoLabel}
            values={filledDrumEvidence}
            hasError={!!filledDrumPhotoUrlFieldErrors}
            id={'filled-summary'}
            isDirty={!!filledDrumPhotoUrlFieldIsDirty}
            nonValidSummary={s.DrumDrawer_RequiredLabel}
            validSummary={s.DrumDrawer_AddedPhotoValidSummary}
            errors={errors}
            {...register(addDrumFormFieldNames.filledDrumPhotoUrl)}
          />
          <StageSummaryInput<AddDrumFormState, typeof addDrumFormFieldNames.sealedDrumPhotoUrl>
            onClick={onSealedDrumPhotoClick}
            label={s.DrumDrawer_SealedDrumPhotoLabel}
            values={sealedDrumEvidence}
            hasError={!!sealedDrumPhotoUrlFieldErrors}
            id={'sealed-summary'}
            isDirty={!!sealedDrumPhotoUrlFieldIsDirty}
            nonValidSummary={s.DrumDrawer_RequiredLabel}
            validSummary={s.DrumDrawer_AddedPhotoValidSummary}
            isLastInSequence
            errors={errors}
            {...register(addDrumFormFieldNames.sealedDrumPhotoUrl)}
          />
        </div>
      </_DrawerFormBase>
      {piles && (
        <PilesConsumedConfirmationDialog
          open={openConfirmationDialog}
          piles={filterPilesBySelected(piles, selectedPileIdsSet)}
          handleConfirm={handleConfirmPilesConsumed}
          confirmDisabled={disallowEdit}
          handleCancel={handleCloseConfirmationDialog}
          cancelDisabled={isSaving}
        />
      )}
    </>
  );
};
