import { useState, useEffect } from 'react';
import { useParams } from 'react-router';
import { useForm, SubmitHandler } from 'react-hook-form';

import { Truck, TruckWrite } from 'models/truck';
import { User } from 'models/user';
import { RepresentativeWrite, InstitutionNames } from 'models/representative';
import { Shipment } from 'models/shipment';
import { shipmentsService } from 'services/shipmentsService';
import { truckService } from 'services/truckService';
import { permissionsService } from 'services/permissionsService';
import { useAccessToken, useLoggedInUser, useAppNavigation, useServerError } from 'hooks';

import { s } from 'i18n/strings';

import { ContextHeader } from 'components/headers';
import { buttonTypes, PrimaryButton, SecondaryButton } from 'components/buttons';
import { Spinner } from 'components/Spinner';
import { Notification } from 'components/Notification';
import { TruckArrivalForm, TruckFormState, truckFormStateFieldNames, truckFormStateSchema } from 'components/form-components';

import { zodResolver } from '@hookform/resolvers/zod';
import { display, justifyContent, typedUtilityClassnames } from 'style/compoundClassnames';

const formContentContainerClassNames = typedUtilityClassnames('mainLayoutPadding', display('flex'), justifyContent('justify-center'));

const truckArrivalFormId = 'truckArrivalForm';

const canEditTruckForm = (user: User | null, isSavingForm: boolean) => {
  if (!user || isSavingForm) {
    return false;
  }

  return permissionsService.canEditTruck(user) || permissionsService.canOverrideTruck(user);
};

export const TruckArrivalProcessingPage = (): JSX.Element => {
  const { shipmentId } = useParams<{ shipmentId: string }>();
  const accessToken = useAccessToken();
  const user = useLoggedInUser();

  const { navigateBagsProcessing, navigateShipments } = useAppNavigation();
  const { handleServerError } = useServerError();

  const [shipment, setShipment] = useState<Shipment | null>(null);
  const [truck, setTruck] = useState<Truck | null>(null);

  const [isLoading, setIsLoading] = useState(true);
  const [isSavingForm, setIsSavingForm] = useState(false);
  const [error, setError] = useState<string | null>(null);

  const {
    register,
    formState: {
      errors,
      errors: {
        [truckFormStateFieldNames.registrationNo]: registrationNoInputError,
        [truckFormStateFieldNames.trailer]: trailerInputError,
        [truckFormStateFieldNames.driverFirstName]: driverFirstNameInputError,
        [truckFormStateFieldNames.driverLastName]: driverLastNameInputError,
        [truckFormStateFieldNames.driverPassportNumber]: driverPassportNumberInputError,
        [truckFormStateFieldNames.coprocoStaffWitnessFirstName]: coprocoStaffWitnessFirstNameInputError,
        [truckFormStateFieldNames.coprocoStaffWitnessLastName]: coprocoStaffWitnessLastNameInputError,
        [truckFormStateFieldNames.ceecOfficerWitnessFirstName]: ceecOfficerWitnessFirstNameInputError,
        [truckFormStateFieldNames.ceecOfficerWitnessLastName]: ceecOfficerWitnessLastNameInputError,
        [truckFormStateFieldNames.divisionOfMineWitnessFirstName]: divisionOfMineWitnessFirstNameInputError,
        [truckFormStateFieldNames.divisionOfMineWitnessLastName]: divisionOfMineWitnessLastNameInputError,
      },
      isDirty,
    },
    handleSubmit,
    reset,
  } = useForm<TruckFormState>({
    resolver: zodResolver(truckFormStateSchema),
    defaultValues: {
      [truckFormStateFieldNames.registrationNo]: '',
      [truckFormStateFieldNames.trailer]: '',
      [truckFormStateFieldNames.driverFirstName]: '',
      [truckFormStateFieldNames.driverLastName]: '',
      [truckFormStateFieldNames.driverPassportNumber]: '',
      [truckFormStateFieldNames.coprocoStaffWitnessFirstName]: '',
      [truckFormStateFieldNames.coprocoStaffWitnessLastName]: '',
      [truckFormStateFieldNames.ceecOfficerWitnessFirstName]: '',
      [truckFormStateFieldNames.ceecOfficerWitnessLastName]: '',
      [truckFormStateFieldNames.divisionOfMineWitnessFirstName]: '',
      [truckFormStateFieldNames.divisionOfMineWitnessLastName]: '',
    },
  });

  useEffect(() => {
    (async () => {
      try {
        setError(null);
        setIsLoading(true);
        // TODO: factor out accessToken null checking into a base API class to run at every call, then remove access token null checking from all components
        if (shipmentId && accessToken) {
          const shipmentRequest = shipmentsService.getById(accessToken, parseInt(shipmentId));
          const trucksRequest = truckService.getAllForShipment(accessToken, parseInt(shipmentId));

          const [shipment, trucks] = await Promise.all([shipmentRequest, trucksRequest]);

          setShipment(shipment);

          if (trucks && trucks.length > 0) {
            const truck = trucks[0];
            setTruck(truck);

            reset({
              [truckFormStateFieldNames.registrationNo]: truck.truckLicensePlate || '',
              [truckFormStateFieldNames.trailer]: truck.trailerLicensePlate || '',
              [truckFormStateFieldNames.driverFirstName]: truck.driverFirstName || '',
              [truckFormStateFieldNames.driverLastName]: truck.driverLastName || '',
              [truckFormStateFieldNames.driverPassportNumber]: truck.driverPassportNumber || '',
              [truckFormStateFieldNames.coprocoStaffWitnessFirstName]:
                truck.witnesses.find((w) => w.institutionName === InstitutionNames.CoprocoStaff)?.firstName || '',
              [truckFormStateFieldNames.coprocoStaffWitnessLastName]:
                truck.witnesses.find((w) => w.institutionName === InstitutionNames.CoprocoStaff)?.lastName || '',
              [truckFormStateFieldNames.ceecOfficerWitnessFirstName]:
                truck.witnesses.find((w) => w.institutionName === InstitutionNames.CeecOfficer)?.firstName || '',
              [truckFormStateFieldNames.ceecOfficerWitnessLastName]:
                truck.witnesses.find((w) => w.institutionName === InstitutionNames.CeecOfficer)?.lastName || '',
              [truckFormStateFieldNames.divisionOfMineWitnessFirstName]:
                truck.witnesses.find((w) => w.institutionName === InstitutionNames.DivisionOfMine)?.firstName || '',
              [truckFormStateFieldNames.divisionOfMineWitnessLastName]:
                truck.witnesses.find((w) => w.institutionName === InstitutionNames.DivisionOfMine)?.lastName || '',
            });
          }
        }
      } catch (error) {
        const errorMessage = handleServerError(error);
        setError(`${s.TruckArrivalProcessingPage_RetrievingTruckDetailsError}: ${errorMessage}`);
      } finally {
        setIsLoading(false);
      }
    })();
  }, [accessToken, shipmentId, reset, handleServerError]);

  const onSubmit: SubmitHandler<TruckFormState> = async (truckState) => {
    try {
      setIsSavingForm(true);
      // TODO: factor out accessToken null checking into a base API class to run at every call, then remove access token null checking from all components
      if (shipmentId && accessToken) {
        const truckWrite = new TruckWrite(
          parseInt(shipmentId),
          truckState.registrationNo,
          truckState.trailer,
          truckState.driverFirstName,
          truckState.driverLastName,
          truckState.driverPassportNumber,
        );

        if (truckState.coprocoStaffWitnessFirstName && truckState.coprocoStaffWitnessLastName) {
          const coprocoStaffWitness = new RepresentativeWrite(
            truckState.coprocoStaffWitnessFirstName,
            truckState.coprocoStaffWitnessLastName,
            InstitutionNames.CoprocoStaff,
          );
          truckWrite.witnesses.push(coprocoStaffWitness);
        }

        if (truckState.ceecOfficerWitnessFirstName && truckState.ceecOfficerWitnessLastName) {
          const ceecOfficerWitness = new RepresentativeWrite(
            truckState.ceecOfficerWitnessFirstName,
            truckState.ceecOfficerWitnessLastName,
            InstitutionNames.CeecOfficer,
          );
          truckWrite.witnesses.push(ceecOfficerWitness);
        }

        if (truckState.divisionOfMineWitnessFirstName && truckState.divisionOfMineWitnessLastName) {
          const divisionOfMineWitness = new RepresentativeWrite(
            truckState.divisionOfMineWitnessFirstName,
            truckState.divisionOfMineWitnessLastName,
            InstitutionNames.DivisionOfMine,
          );
          truckWrite.witnesses.push(divisionOfMineWitness);
        }

        if (truck) {
          await truckService.update(accessToken, truck.id, truckWrite);
        } else {
          await truckService.create(accessToken, truckWrite);
        }

        shipmentId ? navigateBagsProcessing(shipmentId) : navigateShipments();
      }
    } catch (error) {
      const errorMessage = handleServerError(error);
      setError(`${s.TruckArrivalProcessingPage_SavingTruckDetailsError}: ${errorMessage}`);
    } finally {
      setIsSavingForm(false);
    }
  };

  const handleCancel = () => {
    shipmentId ? navigateBagsProcessing(shipmentId) : navigateShipments();
  };

  const canEdit = canEditTruckForm(user, isSavingForm);

  return (
    <>
      {isLoading ? (
        <Spinner />
      ) : (
        <>
          <ContextHeader
            contextTitle={s.TruckArrivalProcessingPage_Title}
            contextSubTitle={shipment?.shipmentReference}
            leftButtonOne={<SecondaryButton label={s.TruckArrivalProcessingPage_CancelButton} onClick={handleCancel} />}
            rightButtonOne={
              <PrimaryButton
                label={s.TruckArrivalProcessingPage_SaveButton}
                type={buttonTypes.submit}
                form={truckArrivalFormId}
                disabled={!canEdit || !isDirty}
              />
            }
            showContextHeaderContentSpacer
          />
          <Notification message={error!} severity="error" onClose={() => setError(null)} />
          <section className={formContentContainerClassNames}>
            <TruckArrivalForm
              formId={truckArrivalFormId}
              canEdit={canEdit}
              onSubmit={onSubmit}
              handleSubmit={handleSubmit}
              register={register}
              registrationNoInputError={registrationNoInputError}
              trailerInputError={trailerInputError}
              driverFirstNameInputError={driverFirstNameInputError}
              driverLastNameInputError={driverLastNameInputError}
              driverPassportNumberInputError={driverPassportNumberInputError}
              coprocoStaffWitnessFirstNameInputError={coprocoStaffWitnessFirstNameInputError}
              coprocoStaffWitnessLastNameInputError={coprocoStaffWitnessLastNameInputError}
              ceecOfficerWitnessFirstNameInputError={ceecOfficerWitnessFirstNameInputError}
              ceecOfficerWitnessLastNameInputError={ceecOfficerWitnessLastNameInputError}
              divisionOfMineWitnessFirstNameInputError={divisionOfMineWitnessFirstNameInputError}
              divisionOfMineWitnessLastNameInputError={divisionOfMineWitnessLastNameInputError}
              errors={errors}
            />
          </section>
        </>
      )}
    </>
  );
};
