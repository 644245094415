import { useCallback, useMemo, useState, useEffect } from 'react';

import { Drum } from 'models/drum';
import type { Document } from 'models/document';

import { _DrawerFormBase, _DrawerFormBaseProps } from 'components/drawer-forms/_base';
import { PhotoInput } from 'components/form-components';
import { Spinner } from 'components/Spinner';

import { useForm } from 'react-hook-form';
import { z } from 'zod';
import { zodResolver } from '@hookform/resolvers/zod';
import type { UseSingleDocumentInputCacheManagerReturn } from 'hooks/useSingleDocumentInputCache';
import { FormFieldNames } from 'utility-types';

interface PhotoEvidenceFormState {
  photo: FileList;
}

export const photoEvidenceFormFieldNames: FormFieldNames<PhotoEvidenceFormState> = {
  photo: 'photo',
} as const;

export interface DrumDrawerFormAddPhotoEvidenceProps
  extends Omit<
    _DrawerFormBaseProps,
    'children' | 'closeOnClick' | 'closeButtonAriaLabel' | 'showPrimarySubmitButton' | 'primarySubmitDisabled' | 'handleSubmit'
  > {
  photoManager: UseSingleDocumentInputCacheManagerReturn;
  previewAlternativeText: string;
  photoInputAriaLabel: string;
  photoButtonAriaLabel: string;
  drum: Drum | null;
  isExistingPhoto: boolean;
  isLoadingDocumentInfo: boolean;
  checkedForExistingDocument: boolean;
  existingPhotoInfo?: Document;
  canEdit: boolean;
}

export const DrumDrawerFormAddPhotoEvidence = ({
  title,
  backOnClick,
  photoManager: {
    documentValidatorDefinition,
    documentURL,
    setValidatedDocumentFromInput,
    documentStagedForUpload,
    handleDestageDocument,
    wasEverExistingDocument,
    handleDownloadDocument,
    setExistingDocument,
    determinedDocumentSource,
    documentDownloadError,
  },
  formId,
  previewAlternativeText,
  photoInputAriaLabel,
  photoButtonAriaLabel,
  formAccessibilityTitle,
  drum,
  isExistingPhoto,
  isLoadingDocumentInfo,
  checkedForExistingDocument,
  existingPhotoInfo,
  backButtonAriaLabel,
  canEdit,
}: DrumDrawerFormAddPhotoEvidenceProps): JSX.Element => {
  const photoEvidenceFormSchema = useMemo(
    () => z.object({ [photoEvidenceFormFieldNames.photo]: documentValidatorDefinition }),
    [documentValidatorDefinition],
  );

  // TODO: promote into useSingleDocumentInputCache if possible.
  const [photoAwaitingStaging, setPhotoAwaitingStaging] = useState(false);

  const {
    register,
    formState: {
      errors,
      errors: { [photoEvidenceFormFieldNames.photo]: photoInputFieldErrors },
      isValidating,
    },
    clearErrors,
    trigger,
    getValues,
    setValue,
  } = useForm<PhotoEvidenceFormState>({
    resolver: zodResolver(photoEvidenceFormSchema),
  });

  // Handles setting photo from input.
  useEffect(() => {
    (async () => {
      if (!photoAwaitingStaging) {
        return;
      }
      const photoEvidenceInputValue = getValues(photoEvidenceFormFieldNames.photo);
      if (!photoEvidenceInputValue || photoEvidenceInputValue.length === 0) {
        return;
      }

      if (!isValidating && !photoInputFieldErrors) {
        setValidatedDocumentFromInput(photoEvidenceInputValue[0]);
        clearErrors(photoEvidenceFormFieldNames.photo);
        setPhotoAwaitingStaging(false);
        return;
      }
      setPhotoAwaitingStaging(false);
    })();
  }, [
    clearErrors,
    errors,
    getValues,
    isValidating,
    photoAwaitingStaging,
    photoInputFieldErrors,
    setPhotoAwaitingStaging,
    setValidatedDocumentFromInput,
  ]);

  useEffect(() => {
    (async () => {
      if (documentURL || !isExistingPhoto) {
        // A photo either already exists and has been loaded, so nothing to do; or does not exist and so, also, nothing to do.
        return;
      }
      try {
        // TODO: factor out accessToken null checking into a base API class to run at every call, then remove access token null checking from all components
        if (!existingPhotoInfo) {
          return;
        }
        setExistingDocument(existingPhotoInfo);
      } catch (error) {
        console.error(error);
      }
    })();
  }, [checkedForExistingDocument, documentURL, drum, existingPhotoInfo, isExistingPhoto, setExistingDocument]);

  const photoInputOnChange = useCallback(() => {
    setPhotoAwaitingStaging(true);
    trigger(photoEvidenceFormFieldNames.photo);
  }, [setPhotoAwaitingStaging, trigger]);

  const handleDestagePhoto = useCallback(() => {
    handleDestageDocument();
    const newValue = getValues(photoEvidenceFormFieldNames.photo);
    // Handles unsetting an existing photo, to ensure the form can be submitted
    setValue(photoEvidenceFormFieldNames.photo, newValue, { shouldDirty: !!wasEverExistingDocument });
  }, [getValues, handleDestageDocument, setValue, wasEverExistingDocument]);

  return (
    <_DrawerFormBase
      formId={formId}
      backButtonAriaLabel={backButtonAriaLabel}
      primarySubmitDisabled={false}
      title={title}
      backOnClick={backOnClick}
      formAccessibilityTitle={formAccessibilityTitle}
      showPrimarySubmitButton={false}
    >
      {isLoadingDocumentInfo || !determinedDocumentSource ? (
        <Spinner />
      ) : (
        <>
          <PhotoInput<PhotoEvidenceFormState, typeof photoEvidenceFormFieldNames.photo>
            {...register(photoEvidenceFormFieldNames.photo, {
              onChange: photoInputOnChange,
              disabled: !canEdit, // TODO: is this needed?
            })}
            photoURL={documentURL}
            label={photoInputAriaLabel}
            ariaLabelOnly={true}
            previewAlternativeText={previewAlternativeText}
            buttonAriaLabel={photoButtonAriaLabel}
            readOnly={!canEdit}
            showValidationErrors={!!photoInputFieldErrors}
            validationErrors={errors}
            documentStagedForUpload={documentStagedForUpload}
            hasError={documentDownloadError != null && documentDownloadError !== undefined}
            onRemove={handleDestagePhoto}
            onDownload={handleDownloadDocument}
          />
        </>
      )}
    </_DrawerFormBase>
  );
};
