import { Outlet } from 'react-router-dom';
import { NavigationHeader } from 'components/headers';
import { useMsal } from '@azure/msal-react';

import { sessionStorageService } from 'services/sessionStorageService';

export const MainLayout = () => {
  const { instance } = useMsal();

  const logoutHandler = async () => {
    sessionStorageService.clear();
    await instance.logoutRedirect();
  };

  return (
    <>
      <NavigationHeader logoutHandler={logoutHandler} />
      <Outlet />
    </>
  );
};
