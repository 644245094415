import { State } from 'models/state';
import { Drum } from 'models/drum';

export class Container {
  // eslint-disable-next-line no-useless-constructor
  constructor(
    public id: number,
    public state: State,
    public drums: Drum[],
    public tagBoxId: string,
    public leopardLockId: string,
    public containerSealId: string,
    public truckLicensePlate: string,
    public trailerLicensePlate: string,
    public driverFirstName: string,
    public driverLastName: string,
    public driverPassportNumber: string,
    public freightForwarder: string,
  ) {}
}

export class ContainerWrite {
  // eslint-disable-next-line no-useless-constructor
  constructor(
    public shipmentId: number,
    public drumIds: number[],
    public tagBoxId: string,
    public leopardLockId?: string,
    public containerSealId?: string,
  ) {}
}

export class ContainerFreightForwardWrite {
  // eslint-disable-next-line no-useless-constructor
  constructor(
    public truckLicensePlate: string,
    public trailerLicensePlate: string,
    public driverFirstName: string,
    public driverLastName: string,
    public driverPassportNumber: string,
    public freightForwarder?: string,
  ) {}
}

export type ContainerStateName = keyof typeof containerStates;

export const containerStates = {
  New: 'New',
  Full: 'Full',
  Sealed: 'Sealed',
  Done: 'Done',
} as const;
