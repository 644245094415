import { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useForm, SubmitHandler } from 'react-hook-form';

import { User } from 'models/user';
import { permissionsService } from 'services/permissionsService';
import { shipmentsService } from 'services/shipmentsService';
import { Shipment, shipmentStates, ShipmentWrite } from 'models/shipment';
import { useAccessToken, useLoggedInUser, useAppNavigation, useServerError } from 'hooks';

import { s } from 'i18n/strings';

import { display, justifyContent, typedUtilityClassnames } from 'style/compoundClassnames';

import { ContextHeader } from 'components/headers';
import { buttonTypes, DestructiveButton, PrimaryButton, SecondaryButton } from 'components/buttons';
import { Spinner } from 'components/Spinner';
import { Notification } from 'components/Notification';
import { BasicConfirmationDialog } from 'components/dialogs';
import {
  EditShipmentForm,
  EditShipmentFormState,
  editShipmentFormStateFieldNames,
  editShipmentFormStateSchema,
} from 'components/form-components';
import { zodResolver } from '@hookform/resolvers/zod';

const formContentContainerClassNames = typedUtilityClassnames('mainLayoutPadding', display('flex'), justifyContent('justify-center'));

const editShipmentFormId = 'editShipmentForm';

const canEditShipmentForm = (user: User | null, shipment: Shipment | null, isSavingForm: boolean) => {
  if (!user || !shipment || isSavingForm) {
    return false;
  }

  return permissionsService.canEditShipment(user) && (shipment.state.name !== shipmentStates.Complete || shipment.state.isOverridden);
};

const canDeleteShipment = (user: User | null, isSavingForm: boolean) =>
  user != null && permissionsService.canDeleteShipment(user) && !isSavingForm;

export const EditShipmentPage = () => {
  const { shipmentId } = useParams<{ shipmentId: string }>();
  const accessToken = useAccessToken();
  const user = useLoggedInUser();

  const { navigateShipments, navigateViewShipment } = useAppNavigation();
  const { handleServerError } = useServerError();

  const [shipment, setShipment] = useState<Shipment | null>(null);
  const [shipmentReference, setShipmentReference] = useState<string | null>(null);

  const [isInProgress, setIsInProgress] = useState(true);
  const [error, setError] = useState<string | null>(null);

  const [openConfirmationDialog, setOpenConfirmationDialog] = useState(false);
  const handleOpenConfirmationDialog = () => setOpenConfirmationDialog(true);
  const handleCloseConfirmationDialog = () => setOpenConfirmationDialog(false);

  const {
    register,
    formState: {
      errors,
      errors: { [editShipmentFormStateFieldNames.shipmentReference]: shipmentReferenceInputError },
      isDirty,
    },
    handleSubmit,
    reset,
  } = useForm<EditShipmentFormState>({
    resolver: zodResolver(editShipmentFormStateSchema),
    defaultValues: { [editShipmentFormStateFieldNames.shipmentReference]: '' },
  });

  useEffect(() => {
    (async () => {
      try {
        setError(null);
        setIsInProgress(true);
        // TODO: factor out accessToken null checking into a base API class to run at every call, then remove access token null checking from all components
        if (accessToken && shipmentId) {
          const shipment = await shipmentsService.getById(accessToken, parseInt(shipmentId));
          setShipment(shipment);
          setShipmentReference(shipment.shipmentReference);

          reset({ [editShipmentFormStateFieldNames.shipmentReference]: shipment.shipmentReference || '' });
        }
      } catch (error) {
        const errorMessage = handleServerError(error);
        setError(`${s.EditShipmentPage_CouldNotGetShipment_ErrorMessage}: ${errorMessage}`);
      } finally {
        setIsInProgress(false);
      }
    })();
  }, [accessToken, handleServerError, reset, shipmentId]);

  const onSubmit: SubmitHandler<EditShipmentFormState> = async (shipmentReferenceState) => {
    try {
      setIsInProgress(true);
      // TODO: factor out accessToken null checking into a base API class to run at every call, then remove access token null checking from all components
      if (accessToken && shipmentId) {
        const newShipmentReference = new ShipmentWrite(shipmentReferenceState.shipmentReference);
        await shipmentsService.update(accessToken, parseInt(shipmentId), newShipmentReference);
        navigateViewShipment(shipmentId);
      }
    } catch (error) {
      const errorMessage = handleServerError(error);
      setError(`${s.EditShipmentPage_CouldNotUpdateShipment_ErrorMessage}: ${errorMessage}`);
    } finally {
      setIsInProgress(false);
    }
  };

  const handleDeleteShipment = async () => {
    try {
      setIsInProgress(true);
      // TODO: factor out accessToken null checking into a base API class to run at every call, then remove access token null checking from all components
      if (accessToken && shipmentId) {
        await shipmentsService.delete(accessToken, parseInt(shipmentId));
        navigateShipments();
      }
    } catch (error) {
      const errorMessage = handleServerError(error);
      setError(`${s.EditShipmentPage_CouldNotDeleteShipment_ErrorMessage}: ${errorMessage}`);
    } finally {
      handleCloseConfirmationDialog();
      setIsInProgress(false);
    }
  };

  const handleCancel = () => {
    shipmentId ? navigateViewShipment(shipmentId) : navigateShipments();
  };

  const canEdit = canEditShipmentForm(user, shipment, isInProgress);
  const canDelete = canDeleteShipment(user, isInProgress);

  return (
    <>
      <ContextHeader
        contextTitle={s.EditShipmentPage_Title}
        leftButtonOne={<SecondaryButton label={s.EditShipmentPage_CancelButtonCaption} onClick={handleCancel} disabled={isInProgress} />}
        rightButtonOne={
          <DestructiveButton label={s.EditShipmentPage_DeleteButtonCaption} onClick={handleOpenConfirmationDialog} disabled={!canDelete} />
        }
        rightButtonTwo={
          <PrimaryButton
            label={s.EditShipmentPage_SaveButtonCaption}
            type={buttonTypes.submit}
            form={editShipmentFormId}
            disabled={!canEdit || !isDirty}
          />
        }
        showContextHeaderContentSpacer
      />

      <Notification message={error!} severity="error" onClose={() => setError(null)} />
      <div className={formContentContainerClassNames}>
        {shipmentReference ? (
          <EditShipmentForm
            formId={editShipmentFormId}
            canEdit={canEdit}
            onSubmit={onSubmit}
            handleSubmit={handleSubmit}
            register={register}
            shipmentReferenceInputError={shipmentReferenceInputError}
            errors={errors}
          />
        ) : (
          <Spinner />
        )}
      </div>

      <BasicConfirmationDialog
        open={openConfirmationDialog}
        title={s.EditShipmentPage_ConfirmDeleteShipmentDialogTitle}
        content={s.EditShipmentPage_ConfirmDeleteShipmentDialogContent}
        handleConfirm={handleDeleteShipment}
        confirmDisabled={!canDelete && isInProgress}
        handleCancel={handleCloseConfirmationDialog}
        cancelDisabled={isInProgress}
      />
    </>
  );
};
