import { Representative, RepresentativeWrite } from 'models/representative';
import { State } from 'models/state';

export class Truck {
  // eslint-disable-next-line no-useless-constructor
  constructor(
    public id: number,
    public state: State,
    public truckLicensePlate: string,
    public trailerLicensePlate: string,
    public driverFirstName: string,
    public driverLastName: string,
    public driverPassportNumber: string,
    public witnesses: Representative[],
  ) {}
}

export class TruckWrite {
  // eslint-disable-next-line no-useless-constructor
  constructor(
    public shipmentId: number,
    public truckLicensePlate: string,
    public trailerLicensePlate: string,
    public driverFirstName: string,
    public driverLastName: string,
    public driverPassportNumber: string,
    public witnesses: RepresentativeWrite[] = [],
  ) {}
}

export const TruckStates = {
  New: 'New',
  Arrived: 'Arrived',
  Inspected: 'Inspected',
  Offloaded: 'Offloaded',
} as const;
