import { ReactNode } from 'react';
import { Box, Modal } from '@mui/material';
import { display, justifyContent, margin, padding, textColor, typedUtilityClassnames } from 'style/compoundClassnames';
import { PrimaryButton, SecondaryButton } from 'components/buttons';

const dialogStyle = {
  position: 'absolute',
  top: '15%',
  left: '50%',
  minWidth: '40vw',
  transform: 'translateX(-50%)',
  bgcolor: 'white',
  borderRadius: 2,
  p: 3,
};

const dialogTitleClassNames = typedUtilityClassnames('headline5', textColor('text-primary-900'), padding('pb-2'));
const buttonsContainerClassNames = typedUtilityClassnames(display('flex'), justifyContent('justify-end'), padding('pt-4'));
const buttonClassNames = typedUtilityClassnames(margin('ml-2'));

export interface _ConfirmationDialogBaseProps {
  open: boolean;
  title: string;
  children: ReactNode;
  primaryButtonCaption: string;
  primaryButtonAction: () => void;
  primaryButtonDisabled: boolean;
  secondaryButtonCaption: string;
  secondaryButtonAction: () => void;
  secondaryButtonDisabled: boolean;
}

export const _ConfirmationDialogBase = ({
  open,
  title,
  children,
  primaryButtonCaption,
  primaryButtonAction,
  primaryButtonDisabled,
  secondaryButtonCaption,
  secondaryButtonAction,
  secondaryButtonDisabled,
}: _ConfirmationDialogBaseProps): JSX.Element => {
  return (
    <Modal open={open}>
      <Box sx={dialogStyle}>
        <div className={dialogTitleClassNames}>{title}</div>
        {children}
        <div className={buttonsContainerClassNames}>
          <SecondaryButton
            label={secondaryButtonCaption}
            onClick={secondaryButtonAction}
            disabled={secondaryButtonDisabled}
            buttonClassNames={buttonClassNames}
          />
          <PrimaryButton
            label={primaryButtonCaption}
            onClick={primaryButtonAction}
            disabled={primaryButtonDisabled}
            buttonClassNames={buttonClassNames}
          />
        </div>
      </Box>
    </Modal>
  );
};
