import type { MouseEventHandler, ReactElement } from 'react';
import { _ButtonBase, _ButtonBaseProps } from 'components/buttons/_base/_ButtonBase';
import {
  typedUtilityClassnames,
  backgroundColor,
  borderColor,
  borderStyle,
  borderWidth,
  textColor,
  onlyComputedCombineClassnames,
} from 'style/compoundClassnames';

export interface DestructiveButtonProps extends Partial<Pick<_ButtonBaseProps, 'type'>> {
  disabled?: boolean;
  onClick?: MouseEventHandler;
  label: string | ReactElement;
  buttonClassNames?: string;
}

const enabledClasses = typedUtilityClassnames(
  borderColor('border-error-600'),
  textColor('text-error-600'),
  backgroundColor('hover:bg-error-50'),
);
const disabledClasses = typedUtilityClassnames(textColor('text-error-200'), borderColor('border-error-200'));
const commonClasses = typedUtilityClassnames(backgroundColor('bg-white'), borderStyle('border-solid'), borderWidth('border'));

export const DestructiveButton = ({
  disabled = false,
  onClick,
  label,
  type = 'button',
  buttonClassNames,
}: DestructiveButtonProps): JSX.Element => (
  <>
    <_ButtonBase
      label={label}
      type={type}
      onClick={onClick}
      disabled={disabled}
      buttonClassNames={onlyComputedCombineClassnames(
        { [enabledClasses]: !disabled, [disabledClasses]: disabled },
        commonClasses,
        buttonClassNames,
      )}
    />
  </>
);
