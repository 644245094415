import type { ReactElement, MouseEventHandler, ButtonHTMLAttributes } from 'react';
import { NoUndefined } from 'utility-types';

import {
  onlyComputedCombineClassnames,
  typedUtilityClassnames,
  display,
  justifyItems,
  alignItems,
  padding,
  justifyContent,
  height,
} from 'style/compoundClassnames';

const getIconButtonBaseRootClassNames = (buttonClassNames: string | undefined) =>
  onlyComputedCombineClassnames(
    buttonClassNames,
    typedUtilityClassnames(
      'buttons',
      display('flex'),
      justifyContent('justify-center'),
      justifyItems('justify-items-center'),
      alignItems('items-center'),
      padding('p-4'),
      height('h-fit'),
    ),
  );

export interface _IconButtonBaseProps extends NoUndefined<Required<Pick<ButtonHTMLAttributes<HTMLButtonElement>, 'type'>>> {
  children: ReactElement;
  buttonClassNames?: string;
  onClick?: MouseEventHandler;
  disabled: boolean;
  ariaLabel: string;
}

export const _IconButtonBaseTypes = {
  button: 'button',
} as const;

export const _IconButtonBase = ({ children, buttonClassNames, onClick, disabled, type, ariaLabel }: _IconButtonBaseProps): JSX.Element => {
  return (
    <button
      aria-label={ariaLabel}
      className={getIconButtonBaseRootClassNames(buttonClassNames)}
      onClick={onClick}
      disabled={disabled}
      type={type}
    >
      {children}
    </button>
  );
};
