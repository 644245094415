import { NavLink } from 'react-router-dom';

import { topLevelPages } from 'navigation/paths';
import { User } from 'models/user';
import { sessionStorageKeys, sessionStorageService } from 'services/sessionStorageService';
import { s } from 'i18n/strings';

import { AccountSettingsDropMenu } from 'components/drop-menus/AccountSettingsDropMenu';

import { ReactComponent as AfrimetLogo } from 'icons/logo.svg';

import {
  alignItems,
  backgroundColor,
  borderRadius,
  display,
  flex,
  height,
  padding,
  textColor,
  typedUtilityClassnames,
  width,
  gridTemplateColumns,
  justifySelf,
  boxSizing,
  textDecoration,
  textUnderlineOffset,
  textDecorationThickness,
} from 'style/compoundClassnames';
import { NavigationDropMenu } from 'components/drop-menus';

const NavLinkCommonClassNames = typedUtilityClassnames('navBlockFont', textColor('text-white'), padding('px-5'));
const ActiveLinkClassNames = typedUtilityClassnames(
  NavLinkCommonClassNames,
  textDecoration('underline'),
  textUnderlineOffset('underline-offset-4'),
  textDecorationThickness('decoration-from-font'),
);
const setActiveLinkClassNames = ({ isActive }: { isActive: boolean }) => (isActive ? ActiveLinkClassNames : NavLinkCommonClassNames);

const menuLogoClassNames = typedUtilityClassnames(
  padding('p-3.5'),
  textColor('text-white'),
  height('h-6'),
  display('md:hidden'),
  boxSizing('box-content'),
  borderRadius('rounded'),
  backgroundColor('hover:bg-primary-400'),
);

const smallScreenNav = <NavigationDropMenu paths={topLevelPages} logoButtonClassNames={menuLogoClassNames} />;

const mediumAndLargerNavigationLinksContainerClassNames = typedUtilityClassnames(
  width('w-full'),
  display('hidden', 'md:flex'),
  alignItems('items-center'),
  padding('p-2'),
);

const getMediumAndLargerScreenNavigationLinks = () => {
  return (
    <nav className={mediumAndLargerNavigationLinksContainerClassNames}>
      {topLevelPages.map(({ key, path, pageDisplayName }) => (
        <NavLink key={key} to={path} className={setActiveLinkClassNames}>
          {pageDisplayName}
        </NavLink>
      ))}
    </nav>
  );
};

const logoClassNames = typedUtilityClassnames(flex('flex-none'), justifySelf('justify-self-center'));

export interface NavigationHeaderProps {
  logoutHandler?: () => void;
}

const headerElementClassNames = typedUtilityClassnames(
  width('w-full'),
  'headers',
  alignItems('items-center'),
  backgroundColor('bg-primary-900'),
  gridTemplateColumns('grid-cols-mcfr3'),
  display('grid', 'md:flex'),
  height('h-16'),
);

export const NavigationHeader = ({ logoutHandler }: NavigationHeaderProps) => {
  const user = sessionStorageService.get<User | null>(sessionStorageKeys.loggedInUserData);
  return (
    <header aria-label={s.NavigationHeader_HeaderAriaLabel} className={headerElementClassNames}>
      {user && smallScreenNav}
      <AfrimetLogo title={s.NavigationHeader_AfrimetLogoTitle} className={logoClassNames} />
      {user && getMediumAndLargerScreenNavigationLinks()}
      {user && logoutHandler && (
        <AccountSettingsDropMenu fullName={user.fullName} emailAddress={user.emailAddress} logoutHandler={logoutHandler} />
      )}
    </header>
  );
};
