import { useRoutes, Navigate } from 'react-router-dom';

import { MainLayout } from 'layouts/MainLayout';
import { UsersPage } from 'pages/UsersPage';
import { EditUserPage } from 'pages/EditUserPage';
import { ShipmentsPage } from 'pages/ShipmentsPage';
import { ViewShipmentPage } from 'pages/ViewShipmentPage';
import { EditShipmentPage } from 'pages/EditShipmentPage';
import { BagsProcessingPage } from 'pages/BagsProcessingPage';
import { BagProcessingPage } from 'pages/BagProcessingPage';
import { TruckArrivalProcessingPage } from 'pages/TruckArrivalProcessingPage';
import { PilesProcessingPage } from 'pages/PilesProcessingPage';
import { SamplingInformationPage } from 'pages/SamplingInformationPage';
import { PileProcessingPage } from 'pages/PileProcessingPage';
import { DrumProcessingPage } from 'pages/DrumProcessingPage';
import { ContainerProcessingPage } from 'pages/ContainerProcessingPage';
import { FreightForwardingInformationPage } from 'pages/FreightForwardingInformationPage';
import { ContainerDrumsPage } from 'pages/ContainerDrumsPage';
import { ShipmentProcessingPage } from 'pages/ShipmentProcessingPage';
import { ShipmentDetailsPage } from 'pages/ShipmentDetailsPage';
import { IoTDeviceInformationPage } from 'pages/IoTDeviceInformationPage';
import { ViewDocumentPage } from 'pages/ViewDocumentPage';
import { NavigationPaths } from 'navigation/paths';

import { User } from 'models/user';
import { permissionsService } from 'services/permissionsService';

interface RouterProps {
  user: User;
}

export const Router = ({ user }: RouterProps) => {
  const canEditUser = permissionsService.canEditUser(user);
  const canEditTruck = permissionsService.canEditTruck(user);

  const routes = useRoutes([
    {
      element: <MainLayout />,
      children: [
        { path: NavigationPaths.RootPath, element: <Navigate to={NavigationPaths.ShipmentsPath} /> },
        { path: NavigationPaths.ShipmentsPath, element: <ShipmentsPage /> },
        { path: NavigationPaths.ViewShipmentPath, element: <ViewShipmentPage /> },
        { path: NavigationPaths.EditShipmentPath, element: <EditShipmentPage /> },
        { path: NavigationPaths.BagsProcessingPath, element: <BagsProcessingPage /> },
        { path: NavigationPaths.BagProcessingPath, element: <BagProcessingPage /> },
        {
          path: NavigationPaths.TruckArrivalProcessingPath,
          element: canEditTruck ? <TruckArrivalProcessingPage /> : <Navigate to={NavigationPaths.BagsProcessingPath} />,
        },
        { path: NavigationPaths.UsersPath, element: <UsersPage /> },
        { path: NavigationPaths.EditUserPath, element: canEditUser ? <EditUserPage /> : <Navigate to={NavigationPaths.UsersPath} /> },
        { path: NavigationPaths.PilesProcessingPath, element: <PilesProcessingPage /> },
        { path: NavigationPaths.SamplingInformationPath, element: <SamplingInformationPage /> },
        { path: NavigationPaths.PileProcessingPath, element: <PileProcessingPage /> },
        { path: NavigationPaths.DrumProcessingPath, element: <DrumProcessingPage /> },
        { path: NavigationPaths.ContainerProcessingPath, element: <ContainerProcessingPage /> },
        { path: NavigationPaths.FreightForwardingInformationPath, element: <FreightForwardingInformationPage /> },
        { path: NavigationPaths.ContainerDrumsPath, element: <ContainerDrumsPage /> },
        { path: NavigationPaths.ShipmentProcessingPath, element: <ShipmentProcessingPage /> },
        { path: NavigationPaths.ShipmentDetailsPath, element: <ShipmentDetailsPage /> },
        { path: NavigationPaths.IoTDeviceInformationPath, element: <IoTDeviceInformationPage /> },
        { path: NavigationPaths.ViewDocumentPath, element: <ViewDocumentPage /> },
      ],
    },
    { path: NavigationPaths.AppStatusPath, element: <div>Version: {window.__RUNTIME_CONFIG__.REACT_APP_VERSION!}</div> },
  ]);

  return routes;
};
