import { ShipmentSummaryDrums } from 'models/shipment';
import { _ManifestItemBase } from 'components/manifest-items/_base/_ManifestItemBase';
import { s } from 'i18n/strings';
import { typedUtilityClassnames, textTransform, textColor, padding } from 'style/compoundClassnames';
import { ReactComponent as DrumsIcon } from 'icons/drums.svg';

const columnTitleClassNames = typedUtilityClassnames(
  'navBlockFont',
  textColor('text-onSurface-mediumEmphasis'),
  textTransform('uppercase'),
  padding('pb-2'),
);
const columnItemContainerClassNames = typedUtilityClassnames('body2');

export interface DrumsProcessingManifestItemProps {
  drumsSummary: ShipmentSummaryDrums;
  editOnClick: () => void;
}

export const DrumsProcessingManifestItem = ({ drumsSummary, editOnClick }: DrumsProcessingManifestItemProps): JSX.Element => {
  return (
    <_ManifestItemBase
      title={s.DrumsProcessingManifestItem_Title}
      icon={<DrumsIcon />}
      actionButtonCaption={s.DrumsProcessingManifestItem_EditButtonCaption}
      actionButtonOnClick={editOnClick}
      actionButtonDisabled={false}
      actionButtonVisible={true}
      content={
        <div className={columnItemContainerClassNames}>
          <div className={columnTitleClassNames}>{s.DrumsProcessingManifestItem_DrumsProcessedSectionTitle}</div>
          <div className={columnItemContainerClassNames}>{drumsSummary.count}</div>
        </div>
      }
    />
  );
};
