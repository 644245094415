import { s } from 'i18n/strings';
import { FieldError, FieldErrors, SubmitHandler, UseFormHandleSubmit, UseFormRegister } from 'react-hook-form';
import {
  display,
  gap,
  gridRowStart,
  gridTemplateColumns,
  gridTemplateRows,
  margin,
  padding,
  textColor,
  typedUtilityClassnames,
} from 'style/compoundClassnames';
import { getOptionalInputLength, getRequiredInputLength } from 'utilities/formValidation/stringValidation';
import { ExclusivelyStringKeyedRecordAsNonOptional, FormFieldNames } from 'utility-types';
import { z } from 'zod';
import { UncontrolledTextInput } from 'components/form-components/inputs';
import { ReactComponent as TruckIcon } from 'icons/truck.svg';

const sectionsSharedClassNames = typedUtilityClassnames(
  display('grid'),
  gridTemplateColumns('lg:grid-cols-2', 'grid-cols-1'),
  gap('gap-x-6'),
);
const formClassNames = typedUtilityClassnames('nonExpandingMainLayoutContentSection');
const formSectionClassNames = typedUtilityClassnames(sectionsSharedClassNames, gridTemplateRows('grid-rows-mc4', 'lg:grid-rows-mc3'));
const sectionsTitlesSharedClassNames = typedUtilityClassnames('headline5', display('flex'), textColor('text-primary-900'), padding('pb-6'));
const sectionsTitleIconContainerClassNames = typedUtilityClassnames(margin('mt-1'), padding('pr-3'));
const sectionFirstRowSharedClassNames = typedUtilityClassnames(gridRowStart('lg:row-start-2'));
const sectionSecondRowSharedClassNames = typedUtilityClassnames(gridRowStart('lg:row-start-3'));
const sectionThirdRowSharedClassNames = typedUtilityClassnames(gridRowStart('lg:row-start-4'));
const sectionFourthRowSharedClassNames = typedUtilityClassnames(gridRowStart('lg:row-start-5'));

const freightForwarderRequiredFieldsValidation = (minLength: number, maxLength: number) =>
  getRequiredInputLength(
    s.FormValidationError_FieldRequired,
    minLength,
    s.FormValidationError_MinimumLengthError.replace('{{length}}', minLength.toString()),
    maxLength,
    s.FormValidationError_MaximumLengthError.replace('{{length}}', maxLength.toString()),
  );

const freightForwarderOptionalFieldsValidation = (minLength: number, maxLength: number) =>
  getOptionalInputLength(
    minLength,
    s.FormValidationError_MinimumLengthError.replace('{{length}}', minLength.toString()),
    maxLength,
    s.FormValidationError_MaximumLengthError.replace('{{length}}', maxLength.toString()),
  );

export const freightForwarderFormStateSchema = z.object({
  registrationNo: freightForwarderRequiredFieldsValidation(5, 20),
  trailer: freightForwarderRequiredFieldsValidation(5, 20),
  driverFirstName: freightForwarderRequiredFieldsValidation(1, 50),
  driverLastName: freightForwarderRequiredFieldsValidation(1, 50),
  driverPassportNumber: freightForwarderRequiredFieldsValidation(1, 30),
  freightForwarder: freightForwarderOptionalFieldsValidation(3, 50),
});

export type FreightForwarderFormState = z.infer<typeof freightForwarderFormStateSchema>;

export const freightForwarderFormStateFieldNames: ExclusivelyStringKeyedRecordAsNonOptional<FormFieldNames<FreightForwarderFormState>> = {
  registrationNo: 'registrationNo',
  trailer: 'trailer',
  driverFirstName: 'driverFirstName',
  driverLastName: 'driverLastName',
  driverPassportNumber: 'driverPassportNumber',
  freightForwarder: 'freightForwarder',
} as const;

export interface FreightForwarderFormProps {
  formId: string;
  canEdit: boolean;

  onSubmit: SubmitHandler<FreightForwarderFormState>;
  handleSubmit: UseFormHandleSubmit<FreightForwarderFormState>;
  register: UseFormRegister<FreightForwarderFormState>;

  registrationNoInputError: FieldError | undefined;
  trailerInputError: FieldError | undefined;
  driverFirstNameInputError: FieldError | undefined;
  driverLastNameInputError: FieldError | undefined;
  driverPassportNumberInputError: FieldError | undefined;
  freightForwarderInputError: FieldError | undefined;

  errors: FieldErrors<FreightForwarderFormState>;
}

export const FreightForwarderForm = ({
  formId,
  canEdit,
  onSubmit,
  handleSubmit,
  register,
  registrationNoInputError,
  trailerInputError,
  driverFirstNameInputError,
  driverLastNameInputError,
  driverPassportNumberInputError,
  freightForwarderInputError,
  errors,
}: FreightForwarderFormProps): JSX.Element => {
  return (
    <form id={formId} onSubmit={handleSubmit(onSubmit)} className={formClassNames}>
      <section className={formSectionClassNames}>
        <div className={sectionsTitlesSharedClassNames}>
          <div className={sectionsTitleIconContainerClassNames}>
            <TruckIcon />
          </div>
          {s.FreightForwardingInformationPage_TruckInformation}
        </div>
        <div className={sectionFirstRowSharedClassNames}>
          <UncontrolledTextInput<FreightForwarderFormState, typeof freightForwarderFormStateFieldNames.registrationNo>
            readOnly={!canEdit}
            label={s.FreightForwardingInformationPage_RegistrationNo}
            {...register(freightForwarderFormStateFieldNames.registrationNo, { disabled: !canEdit })}
            hasError={!!registrationNoInputError}
            errors={errors}
            isRequired={true}
          />
        </div>
        <div className={sectionFirstRowSharedClassNames}>
          <UncontrolledTextInput<FreightForwarderFormState, typeof freightForwarderFormStateFieldNames.trailer>
            readOnly={!canEdit}
            label={s.FreightForwardingInformationPage_Trailer}
            {...register(freightForwarderFormStateFieldNames.trailer, { disabled: !canEdit })}
            hasError={!!trailerInputError}
            errors={errors}
            isRequired={true}
          />
        </div>
        <div className={sectionSecondRowSharedClassNames}>
          <UncontrolledTextInput<FreightForwarderFormState, typeof freightForwarderFormStateFieldNames.driverFirstName>
            readOnly={!canEdit}
            label={s.FreightForwardingInformationPage_DriverFirstName}
            {...register(freightForwarderFormStateFieldNames.driverFirstName, { disabled: !canEdit })}
            hasError={!!driverFirstNameInputError}
            errors={errors}
            isRequired={true}
          />
        </div>
        <div className={sectionSecondRowSharedClassNames}>
          <UncontrolledTextInput<FreightForwarderFormState, typeof freightForwarderFormStateFieldNames.driverLastName>
            readOnly={!canEdit}
            label={s.FreightForwardingInformationPage_DriverLastName}
            {...register(freightForwarderFormStateFieldNames.driverLastName, { disabled: !canEdit })}
            hasError={!!driverLastNameInputError}
            errors={errors}
            isRequired={true}
          />
        </div>
        <div className={sectionThirdRowSharedClassNames}>
          <UncontrolledTextInput<FreightForwarderFormState, typeof freightForwarderFormStateFieldNames.driverPassportNumber>
            readOnly={!canEdit}
            label={s.FreightForwardingInformationPage_DriverPsp}
            {...register(freightForwarderFormStateFieldNames.driverPassportNumber, { disabled: !canEdit })}
            hasError={!!driverPassportNumberInputError}
            errors={errors}
            isRequired={true}
          />
        </div>
        <div className={sectionFourthRowSharedClassNames}>
          <UncontrolledTextInput<FreightForwarderFormState, typeof freightForwarderFormStateFieldNames.freightForwarder>
            readOnly={!canEdit}
            label={s.FreightForwardingInformationPage_FreightForwarder}
            {...register(freightForwarderFormStateFieldNames.freightForwarder, { disabled: !canEdit })}
            hasError={!!freightForwarderInputError}
            errors={errors}
            isRequired={false}
          />
        </div>
      </section>
    </form>
  );
};
