import { ShipmentSummaryBags } from 'models/shipment';
import { s } from 'i18n/strings';
import { _ManifestItemBase } from 'components/manifest-items/_base/_ManifestItemBase';
import { display, gap, gridTemplateColumns, typedUtilityClassnames, width } from 'style/compoundClassnames';
import { ReactComponent as BagsIcon } from 'icons/bags.svg';

const contentLayoutClassNames = typedUtilityClassnames(
  display('grid'),
  gridTemplateColumns('xl:grid-cols-3', 'lg:grid-cols-3', 'md:grid-cols-3', 'grid-cols-1'),
  gap('gap-6'),
  width('w-full'),
);
const columnItemContainerClassNames = typedUtilityClassnames('body2');
const columnItemCaptionClassNames = typedUtilityClassnames('label');

export interface BagsManifestItemProps {
  bagsSummary: ShipmentSummaryBags;
  editOnClick: () => void;
}

export const BagsManifestItem = ({ bagsSummary, editOnClick }: BagsManifestItemProps) => {
  return (
    <_ManifestItemBase
      title={s.BagsManifestItem_Title}
      icon={<BagsIcon />}
      actionButtonCaption={s.BagsManifestItem_EditButtonCaption}
      actionButtonOnClick={editOnClick}
      actionButtonDisabled={false}
      actionButtonVisible={true}
      content={
        <div className={contentLayoutClassNames}>
          <div className={columnItemContainerClassNames}>
            <span className={columnItemCaptionClassNames}>{s.BagsManifestItem_BagsCountLabel}: </span>
            {bagsSummary.count}
          </div>
          <div className={columnItemContainerClassNames}>
            <span className={columnItemCaptionClassNames}>{s.BagsManifestItem_BagsExpectedWeightLabel}: </span>
            {bagsSummary.totalExpectedWeightInKg} kg
          </div>
          <div className={columnItemContainerClassNames}>
            <span className={columnItemCaptionClassNames}>{s.BagsManifestItem_BagsActualWeightLabel}: </span>
            {bagsSummary.totalActualWeightInKg} kg
          </div>
        </div>
      }
    />
  );
};
