import { z } from 'zod';
import { twoDecimalPlacesValidCommaOrPointDelimitedFloat } from 'utilities/regEx';
import { ControlledInputTransform } from 'components/form-components';

export const getRequiredInputValue = (
  requiredErrorMessage: string,
  minValue: number,
  belowMinValueErrorMessage: string,
  maxValue: number,
  aboveMaxValueErrorMessage: string,
) =>
  z
    .number({ required_error: requiredErrorMessage })
    .superRefine((value, ctx) => {
      if (value === 0) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message: requiredErrorMessage,
          fatal: true,
        });
        return undefined;
      }
    })
    .superRefine((value, ctx) => {
      if (value < minValue) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message: belowMinValueErrorMessage,
          fatal: true,
        });
      }
    })
    .superRefine((value, ctx) => {
      if (value > maxValue) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message: aboveMaxValueErrorMessage,
          fatal: true,
        });
      }
    });

export const getOptionalInputValue = (
  minValue: number,
  belowMinValueErrorMessage: string,
  maxValue: number,
  aboveMaxValueErrorMessage: string,
) =>
  z
    .number()
    .superRefine((value) => {
      if (value === 0) {
        return undefined;
      }
    })
    .superRefine((value, ctx) => {
      if (!value) {
        return undefined;
      }
      value < minValue &&
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message: belowMinValueErrorMessage,
          fatal: true,
        });
    })
    .superRefine((value, ctx) => {
      if (!value) {
        return undefined;
      }
      value > maxValue &&
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message: aboveMaxValueErrorMessage,
          fatal: true,
        });
    })
    .optional();

export const inputValueOnChangeValidator = (s: string): boolean => twoDecimalPlacesValidCommaOrPointDelimitedFloat.test(s);

const inputValueToInputTransform = <TFieldValue>(val: TFieldValue) => (val ? String(val) : '');
const inputValueAsFloat = (val: string) => parseFloat(val.replace(',', '.'));

export const requiredInputValueTransform: ControlledInputTransform<number> = {
  toInput: inputValueToInputTransform,
  toOutput: (val) => {
    const asFloat = inputValueAsFloat(val);
    return isNaN(asFloat) ? 0 : asFloat;
  },
};

export const optionalInputValueTransform: ControlledInputTransform<number | undefined> = {
  toInput: inputValueToInputTransform,
  toOutput: (val) => {
    const asFloat = inputValueAsFloat(val);
    return isNaN(asFloat) ? undefined : asFloat;
  },
};
