import { useState, useEffect } from 'react';
import { useParams } from 'react-router';

import { Shipment } from 'models/shipment';
import { shipmentsService } from 'services/shipmentsService';
import { documentsService } from 'services/documentsService';
import { SensorReadingHistoricalItem } from 'models/sensorData';
// import { User } from 'models/user';
// import { permissionsService } from 'services/permissionsService';

import { useAccessToken, useAppNavigation, useServerError } from 'hooks';

import { s } from 'i18n/strings';

import { ContextHeader } from 'components/headers';
import { SecondaryButton } from 'components/buttons';
import { Spinner } from 'components/Spinner';
import { Notification } from 'components/Notification';
import { containerService } from 'services/containerService';
import {
  alignItems,
  backgroundColor,
  borderColor,
  borderRadius,
  borderWidth,
  display,
  flexDirection,
  fontWeight,
  gridRowStart,
  height,
  justifyContent,
  objectFit,
  overflow,
  padding,
  typedUtilityClassnames,
  width,
} from 'style/compoundClassnames';
import { Backdrop, Box, IconButton } from '@mui/material';
import ViewIcon from '@mui/icons-material/FitScreenRounded';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import { Map } from 'components/Map';
import { formatDate } from 'utilities';

type DocumentInfo = { url: string; name: string; id?: number };

const formContentContainerClassNames = typedUtilityClassnames(
  'mainLayoutPadding',
  display('flex'),
  justifyContent('justify-center'),
  alignItems('items-center'),
  flexDirection('flex-col'),
  padding('pb-12'),
);

const imageSelectionAreaClassNames = typedUtilityClassnames(
  width('w-1/4'),
  display('flex'),
  justifyContent('justify-center'),
  backgroundColor('bg-overlayBlack-hover'),
  height('h-40'),
  borderRadius('rounded'),
  alignItems('items-center'),
  overflow('overflow-hidden'),
  gridRowStart('row-start-2'),
  padding('px-8'),
);
const imageThumbnailClassNames = typedUtilityClassnames(objectFit('object-contain'), height('h-full'), width('w-full'));

const getOverlayDownloadButtonStyles = (readOnly: boolean) => {
  return {
    position: 'absolute',
    top: '50%',
    left: readOnly ? '50%' : '60%',
    transform: 'translate(-50%, -50%)',
    color: '#e9eaee',
  };
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const CustomTooltip = ({ active, payload }: any) => {
  if (active) {
    return (
      <div
        className={typedUtilityClassnames(
          backgroundColor('bg-white'),
          borderWidth('border'),
          borderColor('border-grey-700'),
          borderRadius('rounded-lg'),
          padding('px-2', 'py-1'),
        )}
      >
        <p className={typedUtilityClassnames(display('flex'), flexDirection('flex-col'))}>
          <span className={typedUtilityClassnames(fontWeight('font-bold'))}>Location: {payload[0].payload.location}</span>
          {/* <span>Timestamp: {formatDate.getDateAndTime(new Date(payload[0].payload.timestamp))}</span> */}
          <span>Vibration: {payload[0]?.value} Hz</span>
          <span>Temperature: {payload[1]?.value} °C</span>
          <span>Light: {payload[2]?.value} lx</span>
          <span>Movement: {payload[3]?.value} m</span>
        </p>
      </div>
    );
  }

  return null;
};

export const IoTDeviceInformationPage = (): JSX.Element => {
  const { shipmentId } = useParams<{ shipmentId: string }>();
  const accessToken = useAccessToken();
  // const user = useLoggedInUser();

  const { navigateShipmentProcessing, navigateShipments } = useAppNavigation();
  const { handleServerError } = useServerError();

  const [shipment, setShipment] = useState<Shipment | null>(null);
  const [documentInfo, setDocumentInfo] = useState<DocumentInfo | null>(null);

  const [historicalData, setHistoricalData] = useState<SensorReadingHistoricalItem[] | null>(null);

  const [isLoading, setIsLoading] = useState(true);
  // const [isSavingForm, setIsSavingForm] = useState(false);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    (async () => {
      try {
        setError(null);
        setIsLoading(true);
        // TODO: factor out accessToken null checking into a base API class to run at every call, then remove access token null checking from all components
        if (shipmentId && accessToken) {
          const shipmentRequest = shipmentsService.getById(accessToken, parseInt(shipmentId));
          const containerRequest = shipmentsService.getContainer(accessToken, parseInt(shipmentId));
          const currentSensorDataRequest = shipmentsService.getSensorData(accessToken, parseInt(shipmentId));
          const historicalSensorDataRequest = shipmentsService.getHistoricalSensorData(accessToken, parseInt(shipmentId));

          const [shipment, container, currentSensorData, historicalSensorData] = await Promise.all([
            shipmentRequest,
            containerRequest,
            currentSensorDataRequest,
            historicalSensorDataRequest,
          ]);

          setShipment(shipment);

          if (historicalSensorData) {
            historicalSensorData.forEach((h) => {
              switch (currentSensorData.location) {
                case 'Production Site':
                case 'Load Port Storage Location':
                  h.timestamp = formatDate.getTime(new Date(h.timestamp));
                  break;
                case 'Ocean Freight':
                case 'Discharge Port Storage Location':
                  h.timestamp = formatDate.getDate(new Date(h.timestamp));
              }

              h.vibration = Number(h.vibration.toFixed(0));
              h.temperature = Number(h.temperature.toFixed(1));
              h.light = Number(h.light.toFixed(0));
              h.movement = Number(h.movement.toFixed(2));
            });
            setHistoricalData(historicalSensorData);
          }

          const containerDocuments = await containerService.getDocuments(accessToken, container.id);
          const iotDevicePhoto = containerDocuments.filter((doc) => doc.documentType.name === 'Photo of TagBox')[0];
          if (accessToken && iotDevicePhoto.id) {
            const documentBlob = await documentsService.download(accessToken, iotDevicePhoto.id);
            setDocumentInfo({ url: URL.createObjectURL(documentBlob), name: iotDevicePhoto.filename, id: iotDevicePhoto.id });
          }
        }
      } catch (error) {
        const errorMessage = handleServerError(error);
        setError(`${s.FreightForwardingInformationPage_GetContainerDetailsError}: ${errorMessage}`);
      } finally {
        setIsLoading(false);
      }
    })();
  }, [accessToken, handleServerError, shipmentId]);

  const handleCancel = () => {
    shipmentId ? navigateShipmentProcessing(shipmentId) : navigateShipments();
  };

  const [openPhotoView, setOpenPhotoView] = useState(false);
  const handleOpenPhotoView = () => setOpenPhotoView(true);
  const handleClosePhotoView = () => setOpenPhotoView(false);

  // const canEdit = canEditFreightForwarderForm(user, isSavingForm);

  return (
    <>
      {isLoading ? (
        <Spinner />
      ) : (
        <>
          <ContextHeader
            contextTitle={s.IoTDeviceInformationPage_Title}
            contextSubTitle={shipment?.shipmentReference}
            leftButtonOne={<SecondaryButton label={s.IoTDeviceInformationPage_CancelButton} onClick={handleCancel} />}
            // rightButtonOne={
            //   <PrimaryButton
            //     label={s.IoTDeviceInformationPage_SaveButton}
            //     type={buttonTypes.submit}
            //     // form={freightForwarderFormId}
            //     // disabled={!canEdit || !isDirty}
            //   />
            // }
            showContextHeaderContentSpacer
          />
          <Notification message={error!} severity="error" onClose={() => setError(null)} />

          <section className={formContentContainerClassNames}>
            <label className={typedUtilityClassnames('label', padding('pb-2'))} htmlFor="iotPhoto">
              IoT Device Photo
            </label>
            <div className={imageSelectionAreaClassNames} id="iotPhoto">
              {documentInfo?.url && (
                <Box sx={{ position: 'relative' }}>
                  <img className={imageThumbnailClassNames} src={documentInfo.url} alt="" />
                  <IconButton title={s.PhotoInput_ViewButtonTitle} onClick={handleOpenPhotoView} sx={getOverlayDownloadButtonStyles(true)}>
                    <Box
                      sx={{
                        backgroundColor: 'rgb(0 0 0 / 0.6)',
                        '&:hover': {
                          backgroundColor: 'rgb(0 0 0 / 0.38)',
                        },
                        borderRadius: 8,
                        height: 'auto',
                        width: 'auto',
                      }}
                    >
                      <ViewIcon fontSize="large" sx={{ mt: '-5px', padding: '2px' }} />
                    </Box>
                  </IconButton>
                </Box>
              )}
            </div>
          </section>

          {historicalData && (
            <>
              <label className={typedUtilityClassnames('label', padding('pl-32'))} htmlFor="sensors">
                IoT Sensor Readings History
              </label>
              <ResponsiveContainer height={300} id="sensors">
                <LineChart
                  data={historicalData}
                  margin={{
                    top: 5,
                    right: 150,
                    left: 100,
                    bottom: 5,
                  }}
                >
                  <CartesianGrid strokeDasharray="3 3" />
                  <XAxis dataKey="timestamp" />
                  <YAxis />
                  <Tooltip content={<CustomTooltip />} />
                  <Legend />
                  <Line type="monotone" dataKey="vibration" stroke="red" />
                  <Line type="monotone" dataKey="temperature" stroke="green" />
                  <Line type="monotone" dataKey="light" stroke="blue" />
                  <Line type="monotone" dataKey="movement" stroke="orange" />
                </LineChart>
              </ResponsiveContainer>
            </>
          )}

          <div className={typedUtilityClassnames(display('flex'), justifyContent('justify-center'))}>
            <Map />
          </div>

          <Backdrop open={openPhotoView} onClick={handleClosePhotoView}>
            <Box
              component="img"
              src={documentInfo?.url}
              alt="preview"
              sx={{ display: 'flex', justifyContent: 'center', maxHeight: '90vh' }}
            />
          </Backdrop>
        </>
      )}
    </>
  );
};
